import { ListingModel } from "../models/listing.model";
import { Contact } from '../models/contact.model';

export type ContactInfo = {
    contact: Contact[],
    hasContact: boolean
}

export class ContactHelper {
    getContactExistsByType(type: string, listing: ListingModel): boolean {
        const contacts = this.getContactByType(type, listing);
        return contacts?.length > 0;
    }
    
    getContactByType(type: string, listing: ListingModel): Contact[] {
        const filteredContacts = listing?.Contacts?.filter(contact => contact.type === type);
        return filteredContacts;
    }

    getContactInfo(listing: ListingModel, type: string): ContactInfo {
        return {
            contact: this.getContactByType(type, listing),
            hasContact: this.getContactExistsByType(type, listing)
        };
    }
}
