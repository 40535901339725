import { ElementRef, Injectable, QueryList, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadBannerScript(url: string) {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    this.renderer.appendChild(document.body, script);
  }

  initMutationObservers(observers: MutationObserver[], bannerElements: QueryList<ElementRef>) {
    const config = { childList: true, subtree: true };

    bannerElements.forEach(bannerElement => {
      const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach((node) => {
              if (node.nodeName === 'INS') {
                const element = node as HTMLElement;
                let banner = element.querySelector("iframe");
                // if TOP layout with button
                if (!banner && !element.querySelector("a > img + .outline-btn") && !element.querySelector(".card.card--top-banner")) {
                  banner = element.querySelector("a > img");
                }

                this.resizeBanner(bannerElement, banner);
              }
            });
          }
        }
      });

      const targetNode = bannerElement.nativeElement;
      observer.observe(targetNode, config);
      observers.push(observer);
    });
  }

  resizeBanner(parentElement: ElementRef, banner: Node) {
    if (banner === null) {
      return;
    }

    const PADDING: number = 8;
    const BORDER: number = 1;

    const parent: HTMLElement = parentElement.nativeElement;
    const bannerHTML = banner as HTMLElement;

    bannerHTML.style.position = "absolute";
    bannerHTML.style.top = PADDING + "px";
    bannerHTML.style.left = PADDING + "px";
    
    const topWidth: number = parent.clientWidth - 2 * (PADDING + BORDER);
    const topHeight: number = parent.clientHeight - 2 * (PADDING + BORDER);
    const adWidth: number = bannerHTML.clientWidth || 0;
    const adHeight: number = bannerHTML.clientHeight || 0;

    this.calculateRatio(topWidth, topHeight, adWidth, adHeight, bannerHTML);
  }

  calculateRatio(topWidth: number, topHeight: number, adWidth: number, adHeight: number, bannerHTML: HTMLElement) {
    if (adWidth > 0 && adWidth !== topWidth) {
      let ratio: number = topWidth / adWidth;
      if (topHeight < adHeight) {
          ratio = topHeight / adHeight;
      }

      bannerHTML.style.transform = `scale(${ratio})`;
      if (topWidth > bannerHTML.clientWidth * ratio) {
        bannerHTML.style.transform += " translateX(-50%)";
        bannerHTML.style.left = "50%";
      }

      bannerHTML.style.transformOrigin = "left top 0";
      bannerHTML.style.alignItems = "flex-start";
      bannerHTML.style.justifyContent = "flex-start";
      bannerHTML.style.overflow = "hidden";
    }
  }

  refreshRevive() {
    if (window.reviveAsync) {
      window.reviveAsync[environment.AdRevive.id].apply(window.reviveAsync[environment.AdRevive.id].detect())
    }
  }
}