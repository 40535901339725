<!--
    PARENT:
    ---------
    logo
    quote
    appointment
    trafficDescription
    LaB
    extra articles (not sure after changes)
    badges
    financial info
    
    CHILD:
    ---------
    title
    address
    oh
    website
    email
    CYB
    map
    reviews
    social links
    payment methods
    parking info

-->

<div class="md:grid grid-cols-1 md:gap-4 md:grid-cols-12">
    <!-- left column -->
    <div id="left-column" class="col-span-4 flex flex-col relative">
        <div id="listing-info" class="listing-info bg-white sticky-box">
            <div class="listing-info__inner">

                <div class="md:border border-gray-200 p-4 rounded-2 md:shadow-small text-sm">
                    <!-- left column content -->
                    <app-detail-info 
                        [listing]="listing" 
                        [childListing]="childListing"
                        [detailPageType]="detailPageType"
                        [requestForms]="requestForms" 
                        [pois]="pois"
                        [isChildListing]="isChildListing">
                    </app-detail-info>

                    <!-- folder specific - all addresses -->
                    <app-all-addresses 
                        [listing]="listing" 
                        [detailPageType]="detailPageType"
                        *ngIf="listing?.FolderAddresses.length > 0">
                    </app-all-addresses>

                    <!-- buttons -->
                    <app-detail-actions 
                        [listing]="listing" 
                        [childListing]="childListing" 
                        [pois]="pois"
                        [requestForms]="requestForms" 
                        [detailPageType]="detailPageType"
                        [isChildListing]="isChildListing">
                    </app-detail-actions>

                </div>

                <!-- USP -->
                <app-usp 
                    [listing]="listing">
                </app-usp>

                <!-- financial info -->
                <app-financial-info 
                    [listing]="listing"
                    [detailPageType]="detailPageType"
                    [wrap]="true">
                </app-financial-info>

            </div>
        </div>
    </div>

    <!-- Right column -->
    <section id="right-column" class="col-span-8 lg:pl-28 mt-10 md:mt-0">

        <!-- common for all -->
        <app-special-offer 
            [listing]="listing">
        </app-special-offer>
        <!-- /common for all -->

        <!-- could be different per detail type -->
        <!-- show only in case: isPaid == false && (IsClaimed() == true || IsManageable() == true) -->
        <app-traffic-description 
            *ngIf="showTrafficDescription" 
            [listing]="listing"
            [childListing]="childListing"
            [isChildListing]="isChildListing">
        </app-traffic-description>
        <!-- show only in case -->

        <app-labels-and-brands [listing]="listing">
        </app-labels-and-brands>
        <app-oh-and-map 
            [listing]="childListing" 
            [pois]="pois" 
            [detailPageType]="detailPageType">
        </app-oh-and-map>
        <app-extra-articles 
            [listing]="listing"
            [childListing]="childListing"
            [detailPageType]="detailPageType">
        </app-extra-articles>
        <app-video [listing]="listing">
        </app-video>

        <!-- reviews component here -->
        <app-review-display 
            [listing]="childListing">
        </app-review-display>
        

        <app-badges [listing]="listing">
        </app-badges>
        <app-social-links [listing]="childListing"></app-social-links>
        <app-payment-methods [listing]="childListing"></app-payment-methods>
        <!-- /could be different per detail type -->

        <!-- common for all -->
        <app-spoken-languages [listing]="listing"></app-spoken-languages>
        <app-extended-categories [listing]="listing"></app-extended-categories>

        <app-parking-info
            [listing]="childListing"
            [wrap]="true">
        </app-parking-info>
        <!-- /common for all -->

    </section>

</div>