import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListingImage } from '../../../../models/listing-image.model';
import { Badge } from '../../../../services/share-data.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss'
})
export class LogoComponent implements OnInit {
  @Input('listingId')
  listingId: string;

  @Input('logo')
  logo: ListingImage;

  @Input('badges')
  badges: Badge[] = [];

  @Input()
  isPaid: boolean = false;

  @Input()
  showClaimLinkAll: boolean;

  @Input()
  trafficExtraContent: boolean = false;

  @Input()
  isTrafficPage: boolean = false;

  currentLang: string;
  hasLogo: boolean = false;
  logoPath: string;
  
  constructor(private translate: TranslateService){}

  ngOnInit() {
    this.hasLogo = !!this.logo && this.isPaid && this.trafficExtraContent;
 
    if (this.hasLogo) {
      this.logoPath = this.logo?.path;
    }

    this.currentLang = this.translate.currentLang;
  }
}
