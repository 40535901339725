<ng-container *ngIf="checkDeviceService.isMobile$ | async; else dekstopBannerUnderResults">
    <div class="leaderboard-banner">
        <ins
            *ngIf="searchResults$ | async as searchResults" 
            [attr.data-revive-zoneid]="('banners.underResultsMobile' | translate)" 
            [attr.data-revive-code]="searchResults?.data?.advertsCodeInfo?.adKey"
            [attr.data-revive-target]="'_blank'"
            [attr.data-revive-block]="1"
            [attr.data-revive-id]="adId">
        </ins>
    </div>
</ng-container>
<ng-template #dekstopBannerUnderResults>
    <div class="leaderboard-banner">
        <ins 
            *ngIf="searchResults$ | async as searchResults"
            [attr.data-revive-zoneid]="('banners.underResults' | translate)" 
            [attr.data-revive-code]="searchResults?.data?.advertsCodeInfo?.adKey"
            [attr.data-revive-target]="'_blank'"
            [attr.data-revive-block]="1"
            [attr.data-revive-id]="adId">
        </ins>
    </div>
</ng-template>