import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ModalService } from '../../../../components/modal/modal.service';
import { AddressHelper } from '../../../../helpers/address.helper';
import { ListingModel } from '../../../../models/listing.model';
import { DetailPageType, Poi, ShareDataService } from '../../../../services/share-data.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss'
})
export class AddressComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('detailPageType')
  detailPageType: DetailPageType

  @Input()
  pois: Poi[] = [];

  address: string;

  modalOpt = {
    classes: [
      'modal-inner', 
      'yp-container--2xl', 
      'h-full', 
      'md\:h-80', 
      'p-0'
    ],
    size: {
      width: '100%',
    },
  };

  constructor(private modalService: ModalService,
    public shareDataService: ShareDataService,
    private addressHelper: AddressHelper) {}

  ngOnInit(): void {
    this.address = this.addressHelper.getAddress(this.listing);
  }

  openModalTemplate(view: TemplateRef<Element>) {
    this.modalService.open(view, this.modalOpt);
  }

  close() {
    this.modalService.close();
  }
}
