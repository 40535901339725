<app-financial-info-wrapper 
    *ngIf="disableFinancialInfo === false && listing?.EconomicalData !== null"
    [wrap]="wrap">
    <section id="financial-info" 
        [ngClass]="{'border-t mb-10 mt-4': detailPageType === 'free'}">

        <!-- if page is not free -->
        <ng-container *ngIf="detailPageType !== 'free'; else elseFreePage">
            <div class="font-medium mb-4 text-4.5 flex items-center gap-2">
                <h2 class="text-4.5">
                    <span>
                        {{ 'CompanyInfoPaid' | translate }}
                    </span>
                </h2>

                <app-claim-button
                    [listing]="listing"
                    [customClass]="'self-start w-4 h-6 text-blue-350'">
                </app-claim-button>

            </div>
        </ng-container>
        
        <!-- if page is free -->
        <ng-template #elseFreePage>
            <h2 class="font-medium mb-4 pt-8 text-1xl">
                <span>
                    {{ 'CompanyInfoFree' | translate }}
                </span>
            </h2>
        </ng-template>

        <!-- financial info -->
        <ul class="text-sm">

            <li class="block mb-2" *ngIf="isVatNumber">
                <span class="font-semibold">{{ 'CompanyNumber' | translate }}: </span> {{ vatNumber }}
            </li>

            <li class="block mb-2" *ngIf="isRegistrationDate">
                <span class="font-semibold">{{ 'CreationDate' | translate}}: </span> {{ registrationDate }}
            </li>

            <li class="block mb-2" *ngIf="isPersonnel">
                <span class="font-semibold">{{ 'NumberEmployees' | translate }}: </span> {{ personnel }}
            </li>

            <li class="block mb-2" *ngIf="isStatus">
                <span class="font-semibold">{{ 'Status' | translate }}: </span> {{ status }}
            </li>
    
            <!--  KBO -->
            <li class="block mb-2" *ngIf="isVatNumber">
                <a href="https://kbopub.economie.fgov.be/kbopub/toonondernemingps.html?lang=nl&ondernemingsnummer={{vatNumber.substring(2)}}"
                    class="hover:no-underline text-blue-200 underline cursor-pointer" target="_blank">
                    {{ 'wq_LinkToKBO' | translate }}
                </a>
            </li>
            <!-- } -->
        </ul>

    </section>
</app-financial-info-wrapper>