import { Component, Input, OnInit } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { SpecialActions } from '../../../../enums/special-actions.enum';
import { ParkingInfo, ParkingInfoHelper } from '../../../../helpers/parking-info.helper';
import { SpecialActionsHelper } from '../../../../helpers/special-actions.helper';
import { ListingModel } from '../../../../models/listing.model';
import { ShareDataService } from '../../../../services/share-data.service';

@Component({
  selector: 'app-parking-info',
  templateUrl: './parking-info.component.html',
  styleUrl: './parking-info.component.scss'
})
export class ParkingInfoComponent implements OnInit {
  parkingInfo$: Observable<ParkingInfo>;

  @Input('listing')
  listing: ListingModel;

  @Input('wrap')
  wrap: boolean = false;

  lat: number;
  lng: number;

  disableParkingInfo: boolean = false;

  constructor(
    private shareDataService: ShareDataService,
    private specialActionsHelper: SpecialActionsHelper,
    private parkingInfoHelper: ParkingInfoHelper
  ) { }

  ngOnInit() {
    this.lat = this.listing?.Latitude;
    this.lng = this.listing?.Longitude;
    
    this.parkingInfo$ = this.shareDataService.fetchParkingInfo(this.lat, this.lng).pipe(
      switchMap((data) => {
        const parkingInfo: ParkingInfo = {
          type: null,
          maxStay: null,
          tarif: null,
          hours: null,
        };
        const parkingData = data?.rules;

        if (parkingData) {
          parkingInfo.type = this.parkingInfoHelper.translateType(data?.rules?.type);
          parkingInfo.maxStay = this.parkingInfoHelper.getMaxStay(data?.rules?.maxStay);
          parkingInfo.tarif = this.parkingInfoHelper.getTarif(data?.rules?.prices);
          parkingInfo.hours = this.parkingInfoHelper.getHours(data?.rules?.hours);
        }

        return of(parkingInfo);
      }),
    );

    this.disableParkingInfo = this.specialActionsHelper.hasAction(this.listing, SpecialActions.DisableParkingInfo);
  }  
}
