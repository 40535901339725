<ng-container *ngIf="all$ | async">
    <ng-container *ngIf="displayedArticles.length > 0">
        <section id="extra-articles" class="border-t mb-10 mt-4">
            <h2 class="font-medium mb-4 pt-8 text-1xl">
                {{ 'Articles' | translate }}
            </h2>

            <article class="extra-article" *ngFor="let article of displayedArticles"
                [attr.data-article-type]="article.articleType" 
                [attr.data-listing-id]=""
                [attr.data-article-id]="article.id">
                <a class="grid md:grid-cols-3 grid-cols-1 p-4 border rounded md:gap-4 md:gap-y-0 gap-y-4 my-4"
                    [attr.href]="article.customUrl" target="_blank" rel="nofollow"
                    [attr.target]="article?.articleType === 3 ? '_blank' : null"
                    [attr.rel]="article?.articleType === 3 ? 'nofollow' : null">
                    <div class="extra-article__image h-44 pointer-events-none"
                        [ngStyle]="{ 'background-image': article?.thumbnailPath ? 'url(' + article?.thumbnailPath + ')' : 'none' }">
                    </div>
                    <ul class="col-span-2 overflow-hidden pointer-events-none">

                        <li *ngIf="listing?.IsPaid === false && listing?.IsTrafficPage === false">
                            <ul class="flex gap-2">
                                <li
                                    class="text-blue-200 border border-blue-300 rounded inline-block px-2 py-0.5 mb-2 text-11">
                                    {{ article?.headings?.[0] }}
                                </li>
                                <!-- display listing title if it's free listing and article is sponsor/advertorial -->
                                <li class="text-blue-200 border border-blue-300 rounded inline-block px-2 py-0.5 mb-2 text-11"
                                    *ngIf="article?.showSponsor">
                                    {{ 'BroughtBy' | translate }} {{ article?.listingSponsorName }}
                                </li>
                            </ul>
                        </li>

                        <li class="mb-1 font-semibold text-base">
                            {{ article?.title }}
                        </li>

                        <li class="text-gray-400 text-sm overflow-hidden h-20 md:block hidden"
                            [innerHTML]="article?.thumbnailText">
                        </li>
                    </ul>
                </a>
            </article>

            <div class="load-more cursor-pointer" 
                *ngIf="remainingArticles.length > 0" 
                (click)="loadMoreArticles();">
                <span class="flex flex-col items-center text-blue-200">
                    <span class="mb-1">{{ 'LoadMoreArticles' | translate }}</span>
                    <app-icon [icon]="'arrow-down-icon'" svgClass="w-3 h-2"></app-icon>
                </span>
            </div>
        </section>
    </ng-container>
</ng-container>