export const environment = {
    EnvironmentName: "PROD",
    MFE: {
        AYB: {
            name: 'listing-form',
            path: '//mfe-listing.fcrmedia.com/listing-form.js'
        },
        Quote: {
            name: 'quote-form',
            path: '//mfe-quote-form.fcrmedia.com/quote-form.js'
        },
        Appointment: {
            name: 'appointment-form',
            path: '//mfe-appointment-form.fcrmedia.com/appointment-form.js'
        },
        RatingReviewForm: {
            name: 'rating-review-form',
            path: '//mfe-reviews-form.fcrmedia.com/rating-review-form.js'
        },
        RatingReviewDisplay: {
            name: 'ratings-reviews',
            path: '//mfe-reviews-component.fcrmedia.com/ratings-reviews.js'
        }
    },
    AdRevive: {
        url: "https://adserver.fcrmedia.com/www/delivery/asyncjs.php",
        id: "9977be5c12e06f84a46e262aa2b05ada"
    },
    Plausible: {
        nl: {
            domain: "goudengids.be",
            apiHost: "https://analytics.fcrtech.cz"
        },
        fr: {
            domain: "pagesdor.be",
            apiHost: "https://analytics.fcrtech.cz"
        },
        en: {
            domain: "goldenpages.be",
            apiHost: "https://analytics.fcrtech.cz"
        }
    },
    GoogleAnalytics: {
        nl: "G-T313MLH3NV",
        fr: "G-MZY18WNGWS",
        en: "G-PNH7EW4LHS"
    },
    GoogleTagManager: {
        id: "GTM-5R59LKB"
    },
    CookieScript: {
        url: "https://cdn.cookie-script.com/s/9dd9858124308bd0560317a26f50699d.js",
        reportUrl: "https://report.cookie-script.com/r/9dd9858124308bd0560317a26f50699d.js"
    },
    Clarity: {
        nl: "mdel0j2fpk",
        fr: "l8d4dujv6n",
        en: "l8d4dujv6n"
    },
    Sentry: {
        isEnabled: true,
        dsn: "https://bb3fa198ce0627573245f4e10afbb1cf@sentry.fcrmedia.com/54",
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    },
    Debug: {
        password: "Yellow.FCR.2021"
    },
    GoogleMap: {
        apiKey: "AIzaSyCNleFeleNZsyC1B0QI1jVyD_FMgPC7Qvc"
    }
};