import { Component, Input } from '@angular/core';
import { ListingModel } from '../../../../models/listing.model';
import { YextProgram } from '../../../../enums/yext-program.enum';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-special-offer',
  templateUrl: './special-offer.component.html',
  styleUrl: './special-offer.component.scss'
})
export class SpecialOfferComponent {
  @Input()
  listing: ListingModel;

  YextProgramEnum = YextProgram;

  displaySpecialOffer: boolean = false;

  constructor(private utils: Utils) {}

  ngOnInit() {
    this.displaySpecialOffer = this.hasSpecialOffer();
  }

  hasSpecialOffer() {
    const hasYextProgram = this.listing?.YextProgram != this.YextProgramEnum.None;
    const hasMessage = !this.utils.isNullOrEmpty(this.listing?.SpecialOfferMessage);
    const hasUrl = !this.utils.isNullOrEmpty(this.listing?.SpecialOfferUrl)
    const isTrafficPage = this.listing?.IsTrafficPage;

    return hasYextProgram && isTrafficPage && hasMessage && hasUrl;
  }
}
