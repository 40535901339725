import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ListingModel } from '../../../../models/listing.model';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrl: './video.component.scss'
})
export class VideoComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  hasVideo: boolean = false;
  isIframe: boolean = false;
  iframeHtml: SafeHtml;

  constructor(
    private utils: Utils,
    private sanitizer: DomSanitizer){}

  ngOnInit() {
    this.hasVideo = !this.utils.isNullOrEmpty(this.listing?.VideoEmbedCode);
    if (this.hasVideo) {
      this.isIframe = this.listing?.VideoEmbedCode.length > 20 && this.listing?.VideoEmbedCode.includes('iframe');

      if (this.isIframe) {
        this.iframeHtml = this.sanitizer.bypassSecurityTrustHtml(this.listing?.VideoEmbedCode);
      }
    }
  }
}
