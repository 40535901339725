import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlHelper } from '../../helpers/url-builder.helper';
import { map } from 'leaflet';
import { tap } from 'rxjs';
import { ShareDataService } from '../../services/share-data.service';
import { SearchArguments } from '../../models/search-arguments.model';

@Component({
  selector: 'app-heading-box',
  templateUrl: './heading-box.component.html',
  styleUrl: './heading-box.component.scss'
})
export class HeadingBoxComponent implements OnInit {
  @Input("headingTitle")
  headingTitle: string = "";

  @Input("headingIcon")
  headingIcon: string = "";

  title: string;
  items: string[] = [];

  constructor(
    private translate: TranslateService,
    private shareDataService: ShareDataService,
    private urlHelper: UrlHelper){}

  ngOnInit(): void {
    this.translate.get("HP_Headings."+this.headingTitle+".title").subscribe(x => {
      this.title = x;
    });
    
    for (let index = 1; index < 6; index++) {
      this.translate.get("HP_Headings."+this.headingTitle+"."+index).subscribe(x => {
        this.items.push(x)
      });        
    }
  }

  getUrl(what: string): string {
    let res = this.urlHelper.getSearchPageRoute(this.translate.instant("Routes.Search.NormalizedWhatWhereSearch"), what);
    if (res) {
      return res;
    }
    return "";
  }
  
}
