import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from '../../../../components/modal/modal.service';
import { ListingAddress } from '../../../../models/listing-address.model';
import { ListingModel } from '../../../../models/listing.model';
import { EventService } from '../../../../services/event.service';
import { DetailPageType, Poi, ShareDataService } from '../../../../services/share-data.service';

@Component({
  selector: 'app-all-addresses',
  templateUrl: './all-addresses.component.html',
  styleUrl: './all-addresses.component.scss'
})
export class AllAddressesComponent implements OnInit {
  @Input() 
  listing: ListingModel;

  @Input('detailPageType')
  detailPageType: DetailPageType;
  
  folderTitle: string;
  addresses: ListingAddress[] = [];

  addressCount: number;

  pois: Poi[];

  // modal
  @ViewChild('folderModal')
  folderModal: TemplateRef<Element>;

  constructor(
    private shareDataService: ShareDataService,
    private modalService: ModalService,
    private eventService: EventService) {}

  ngOnInit() {
    // handle map close event
    document.addEventListener('closeMapControlClick', this.close.bind(this));

    this.folderTitle = this.listing?.Title;
    this.addresses = this.listing?.FolderAddresses;
    this.addressCount = this.addresses.length;
    this.pois = this.shareDataService.getFolderPois(this.addresses);
  }

  openModalTemplate(view: TemplateRef<Element>) {
    this.modalService.open(view, {
      classes: [
        'modal-inner',
        'yp-container--2xl',
        'h-full',
        'md\:h-80',
        'p-0'
      ],
      size: {
        width: '100%',
      },
    });
  }

  close() {
    this.modalService.close();
  }

  handleSelectChild(childListingId) {
    this.eventService.handleSelectChild.emit(childListingId);
  }

}
