import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListingModel } from '../../models/listing.model';
import { ClaimYourBusinessService } from '../../services/claim-your-business.service';
import { ShareDataService } from '../../services/share-data.service';

@Component({
  selector: 'app-claim-button',
  templateUrl: './claim-button.component.html',
  styleUrl: './claim-button.component.scss'
})
export class ClaimButtonComponent implements OnInit {
  @Input()
  listing: ListingModel;

  @Input('customClass')
  customClass: string = "";


  displayFreeClaimBadge: boolean = false;
  displayClaimBadge: boolean = false;

  constructor(private claimService: ClaimYourBusinessService) {}
    
  ngOnInit(): void {
    this.claimService.initialize(this.listing);
    this.displayFreeClaimBadge = this.claimService.displayFreeClaimBadge();
    this.displayClaimBadge = this.claimService.displayClaimBadge();
  }
}
