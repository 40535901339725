<div class="banner-boxes">
    <div class="grid md:grid-cols-4 grid-cols-1">
        <!-- mobile not tablet -->
        <div class="banner-boxes-holder mt-4"
            *ngIf="checkDeviceService.isMobile$ | async; else elseTOPDesktop">
            <div class="banner-box banner-box--top TOP-banner" #resizeBanner>
                <!-- mobile banner -->
                <ins 
                    [attr.data-revive-zoneid]="topBannerMobileId" 
                    [attr.data-revive-id]="adId"
                    [attr.data-revive-target]="'_blank'"
                    [attr.data-revive-block]="1"
                    [attr.data-revive-code]="adKey" 
                    *ngIf="searchResults$ | async">
                </ins>

            </div>
        </div>
        <ng-template #elseTOPDesktop>
            <div class="banner-boxes-holder col-span-3 bg-gray-300 col-span-3 mr-5 p-4 rounded-2 mt-10">
                <span class="bg-white border border-blue-300 inline-block mb-2 px-2 py-0.5">{{ 'Sponsored' | translate }}</span>
                <div class="grid grid-cols-3 gap-4">

                        <div class="col-md-4 col-sm-4 align-center flex"
                            *ngFor="let topBannerId of topBannerIds; let index = index">
                            <div class="banner-box banner-box--top TOP-banner min-h-44.75" #resizeBanner>

                                <ins 
                                    [attr.data-revive-zoneid]="topBannerIds[index]" 
                                    [attr.data-revive-id]="adId"
                                    [attr.data-revive-target]="'_blank'"
                                    [attr.data-revive-block]="1"
                                    [attr.data-revive-code]="adKey" 
                                    *ngIf="searchResults$ | async">
                                </ins>

                            </div>
                        </div>

                </div>
            </div>
            <div class="col-span-1 mt-10">
                
                <div class="banner-box banner-box--top iframe-wrap" #resizeBanner>
                    <!-- Revive Adserver Asynchronous JS Tag - Generated with Revive Adserver v4.1.4 -->
                    <ng-container *ngIf="checkDeviceService.isMobile$ | async; else elseUnderMapDesktop">

                        <ins 
                            [attr.data-revive-zoneid]="('banners.underMapMobile' | translate)" 
                            [attr.data-revive-id]="adId"
                            [attr.data-revive-target]="'_blank'"
                            [attr.data-revive-block]="1"
                            [attr.data-revive-code]="adKey" 
                            *ngIf="searchResults$ | async">
                        </ins>

                    </ng-container>
                    <ng-template #elseUnderMapDesktop>
                        
                        <ins 
                            [attr.data-revive-zoneid]="('banners.underMap' | translate)" 
                            [attr.data-revive-id]="adId"
                            [attr.data-revive-target]="'_blank'"
                            [attr.data-revive-block]="1"
                            [attr.data-revive-code]="adKey" 
                            *ngIf="searchResults$ | async">
                        </ins>
                        
                    </ng-template>
                </div>

            </div>
        </ng-template>
    </div>
</div>