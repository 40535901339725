import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ShortlistService } from '../../services/shortlist.service';
import { ListingModel } from '../../models/listing.model';
import { Observable } from 'rxjs';
import { LangSwitch } from '../../services/lang-switch.service';

@Component({
  selector: 'app-shortlist',
  templateUrl: './shortlist.component.html',
  styleUrl: './shortlist.component.scss'
})
export class ShortlistComponent implements OnInit {
  shortlistResult$: Observable<ListingModel[]>;

  constructor(private shortlist: ShortlistService, private langSwitch: LangSwitch) {}

  ngOnInit(): void {
    this.langSwitch.setShortlist()
    this.fetchShortlist();
  }

  fetchShortlist(): void {
    this.shortlistResult$ = this.shortlist.fetchShortlist();
  }
}
