import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-quote-form',
  templateUrl: './quote-form.component.html',
  styleUrl: './quote-form.component.scss'
})
export class QuoteFormComponent implements OnInit {
  listingId: string;
  locale: string = 'be_nl';

  constructor(
    public translate: TranslateService,
    private modalService: ModalService) {
  }

  ngOnInit(): void {
    //dummy call to wait for translations
    this.translate.get('init').subscribe((text: string) => {
      this.locale = `be_${this.translate.currentLang}`;
      if (this.translate.currentLang === 'en')
        this.locale = 'en'
      this.load();
    });
  }

  load(): void {
    this.listingId = this.modalService.getListingId();

    const configItem = environment.MFE.Quote;
    const content = document.getElementById('quote-form-inner');

    const script = document.createElement('script');
    script.src = configItem.path;
    script.onerror = () => console.error('error loading <span class="hljs-subst">', configItem.path, '</span>');
    content.appendChild(script);

    const element: HTMLElement = document.createElement(configItem.name);
    //element.addEventListener('message', msg => this.handleMessage(msg));
    // Set attributes
    element.setAttribute('id', 'quote-form')
    element.setAttribute('lid', this.listingId)
    element.setAttribute('locale', this.locale);
    content.appendChild(element);
  }

  closeFrom() {
    const event = new Event('closeForm');
    document.dispatchEvent(event);
  }
}
