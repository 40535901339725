import { Injectable } from "@angular/core";
import { ListingAddress } from "../models/listing-address.model";
import { ListingModel } from "../models/listing.model";

@Injectable({
    providedIn: 'root',
})
export class AddressHelper {

    public getAddress(listing: ListingModel) {
        let listingAddress = new ListingAddress();
        listingAddress.city = listing?.City;
        listingAddress.cityDistrict = listing?.CityDistrict;
        listingAddress.zip = listing?.Zip
        listingAddress.address = listing?.Address;

        return this.addressLineReversed(listingAddress, '<br/>')
    }

    // format address
    private addressLineReversed(listing: ListingAddress, divider: string): string {
        const city = this.getFullCityName(listing, listing?.cityDistrict === null);
        let res = "";

        // add postal code
        if(listing?.zip) {
            res += `<span data-yext='postal-code'>${listing.zip}</span>`;
        }

        // add city
        if (listing?.city) {
            if (listing?.zip) {
                res += " ";
            }
            res += `${city}`;
        }

        // add address
        if (listing?.address) {
            if (res !== "") {
                res += divider;
            }
            res += `<span data-yext='street'>${listing?.address}</span>`;
        }

        // trim whitespaces and resturn address
        return res.trim();
    }
  
    private getFullCityName(listing: ListingAddress, cityValueOnly: boolean = false): string {
        if (listing?.city === null) {
            return "";
        }

        if (cityValueOnly) {
            return listing?.city;
        }
  
        let cityFormat = `<span data-yext='city'>${listing?.city}</span>`;
  
        // Use city and city district if they both have values that are also different from each other
        if (listing?.cityDistrict?.trim() !== '' && listing?.city !== listing.cityDistrict) {
            cityFormat = `<span data-yext='city-district'>${listing?.cityDistrict}</span> (<span data-yext='city'>${listing?.city}</span>)`;
            return cityFormat;
        }
  
        return cityFormat;
    }

}