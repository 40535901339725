<ng-container *ngIf="disableParkingInfo === false">
    <ng-container *ngIf="lat && lng">
        <ng-container *ngIf="(parkingInfo$ | async) as parkingInfo">
            <app-parking-info-wrapper 
                *ngIf="parkingInfo.hours || parkingInfo.maxStay || parkingInfo.tarif || parkingInfo.type"
                [wrap]="wrap">
                <section 
                    id="parking-info" 
                    class="border-t mb-10 mt-4">
                    <h2 class="font-medium mb-4 pt-8 text-1xl">
                        {{ 'ParkingInfo' | translate }}
                    </h2>
                    <div class="flex flex-wrap">
                        <ul id="parking-info-list" class="block w-full mb-4 text-sm">
                            <li *ngIf="parkingInfo.type !== null" class="mb-2 flex justify-between"><span class="font-semibold">{{ 'ParkingInfoType' | translate }}:</span> {{ parkingInfo.type }}</li>
                            <li *ngIf="parkingInfo.hours !== null" class="mb-2 flex justify-between"><span class="font-semibold">{{ 'ParkingInfoTime' | translate }}:</span> {{ parkingInfo.hours }}</li>
                            <li *ngIf="parkingInfo.tarif !== null" class="mb-2 flex justify-between"><span class="font-semibold">{{ 'ParkingInfoTarif' | translate }}:</span> {{ parkingInfo.tarif }}</li>
                            <li *ngIf="parkingInfo.maxStay !== null" class="mb-2 flex justify-between"><span class="font-semibold">{{ 'ParkingInfoMax' | translate }}:</span> {{ parkingInfo.maxStay }}</li>
                        </ul>
                        <p style="font-size: 11px;text-align: right;margin-top: -5px;">Powered by <a href="//seety.co/nl/" target="_blank">Seety</a></p>
                    </div>
                </section>
            </app-parking-info-wrapper>
        </ng-container>
    </ng-container>
</ng-container>