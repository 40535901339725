<div class="flex items-baseline mb-4 md:mb-0 gap-4">
    <div class="relative">

        <app-icon [icon]="'share-icon'" svgClass="h-5 pt-0.5 self-start w-5 share-button cursor-pointer"
            (click)="handleOpen()"></app-icon>

        <ul class="border border-gray-200 mt-4 p-2 pb-0 right-0 rounded-2 share-menu z-10" style="width: 125px"
            [ngClass]="{'hidden': !isOpen}">

            <li>
                <a onclick="JavaScript:void(0);" class="flex items-center mb-2 cursor-pointer"
                    [trackingAction]="'SocialShareClick'" 
                    [trackingCategory]="'DETAIL'" 
                    data-sharer="email"
                    [attr.data-title]="title" 
                    [attr.data-subject]="subject" 
                    data-to=""
                    [attr.data-url]="detailUrl">
                    <img class="mr-2 p-1 rounded-2 w-6" style="background-color: #ddd;" src="assets/icons/shareIcons/email-icon_64x64.png" alt="Email" />
                    <span>Email</span>
                </a>
            </li>
            <li>
                <a onclick="JavaScript:void(0);" class="flex items-center mb-2 cursor-pointer"
                    [trackingAction]="'SocialShareClick'" 
                    [trackingCategory]="'DETAIL'" 
                    data-sharer="whatsapp"
                    [attr.data-title]="title" 
                    [attr.data-url]="detailUrl">
                    <img class="mr-2 p-1 rounded-2 w-6" style="background-color: #24d366;" src="assets/icons/shareIcons/whatsapp-icon_64x64.png" alt="Whatsapp" />
                    <span>Whatsapp</span>
                </a>
            </li>
            <li>
                <a onclick="JavaScript:void(0);" class="flex items-center mb-2 cursor-pointer"
                    [trackingAction]="'SocialShareClick'" 
                    [trackingCategory]="'DETAIL'" 
                    data-sharer="twitter"
                    [attr.data-title]="title" 
                    [attr.data-hashtags]="subject"
                    [attr.data-url]="detailUrl">
                    <img class="mr-2 p-1 rounded-2 w-6" style="background-color: #0f141a;" src="assets/icons/shareIcons/twitter-icon_64x64.png" alt="X (Twitter)" />
                    <span>X (Twitter)</span>
                </a>
            </li>
            <li>
                <a onclick="JavaScript:void(0);" class="flex items-center mb-2 cursor-pointer"
                    [trackingAction]="'SocialShareClick'" 
                    [trackingCategory]="'DETAIL'" 
                    data-sharer="facebook"
                    [attr.data-hashtags]="subject"
                    [attr.data-url]="detailUrl">
                    <img class="mr-2 p-1 rounded-2 w-6" style="background-color: #3b5997;" src="assets/icons/shareIcons/facebook-icon_64x64.png" alt="Facebook" />
                    <span>Facebook</span>
                </a>
            </li>
            <li *ngIf="checkDeviceService.isMobile$ | async">
                <a onclick="JavaScript:void(0);" class="flex items-center mb-2 cursor-pointer"
                    [trackingAction]="'SocialShareClick'" 
                    [trackingCategory]="'DETAIL'" 
                    data-sharer="messenger"
                    data-app_id="2553857251340355" 
                    [attr.data-url]="detailUrl" 
                    [attr.data-redirect_url]="detailUrl"
                    data-display="popup">
                    <img class="mr-2 p-1 rounded-2 w-6" style="background-color: #0083ff;" src="assets/icons/shareIcons/messenger-icon_64x64.png" alt="Facebook" />
                    <span>Messenger</span>
                </a>
            </li>

        </ul>

    </div>
</div>