<section id="GO__single-activity" class="mb-10" *ngIf="currentListing?.TrafficLevel >= 3">
    <h2 class="font-medium mb-4 text-1xl">
        {{ 'Activities' | translate }}
    </h2>
    <div class="text-gray-400 text-sm" *ngFor="let trafficDesc of trafficDescriptions; let i = index">
        <h3 class="font-medium mb-4 text-xl" *ngIf="i < trafficSubtitle.length">
            {{ trafficSubtitle[i] }}
        </h3>
        <div class="folder-article__content">
            <div class="folder-article__inner">
                <p *ngIf="trafficDescriptions?.length" [innerHTML]="trafficDescriptions[i]">
                </p>
            </div>
        </div>
    </div>

    <!-- 1 image -->
    <div *ngIf="trafficImages.length == 1" 
        class="gap-4 grid grid-cols-1 mt-6">
        <img [src]="trafficImages[0].path" alt=""
            (click)="handleOpenGallery(0)" />
    </div>
    <!-- 2 images -->
    <div *ngIf="trafficImages.length == 2" class="gap-4 grid md:grid-cols-2 grid-cols-1 mt-6">
        <span *ngFor="let img of trafficImages; let index = index">
            <img [src]="img.path"  
                alt="" 
                (click)="handleOpenGallery(index)"
                [ngClass]="{'transform scale-200 origin-center': index > 0}" />
        </span>
    </div>
    <!-- 3 images -->
    <div *ngIf="trafficImages.length == 3" class="gap-4 grid md:grid-cols-4 grid-cols-1 mt-6">
        <span *ngFor="let img of trafficImages; let index = index" 
            [ngClass]="{
                'md:col-span-2': index === 0,
                'overflow-hidden': index > 0
            }">
            <img [src]="img.path" alt=""
                (click)="handleOpenGallery(index)" />
        </span>
    </div>
    <!-- 4 images -->
    <div *ngIf="trafficImages.length == 4" class="gap-4 grid md:grid-cols-4 grid-cols-1 mt-6">
        <span *ngFor="let img of trafficImages; let index = index" class="overflow-hidden">
            <img [src]="img.path" alt="" 
                (click)="handleOpenGallery(index)" />
        </span>
    </div>
    <!-- 5 images or more -->
    <div *ngIf="trafficImages.length >= 5" class="gap-4 grid md:grid-cols-4 grid-cols-1 mt-6">
        <img *ngFor="let img of trafficImages; let index = index"
            [src]="img.path" 
            alt="" 
            [ngClass]="{
                'md:col-span-2 md:row-span-2': index == 0, 
                'md:row-span-1': index > 0
            }" 
            (click)="handleOpenGallery(index)" />
    </div>
</section>

<ng-template #galleryModal>
    <div id="galleryModal" class="galleryModal">
        <span class="close-gallery cursor" (click)="close()">&times;</span>
        <div class="modal-content">

            <div class="gallerySlides">
                <img [src]="trafficImages[slideIndex].path" class="w-full">
            </div>

            <!-- Next/previous controls -->
            <ng-container *ngIf="trafficImages.length > 1">
                <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
                <a class="next" (click)="plusSlides(1)">&#10095;</a>
            </ng-container>
        </div>
    </div>
</ng-template>