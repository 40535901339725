import { Injectable } from '@angular/core';
import { ListingModel } from '../models/listing.model';
import { SearchType } from '../models/search-arguments.model';
import { PlausibleTrackingService } from './plausible-tracking.service';
import { SearchStateService } from './search-state.service';
import { SearchResults } from './share-data.service';

declare global {
  interface Window {
    dataLayer: any
  }
}

export type TrackingCategory = 'HOME' | 'SEARCH' | 'DETAIL' | 'BLOG' | 'UNKNOWN';

export interface Ga4Object {
  Action: string,
  Category: string,
  LabelHash: number,
  Label: string,
  Label1: string,
  Label2: string,
  Label3: string,
  Label4: string
};

export interface Ga3Object {
  event: string,
  gaCategory: string,
  gaAction: string,
  gaLabel: string
};

export interface gaObjectValues {
  [key: string]: any
}

export interface GaObject extends Ga4Object, Ga3Object { };

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  constructor(
    private plausibleTrackingService: PlausibleTrackingService,
    private searchStateService: SearchStateService
  ) { }

  private createHashCode(eventLabel: string): number {
    let hash: number = 0;
    let i: number = 0;
    let chr: number = 0;
    const strLen: number = eventLabel.length;

    if (strLen === 0)
      return hash;

    for (; i < strLen; i++) {
      chr = eventLabel.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }

    return hash;
  };

  private splitStringByChunk(str: string, chunkSize: number = 100): string[] {
    let i: number = 0;
    let tempStr: string = "";
    let arr: string[] = [];
    const strLen: number = str.length;

    for (; i < strLen; i += chunkSize) {
      tempStr = str.substring(i, i + chunkSize);
      arr.push(tempStr);
    }

    return arr;
  };

  private handleLabelLimit(eventCategory: string, eventAction: string, eventLabel: string, trackingObj: Ga3Object): GaObject {
    var i = 0;
    var dataSplit = this.splitStringByChunk(eventLabel, 100);
    var dataSplitLen = dataSplit.length;
    var ga4Obj: Ga4Object = {
      Action: eventAction,
      Category: eventCategory,
      LabelHash: this.createHashCode(eventLabel),
      Label: eventLabel,
      Label1: "",
      Label2: "",
      Label3: "",
      Label4: ""
    };

    for (; i < dataSplitLen; i++)
      ga4Obj["Label" + (i + 1)] = dataSplit[i];

    return Object.assign(trackingObj, ga4Obj);
  };

  public trackEvent(eventCategory: string, eventAction: string, data?) {
    if (typeof data === "undefined") {
      data = {};
    }

    if (typeof data !== "string") {
      data = JSON.stringify(data);
    } else {
      data = JSON.stringify({"actionData": {"id": data }});
    }

    // console.log(data)

    var trackingObj: Ga3Object = {
      event: eventAction,
      gaCategory: eventCategory,
      gaAction: eventAction,
      gaLabel: data
    }

    var trackingGA4Obj: GaObject = this.handleLabelLimit(
      eventCategory,
      eventAction,
      data,
      trackingObj
    );

    // console.log({
    //   ta: eventAction,
    //   tc: eventCategory,
    //   tl: data
    // });

    // GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(trackingGA4Obj);

    // plausible
    this.plausibleTrackingService.trackCustomEvent(
      trackingGA4Obj.Action, {
      props: {
        Action: trackingGA4Obj.Action,
        Category: trackingGA4Obj.Category,
        Label: trackingGA4Obj.Label
      }
    });
  };

  public getResultsTrackingValues(listings: ListingModel[], numFound: number): gaObjectValues {
    const values = this.baseGetResultsTrackingValues(listings, numFound);

    let u = 0;
    // const beSearchArguments = searchResult.QueryArguments as BeSearchArguments;
    // if (beSearchArguments && beSearchArguments.IsLocalityFallback()) {
    //     u += 2;
    // }
    values["u"] = u;
    values["d"] = listings?.[0]?.Category?.[0];

    return values;
  }

  private baseGetResultsTrackingValues(listings: ListingModel[], numFound: number): gaObjectValues {
    const searchArgs = this.searchStateService.getCurrentValues();

    const ga: gaObjectValues = {
      "h": numFound || 0,
      "s": searchArgs.what,
      "w": searchArgs.where,
      "n": searchArgs.searchType === SearchType.Normalized ? 0 : 1,
      "p": searchArgs.page,
      "r": "rank",
      "o": parseInt(searchArgs.sort) || 0,
      "l": listings.map(l => l.Id).join('|'),
      "i": []
    };

    // if (searchResult.TotalCount > 0) {
    //     if (searchResult.Results.some(r => r.Fallback.Order > 0)) {
    //         const resultCounts = (this.trackingJsonGeneratorSettings.FallbackTrackingMode === FallbackTrackingMode.AllPositionalFallbacks)
    //             ? this.getAllPositionFallbackCounts(searchResult)
    //             : this.getNonEmptyNamedFallbackCounts(searchResult);
    //         ga["hf"] = resultCounts.join(",");
    //         const fallback = searchResult.Results[0].Fallback;
    //         ga["f"] = `${fallback.Order}|${fallback.InputParameters["expansion"] || "0"}`;
    //     }
    //     if (searchResult.DominantCategory) {
    //         ga["d"] = searchResult.DominantCategory;
    //     }
    //     ga["l"] = searchResult.ResultItems.map(r => r.Id).join("|");
    //     ga["i"] = [];
    // }

    return ga;
  }

  private getAllPositionFallbackCounts(searchResult: SearchResults): number[] {
    return [];
  }

  private getNonEmptyNamedFallbackCounts(searchResult: SearchResults): number[] {
    return [];
  }

}
