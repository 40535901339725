import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SearchArguments } from '../../../../models/search-arguments.model';
import { SearchStateService } from '../../../../services/search-state.service';
import { ShareDataService } from '../../../../services/share-data.service';
import { Utils } from '../../../../utils/utils';
import { MetaHelper } from '../../../../helpers/meta.helper';

@Component({
  selector: 'app-result-info',
  templateUrl: './result-info.component.html',
  styleUrl: './result-info.component.scss'
})
export class ResultInfoComponent implements OnInit {
  @Input('numFound')
  numFound: number = 0;

  resultInfo: string = "";
  searchArgs: SearchArguments;

  constructor(private shareDataService: ShareDataService,
    private translate: TranslateService,
    private searchStateService: SearchStateService,
    private utils: Utils,
    private metaHelper: MetaHelper) {}

  ngOnInit(): void {
    this.resultInfo = this.getResultInfo();
  };

  getResultInfo(): string {
    let infoLocation = null;
    let infoWhat = "-";

    this.searchArgs = this.searchStateService.getCurrentValues();
    infoLocation = this.searchArgs.where;
    infoWhat = this.searchArgs.what

    // if (this.data?.analyzedLocation?.city) {
    //   infoLocation = this.data?.analyzedLocation?.city;
    // }

    let infoText = this.translate.instant('ResultsInfoFull');
    
    if (this.searchArgs.geoLocation) {
      infoText = this.translate.instant('ResultsInfoAroundYou');
    }
    else if (this.utils.isNullOrEmpty(infoLocation)) {
      infoText = this.translate.instant('ResultsInfoNoLocation');
    }
    // TODO: do we need an option where no "what" and "where" are not specified?
    this.addMetaDescription(infoWhat, infoLocation, this.numFound)
    return infoText
      .replace("{count}", this.numFound)
      .replace("{what}", infoWhat)
      .replace("{location}", infoLocation);
  }

  addMetaDescription(what, where, totalCount) {
    let description = this.translate.instant('ResultsInfoNoLocation').replace("{count}", totalCount).replace("{what}", what).replace("{location}", where);
    this.metaHelper.addMetaDescription(this.metaHelper.removeHtmlTags(description));
  }

}
