import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { ShortlistResult } from "../models/shortlist-result.model";
import { ShortlistArguments } from "../models/shortlist-arguments.model";
import { Endpoints } from "../constants/endpoints";
import { TranslateService } from "@ngx-translate/core";
import { ListingModel } from "../models/listing.model";

@Injectable({
  providedIn: 'root'
})
export class ShortlistService {
  private storageKey = "GOUDENGIDS_SHORTLIST";
  private favIdsSubject$: BehaviorSubject<string[]>;

  constructor(
    private http: HttpClient,
    private translate: TranslateService) {

    const savedIds = localStorage.getItem(this.storageKey) ? JSON.parse(localStorage.getItem(this.storageKey)) : [];
    this.favIdsSubject$ = new BehaviorSubject<string[]>(savedIds);
  }

  get favIds$(): Observable<string[]> {
    return this.favIdsSubject$.asObservable();
  }

  isShortlisted(id: string): boolean {
    const favIds = this.favIdsSubject$.value;
    return favIds.includes(id);
  }

  addToShortlist(id: string): boolean {
    const favIds = this.favIdsSubject$.value;
    if (favIds.includes(id)) {
      return false;
    }

    const updatedFavIds = [...favIds, id];
    this.updateStorage(updatedFavIds);
    return true;
  }

  removeFromShortlist(id: string): boolean {
    const favIds = this.favIdsSubject$.value;
    if (!favIds.includes(id)) {
      return false;
    }

    const updatedFavIds = favIds.filter(favId => favId !== id);
    this.updateStorage(updatedFavIds);
    return true;
  }

  private updateStorage(favIds: string[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(favIds));
    this.favIdsSubject$.next(favIds);
  }

  fetchShortlist(): Observable<ListingModel[]> {
    const res = this.favIds$.pipe(
      switchMap(favIds => {
        const req = new ShortlistArguments();
        req.ShortList = favIds;
        return this.http.post<ShortlistResult>(Endpoints.SHORTLIST(this.translate.currentLang), req).pipe(
            map(res => res.value)
        );
      })
    );
    return res;
  }
}