import { Directive, Input, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { TrackingCategory, TrackingService } from '../services/tracking.service';

@Directive({
  selector: '[trackingAction]'
})
export class TrackingDirective implements OnInit {
  @Input('trackingCategory')
  eventCategory: TrackingCategory = "UNKNOWN";
  
  @Input('trackingAction')
  eventAction: string = "UNKNOWN";

  @Input('trackingLabel')
  eventLabel: string | TrackingLabel = "UNKNOWN";

  @Input('trackingListingId')
  trackingListingId: string = "";

  @Input('trackingArticleId')
  trackingArticleId: string = "";

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2,
    private trackingService: TrackingService) { }

  ngOnInit() {    
    const { trackingListingId, trackingArticleId } = this;
    const actionData = {} as TrackingData;

    if (trackingArticleId?.length > 0) {
        actionData.articleId = trackingArticleId;
    }

    if (trackingListingId?.length > 0) {
        actionData.id = trackingListingId;
    }

    this.eventLabel = Object.keys(actionData).length > 0 
        ? { actionData: actionData } 
        : "unknown";
    
    this.el.nativeElement.setAttribute('data-ta', this.eventAction);
    this.el.nativeElement.setAttribute('data-tc', this.eventCategory);
    this.el.nativeElement.setAttribute('data-tl', JSON.stringify(this.eventLabel));

    this.renderer.listen(this.el.nativeElement, 'click', _ => {
      // console.log({
      //   ta: this.eventAction,
      //   tc: this.eventCategory,
      //   tl: this.eventLabel
      // });

      this.trackingService.trackEvent(this.eventCategory, this.eventAction, this.eventLabel);
    });
  }

}

interface TrackingData {
  id: string,
  articleId?: string 
};

interface TrackingLabel {
  actionData: TrackingData
}