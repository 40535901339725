<div class="default-gradient border-gray-100">
    <div class="yp-container AYB-bg pt-20 pb-56 md:pb-20 bg-contain md:bg-680 md:bg-103 bg-white">
        <h1 class="font-medium mb-14 text-4xl md:text-5xl md:w-144 sm:w-full text-shadow">
            {{ 'AYB.MainTitle' | translate }}
        </h1>
        <h2 class="YB-message">
            {{ 'AYB.MainText' | translate }}
        </h2>
    </div>
</div>

<div class="relative">

    <!-- AYB aztrix form -->
    <div id="aztrix-form" class="yp-container aztrix-default AYB-form-box-wrap">
        <div class="AYB-form-box border-gray-100 shadow mb-12 p-4 relative">
            <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 123 123" class="h-28 w-28 mx-auto absolute hidden md:block" style="top: -6.5rem; left: -1.5rem;">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" href="assets/icons/icons.svg#AYB-arrow-icon"></use>
            </svg>
            <div id="aztrix-default-inner" class="bordered-box p-1">
                <!-- form here -->
            </div>
        </div>
    </div>

    <div class="yp-container">
        <h1 class="text-3.5xl font-medium text-blue-600 text-center mb-16">
            {{ 'AYB.SectionTitle' | translate }}
        </h1>
        <div class="gap-2 grid grid-cols-1 md:grid-cols-3 mb-16">

            <div class="lg:w-70.75 md:w-54.75 mx-auto mb-4">
                <app-icon [icon]="'price-icon'" svgClass="h-10 w-10 mx-auto mb-4"></app-icon>
                <h2 class="border-b-1 border-blue-300 mb-4.75 pb-4.75 text-center font-medium text-1xl">
                    {{ 'AYB.ColumnTitle1' | translate }}
                </h2>
                <p class="text-center">
                    {{ 'AYB.ColumnText1' | translate }}
                </p>
            </div>

            <div class="lg:w-70.75 md:w-54.75 mx-auto mb-4">
                <app-icon [icon]="'visits-icon'" svgClass="h-10 w-10 mx-auto mb-4"></app-icon>
                <h2 class="border-b-1 border-blue-300 mb-4.75 pb-4.75 text-center font-medium text-1xl">
                    {{ 'AYB.ColumnTitle2' | translate }}
                </h2>
                <p class="text-center">
                    {{ 'AYB.ColumnText2' | translate }}
                </p>
            </div>

            <div class="lg:w-70.75 md:w-54.75 mx-auto mb-4">
                <app-icon [icon]="'platform-icon'" svgClass="h-10 w-10 mx-auto mb-4"></app-icon>
                <h2 class="border-b-1 border-blue-300 mb-4.75 pb-4.75 text-center font-medium text-1xl">
                    {{ 'AYB.ColumnTitle3' | translate }}
                </h2>
                <p class="text-center">
                    {{ 'AYB.ColumnText3' | translate }}
                </p>
            </div>

        </div>
    </div>

</div>