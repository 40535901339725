<ng-container *ngIf="disableReviews === false">
    <section id="GO__reviews" class="border-t mb-10 mt-4">
        <h2 class="flex font-medium mb-4 pt-8 text-1xl">
            <span>{{ 'Reviews' | translate }}</span>
            <div id="addReviewButton"
                class="flex font-normal hover:underline items-center ml-auto modal text-blue-200 text-sm cursor-pointer"
                (click)="openReviewForm()" 
                [trackingAction]="'ReviewClick'" 
                [trackingCategory]="'DETAIL'"
                [trackingListingId]="listingId" 
                [attr.title]="'RateThisBusiness' | translate">
                <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 16 16" class="h-4 mr-2 w-4">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" href="#review-icon"></use>
                </svg>
                <span class="">{{ 'LeaveUsReview' | translate }}</span>
            </div>
        </h2>
        <div class="flex items-center mb-2" 
            [attr.itemprop]="ratingCount > 0 && avgRatingRounded !== '0.0' ? 'aggregateRating' : null" 
            itemscope
            [attr.itemtype]="ratingCount > 0 && avgRatingRounded !== '0.0' ? 'http://schema.org/AggregateRating' : null">

            <div class="flex stars rating-indicator traffic-review">
                <ng-container *ngFor="let star of stars">
                    <app-icon [icon]="'star-icon'" [ngClass]="star" svgClass="star w-4 h-4"></app-icon>
                </ng-container>
            </div>

            <ng-container *ngIf="ratingCount > 0 && avgRatingRounded !== '0.0'">
                <meta itemprop="worstRating" content="1">
                <meta itemprop="bestRating" content="5">
                <meta itemprop="ratingValue" [attr.content]="ratingAverage">
                <meta itemprop="ratingCount" [attr.content]="ratingCount">
            </ng-container>

            <ng-container *ngIf="ratingCount > 0 && avgRatingRounded !== '0.0'">
                <div class="active font-semibold ml-2 mr-2 text-blue-200">
                    <span>{{ avgRatingRounded }}</span>
                </div>
                <span class="text-sm text-gray-400">({{ ratingCount }})</span>
            </ng-container>

        </div>
    </section>
</ng-container>

<div id="review-display-inner"></div>
