<ng-content></ng-content>
<div class="border border-t-0 toggle-contacts-wrap absolute min-w-full z-60 bg-white"
    *ngIf="isOpen">
    <a *ngFor="let contact of contacts[0].contact"
        class="flex justify-between hover:bg-gray-300"
        [ngClass]="{
            'p-4': showIcon === true,
            'p-2': showIcon === false 
        }" 
        [href]="getHref(contact)" 
        [trackingAction]="'PhoneNumberClick'" 
        [trackingCategory]="'DETAIL'">
        <app-icon *ngIf="showIcon" [icon]="getIcon(contact)" svgFill="#000" svgClass="h-4 w-4"></app-icon>
        <span data-yext="phone"><!-- itemprop="telephone" -->
            {{ contact.value }}
        </span>
    </a>
</div>