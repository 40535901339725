import { OpeningType } from "../enums/opening-type.enum";
import { SearchSort } from "../enums/search-sort.enum";

export class SearchArguments {
    public what: string;
    public where: string;
    public geoLocation: string;
    public page: number;
    public openingType: OpeningType;
    public sort: SearchSort;
    public id: string;
    public searchType: SearchType;
    public eshop: boolean;

    serialize(): any {
        return {
            what: this.what,
            where: this.where,
            geoLocation: this.geoLocation,
            page: this.page,
            openingType: this.openingType,
            sort: this.sort,
            id: this.id,
            searchType: this.searchType,
            eshop: this.eshop
        }
    }
}

export enum SearchType {
    Dynamic,
    Normalized,
    Local
}