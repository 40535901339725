import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbHelper } from '../../helpers/breadcrumb.helper';
import { ListingModel } from '../../models/listing.model';
import { BreadcrumbLink } from '../../models/breadcrumb-link.model';
import { SearchGroup, SearchResults, ShareDataService } from '../../services/share-data.service';
import { ActivatedRoute } from '@angular/router';
import { BlogPost } from '../../models/blog-post.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('normalizedListings')
  normalizedListings: SearchResults;

  @Input('blogPost')
  blogPost: BlogPost;

  keyword: string;
  locationPath: string[];

  @Input("isDetail")
  isDetail: boolean;

  @Input("isBlog")
  isBlog: boolean;

  searchUrl: string;

  links: BreadcrumbLink[];

  constructor(
    private translate: TranslateService,
    private shareDataService: ShareDataService,
    private breadcrumbHelper: BreadcrumbHelper){}

  ngOnInit(): void {
    this.getLinks();
  }

  getLinks() {
    let keyword = null; 
    let listing = this.listing || null;
    let blogPost = this.blogPost || null;

    // normalized
    if (this.normalizedListings && this.normalizedListings?.searchGroups?.hasOwnProperty('N') && this.normalizedListings?.searchGroups['N']?.listings?.length > 0) {
      listing = this.normalizedListings?.searchGroups['N']?.listings[0];
    }

    // on detail page
    if (this.isDetail) {
      keyword = this.listing?.Category[0];
    }

    const locationPath = this.shareDataService.getLocationPath();

    if(!this.isBlog) {
      this.translate.get("Routes.Search.NormalizedWhatWhereSearch").subscribe((searchUrl: string) => {
        this.links = this.breadcrumbHelper.getLinks(searchUrl, listing, blogPost, keyword, this.isBlog, this.isDetail, locationPath);
      });
    } else {
      this.translate.get("Routes.Blog.BlogDetail").subscribe((searchUrl: string) => {
        this.links = this.breadcrumbHelper.getLinks(searchUrl, null, blogPost, keyword, true, false, null);
      });
    }
  }
}
