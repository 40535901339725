import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-security-captcha',
  templateUrl: './security-captcha.component.html',
  styleUrl: './security-captcha.component.scss'
})
export class SecurityCaptchaComponent {

  currentLang: string = '';

  constructor(
    public translate: TranslateService,
    private title: Title
  ){
    this.currentLang = translate.currentLang;
    
    this.translate.get("SecurityCaptcha").subscribe((x : string) => {
      
      this.title.setTitle(x);
    });
  }
}