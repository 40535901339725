<ul class="flex items-center flex-wrap mt-2.5 gap-2" *ngIf="hasBadges; else elseNoBadges">
    <li class="relative" *ngFor="let badge of badges">
        <img src="assets/images/ratingBadges/{{ badge.type }}{{ badge.index }}_{{ currentLang }}.png" alt="" title=""
            class="h-14 w-14" />
        <span
            style="position: absolute; top: 19px; left: 12px; font-size: .75rem; font-weight: 900; color: #4c4c4c; line-height: .675rem;">
            {{ badge.year}}
        </span>
    </li>
</ul>
<ng-template #elseNoBadges>
    <div class="mt-2.5" *ngIf="listing?.IsPaid">
        <div class="h-14 w-14"></div>
    </div>
</ng-template>