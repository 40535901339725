import { Component, Input, OnInit } from '@angular/core';
import { ListingModel } from '../../models/listing.model';
import { ShareDataService } from '../../services/share-data.service';
import { TrackingCategory } from '../../services/tracking.service';

@Component({
  selector: 'app-related-listing',
  templateUrl: './related-listing.component.html',
  styleUrl: './related-listing.component.scss'
})
export class RelatedListingComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('showLogo')
  showLogo: boolean = false;

  @Input('customTrackingAction')
  customTrackingAction: string = null;

  @Input('customTrackingCategory')
  customTrackingCategory: TrackingCategory;

  detailUrl: string;

  constructor(private shareDataService: ShareDataService) {}

  ngOnInit() {
    this.detailUrl = this.shareDataService.getDetailUrl(this.listing);
  }
}
