export class ListingAddress {
    address?: string;
    city: string;
    cityDistrict?: string;
    country: string;
    id: string;
    latitude?: number;
    locality1: string;
    locality2: string;
    locality3: string;
    longitude?: number;
    title: string;
    zip: string;
}

