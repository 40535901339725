import { Component, Input, OnInit } from '@angular/core';
import { ContactInfo } from '../../helpers/contact.helper';
import { ButtonStyle } from '../../services/share-data.service';

@Component({
  selector: '[app-website-url-btn]',
  templateUrl: './website-url-btn.component.html',
  styleUrl: './website-url-btn.component.scss'
})
export class WebsiteUrlBtnComponent implements OnInit {
  @Input('contacts')
  contacts: ContactInfo[];

  @Input('trafficLink')
  trafficLink;

  @Input('icon')
  icon: string = null;

  @Input('showIcon')
  showIcon: boolean = true;

  @Input('btnBorder')
  btnBorder: string = "";

  @Input('style')
  style: ButtonStyle;

  @Input('label')
  label: string;

  isOpen: boolean = false;

  link: string = "";

  constructor() { }

  ngOnInit() {
    this.link = this.getLinkUrl();
  }

  getLinkUrl() {
    let trafficLink: string = "";
  
    if (this.trafficLink?.length > 0) {
      trafficLink = this.trafficLink[0];
    } else if (this.contacts.length > 0 && this.contacts[0].hasContact) {
      trafficLink = this.contacts[0]?.contact[0]?.value;
    }
  
    if (trafficLink.length > 0) {
      const hasQueryParams = /\?.+=/.test(trafficLink);
      const mark = hasQueryParams ? "&" : "?";
      return `${trafficLink}${mark}utm_source=fcrmedia&utm_medium=internet&utm_campaign=goudengidspagesdor`;
    }
  
    return "";
  }

  handleDropdownOpen(): void {
    if (this.contacts?.length > 1) {
      this.isOpen = !this.isOpen;
    }
  }

  handleButtonClick(): void {
    
  }
}
