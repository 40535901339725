import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListingModel } from '../../../models/listing.model';
import { KeyValuePair } from '../../../models/query-results.model';
import { LangSwitch } from '../../../services/lang-switch.service';
import { DataService } from '../../../services/data.service';
import { Endpoints } from '../../../constants/endpoints';
import { TranslateService } from '@ngx-translate/core';
import { UrlHelper } from '../../../helpers/url-builder.helper';

export class AzIndexResult {
  listings: ListingModel[];
  indexes: KeyValuePair<string,number>[];
}

export class AzIndexRequest {
  level1: string;
  level2: string;
}

@Component({
  selector: 'app-az-index',
  templateUrl: './az-index.component.html',
  styleUrl: './az-index.component.scss'
})
export class AzIndexComponent implements OnInit {

  level1: string;
  level2: string;
  result: AzIndexResult;
  request: AzIndexRequest = new AzIndexRequest();

  constructor(
    private route: ActivatedRoute,
    private langSwitch: LangSwitch,
    private data: DataService,
    private translate: TranslateService,
    private urlHelper: UrlHelper
  ){}

  ngOnInit(): void {
    this.route.paramMap.subscribe((map) => {
      this.level1 = map.get("level1");
      this.level2 = map.get("level2");
      
      this.loadIndex();
      this.langSwitch.setIndexAZ(this.level1, this.level2)
    });
  }

  loadIndex() {
    this.request.level1 = this.level1 && this.level1 != '' ? this.level1 : null;
    this.request.level2 = this.level2 && this.level2 != '' ? this.level2 : null;
    this.data.post<AzIndexResult>(Endpoints.AZ_INDEX(this.translate.currentLang), this.request).subscribe( x => {
      this.result = x;
      console.log(x);
    })
  }

  getDetailUrl(listing: ListingModel): string {
    return this.urlHelper.getDetailPageRoute(this.translate.instant('Routes.Detail.ListingDetail'), listing.City, listing.Id, listing.Title)
  }
}
