import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../../components/modal/modal.service';
import { ListingModel } from '../../../../models/listing.model';
import { ButtonStyle, DetailPageType, Poi } from '../../../../services/share-data.service';

@Component({
  selector: '[app-plan-route-btn]',
  templateUrl: './plan-route-btn.component.html',
  styleUrl: './plan-route-btn.component.scss'
})
export class PlanRouteBtnComponent implements OnInit {
  @Input()
  listing: ListingModel

  @Input() 
  showIcon: boolean = true;

  @Input()
  btnBorder: string = "";

  @Input()
  pois: Poi[] = [];

  @Input('detailPageType')
  detailPageType: DetailPageType;

  @Input()
  label: string;

  @Input()
  style: ButtonStyle;

  // modal
  modalOpt = {
    classes: [
      'modal-inner', 
      'yp-container--2xl', 
      'h-full', 
      'md\:h-80', 
      'p-0'
    ],
    size: {
      width: '100%',
    },
  };

  constructor(
    public translate: TranslateService,
    private modalService: ModalService){}

  ngOnInit() {
    // handle close event
    document.addEventListener('closeMapControlClick', this.close.bind(this));
  }

  openModalTemplate(view: TemplateRef<Element>) {
    this.modalService.open(view, this.modalOpt);
  }

  close() {
    this.modalService.close();
  }
}
