<section class="mb-10" *ngIf="hasVideo">
    <h2 class="font-medium mb-4 text-1xl">
        {{ 'Video' | translate }}
    </h2>
    <!-- embed -->
    <div class="responsive-video ratio-16-9 detail-video" 
        *ngIf="isIframe; else elseEmbed" 
        [innerHTML]="iframeHtml">
    </div>
    <!-- iframe -->
    <ng-template #elseEmbed>
        <div class="responsive-video ratio-16-9 detail-video">
            <iframe width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/{{ listing?.VideoEmbedCode }}"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
            </iframe>
        </div>
    </ng-template>
</section>