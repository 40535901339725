<div class="yp-container card card--flow-y">
    <div class="grid">

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('fr')">
            <div id="inner-static">
                <h2 class="text-2xl">Politique de gestion des avis</h2>
            
                <p>
                    Cette politique de gestion des avis  porte uniquement sur la manière dont FCR Media traite les évaluations et sur notre politique à cet égard. Si vous souhaitez obtenir de plus amples informations sur les données personnelles que nous recueillons auprès de vous, sur les raisons pour lesquelles nous les recueillons et sur ce que nous en faisons, veuillez lire notre Politique de confidentialité.
                </p>
            
                <p>
                    Les avis sont toujours volontaires. Nous ne payons pas leurs auteurs pour les rédiger.
                </p>
            
                <p>
                    Les avis sont publics. Nous n'acceptons pas les avis anonymes. Vous devez toujours fournir un nom lorsque vous écrivez un avis et ce nom est visible pour les autres.
                </p>
                <p>
                    L'adresse électronique que vous fournissez ne sera pas publiée et n'est utilisée qu'à des fins de vérification.
                </p>
                <p>
                    Bien entendu, toutes les données sont traitées conformément au GDPR. Pour plus d'informations, veuillez vous reporter à notre Politique de confidentialité.
                </p>
            
                <p>
                    Nous mettons tout en œuvre pour garantir que le contenu des avis est utile et honnête.   Les avis peuvent être supprimés s'ils ne sont pas conformes à notre politique en la matière.
                </p>
            
                <p>
                    Les contributions doivent être fondées sur des expériences et des informations réelles. Le contenu intentionnellement faux, le contenu non pertinent, le langage diffamatoire, les attaques personnelles et le contenu inutile ou incorrect sont tous contre notre politique.
                </p>
            
                <p>
                    Nous nous réservons le droit de désactiver les contributions ajoutées par les utilisateurs pour éviter les abus. Nous nous réservons également le droit de supprimer le contenu qui enfreint nos politiques.
                </p>
            
                <p>
                    Le contenu que nous surveillons et désactivons lorsqu'il est détecté est le suivant :
                </p>
            
                <h3 class="small text-xl mt-2">Contenu dangereux ou dénigrant</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu menaçant ou incitant à l'automutilation, ou menaçant ou incitant à nuire à autrui.</li>
                    <li>Contenu qui harcèle, intimide ou offense un individu ou un groupe.</li>
                    <li>Contenu incitant à la haine ou à la discrimination à l'encontre d'un individu ou d'un groupe, ou dénigrant un individu ou un groupe en raison de sa race ou de son origine ethnique, de sa religion, de son handicap, de son âge, de sa nationalité, de son statut d'ancien combattant, de son orientation sexuelle, de son sexe, de son identité sexuelle ou de toute autre caractéristique associée à une discrimination ou une marginalisation systématique.</li>
                </ul>
                <h3 class="small text-xl mt-2">Spam ou contenu incorrect.</h3>
                <ul class="list-disc pl-4">
                    <li>Le même contenu posté plusieurs fois.</li>
                    <li>Contenu pour le même lieu provenant de plusieurs comptes par le même réviseur.</li>
                </ul>
                <h3 class="small text-xl mt-2">Contenu non pertinent</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu qui n'est pas basé sur l'expérience de l'évaluateur dans l'achat d'un bien ou d'un service auprès de l'entreprise où l'évaluation est publiée.</li>
                    <li>Contenu contenant des commentaires politiques ou sociaux ou des diatribes personnelles.</li>
                </ul>
                <h3 class="small text-xl mt-2">Publicité</h3>
                <ul class="list-disc pl-4">
                    <li>Le contenu qui contient des appels à l'action ou des offres de vente de produits ou de services.</li>
                </ul>
                <h3 class="small text-xl mt-2">Contenu offensant</h3>
                <ul class="list-disc pl-4">
                    <li>Comportant un langage ou des gestes obscènes, diffamatoires ou offensants.</li>
                </ul>
                <h3 class="small text-xl mt-2">Contenu sexuellement explicite</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu contenant des éléments sexuellement explicites.</li>
                </ul>
                <h3 class="small text-xl mt-2">Usurpation de l'identité</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu attribué à des personnes, des organisations ou des sociétés que l’auteur n'est pas autorisé à représenter.</li>
                </ul>
                <h3 class="small text-xl mt-2">Conflit d'intérêts</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu qui n'est pas équitable ou impartial, tel que :</li>
                    <li>Une critique écrite sur la propre entreprise de l’auteur.</li>
                    <li>Un bilan sur une expérience professionnelle actuelle ou passée.</li>
                    <li>Un avis malhonnête destiné à influencer l'évaluation d'un concurrent.</li>
                </ul>
                <h3 class="small text-xl mt-2">Contenu illégal</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu illégal ou présentant des activités illégales.</li>
                </ul>
            </div>
        </article>

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('nl')">
            <div id="inner-static">
                <h2 class="text-2xl">Reviewbeleid</h2>
            
                <p>Dit reviewbeleid handelt enkel over hoe FCR Media omgaat met reviews en wat ons beleid hieromtrent is. Indien u meer info wenst over welke persoonsgegevens wij van u verzamelen, waarom wij die verzamelen en wat we er mee doen, verwijzen wij u graag naar onze Privacyverklaring.</p>
                <p>Reviews zijn steeds vrijwillig. We betalen reviewers niet om ze toe te voegen.</p>
            
                <p>Reviews zijn openbaar.  Wij aanvaarden geen anonieme reviews.  U dient steeds een naam op te geven bij het schrijven van een review en deze naam is zichtbaar voor anderen.</p>
                <p>Uw e-mailadres dat u opgeeft wordt niet gepubliceerd en dient enkel ter verificatie.</p>
                <p>Uiteraard worden alle gegevens verwerkt conform de GDPR. Voor meer informatie, gelieve zich te wenden tot onze Privacyverklaring.</p>
            
                <p>We doen veel moeite om ervoor te zorgen dat content in reviews nuttig en eerlijk is.  Reviews kunnen worden verwijderd als ze niet voldoen aan onze review policy.</p>
            
                <p>Bijdragen moeten zijn gebaseerd op echte ervaringen en informatie. Opzettelijk valse content, niet-relevante content, lasterlijk taalgebruik, persoonlijke aanvallen en overbodige of onjuiste content zijn allemaal in strijd met ons beleid.</p>
            
                <p>We behouden ons het recht voor door gebruikers toegevoegde bijdragen uit te schakelen om misbruik te voorkomen. We behouden ons ook het recht voor content die in strijd is met ons beleid te verwijderen.</p>
            
                <p>Content waarop we controleren en die we uitschakelen wanneer ze gedetecteerd wordt is:</p>
            
                <h3 class="small text-xl mt-2">Gevaarlijke of denigrerende content</h3>
                <ul class="list-disc pl-4">
                    <li>Content die dreigt met of aanzet tot zelfschade, of die dreigt of ertoe aanzet anderen schade toe te brengen.</li>
                    <li>Content die een individu of een groep treitert, intimideert of kwetst.</li>
                    <li>Content die aanzet tot haat of discriminatie van, of kleinerend is voor een individu of groep op basis van ras of etnische afkomst, religie, beperking, leeftijd, nationaliteit, veteranenstatus, seksuele geaardheid, geslacht, genderidentiteit of een ander kenmerk dat is gekoppeld aan systematische discriminatie of marginalisering.</li>
                </ul>
                <h3 class="small text-xl mt-2">Spam of valse content</h3>
                <ul class="list-disc pl-4">
                    <li>Valse content.</li>
                    <li>Dezelfde content die meerdere keren is geplaatst.</li>
                    <li>Content voor dezelfde plaats vanuit meerdere accounts van dezelfde reviewer.</li>
                </ul>
                <h3 class="small text-xl mt-2">Niet-relevante content</h3>
                <ul class="list-disc pl-4">
                    <li>Content die niet gebaseerd is op ervaringen van de reviewer met een aankoop van een goed of dienst bij de zaak waarbij de review geplaatst wordt.</li>
                    <li>Content die politieke of sociale commentaar of persoonlijke tirades bevat.</li>
                </ul>
                <h3 class="small text-xl mt-2">Reclame</h3>
                <ul class="list-disc pl-4">
                    <li>Content die call-to-actions of aanbiedingen bevat voor de verkoop van producten of services.</li>
                </ul>
                <h3 class="small text-xl mt-2">Aanstootgevende content</h3>
                <ul class="list-disc pl-4">
                    <li>Content met obscene, lasterlijke of aanstootgevende taal of gebaren.</li>
                </ul>
                <h3 class="small text-xl mt-2">Seksueel expliciete content</h3>
                <ul class="list-disc pl-4">
                    <li>Content met seksueel expliciet materiaal.</li>
                </ul>
                <h3 class="small text-xl mt-2">Nabootsing van identiteit</h3>
                <ul class="list-disc pl-4">
                    <li>Content toegeschreven aan personen, organisaties of bedrijven die de reviewer niet bevoegd is te vertegenwoordigen.</li>
                </ul>
                <h3 class="small text-xl mt-2">Belangenverstrengeling</h3>
                <ul class="list-disc pl-4">
                    <li>Content die niet eerlijk of onpartijdig is, zoals:</li>
                    <li>Een review geschreven over het eigen bedrijf van de reviewer,</li>
                    <li>Een review over een huidige of vroegere werkervaring.</li>
                    <li>Een oneerlijke review bedoeld om de beoordeling van een concurrent te beïnvloeden.</li>
                </ul>
                <h3 class="small text-xl mt-2">Illegale content</h3>
                <ul class="list-disc pl-4">
                    <li>Content die illegaal is of illegale activiteiten weergeeft.</li>
                </ul>
            </div>
        </article>

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('en')">
            <div id="inner-static">
                <h2 class="text-2xl">Politique de gestion des avis</h2>
            
                <p>
                    Cette politique de gestion des avis  porte uniquement sur la manière dont FCR Media traite les évaluations et sur notre politique à cet égard. Si vous souhaitez obtenir de plus amples informations sur les données personnelles que nous recueillons auprès de vous, sur les raisons pour lesquelles nous les recueillons et sur ce que nous en faisons, veuillez lire notre Politique de confidentialité.
                </p>
            
                <p>
                    Les avis sont toujours volontaires. Nous ne payons pas leurs auteurs pour les rédiger.
                </p>
            
                <p>
                    Les avis sont publics. Nous n'acceptons pas les avis anonymes. Vous devez toujours fournir un nom lorsque vous écrivez un avis et ce nom est visible pour les autres.
                </p>
                <p>
                    L'adresse électronique que vous fournissez ne sera pas publiée et n'est utilisée qu'à des fins de vérification.
                </p>
                <p>
                    Bien entendu, toutes les données sont traitées conformément au GDPR. Pour plus d'informations, veuillez vous reporter à notre Politique de confidentialité.
                </p>
            
                <p>
                    Nous mettons tout en œuvre pour garantir que le contenu des avis est utile et honnête.   Les avis peuvent être supprimés s'ils ne sont pas conformes à notre politique en la matière.
                </p>
            
                <p>
                    Les contributions doivent être fondées sur des expériences et des informations réelles. Le contenu intentionnellement faux, le contenu non pertinent, le langage diffamatoire, les attaques personnelles et le contenu inutile ou incorrect sont tous contre notre politique.
                </p>
            
                <p>
                    Nous nous réservons le droit de désactiver les contributions ajoutées par les utilisateurs pour éviter les abus. Nous nous réservons également le droit de supprimer le contenu qui enfreint nos politiques.
                </p>
            
                <p>
                    Le contenu que nous surveillons et désactivons lorsqu'il est détecté est le suivant :
                </p>
            
                <h3 class="small text-xl mt-2">Contenu dangereux ou dénigrant</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu menaçant ou incitant à l'automutilation, ou menaçant ou incitant à nuire à autrui.</li>
                    <li>Contenu qui harcèle, intimide ou offense un individu ou un groupe.</li>
                    <li>Contenu incitant à la haine ou à la discrimination à l'encontre d'un individu ou d'un groupe, ou dénigrant un individu ou un groupe en raison de sa race ou de son origine ethnique, de sa religion, de son handicap, de son âge, de sa nationalité, de son statut d'ancien combattant, de son orientation sexuelle, de son sexe, de son identité sexuelle ou de toute autre caractéristique associée à une discrimination ou une marginalisation systématique.</li>
                </ul>
                <h3 class="small text-xl mt-2">Spam ou contenu incorrect.</h3>
                <ul class="list-disc pl-4">
                    <li>Le même contenu posté plusieurs fois.</li>
                    <li>Contenu pour le même lieu provenant de plusieurs comptes par le même réviseur.</li>
                </ul>
                <h3 class="small text-xl mt-2">Contenu non pertinent</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu qui n'est pas basé sur l'expérience de l'évaluateur dans l'achat d'un bien ou d'un service auprès de l'entreprise où l'évaluation est publiée.</li>
                    <li>Contenu contenant des commentaires politiques ou sociaux ou des diatribes personnelles.</li>
                </ul>
                <h3 class="small text-xl mt-2">Publicité</h3>
                <ul class="list-disc pl-4">
                    <li>Le contenu qui contient des appels à l'action ou des offres de vente de produits ou de services.</li>
                </ul>
                <h3 class="small text-xl mt-2">Contenu offensant</h3>
                <ul class="list-disc pl-4">
                    <li>Comportant un langage ou des gestes obscènes, diffamatoires ou offensants.</li>
                </ul>
                <h3 class="small text-xl mt-2">Contenu sexuellement explicite</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu contenant des éléments sexuellement explicites.</li>
                </ul>
                <h3 class="small text-xl mt-2">Usurpation de l'identité</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu attribué à des personnes, des organisations ou des sociétés que l’auteur n'est pas autorisé à représenter.</li>
                </ul>
                <h3 class="small text-xl mt-2">Conflit d'intérêts</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu qui n'est pas équitable ou impartial, tel que :</li>
                    <li>Une critique écrite sur la propre entreprise de l’auteur.</li>
                    <li>Un bilan sur une expérience professionnelle actuelle ou passée.</li>
                    <li>Un avis malhonnête destiné à influencer l'évaluation d'un concurrent.</li>
                </ul>
                <h3 class="small text-xl mt-2">Contenu illégal</h3>
                <ul class="list-disc pl-4">
                    <li>Contenu illégal ou présentant des activités illégales.</li>
                </ul>
            </div>
        </article>

    </div>
</div>