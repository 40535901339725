<!-- top banners - mobile placeoholder -->
<div class="banner-boxes-holder mt-4"
    *ngIf="checkDeviceService.isMobile$ | async; else showPlaceholderTopBannersDesktop">
    <div class="banner-box banner-box--top TOP-banner"></div>
</div>
<!-- top banners - desktop placeholder -->
<ng-template #showPlaceholderTopBannersDesktop>
    <div class="banner-boxes">
        <div class="grid md:grid-cols-4 grid-cols-1">
            <div class="banner-boxes-holder col-span-3 bg-gray-300 col-span-3 mr-5 p-4 rounded-2 mt-10">
                <span class="bg-white border border-blue-300 inline-block mb-2 px-2 py-0.5">{{ 'Sponsored' | translate }}</span>
                <div class="grid grid-cols-3 gap-4">
                        <div class="col-md-4 col-sm-4 align-center flex"
                            *ngFor="let item of [1, 2, 3]">
                            <div class="banner-box banner-box--top TOP-banner min-h-44.75"></div>
                        </div>
                </div>
            </div>
            <div class="col-span-1 mt-10">      
                <div class="banner-box banner-box--top iframe-wrap"></div>
            </div>
        </div>
    </div>
</ng-template>