import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShareDataService } from '../../../../services/share-data.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.scss'
})
export class CategoriesComponent {
  @Input()
  categories: string[];

  constructor(public shareDataService: ShareDataService){}
}
