import { Injectable } from "@angular/core";
import { TaxonomyItemModel } from "../models/taxonomy-item.model";
import { LocationModel } from "../models/location.model";
import { TranslateService } from "@ngx-translate/core";
import { UrlHelper } from "../helpers/url-builder.helper";
import { SearchType } from "../models/search-arguments.model";
import { ListingModel } from "../models/listing.model";
import { BlogPost } from "../models/blog-post.model";

@Injectable({
    providedIn: 'root'
  })
export class LangSwitch {
    private domainNl: string
    private domainFr: string
    private domainEn: string

    linkNL: string;
    linkFR: string;
    linkEN: string;

    constructor(private translate: TranslateService, private urlHelper: UrlHelper) {
        let domain = document.location.protocol + '//' + document.location.host;

        this.domainFr = !domain.includes('localhost') ? domain.replace('goudengids', 'pagesdor').replace('goldenpages', 'pagesdor') : "http://localhost:4200"
        this.domainEn = !domain.includes('localhost') ? domain.replace('goudengids', 'goldenpages').replace('pagesdor', 'goldenpages') : "http://localhost:4200"
        this.domainNl = !domain.includes('localhost') ? domain.replace('pagesdor', 'goudengids').replace('goldenpages', 'goudengids') : "http://localhost:4200"
    }

    getSearchPage(subject: TaxonomyItemModel, location: LocationModel, what: string, where: string, searchType: SearchType, page: number) {

        if(searchType == SearchType.Dynamic){
            this.linkNL =  this.domainNl + this.urlHelper.getSearchPageRouteWithPage(StaticRoutes.dynamicSearchNL, subject ? subject.nameNl : what, location ? location.nameNl : where, page)
            this.linkFR =  this.domainFr + this.urlHelper.getSearchPageRouteWithPage(StaticRoutes.dynamicSearchFR, subject ? subject.nameFr : what, location ? location.nameFr : where, page)
            this.linkEN =  this.domainEn + this.urlHelper.getSearchPageRouteWithPage(StaticRoutes.dynamicSearchEN, subject ? subject.nameEn : what, location ? location.nameEn : where, page)
        }

        if(searchType == SearchType.Normalized){
            this.linkNL =  this.domainNl + this.urlHelper.getSearchPageRouteWithPage(StaticRoutes.normalizedSearchNL, subject ? subject.nameNl : what, location ? location.nameNl : where, page)
            this.linkFR =  this.domainFr + this.urlHelper.getSearchPageRouteWithPage(StaticRoutes.normalizedSearchFR, subject ? subject.nameFr : what, location ? location.nameFr : where, page)
            this.linkEN =  this.domainEn + this.urlHelper.getSearchPageRouteWithPage(StaticRoutes.normalizedSearchEN, subject ? subject.nameEn : what, location ? location.nameEn : where, page)
        }

        if(searchType == SearchType.Local){
            this.linkNL =  this.domainNl + this.urlHelper.getSearchPageRouteWithPage(StaticRoutes.localResultsSearchNL, subject ? subject.nameNl : what, location ? location.nameNl : where, page)
            this.linkFR =  this.domainFr + this.urlHelper.getSearchPageRouteWithPage(StaticRoutes.localResultsSearchFR, subject ? subject.nameFr : what, location ? location.nameFr : where, page)
            this.linkEN =  this.domainEn + this.urlHelper.getSearchPageRouteWithPage(StaticRoutes.localResultsSearchEN, subject ? subject.nameEn : what, location ? location.nameEn : where, page)
        }
    }

    setBlogPage(isCategory: boolean, categoryEn?: string, categoryFr?: string, categoryNl?: string) {
        if(isCategory){
            this.linkNL = this.domainNl + StaticRoutes.blog.replace(':category', categoryNl);
            this.linkFR = this.domainFr + StaticRoutes.blog.replace(':category', categoryFr);
            this.linkEN = this.domainEn + StaticRoutes.blog.replace(':category', categoryEn);
        }
        else{
            this.linkNL = this.domainNl + '/blog'
            this.linkFR = this.domainFr + '/blog'
            this.linkEN = this.domainEn + '/blog'
        }
    }

    setBlogDetailPage(blog: BlogPost) {
        this.linkNL = blog.titleNl == '' || !blog.titleNl ? this.domainNl : this.domainNl + this.urlHelper.getBlogPageRoute(StaticRoutes.blogDetail, blog.headingsNl[0], blog.id, blog.titleNl)
        this.linkFR = blog.titleFr == '' || !blog.titleFr ? this.domainFr : this.domainFr + this.urlHelper.getBlogPageRoute(StaticRoutes.blogDetail, blog.headingsFr[0], blog.id, blog.titleFr)
        this.linkEN = blog.titleEn == '' || !blog.titleEn ? this.domainEn : this.domainEn + this.urlHelper.getBlogPageRoute(StaticRoutes.blogDetail, blog.headingsEn[0], blog.id, blog.titleEn)
    }

    setHomePage() {
        this.linkNL = this.domainNl
        this.linkFR = this.domainFr
        this.linkEN = this.domainEn
    }
    
    /* STATICS ----------------------------------- */

    setAddYourBusiness() {
        this.linkNL = this.domainNl + StaticRoutes.addYourBusinessNL
        this.linkFR = this.domainFr + StaticRoutes.addYourBusinessFR
        this.linkEN = this.domainEn + StaticRoutes.addYourBusinessEN
    }

    setClaimYourBusiness() {
        this.linkNL = this.domainNl + StaticRoutes.claimYourBusinessNL
        this.linkFR = this.domainFr + StaticRoutes.claimYourBusinessFR
        this.linkEN = this.domainEn + StaticRoutes.claimYourBusinessEN
    }

    setManageYourBusiness() {
        this.linkNL = this.domainNl + StaticRoutes.manageYourBusinessNL
        this.linkFR = this.domainFr + StaticRoutes.manageYourBusinessFR
        this.linkEN = this.domainEn + StaticRoutes.manageYourBusinessEN
    }

    setPrivacyPolicy() {
        this.linkNL = this.domainNl + StaticRoutes.privacyPolicy
        this.linkFR = this.domainFr + StaticRoutes.privacyPolicy
        this.linkEN = this.domainEn + StaticRoutes.privacyPolicy
    }

    setReviewPolicy() {
        this.linkNL = this.domainNl + StaticRoutes.reviewPolicyNL
        this.linkFR = this.domainFr + StaticRoutes.reviewPolicyFR
        this.linkEN = this.domainEn + StaticRoutes.reviewPolicyEN
    }

    setCookiePolicy() {
        this.linkNL = this.domainNl + StaticRoutes.cookiePolicy
        this.linkFR = this.domainFr + StaticRoutes.cookiePolicy
        this.linkEN = this.domainEn + StaticRoutes.cookiePolicy
    }

    setTermsAndConditions() {
        this.linkNL = this.domainNl + StaticRoutes.termsAndConditionsNL
        this.linkFR = this.domainFr + StaticRoutes.termsAndConditionsFR
        this.linkEN = this.domainEn + StaticRoutes.termsAndConditionsEN
    }

    setShortlist() {
        this.linkNL = this.domainNl + StaticRoutes.shortlist
        this.linkFR = this.domainFr + StaticRoutes.shortlist
        this.linkEN = this.domainEn + StaticRoutes.shortlist
    }

    SetDetailPage(listing: ListingModel) {
        listing = this.adjustCityOnListing(this.adjustTitleOnListing(listing))
        this.linkNL = this.domainNl + this.urlHelper.getDetailPageRoute(StaticRoutes.listingDetailNL, listing.CityNl, listing.Id, listing.TitleNl)
        this.linkFR = this.domainFr + this.urlHelper.getDetailPageRoute(StaticRoutes.listingDetailFR, listing.CityFr, listing.Id, listing.TitleFr)
        this.linkEN = this.domainEn + this.urlHelper.getDetailPageRoute(StaticRoutes.listingDetailEN, listing.CityEn, listing.Id, listing.TitleEn)
    }

    SetFolderDetailPage(child: ListingModel, parrentId: string, ) {
        child = this.adjustTitleOnListing(child)
        this.linkNL = this.domainNl + this.urlHelper.getFolderDetailPageRoute(StaticRoutes.listingFolderDetailNL, child.Id, parrentId, child.TitleNl)
        this.linkFR = this.domainFr + this.urlHelper.getFolderDetailPageRoute(StaticRoutes.listingFolderDetailFR, child.Id, parrentId, child.TitleFr)
        this.linkEN = this.domainEn + this.urlHelper.getFolderDetailPageRoute(StaticRoutes.listingFolderDetailEN, child.Id, parrentId, child.TitleEn)
    }

    setIndexAZ(level1: string = null, level2: string = null) {
        if((level1 && level1 != '') && (level2 || level2 && '')){
            this.linkNL = this.domainNl + StaticRoutes.indexAZ.replace(':level1',level1).replace(':level2', level2)
            this.linkFR = this.domainFr + StaticRoutes.indexAZ.replace(':level1',level1).replace(':level2', level2)
            this.linkEN = this.domainEn + StaticRoutes.indexAZ.replace(':level1',level1).replace(':level2', level2)
        }
        else if(level1 && level1 != ''){
            this.linkNL = this.domainNl + StaticRoutes.indexAZ.replace(':level1',level1).replace('/:level2', '')
            this.linkFR = this.domainFr + StaticRoutes.indexAZ.replace(':level1',level1).replace('/:level2', '')
            this.linkEN = this.domainEn + StaticRoutes.indexAZ.replace(':level1',level1).replace('/:level2', '')
        }
        else {
            this.linkNL = this.domainNl + StaticRoutes.indexAZ.replace('/:level1','').replace('/:level2', '')
            this.linkFR = this.domainFr + StaticRoutes.indexAZ.replace('/:level1','').replace('/:level2', '')
            this.linkEN = this.domainEn + StaticRoutes.indexAZ.replace('/:level1','').replace('/:level2', '')
        }
    }

    setKeywordIndex(letter: string, page: number) {
        this.linkNL = this.domainNl + StaticRoutes.keywordIndexNL.replace(':letter', letter).replace(':page', page.toString())
        this.linkFR = this.domainFr + StaticRoutes.keywordIndexFR.replace(':letter', letter).replace(':page', page.toString())
        this.linkEN = this.domainEn + StaticRoutes.keywordIndexEN.replace(':letter', letter).replace(':page', page.toString())
    }

    setCategoryIndex(letter: string, page: number) {
        this.linkNL = this.domainNl + StaticRoutes.categoryIndexNL.replace(':letter', letter).replace(':page', page.toString())
        this.linkFR = this.domainFr + StaticRoutes.categoryIndexFR.replace(':letter', letter).replace(':page', page.toString())
        this.linkEN = this.domainEn + StaticRoutes.categoryIndexEN.replace(':letter', letter).replace(':page', page.toString())
    }

    private adjustCityOnListing(listing: ListingModel): ListingModel {
        listing.CityNl = listing.CityNl && listing.CityNl != '' ? listing.CityNl : listing.City;
        listing.CityFr = listing.CityFr && listing.CityFr != '' ? listing.CityFr : listing.CityNl;
        listing.CityFr = listing.CityEn && listing.CityEn != '' ? listing.CityEn : listing.CityNl;

        return listing
    }

    private adjustTitleOnListing(listing: ListingModel): ListingModel {
        listing.TitleNl = listing.TitleNl && listing.TitleNl != '' ? listing.TitleNl : listing.Title;
        listing.TitleFr = listing.TitleFr && listing.TitleFr != '' ? listing.TitleFr : listing.TitleNl;
        listing.TitleEn = listing.TitleEn && listing.TitleEn != '' ? listing.TitleEn : listing.TitleNl;

        return listing
    }
}



export class StaticRoutes{
    static dynamicSearchNL = "/zoeken/:what/:where/:page"
    static dynamicSearchFR = "/trouvez/:what/:where/:page"
    static dynamicSearchEN = "/search/:what/:where/:page"

    static normalizedSearchNL = "/bedrijven/:where/:what/:page"
    static normalizedSearchFR = "/entreprises/:where/:what/:page"
    static normalizedSearchEN = "/companies/:where/:what/:page"

    static localResultsSearchNL = "/Search/LokaleResultaten/:what/:where/:page"
    static localResultsSearchFR = "/Search/ResultatsLocaux/:what/:where/:page"
    static localResultsSearchEN = "/Search/LocalResults/:what/:where/:page"

    static shortlist = "shortlist"

    /* DETAIL ----------------------------------- */

    static listingDetailNL = "/bedrijf/:city/:id/:title"
    static listingDetailFR = "/entreprise/:city/:id/:title"
    static listingDetailEN = "/company/:city/:id/:title"

    static listingFolderDetailNL = "/bedrijf/:id/:childId/:title"
    static listingFolderDetailFR = "/entreprise/:id/:childId/:title"
    static listingFolderDetailEN = "/company/:id/:childId/:title"

    /* BLOG ----------------------------------- */

    static blog = "blog/:category";

    static blogDetail = "/blog/:heading/:id/:title"

    /* STATICS ----------------------------------- */

    static addYourBusinessNL = "/bedrijftoevoegen"
    static addYourBusinessFR = "/ajouterentreprise"
    static addYourBusinessEN = "/addyourbusiness"
    
    static claimYourBusinessNL = "/mijnbedrijfsprofiel"
    static claimYourBusinessFR = "/monprofilprofessionnel"
    static claimYourBusinessEN = "/claimyourbusiness"

    static manageYourBusinessNL = "/mijnbedrijfbeheren"
    static manageYourBusinessFR = "/gerermonentreprise"
    static manageYourBusinessEN = "/manageyourbusiness"

    static privacyPolicy = "/privacy"

    static termsAndConditionsNL = "/juridisch"
    static termsAndConditionsFR = "/juridique"
    static termsAndConditionsEN = "/legal"

    static cookiePolicy = "/cookie"

    static reviewPolicyNL = "/reviewbeleid"
    static reviewPolicyFR = "/politique-de-gestion-des-avis"
    static reviewPolicyEN = "/reviewpolicy"

    static securityCaptcha = "/captcha"

    static indexAZ = "/index-az/:level1/:level2"

    static categoryIndexNL = "/categorieen/:letter/:page"
    static categoryIndexFR = "/les-categories/:letter/:page"
    static categoryIndexEN = "/categories/:letter/:page"

    static keywordIndexNL = "/trefwoorden/:letter/:page"
    static keywordIndexFR = "/mots-cles/:letter/:page"
    static keywordIndexEN = "/keywords/:letter/:page"

}