<section id="categories" class="border-t mb-10 mt-4">
    <h2 class="font-medium mb-4 pt-8 text-1xl">
        {{ 'Categories' | translate }}
    </h2>
    <ul class="flex flex-wrap">
        <ng-container *ngIf="listing?.Category?.length > 0">

            <li *ngFor="let category of listing?.Category" class="mr-2 mb-2.5">
                <a class="category group" [routerLink]="shareDataService.getCategoryLink(category)">
                    <span class="group-hover:underline">
                        {{ category }}
                    </span>
                </a>
            </li>

        </ng-container>


        <li *ngIf="listing?.City && listing?.Category[0] !== null" class="mr-2 mb-2.5">
            <a class="category" [routerLink]="shareDataService.getCategoryLink(listing?.Category[0], listing?.City)">
                <span class="group-hover:underline">
                    {{ listing?.City }}
                </span>
            </a>
        </li>

        <ng-container *ngIf="listing?.IsPaid && keywords?.length > 0">

            <li *ngFor="let keyword of keywords" class="mr-2 mb-2.5">
                <a class="category" [routerLink]="shareDataService.getCategoryLink(keyword, listing?.City)">
                    <span class="group-hover:underline" data-yext="category">
                        {{ keyword }}
                    </span>
                </a>
            </li>

        </ng-container>

    </ul>
</section>