<div class="default-gradient border-gray-100">
    <div class="yp-container AYB-bg pt-20 pb-56 md:pb-20 bg-contain md:bg-680 bg-bottom md:bg-103 bg-white">
        <h1 class="font-semibold mb-14 text-4xl md:text-5xl md:w-144 sm:w-full text-shadow">
            {{ 'CYB.MainTitle' | translate }}
        </h1>
        <h2 class="font-light italic mb-14 text-20 md:text-1xl md:w-144 sm:w-full text-shadow">
            {{ 'CYB.MainText' | translate }}
        </h2>
    </div>
</div>

<div class="relative">

    <div id="aztrix-form" class="yp-container aztrix-default AYB-form-box-wrap">
        <div class="AYB-form-box border-gray-100 shadow mb-12 p-9.5 relative">
            <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 123 123" class="h-28 w-28 mx-auto absolute hidden md:block" style="top: -6.5rem; left: -1.5rem;">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" href="assets/icons/icons.svg#AYB-arrow-icon"></use>
            </svg>
            <div id="aztrix-default-inner" class="bordered-box p-1">

            </div>
        </div>
    </div>

</div>