<section 
    *ngIf="disableMap === false || isOh === true"
    id="opening-hours-and-map" 
    class="border-t mb-10 mt-4">

    <h2 class="font-medium mb-4 text-1xl pt-8"
        *ngIf="isOh === true">
        {{ 'OpeningHours' | translate }} {{ title }}
    </h2>

    <div class="grid gap-4" 
    [ngClass]="{
        'md:grid-cols-2': isOh === true,
        'grid-cols-1': isOh === false
    }">
     
        <!-- oh -->
        <app-opening-hours
            [listing]="listing"
            [open]="true">
        </app-opening-hours>

        <!-- map -->
        <app-map 
            [isOh]="isOh" 
            [detailPageType]="detailPageType"
            [pois]="pois"
            [disableMap]="disableMap">
        </app-map>

    </div>

</section>