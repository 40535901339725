<div class="md:-mx-4 -mx-8 -mt-4 border-b border-gray-200 flex font-bold h-52 is-not-logo items-center justify-center relative" 
    *ngIf="(hasLogo && trafficExtraContent); else elseNoLogo">
    <img class="max-h-full max-w-full self-center" 
    data-yext="logo" 
    [src]="logoPath"
    alt="" />
</div>
<ng-template #elseNoLogo>
    <div class="md:-mx-4 -mx-8 -mt-4 border-b border-gray-200 flex font-bold h-52 is-not-logo items-center justify-center relative p-4"
        *ngIf="(!isTrafficPage && showClaimLinkAll)">
        
        <div class="flex-col">
            <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 50 50" class="h-12 mx-auto w-12">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" href="assets/icons/icons.svg#building-icon"></use>
            </svg>

            <a *ngIf="showClaimLinkAll" 
                [routerLink]="'Routes.Static.ClaimYourBusiness' | translate"
                [queryParams]="{ listingId: listingId}" 
                target="_blank"
                class="font-normal text-blue-200 underline hover:no-underline no-redirect" 
                [trackingAction]="'ClaimYourListingClick'"
                [trackingCategory]="'DETAIL'" 
                [trackingListingId]="listingId">
                {{ 'OwnBusiness' | translate }}
            </a>
        </div>
    
        <!-- logo badge -->
        <div class="logo-badge" *ngIf="badges.length > 0">
            <div class="relative" *ngFor="let badge of badges">
                <img src="assets/images/ratingBadges/{{ badge.type }}{{ badge.index }}_{{ currentLang }}.png"
                    [alt]="badge.badge" [title]="badge.badge" />
                <span style="position: absolute; top: 18%; left: 22%; font-size: 58%; font-weight: 900; color: #4c4c4c;">
                    {{ badge.year }}
                </span>
            </div>
        </div>

    </div>
</ng-template>