<div class="yp-container card card--flow-y">
    <h1 class="text-xl font-semibold mb-4">{{ "Keywords" | translate }}:</h1>
    <ul class="flex flex-wrap mb-4">
        <li *ngFor="let letter of this.alphabet"
            class="mr-3">
            <app-button 
                [routerLink]="getKeywordUrl(letter)" 
                [label]="letter" 
                [showIcon]="false">
            </app-button>
        </li>
    </ul>
    <ul class="gap-x-4 grid grid-cols-1 mb20 md:grid-cols-2" *ngIf="this.result">
        <li *ngFor="let item of this.result">
            <app-button 
                [routerLink]="getSearchUrl(item.key)" 
                [label]="item.key" 
                [showIcon]="false">
            </app-button>
        </li>
    </ul>
</div>