<div class="yp-container" *ngIf="showRelatedCities">
    <div class="border border-gray-200 editorial--bottom flex flex-col leading-5 mb-4 p-4 rounded-2">
        <span class="editorial-heading font-bold mb-4">
            {{ this.whatValue }} {{ "RelatedSearchesHeading" | translate }}
        </span>
        <div class="editorial-column-wrap grid md:gap-4 md:grid-cols-3 text-sm">

            <ul>
                <!-- foreach -->
                <li *ngFor="let item of relatedCityItems[0]">
                    <a [routerLink]="item.url" class="hover:underline text-blue-200">
                        {{ item.label }}
                    </a>
                </li>
                <!-- /foreach -->
            </ul>
            <ul>
                <!-- foreach -->
                <li *ngFor="let item of relatedCityItems[1]">
                    <a [routerLink]="item.url" class="hover:underline text-blue-200">
                        {{ item.label }}
                    </a>
                </li>
                <!-- /foreach -->
            </ul>
            <ul>
                <!-- foreach -->
                <li *ngFor="let item of relatedCityItems[2]">
                    <a [routerLink]="item.url" class="hover:underline text-blue-200">
                        {{ item.label }}
                    </a>
                </li>
                <!-- /foreach -->
            </ul>
        </div>
    </div>
</div>