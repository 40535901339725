<li *ngIf="address" 
    class="flex mb-2.5"  
    (click)="openModalTemplate(addressMapModal)">
    <div class="flex">
        <app-icon [icon]="'poi-icon'" svgClass="h-6 pt-0.5 self-start w-5 mr-2 text-blue-600"></app-icon>
    </div>
    <span id="handleLinkOpenMap" class="hover:no-underline text-blue-200 underline cursor-pointer"
        [trackingAction]="'MapClick'" 
        [trackingCategory]="'DETAIL'" 
        [trackingListingId]="listing?.Id"
        title="map" 
        [innerHTML]="address">
    </span>
</li>

<!-- map modal on detail opened on address click -->
<ng-template #addressMapModal>
    <app-map 
        [id]="'modal-map'" 
        [mode]="'modal'" 
        [detailPageType]="detailPageType"
        [pois]="pois">
    </app-map>
</ng-template>