import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SearchArguments } from '../../models/search-arguments.model';
import { SearchStateService } from '../../services/search-state.service';

interface Page {
  pageNumber: number;
  pageLabel: number | string;
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Output() 
  emitPageNum: EventEmitter<SearchArguments> = new EventEmitter();

  @Input('pageSize')
  pageSize: number = 20;

  @Input('pageToShow')
  pagesToShow: number = 5;

  @Input('numFound')
  numFound: number = 0;

  currentPage: number = 1;
  
  pages: Page[] = [];
  searchArgs: SearchArguments;
  totalPages: number = 0;

  constructor(private searchStateService: SearchStateService) { }

  ngOnInit(): void {
    this.currentPage = this.searchStateService.getCurrentValues().page || 1;
    this.getTotalPages();
    this.pages = this.getPages();
  }

  public goToPage(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.handleSearchArgs();
  }

  public nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.handleSearchArgs();
    }
  }

  public prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.handleSearchArgs();
    }
  }

  private getPages(): Page[] {
    const pages: Page[] = [];
    const startPage = Math.max(1, this.currentPage - 4);
    const endPage = Math.min(this.totalPages, this.currentPage + 4);

    if (startPage > 2) {
      pages.push({ pageNumber: 1, pageLabel: 1 });
      pages.push({ pageNumber: startPage - 1, pageLabel: '...' });
    } else if (startPage === 2) {
      pages.push({ pageNumber: 1, pageLabel: 1 });
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push({ pageNumber: i, pageLabel: i });
    }

    if (endPage < this.totalPages - 1) {
      pages.push({ pageNumber: endPage + 1, pageLabel: '...' });
      pages.push({ pageNumber: this.totalPages, pageLabel: this.totalPages });
    } else if (endPage === this.totalPages - 1) {
      pages.push({ pageNumber: this.totalPages, pageLabel: this.totalPages });
    }

    return pages;
  }

  private getTotalPages(): void {
    this.totalPages = Math.ceil(this.numFound / this.pageSize);
  }

  private handleSearchArgs(): void {
    this.pages = this.getPages();
    this.searchStateService.setPage(this.currentPage);
    this.emitPageNum.emit(this.searchStateService.getCurrentValues());
  }
}
