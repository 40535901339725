<div class="md:grid grid-cols-1 md:gap-4 md:grid-cols-12">
    <!-- detail info -->
    <div class="col-span-9 md:mb-4">

        <div
            class="grid grid-cols-1 md:grid-cols-6 md:border md:border-gray-200 md:h-full md:min-h-250 md:shadow-small rounded-2 text-sm">
            <!-- logo -->
            <div
                class="col-span-2 flex font-bold is-not-logo items-center justify-center md:h-64 md:p-4 md:w-64 rounded-2 mx-auto h-44">
                <div class="flex items-end">
                    <app-icon [icon]="'logo_symbol_border'" svgClass="w-25 h-25"></app-icon>
                    <div>
                        <app-icon [icon]="'logo_txt_' + translate.currentLang" svgClass="w-18 h-4"></app-icon>
                        <span class="text-blue-600">
                            100% DIGITAL
                        </span>
                    </div>
                </div>

            </div>
            <!-- info -->
            <div class="col-span-4 flex flex-col md:p-4 pt-4 border-t md:border-0">

                <div class="flex justify-between">
                    <div class="ml-0 md:min-w-352">

                        <app-detail-title 
                            [listing]="listing" 
                            [detailPageType]="detailPageType">
                        </app-detail-title>

                        <ul class="text-gray-400">

                            <app-address [listing]="listing" [detailPageType]="detailPageType" [pois]="pois">
                            </app-address>

                            <!-- opening hours here -->
                            <app-opening-hours [listing]="listing" [open]="false">
                            </app-opening-hours>

                            <!-- is listing has webshop -->
                            <li app-website-url-btn 
                                *ngIf="showWebshop && showWebsite" 
                                [contacts]="websites"
                                [type]="'website'" 
                                [trafficLink]="listing?.TrafficLink" 
                                [style]="'link'"
                                [label]="'Website' | translate" 
                                [trackingAction]="'LinkClick'"
                                [trackingCategory]="'DETAIL'"
                                [trackingListingId]="listing?.Id"
                                class="basis-42">
                            </li>
                            
                            <li class="flex mb-2.5" *ngIf="requestForms?.enabledRequestForm">
                                <!-- isQuote -->
                                <ng-container *ngIf="requestForms?.isQuote; else elseAppointment">
                                    <app-button (click)="openQuoteForm()" [icon]="'quote-icon'"
                                        [label]="'Quote' | translate" [style]="'link'">
                                    </app-button>
                                </ng-container>
                                <ng-template #elseAppointment>
                                    <!-- isAppointment -->
                                    <app-button (click)="openAppointmentForm()" [icon]="'appointment-icon'"
                                        [label]="'Appointment' | translate" [style]="'link'">
                                    </app-button>
                                </ng-template>
                            </li>

                        </ul>
                    </div>

                    <app-share-button
                        [listing]="listing">
                    </app-share-button>
                </div>

                <!-- actions -->
                <ul class="grid grid-cols-2 md:flex gap-2 md:justify-end md:mt-auto md:mb-0 md:pb-0 pb-8 mb-4 mt-8" #actionsRef>

                    <!-- webshop -->
                    <li app-website-url-btn 
                        *ngIf="showWebshop; else elseWebsite" 
                        [btnBorder]="'thin'"
                        [contacts]="webshops" 
                        [showIcon]="true" 
                        [icon]="'webshop-icon'"
                        [type]="'webshop'" 
                        [style]="'outline'"
                        [label]="'Webshop' | translate"
                        [trackingAction]="'WebshopClick'"
                        [trackingCategory]="'DETAIL'"
                        [trackingListingId]="listing?.Id"
                        class="min-w-28.5">
                    </li>
                    <!-- website -->
                    <ng-template #elseWebsite>
                        <li app-website-url-btn 
                            *ngIf="showWebsite" 
                            [btnBorder]="'thin'" 
                            [contacts]="websites"
                            [showIcon]="true" 
                            [style]="'outline'" 
                            [type]="'website'" 
                            [label]="'Website' | translate"
                            [trackingAction]="'LinkClick'"
                            [trackingCategory]="'DETAIL'"
                            [trackingListingId]="listing?.Id"
                            [trafficLink]="listing?.TrafficLink" class="min-w-28.5">
                        </li>
                    </ng-template>

                    <!-- email -->
                    <li app-email-btn class="min-w-28.5" 
                        [btnBorder]="'thin'" 
                        [showIcon]="true" 
                        [contacts]="emails"
                        *ngIf="emails[0]?.hasContact"
                        [trackingAction]="'EmailBtnClick'"
                        [trackingCategory]="'DETAIL'"
                        [trackingListingId]="listing?.Id">
                    </li>

                    <!-- plan route -->
                    <li app-plan-route-btn 
                        [btnBorder]="'thin'" 
                        [pois]="pois"
                        [detailPageType]="detailPageType" 
                        [showIcon]="true" 
                        class="min-w-28.5"
                        *ngIf="pois[0].location.latitude !== null">
                    </li>

                    <!-- phone -->
                    <li app-phone-number-btn class="min-w-28.5 relative" 
                        [btnBorder]="'thin'" 
                        [showIcon]="true"
                        [contacts]="phones" *ngIf="phones[0]?.hasContact">
                    </li>

                </ul>
            </div>
        </div>
    </div>
    <!-- banner -->
    <div class="col-span-3">

        <ng-container *ngIf="!hasParameter && !listing?.IsPaid">
            <!-- banner here -->
            <div class="align-center mb-4 mt-4" *ngIf="checkDeviceService.isMobile$ | async; else elseOtherDevices">
                <div class="banner-box">

                    <ins 
                        [attr.data-revive-zoneid]="('banners.freeSquare' | translate)" 
                        [attr.data-revive-id]="adId"
                        [attr.data-revive-target]="'_blank'"
                        [attr.data-revive-block]="1"
                        [attr.data-revive-code]="adKey">
                    </ins>
                    
                </div>
            </div>
            <ng-template #elseOtherDevices>
                <div class="rectangle-box">
                    <div class="rectangle-box__inner">
                        
                        <ins 
                            [attr.data-revive-zoneid]="('banners.IMUBanner' | translate)" 
                            [attr.data-revive-id]="adId"
                            [attr.data-revive-target]="'_blank'"
                            [attr.data-revive-block]="1"
                            [attr.data-revive-code]="adKey">
                        </ins>

                    </div>
                </div>
            </ng-template>
        </ng-container>

    </div>
    <!-- banner -->
    <div class="col-span-3">
        <!-- AYB banner -->
        <div class="flex gap-2 AYB-banner AYB-banner--mobile">
            <p class="font-normal mb-0">{{ 'AYBText' | translate }}</p>
            <a [href]="('AYB_BannerLink' | translate) + '?utm_source=goudengids&utm_medium=link&utm_campaign=banner'"
                target="_blank"
                class="no-redirect" 
                [trackingAction]="'ClaimYourListingClick'" 
                [trackingCategory]="'DETAIL'"
                [trackingListingId]="listing?.Id">
                {{ 'AYBButton' | translate }}
            </a>
        </div>
    </div>
    <!-- banner -->
    <div class="col-span-9">
        <!-- banner here -->
        <ng-container *ngIf="!hasParameter && !listing?.IsPaid">
            <div class="align-center mt-4" *ngIf="checkDeviceService.isMobile$ | async; else elseOtherDevices">
                <div class="banner-box banner-box--mobile">

                    <ins 
                        [attr.data-revive-zoneid]="('banners.IMUMobile' | translate)" 
                        [attr.data-revive-id]="adId"
                        [attr.data-revive-target]="'_blank'"
                        [attr.data-revive-block]="1"
                        [attr.data-revive-code]="adKey">
                    </ins>

                </div>
            </div>
            <ng-template #elseOtherDevices>
                <div class="align-center">
                    <div class="banner-box banner-box--top">

                        <ins 
                            [attr.data-revive-zoneid]="('banners.freeSquare' | translate)" 
                            [attr.data-revive-id]="adId"
                            [attr.data-revive-target]="'_blank'"
                            [attr.data-revive-block]="1"
                            [attr.data-revive-code]="adKey">
                        </ins>

                    </div>
                </div>
            </ng-template>
        </ng-container>
    </div>
    <!-- detail content -->
    <div class="col-span-9">

        <!-- wijwerken description? -->
        <section *ngIf="!utils.isNullOrEmpty(listing?.WijwerkenDescription)" 
            id="GO__wijwerken-description" 
            class="card card--flow-y card--box border-t mb-10 mt-4 pt-8"
            [innerHTML]="listing?.WijwerkenDescription">
        </section>

        <app-labels-and-brands [listing]="listing"></app-labels-and-brands>

        <app-oh-and-map 
            [listing]="listing" 
            [detailPageType]="detailPageType" 
            [pois]="pois">
        </app-oh-and-map>

        <app-extra-articles [listing]="listing" [detailPageType]="detailPageType">
        </app-extra-articles>

        <!-- reviews component here -->
        <app-review-display 
            [listing]="listing">
        </app-review-display>

        <app-social-links [listing]="listing"></app-social-links>

        <app-categories [categories]="listing?.Category">
        </app-categories>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

            <app-financial-info 
                [listing]="listing"
                [detailPageType]="detailPageType">
            </app-financial-info>

            <app-parking-info
                [listing]="listing">
            </app-parking-info>

        </div>

        <!-- banner here -->
        <div class="align-center">
            <div class="banner-box iframeWrap iframeWrap--mobile mr-auto ml-auto" style="max-width: 728px;">
                <!-- Revive Adserver Asynchronous JS Tag - Generated with Revive Adserver v4.1.4 -->
                <ng-container *ngIf="!(checkDeviceService.isMobile$ | async) else elseMobileBanner">
                    <ins 
                        [attr.data-revive-zoneid]="('banners.freeUnderDetail' | translate)" 
                        [attr.data-revive-id]="adId"
                        [attr.data-revive-target]="'_blank'" 
                        [attr.data-revive-block]="1"
                        [attr.data-revive-code]="adKey">
                    </ins>
                </ng-container>
                <ng-template #elseMobileBanner>
                    <ins 
                        [attr.data-revive-zoneid]="('banners.freeUnderDetailMobile' | translate)" 
                        [attr.data-revive-id]="adId"
                        [attr.data-revive-target]="'_blank'" 
                        [attr.data-revive-block]="1"
                        [attr.data-revive-code]="adKey">
                    </ins>
                </ng-template>

            </div>
        </div>
        
    </div>

    <div class="col-span-3">

        <section id="rivals" *ngIf="rivals?.length > 0">
            <h2 class="font-medium mb-4 text-blue-600 text-xl">
                {{ 'MightInterested' | translate }}
            </h2>
            <ul class="">

                <app-related-listing 
                    *ngFor="let rival of rivals" 
                    [listing]="rival">
                </app-related-listing>

            </ul>
        </section>

        <!-- banner here -->
        <div *ngIf="!listing.IsPaid && !(checkDeviceService.isMobile$ | async)"
            class="rectangle-box">
            <div class="rectangle-box__inner">

                <ins 
                    [attr.data-revive-zoneid]="('banners.freeBottom' | translate)" 
                    [attr.data-revive-id]="adId"
                    [attr.data-revive-target]="'_blank'"
                    [attr.data-revive-block]="1"
                    [attr.data-revive-code]="adKey">
                </ins>

            </div>
        </div>

    </div>
</div>

<ng-container *ngIf="checkDeviceService.isMobile$ | async">
    <a (click)="navigateBack()"
        class="bottomMapOpener border-2 border-blue-200 bg-white -mb-8 items-center gap-2 shadow-small pr-4 pt-2 pb-2 pl-2"
        id="phoneNumberHidden"
        #stickyButtonRef>
        <div class="w-10 h-10 p-3 rounded-full bg-yp_yellow flex">
            <app-icon [icon]="'search-icon'" [svgClass]="'h-5 left-4 text-blue-600 top-3.5 w-5'"></app-icon>
        </div>
        <div class="text-sm text-black font-medium whitespace-nowrap">{{ 'BackToResultPage' | translate }}</div>
    </a>
</ng-container>