import { Component, Input } from '@angular/core';
import { ContactInfo } from '../../helpers/contact.helper';
import { Contact } from '../../models/contact.model';
@Component({
  selector: 'app-contact-dropdown',
  templateUrl: './contact-dropdown.component.html',
  styleUrl: './contact-dropdown.component.scss'
})
export class ContactDropdownComponent {
  @Input('contacts') 
  contacts: ContactInfo[];

  @Input('isOpen')
  isOpen: boolean = false;

  @Input('showIcon')
  showIcon: boolean = true;

  href: string = "";

  getHref(contact: Contact) {
    let href = "";
    switch(contact.type) {
      case 'PHONE':
      case 'MOBILE':
      case 'PHONE_FAX':
      case 'FAX':
         href = 'tel:'
         break;
      case 'EMAIL':
        href = 'mailto:';
        break;
    }

    return href + contact.value;
  }

  getIcon(contact: Contact): string {
    switch(contact.type) {
      case 'PHONE':
      case 'MOBILE':
        return 'phone-icon';
      case 'PHONE_FAX':
      case 'FAX':
         return'fax-icon';
      case 'EMAIL':
        return 'email-icon';
      case 'URL':
        return 'website-icon';
      default:
        return "";
    }
  }
}
