<div class="grow"
    [attr.data-index]="index"
    itemprop="itemListElement" 
    itemscope
    itemtype="http://schema.org/LocalBusiness">

    <a [routerLink]="detailUrl" class="absolute bottom-0 left-0 right-0 top-0 z-10"
        [trackingAction]="'MoreInfoClick'" 
        [trackingCategory]="'SEARCH'" 
        [trackingListingId]="listing?.Id"
        [ariaLabel]="this.listing.Title">
    </a>

    <div class="result-item__inner">

        <div class="result-item__logo">

            <!-- logo -->
            <div class="result-item__logo-inner" [ngClass]="{'md:flex hidden': hasLogo === false}">
                <img *ngIf="hasLogo" 
                    class="max-h-full max-w-full self-center logo-item h-28 object-contain "
                    [src]="logoPath"
                    [title]="listing?.Title + 'Logo'" 
                    [alt]="listing?.Title + 'Logo'" 
                    itemprop="logo" 
                    loading="lazy"/>
            </div>

            <!-- rating badges under logo -->
            <app-badges [listing]="listing"></app-badges>

            <!-- promo badge next to logo -->
            <app-result-special-offer [listing]="listing"></app-result-special-offer>

        </div>

        <div class="result-item__data">

            <div class="flex gap-4 justify-between">
                <a [routerLink]="detailUrl" class="flex gap-2">
                    <h2 itemprop="name" class="font-bold">
                        <span class="words-break">{{ listing?.IsFolder ? ('AllCompaniesFromFolder' | translate) : '' }} {{ listing?.Title }}</span>
                    </h2>

                    <app-claim-button
                        [listing]="listing">
                    </app-claim-button>
                </a>

                <div class="flex gap-2 items-start">

                    <a [routerLink]="detailUrl + '#addreview'" id="addReviewButton"
                        class="flex items-center modal gap-2" 
                        [trackingAction]="'ReviewClick'" 
                        [trackingCategory]="'SEARCH'"
                        [trackingListingId]="listing?.Id"
                        [title]="listing?.Title">

                        <app-rating [ratingAverage]="avgRatingRounded"></app-rating>

                        <div class="active font-semibold text-blue-200" *ngIf="this.isAverageBiggerThanZero()">
                            <span>{{ avgRatingRounded }}</span>
                        </div>

                        <span class="text-sm text-gray-400" *ngIf="listing?.RatingCount > 0">
                            ({{ listing?.RatingCount }})
                        </span>

                    </a>

                    <div class="cursor-pointer relative z-20" 
                        (click)="toggleShortlist()"
                        [trackingAction]="'FavouritesButtonClick'"
                        [trackingCategory]="'SEARCH'"
                        [trackingListingId]="listing?.Id"
                        [ngClass]="{
                            'text-blue-600': isShortlist,
                            'text-white': !isShortlist
                        }">
                        <app-icon [icon]="'favourite-icon'" svgClass="h-6 pt-0.5 w-5"></app-icon>
                    </div>

                </div>

            </div>

            <div class="grid md:grid-cols-9 md:gap-4 gap-2">

                <ul class="flex flex-col gap-2 text-gray-400 col-span-4">

                    <li *ngIf="address"
                        class="flex gap-2" 
                        itemprop="address" 
                        itemscope 
                        itemtype="http://schema.org/PostalAddress">
                        <app-icon [icon]="'poi-icon'" svgClass="h-6 w-5 self-start text-blue-600"></app-icon>
                        <span [innerHTML]="address"></span>
                    </li>

                    <li *ngIf="this.displayServesLocation"
                        class="flex gap-2" >
                        <app-icon [icon]="'thumbs-up-icon'" svgClass="h-6 w-5 self-start text-blue-600"></app-icon>
                        <span>{{ "Serves" | translate }} {{ this.displayServesLocation }}</span>
                    </li>

                    <li class="flex gap-2" *ngIf="OHStatus">
                        <app-icon [icon]="'hours-icon'" svgClass="h-5 w-5 self-start text-blue-600"></app-icon>
                        <span class="opening__hours__state">
                            <span class="state" 
                                [ngClass]="{
                                    'state--open': OHStatus.CommentedOpeningHours?.isCurrentlyOpen === true,
                                    'state--close': OHStatus?.CommentedOpeningHours?.isCurrentlyOpen === false && OHStatus?.CommentedOpeningHours?.isClosingSoon === false,
                                    'state--closing-soon': OHStatus?.CommentedOpeningHours?.isClosingSoon === true || OHStatus?.CommentedOpeningHours.isOpeningSoon == true}">{{ ohStatus }}</span> -
                        </span>
                        <span class="open_hours">
                            <span class="flex font-normal items-center">
                                <span class="opening__hours flex flex-col items-end">
                                    <span class="md:mr-3" *ngFor="let item of OHStatus?.CommentedOpeningHours.times">
                                        {{ item.from.hours }}:{{ item.from.minutes || "00" }} - {{ item.to.hours }}:{{ item.to.minutes || "00" }}
                                    </span>

                                </span>
                            </span>

                        </span>
                    </li>

                </ul>

                <ng-container *ngIf="listing?.IsTrafficPage && listing?.IsPaid && listing?.TrafficLevel >= 3">
                    <ng-container *ngIf="listing?.UniqueSummary else elseDescription">
                        <div class="shortcut-traffic col-span-5" [innerHTML]="listing?.UniqueSummary"></div>
                    </ng-container>
                    <ng-template #elseDescription>
                        <ng-container *ngIf="listing?.TrafficDescriptions?.length > 0; else description">
                            <div 
                                class="shortcut-traffic col-span-5" 
                                [innerHTML]="listing?.TrafficDescriptions[0]">
                            </div>
                        </ng-container>
                        <ng-template #description>
                            <div 
                                *ngIf="listing?.Description" 
                                class="shortcut-traffic col-span-5" 
                                [innerHTML]="listing?.Description">
                            </div>
                        </ng-template>
                    </ng-template>
                </ng-container>

            </div>

            <div id="result-item-actions" class="xl:flex gap-2 justify-end mt-auto grid sm:grid-cols-2 xs:grid-cols-1">
                <ng-container *ngIf="isPaid">
                    <!-- phone -->
                    <div app-phone-number-btn 
                        class="relative basis-42 z-30" 
                        [contacts]="phones" 
                        [btnBorder]="'thin'"
                        *ngIf="phones[0]?.hasContact"
                        [trackingAction]="'PhoneNumberClick'"
                        [trackingCategory]="'SEARCH'"
                        [trackingListingId]="listing?.Id">
                    </div>
                </ng-container>
                    <!-- webshop -->
                    <div app-website-url-btn 
                        *ngIf="showWebshop && isPaid || showWebshop && showWebshopForFree; else elseWebsite" 
                        [btnBorder]="'thin'"
                        [contacts]="webshops"
                        [icon]="'webshop-icon'"
                        [label]="'Webshop' | translate"
                        [style]="'outline'"
                        class="relative basis-42 z-30"
                        [trackingAction]="'WebshopClick'"
                        [trackingCategory]="'SEARCH'"
                        [trackingListingId]="listing?.Id">
                    </div>
                    <!-- website -->
                    <ng-template #elseWebsite>
                        <ng-container *ngIf="isPaid">
                            <div app-website-url-btn
                                *ngIf="showWebsite" 
                                [btnBorder]="'thin'"
                                [contacts]="websites"
                                [style]="'outline'"
                                [trafficLink]="listing?.TrafficLink"
                                [trackingAction]="'LinkClick'"
                                [trackingCategory]="'SEARCH'"
                                [trackingListingId]="listing?.Id"
                                class="relative basis-42 z-30">
                            </div>
                        </ng-container>
                    </ng-template>

                <!-- more info button -->
                <app-button 
                    [routerLink]="detailUrl"
                    [label]="'ResultItem_MoreInfo' | translate"
                    [style]="'outline'"
                    [btnBorder]="'thin'"
                    class="relative basis-42 z-30"
                    [trackingAction]="'MoreInfoClick'"
                    [trackingCategory]="'SEARCH'"
                    [trackingListingId]="listing?.Id">
                </app-button>

            </div>
        </div>
    </div>
</div>
<div *ngIf="debug?.data?.debug !== null && showDebug === true">
    <app-debug-info 
        [listing]="listing"
        [debug]="debug">
    </app-debug-info>
</div>