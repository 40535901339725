import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable } from 'rxjs';
import { BLOG_PLATFORM } from '../../../../constants/constants';
import { ArticleType } from '../../../../enums/article-type.enum';
import { UrlHelper } from '../../../../helpers/url-builder.helper';
import { ArticleSnippetDto } from '../../../../models/blog-post-snippet.model';
import { ExtraBlogPost } from '../../../../models/extra-blog-post.model';
import { ListingModel } from '../../../../models/listing.model';
import { DetailPageType, ShareDataService } from '../../../../services/share-data.service';
import { Utils } from '../../../../utils/utils';

export interface ExtraArticles {
  articles: ArticleSnippetDto[],
  blogPosts: ArticleSnippetDto[]
}

export interface ExtraArticle extends ArticleSnippetDto {
  showSponsor: boolean
}

@Component({
  selector: 'app-extra-articles',
  templateUrl: './extra-articles.component.html',
  styleUrl: './extra-articles.component.scss'
})
export class ExtraArticlesComponent implements OnInit {
  relatedBlogPosts$: Observable<ArticleSnippetDto[]>;
  popularBlogPosts$: Observable<ArticleSnippetDto[]>;
  extraArticles$: Observable<ExtraArticles>;

  all$: Observable<ArticleSnippetDto[]>;

  @Input('listing')
  listing: ListingModel;
  
  @Input('childListing')
  childListing: ListingModel;

  @Input('detailPageType')
  detailPageType: DetailPageType;

  extraBlogPosts: ExtraBlogPost[] = [];

  loading: boolean = true;
  articleType: ArticleType;

  showSponsorName: boolean = false;

  // displaying articles
  displayedArticles: ExtraArticle[] = [];

  // remaining 
  remainingArticles: ExtraArticle[] = [];

  loadBatchSize = 3;

  hasArticles:boolean = false; 

  parent: ListingModel;
  child: ListingModel;

  constructor(
    private shareDataService: ShareDataService,
    private urlHelper: UrlHelper,
    private translate: TranslateService,
    private utils: Utils
  ) {}

  ngOnInit() {
    let listingIds = [this.listing?.Id];
    if (this.childListing) {
      listingIds = [...listingIds, this.childListing?.Id];
    }

    // if CHILD set new detail page type
    this.detailPageType = this.getListingPageType();
    const segments = this.listing?.TaxonomyInfo?.segment ? [this.listing?.TaxonomyInfo?.segment] : [];
    if (this.detailPageType === "free") {
      this.handleExtraArticlesForFree(segments, listingIds);
    } else {
      this.handleExtraArticlesForPaid(segments, listingIds);
    }
  }

  handleExtraArticlesForPaid(segments: string[], listingIds) {
    const extraArtcilesReq = { 
      platform: BLOG_PLATFORM, 
      uniListingIds: listingIds,
      category: this.listing?.Category?.[0],
      segments,
      paid: true,
      limit: 3 
    }
    this.all$ = this.shareDataService.fetchExtraBlogPosts(extraArtcilesReq)
    .pipe(
      map((extra) => this.getRemainingArticles(extra))
    );
  }

  handleExtraArticlesForFree(segments: string[], listingIds) { 
    const headingId = this.getListingHeading();
    const extraBlogPostsReq = { 
      headingId, 
      platform: BLOG_PLATFORM, 
      limit: 3 
    };
    const extraArtcilesReq = { 
      platform: BLOG_PLATFORM, 
      uniListingIds: listingIds,
      category: this.listing?.Category?.[0],
      segments,
      limit: 3 
    }

    // related
    this.relatedBlogPosts$ = this.shareDataService.fetchRelatedBlogPosts(extraBlogPostsReq);
    // popular
    this.popularBlogPosts$ = this.shareDataService.fetchPopularBlog(extraArtcilesReq);
    // extra articles
    this.extraArticles$ = this.shareDataService.fetchExtraBlogPosts(extraArtcilesReq);

    this.all$ = forkJoin({
      related: this.relatedBlogPosts$,
      popular: this.popularBlogPosts$,
      extra: this.extraArticles$
    }).pipe(
      map(({ related, popular, extra }) => this.getRemainingArticles(extra, popular, related))
    );
  }

  getRemainingArticles(extra: ExtraArticles, popular: ArticleSnippetDto[] = [], related: ArticleSnippetDto[] = []): ExtraArticle[] {
    const filteredArticles = [...extra.articles, ...extra.blogPosts, ...popular, ...related].filter(article => article?.title !== null);
    const uniqueArticles = this.removeDuplicateArticles(filteredArticles);
    this.remainingArticles = this.updateArticleUrlAndSponsor(uniqueArticles);
    this.loadMoreArticles();

    return this.remainingArticles;
  }

  removeDuplicateArticles(articlesOrBlogPosts: ArticleSnippetDto[] | ExtraBlogPost[]) {
    let uniqueObj = {};
    articlesOrBlogPosts.forEach(article => uniqueObj[article.id] = article);

    let filteredArticles = Object.keys(uniqueObj).map(value => uniqueObj[value]);

    const sortByArticleType = filteredArticles.sort((a, b) => a.articleType - b.articleType);
    return sortByArticleType;
  }

  getListingPageType() {
    const isFree = !this.listing?.IsTrafficPage && !this.listing?.IsPaid;
    return isFree ? "free" : this.detailPageType;
  }

  getListingHeading() {
    return this.listing?.CategoryId?.length > 0 ? this.listing?.CategoryId[0] : null
  }

  getHref(article: ArticleSnippetDto): string {
    const { headings, id, title, customUrl } = article;
    const heading = headings?.[0];
    const route = this.translate.instant("Routes.Blog.BlogDetail");
    return customUrl ?? this.urlHelper.getBlogPageRoute(route, heading, id, title);
  }

  updateArticleUrlAndSponsor(extraArticles: ArticleSnippetDto[]): ExtraArticle[] {
    return extraArticles
      .map(article => {
        const displaySponsorName = (article.articleType === ArticleType.SPONSOR
          || article.articleType === ArticleType.ADVERTORIALPAID
          || article.articleType === ArticleType.ADVERTORIALFREE) 
          && !this.utils.isNullOrEmpty(article.listingSponsorName);

        return {
          ...article,
          customUrl: this.getHref(article),
          showSponsor: displaySponsorName
        } as ExtraArticle
      });
  }

  loadMoreArticles(): void{
    let loadBatchSize = this.loadBatchSize;
    const nextBatchArticles = this.remainingArticles.splice(0, loadBatchSize);
    loadBatchSize = nextBatchArticles.length;

    // display batch
    this.displayedArticles.push(...nextBatchArticles);
  }
}
