import {
  ApplicationRef,
  ComponentRef,
  EnvironmentInjector,
  Injectable,
  TemplateRef,
  Type,
  ViewContainerRef,
  createComponent,
  Component,
  ViewChild,
} from '@angular/core';
import { ModalComponent } from './modal.component';
import { Options } from './modal-options';
import { TrackingCategory, TrackingService } from '../../services/tracking.service';

@Component({
  template: '<ng-template #vc></ng-template>',
})
export class ModalHostComponent {
  @ViewChild('vc', { read: ViewContainerRef, static: true }) vc!: ViewContainerRef;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  newModalComponent!: ComponentRef<ModalComponent>;
  hostComponentRef!: ComponentRef<any>;
  options!: Options | undefined;

  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector,
    private tracking: TrackingService
  ) {}

  // Overload signatures
  open(
    template: TemplateRef<Element>,
    options?: Options,
    callback?: any,
    trackingAction?: string,
    trackingCategory?: TrackingCategory
  ): void;
  
  open<C>(
    component: Type<C>,
    options?: Options,
    callback?: any,
    trackingAction?: string,
    trackingCategory?: TrackingCategory,
  ): void;

  // Implementation signature
  open<C>(
    templateOrComponent: TemplateRef<Element> | Type<C>,
    options?: Options,
    callback?: any,
    trackingAction?: string,
    trackingCategory?: TrackingCategory
  ) {
    this.options = options;
    
    if (trackingAction && trackingCategory) {
      this.tracking.trackEvent(trackingCategory, trackingAction);
    }

    if (templateOrComponent instanceof TemplateRef) {
      this.openWithTemplate(templateOrComponent, callback);
    } else {
      this.openWithComponent(templateOrComponent as Type<C>, this.options?.listingId, callback);
    }
  }

  private openWithTemplate(
    content: TemplateRef<Element>,
    callback: any
  ) {
    const hostComponent = createComponent(ModalHostComponent, {
      environmentInjector: this.injector
    });
    this.hostComponentRef = hostComponent;

    this.appRef.attachView(this.hostComponentRef.hostView);
    document.body.appendChild(this.hostComponentRef.location.nativeElement);

    const hostViewContainerRef = this.hostComponentRef.instance.vc;
    const innerContent = hostViewContainerRef.createEmbeddedView(content);

    this.newModalComponent = hostViewContainerRef.createComponent(ModalComponent, {
      environmentInjector: this.injector,
      projectableNodes: [innerContent.rootNodes],
    });

    if (callback) {
      this.newModalComponent.instance.viewInitialized.subscribe(() => {
        callback();
      });
    }
  }

  private openWithComponent<C>(
    component: Type<C>,
    listingId?: string,
    callback?: any
  ) {
    const newComponent = createComponent(component, {
      environmentInjector: this.injector,
    });

    this.newModalComponent = createComponent(ModalComponent, {
      environmentInjector: this.injector,
      projectableNodes: [[newComponent.location.nativeElement]],
    });

    // Set listingId if provided
    if (listingId) {
      this.newModalComponent.instance.listingId = listingId;
    }

    document.body.appendChild(this.newModalComponent.location.nativeElement);

    // Attach views to the changeDetection cycle
    this.appRef.attachView(newComponent.hostView);
    this.appRef.attachView(this.newModalComponent.hostView);

    if (callback) {
      this.newModalComponent.instance.viewInitialized.subscribe(() => {
        callback();
      });
    }
  }

  close() {
    if (this.newModalComponent) {
      if (this.newModalComponent.location && this.newModalComponent.location.nativeElement.parentNode) {
        // if we insert a template we have to check the parentNode
        let removingChild = this.newModalComponent.location.nativeElement;
        if (this.newModalComponent.location.nativeElement.parentNode.nodeName !== "BODY") {
          removingChild = this.newModalComponent.location.nativeElement.parentNode;
        }

        document.body.removeChild(removingChild);
        
        // detach view from change detection cycle
        this.appRef.detachView(this.newModalComponent.hostView);
        // destroy component
        this.newModalComponent.destroy();
        this.newModalComponent = undefined;
      }

      if (this.hostComponentRef && this.hostComponentRef.location && this.hostComponentRef.location.nativeElement.parentNode) {
        document.body.removeChild(this.hostComponentRef.location.nativeElement);
        // detach view from change detection cycle
        this.appRef.detachView(this.hostComponentRef.hostView);
        // destroy component
        this.hostComponentRef.destroy();
        this.hostComponentRef = undefined;
      }
    }
  }

  getListingId(): string {
    return this.options?.listingId || '';
  }
}