<ng-container *ngIf="oh && oh.hours.length > 0">
    
    <ng-container *ngIf="showOh === true">
        <li class="flex gap-2 mb-2.5 opening-hours" *ngIf="open === false">
            <app-icon [icon]="'hours-icon'" [svgClass]="'pt-0.5 self-start text-blue-600 w-5 h-5'"></app-icon>
            <!-- not closed - display one line -->
            <span class="opening__hours__state" 
                *ngIf="ohStatus?.CurrentStatus !== 'Closed'">
                <span [ngClass]="'state ' + ohStatusClass">{{ ohStatusLabel }}</span> -
            </span>
            <span class="open_hours"
                (click)="handleOhOpen()">
                <!-- closed - display two lines -->
                <span *ngIf="ohStatus?.CurrentStatus === 'Closed'; else elseOtherStatus">
                    <span class="opening__hours__state">
                        <span class="state state--close">{{ ohStatusLabel }}</span>
                    </span>
                    <span class="ml-1 open_hours">
                        <span class="flex font-normal hover:no-underline items-center text-blue-200 underline cursor-pointer">
                            <span class="opening__hours whitespace-nowrap flex flex-row gap-1 mr-2" data-yext="business-hours">
                                <span>{{ currentDay }}</span> 
                                <span class="flex flex-col" 
                                    *ngFor="let currentTime of currentOhTimes">
                                    {{ currentTime }}
                                </span>
                            </span>
                            <app-icon [icon]="'arrow-down-icon'" [svgClass]="'w-2.5 h-2.5 pt-0.5 transform'" [ngClass]="{'rotate-180' : isOpen}"></app-icon>
                        </span>
                    </span>
                </span>
                <ng-template #elseOtherStatus>
                    <span class="flex font-normal hover:no-underline items-center text-blue-200 underline cursor-pointer">
                        <span class="opening__hours whitespace-nowrap mr-2" data-yext="business-hours"
                            *ngFor="let currentTime of currentOhTimes">
                        {{ currentTime }}
                        </span>
                        <app-icon [icon]="'arrow-down-icon'" [svgClass]="'w-2.5 h-2.5 pt-0.5 transform'" [ngClass]="{'rotate-180' : isOpen}"></app-icon>
                    </span>
                </ng-template>
                <span class="oh-table__note align-right italic"
                    *ngIf="ohStatus?.CommentedOpeningHours?.comment.length > 0">
                    {{ ohStatus?.CommentedOpeningHours?.comment }}
                </span>
            </span>
        </li>
    </ng-container>

    <!-- open all oh when click on actual one -->
    <ng-container *ngIf="open === false; else elseTimeTable">
        <li class="mb-4 pl-7"
            *ngIf="isOpen">
            <div class="border-gray-400 border-t pt-2 w-4"></div>
            <ng-container *ngFor="let oh of ohTimes">
                <div class="flex md:items-center md:flex-row items-start"
                    [ngClass]="{'mb-1':oh?.comment?.length === 0}">
                    <div class="">{{ oh.ohDay }}</div>
                    <div class="flex ml-auto">
                        <div class="whitespace-nowrap" [ngClass]="{
                            'oh-table__am': index == 0,
                            'oh-table__pm ml-1': index == 1}" 
                            *ngFor="let time of oh.ohTimesArr; let index = index;">
                            {{ time }}
                        </div>
                    </div>
                </div>
                <div *ngIf="oh?.comment?.length > 0"
                    class="text-right italic"
                    [ngClass]="{'mb-1':oh?.comment?.length > 0}">
                    ({{ oh.comment }})
                </div>
            </ng-container>
            <ng-container *ngIf="oh?.note">
                <div class="border-gray-400 border-t mt-3 pt-3 w-4"></div>
                <div class="oh-table__row italic">{{ oh?.note }}</div>
            </ng-container>
        </li>
    </ng-container>
    <!-- time table on detail -->
    <ng-template #elseTimeTable>
        <div class="opening-hours text-3.5 text-gray-400">
            <div class="px-1 py-2 flex flex-col items-end"
                *ngFor="let oh of ohTimes">
                <div class="flex justify-between w-full">
                    <div class="">{{ oh.ohDay }}</div>
                    <div class="flex justify-end ml-auto">
                        <ng-container *ngFor="let time of oh.ohTimesArr; let index = index;">
                            <ng-container *ngIf="time === null; else displayTimes">
                                ({{ oh.comment }})
                            </ng-container>
                            <ng-template #displayTimes>
                                <div class="whitespace-nowrap" 
                                    [ngClass]="{
                                    'oh-table__am': index == 0, 
                                    'oh-table__pm ml-2': index == 1}">
                                    {{ time }}
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="oh?.comment?.length > 0"
                    class="italic"
                    [ngClass]="{'mb-1':oh?.comment?.length > 0}">
                    ({{ oh.comment }})
                </div>
            </div>
        </div>
        <div *ngIf="oh?.note" class="mt-2 text-gray-400 text-3.5 italic">{{ oh?.note }}</div>
    </ng-template>
</ng-container>