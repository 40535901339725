import { Component, Input } from '@angular/core';
import { ArticleSnippetDto } from '../../../../models/blog-post-snippet.model';

@Component({
  selector: '[app-result-popular-articles]',
  templateUrl: './result-popular-articles.component.html',
  styleUrl: './result-popular-articles.component.scss'
})
export class ResultPopularArticlesComponent {
  @Input()
  articles: ArticleSnippetDto[] = [];
}
