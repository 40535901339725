import { Component, Input, OnInit } from '@angular/core';
import { modalOptions } from '../../../../components/modal/modal-options-values';
import { ModalService } from '../../../../components/modal/modal.service';
import { ReviewFormComponent } from '../review-form/review-form.component';
import { SpecialActions } from '../../../../enums/special-actions.enum';
import { SpecialActionsHelper } from '../../../../helpers/special-actions.helper';
import { ListingModel } from '../../../../models/listing.model';

@Component({
  selector: 'app-ratings-and-reviews-info',
  templateUrl: './ratings-and-reviews-info.component.html',
  styleUrl: './ratings-and-reviews-info.component.scss'
})
export class RatingsAndReviewsInfoComponent implements OnInit {
  @Input()
  listing: ListingModel;
  avgRatingRounded: string;
  modalOptions = {};

  disableRaviews: boolean = false;

  constructor(
    private modalService: ModalService,
    private specialActionsHelper: SpecialActionsHelper) {}

  ngOnInit() {
    this.avgRatingRounded = parseFloat(this.listing?.RatingAverage).toFixed(1);
    const listingId = { listingId: `${this.listing.Id}` };
    this.modalOptions = {...modalOptions, ...listingId };
    this.disableRaviews = this.specialActionsHelper.hasAction(this.listing, SpecialActions.DisableReviews);
  }

  openReviewForm() {
    this.modalService.open(ReviewFormComponent, this.modalOptions);
  }

  closeFrom() {
    const event = new Event('closeForm');
    document.dispatchEvent(event);
  }

  isAverageBiggerThanZero() {
    return Number.parseInt(this.listing?.RatingAverage) > 0
  }
}