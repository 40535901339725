import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-parking-info-wrapper',
  templateUrl: './parking-info-wrapper.component.html',
  styleUrl: './parking-info-wrapper.component.scss'
})
export class ParkingInfoWrapperComponent {
  @Input('wrap')
  wrap: boolean = false;
}
