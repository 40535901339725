import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-sticky-element',
  templateUrl: './sticky-element.component.html',
  styleUrl: './sticky-element.component.scss'
})
export class StickyElementComponent implements AfterViewInit {
  @Input('stickyClass') 
  stickyClass: string = 'sticky';

  sticky: boolean = false;
  placeholderHeight: number = 0;

  @ViewChild('element') 
  element: ElementRef;

  elementPosition: any;

  constructor() {}

  ngAfterViewInit(): void {
    this.elementPosition = this.element.nativeElement.offsetTop;
    this.calculatePlaceholderHeight();

  }

  calculatePlaceholderHeight() {
    this.placeholderHeight = this.sticky ? this.element.nativeElement.offsetHeight : 0;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    this.sticky = windowScroll >= this.elementPosition;
    this.calculatePlaceholderHeight();
  }
}
