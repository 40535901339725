import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OpeningHoursHelper } from '../../../../helpers/opening-hours.helper';
import { ListingModel } from '../../../../models/listing.model';
import { Hours, OHStatusModel, OpeningHours, OpeningHoursTimeSpan } from '../../../../models/opening-hours.model';

export interface OHRow {
  ohDay: string; 
  ohTimesArr: string[]; 
  comment: string 
};

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrl: './opening-hours.component.scss'
})
export class OpeningHoursComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input()
  open: boolean = false;

  isOpen: boolean = false;

  ohStatus: OHStatusModel;
  ohStatusLabel: string = "";
  ohStatusClass: string = "";
  currentDay: string;
  currentOhTimes: string[];
  ohTimes: OHRow[];
  oh: OpeningHours;
  showOh: boolean = false;
  hours: Hours[];

  constructor(
    public ohHelper: OpeningHoursHelper,
    private translate: TranslateService) { }

  ngOnInit() {
    this.oh = this.listing?.OpeningHours;

    if (this.oh) {
      this.getCurrentDay();
    }
  }

  getCurrentDay() {
    if (this.oh && this.oh?.hours?.length === 0) {
      return;
    }

    this.hours = [...this.oh?.hours];

    this.ohStatus = this.ohHelper.currentOpeningHoursStatus(this.hours);
    const currentDayInfo = this.ohStatus?.CommentedOpeningHours;
    this.getOhStatus();

    this.currentOhTimes = currentDayInfo?.times?.map((time) => {
      return this.formatTime(time.from, time.to);
    });

    if (this.currentOhTimes?.some(time => time !== null)) {
      this.showOh = true;
    }

    const currentDay = this.ohHelper.getCurrentStatus(this.hours)?.day;
    this.currentDay = `${this.getTransaltedDay(currentDay)} -`;
    this.fillEmptyHours();
    this.ohTimes = this.getAllTimes();
  }

  formatTime(from: OpeningHoursTimeSpan, to: OpeningHoursTimeSpan) {
    if (from == null || to == null) {
      return null;
    }
    if ((from.hours == 0 && from.minutes == 0) && (to.hours == 0 && to.minutes == 0)) {
      return this.translate.instant('Closed');
    }
    return `${from.hours}:${from.minutes || "00"} - ${to.hours}:${to.minutes || "00"}`
  }

  getAllTimes(): OHRow[] {
    let tempDay = "";
    let ohTimesArr: string[] = [];
    let ohAll: OHRow[] = [];
    let tempComment = "";
  
    this.hours.forEach((hour, index, arr) => {
      const ohDay = this.getTransaltedDay(hour.dow);
      const ohTimes = this.formatTime(hour.opening, hour.closing);
      const comment = hour?.comment || "";
  
      if (tempDay === ohDay) {
        ohTimesArr.push(ohTimes);
        if (comment) {
          tempComment = comment;
        }
      } else {
        if (tempDay !== "") {
          const ohRow: OHRow = {
            ohDay: tempDay,
            ohTimesArr: [...ohTimesArr],
            comment: tempComment
          };
          ohAll.push(ohRow);
        }
        tempDay = ohDay;
        ohTimesArr = [ohTimes];
        tempComment = comment;
      }
  
      if (index === arr.length - 1) {
        const ohRow: OHRow = {
          ohDay: tempDay,
          ohTimesArr: [...ohTimesArr],
          comment: tempComment
        };
        ohAll.push(ohRow);
      }
    });
  
    return ohAll;
  }

  fillEmptyHours() {
    const tempHours = [];
    const hoursMap = new Map();

    // Fill the hoursMap with arrays of hours for each day
    this.hours.forEach(hour => {
      if (!hoursMap.has(hour.dow)) {
        hoursMap.set(hour.dow, []);
      }
      hoursMap.get(hour.dow).push(hour);
    });

    // Fill all days with hours or empty if not present
    for (let index = 1; index <= 6; index++) {
      const foundHours = hoursMap.get(index);
      if (!foundHours) {
        tempHours.push(new Hours(index, null, null));
      } else {
        tempHours.push(...foundHours);
      }
    }

    // Handle Sunday separately
    let sundayHours = hoursMap.get(0);
    if (!sundayHours) {
      sundayHours = [new Hours(0, null, null)];
    }

    // Add Sunday hours to the end
    tempHours.push(...sundayHours);

    this.hours = tempHours;
  }

  getTransaltedDay(currentDayOfWeek) {
    switch (currentDayOfWeek) {
      case 0:
        return this.translate.instant('Sunday');
      case 1:
        return this.translate.instant('Monday');
      case 2:
        return this.translate.instant('Tuesday');
      case 3:
        return this.translate.instant('Wednesday');
      case 4:
        return this.translate.instant('Thursday');
      case 5:
        return this.translate.instant('Friday');
      case 6:
        return this.translate.instant('Saturday');
    }
  }

  handleOhOpen() {
    this.isOpen = !this.isOpen;
  }

  getOhStatus() {
    const status = this.ohHelper.getCurrentCommentedStatus(this.hours);
    if (status.isOpeningSoon) {
      this.ohStatusClass = 'state--opening-soon';
      this.ohStatusLabel = this.translate.instant('OpeningSoon');
    } else if (status.isClosingSoon) {
      this.ohStatusClass = 'state--closing-soon';
      this.ohStatusLabel = this.translate.instant('ClosingSoon');
    } else if (status.isCurrentlyOpen) {
      this.ohStatusClass = 'state--open';
      this.ohStatusLabel = this.translate.instant('Open');
    } else {
      this.ohStatusClass = 'state--close';
      this.ohStatusLabel = this.translate.instant('Closed')
    }

  }
}
