import { Component, Input, OnInit } from '@angular/core';
import { SpecialActions } from '../../../../enums/special-actions.enum';
import { SpecialActionsHelper } from '../../../../helpers/special-actions.helper';
import { ListingModel } from '../../../../models/listing.model';
import { ClaimYourBusinessService } from '../../../../services/claim-your-business.service';
import { ShareDataService } from '../../../../services/share-data.service';
import { Utils } from '../../../../utils/utils';

type DetailType = 'free' | 'basic' | 'traffic' | 'folder';

@Component({
  selector: 'app-financial-info',
  templateUrl: './financial-info.component.html',
  styleUrl: './financial-info.component.scss'
})
export class FinancialInfoComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('detailPageType')
  detailPageType: DetailType;

  @Input('wrap')
  wrap: boolean = false;

  // economic information
  isVatNumber: boolean = false;
  vatNumber: any;

  isRegistrationDate: boolean = false;
  registrationDate: any;

  isPersonnel: boolean = false;
  personnel: string;

  isStatus: boolean = false;
  status: string;

  disableFinancialInfo: boolean = false;

  constructor(
    private claimService: ClaimYourBusinessService,
    private specialActionsHelper: SpecialActionsHelper,
    private utils: Utils, 
  ) {}

  ngOnInit() {
    this.claimService.initialize(this.listing);

    this.isVatNumber = !this.utils.isNullOrEmpty(this.listing?.EconomicalData?.vat_number);
    this.vatNumber = this.listing?.EconomicalData?.vat_number;

    this.isRegistrationDate = !this.utils.isNullOrEmpty(this.listing?.EconomicalData?.registration_date);
    this.registrationDate = this.listing?.EconomicalData?.registration_date;

    this.isPersonnel = !this.utils.isNullOrEmpty(this.listing?.EconomicalData?.personnel);
    this.personnel = this.listing?.EconomicalData?.personnel;

    this.isStatus = !this.utils.isNullOrEmpty(this.listing?.EconomicalData?.status);
    this.status = this.listing?.EconomicalData?.status;

    this.disableFinancialInfo = this.specialActionsHelper.hasAction(this.listing, SpecialActions.DisableFinancialInfo);
  }
}
