import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { LangSwitch } from '../../../services/lang-switch.service';
import { PlausibleTrackingService } from '../../../services/plausible-tracking.service';

@Component({
  selector: 'app-add-your-business',
  templateUrl: './add-your-business.component.html',
  styleUrl: './add-your-business.component.scss'
})

export class AddYourBusinessComponent implements OnInit {
  listingId: string;
  instance: string = 'BE';
  leadCampaign: string = 'AYB';
  locale: string = 'be_nl';
  formTitle: string = 'nic';
  formText: string = 'nic';
  platform: string = 'nic';
  askForCode: boolean = true;
  
  constructor(
    public translate: TranslateService, 
    public route: ActivatedRoute, 
    private langSwitch: LangSwitch,
    private plausible: PlausibleTrackingService,) {
  }

  ngOnInit(): void {
    this.langSwitch.setAddYourBusiness()
    //dummy call to wait for translations
    this.translate.get('init').subscribe((text:string) => {
      this.initData();
      this.load();
    });
  }

  load(): void {

    const configItem = environment.MFE.AYB;
    const content = document.getElementById('aztrix-default-inner');
  
    const script = document.createElement('script');
    script.src = configItem.path;
    script.onerror = () => console.error('error loading <span class="hljs-subst">', configItem.path, '</span>');
    content.appendChild(script);
  
    const element: HTMLElement = document.createElement(configItem.name); 
    //element.addEventListener('message', msg => this.handleMessage(msg));
    // Set attributes
     element.setAttribute('id', 'form')
     element.setAttribute('instance', this.instance);
     element.setAttribute('lead-campaign', this.leadCampaign);
     element.setAttribute('locale', this.locale);
     element.setAttribute('form-title', this.formTitle);
     element.setAttribute('form-text', this.formText);
     element.setAttribute('platform', this.platform);
     element.setAttribute('ask-for-code', this.askForCode ? 'true' : 'false');

    content.appendChild(element);

    this.replaceMessage();
    this.initTracking();
  }

  initData() {
    this.locale = `be_${this.translate.currentLang}`;
    
    this.formTitle = this.translate.instant("FcrFormTitle")
    this.formText = this.translate.instant("FcrFormText")
    this.platform = this.translate.instant("FcrFormPlatformName")
  }

  replaceMessage() {
    const el = document.getElementsByTagName("listing-form")[0];
    if (!el) return;

    el.addEventListener("replaceMessage", _ => {
        const message = document.querySelector(".YB-message");
        if (message) {
            message.innerHTML = this.translate.instant("CompleteProfile");
        }
    });
  }

  initTracking() {
    const currentLang = this.translate.currentLang;
    const currentDomain = environment.Plausible[currentLang].domain;
    const form = "AYB";

    // track page visited
    this.trackEvent('PageVisited', currentLang, currentDomain, form);
    // track submit
    this.trackSubmitForm('SubmitForm', currentLang, currentDomain, form);
  }

  trackEvent(eventName: string, currentLang: string, currentDomain: string, form: string): void {
    this.plausible.trackCustomEvent(eventName,
    {
      callback: () => {
        console.log({
          eventName,
          form,
          currentLang,
          currentDomain
        });    
      },
      props: {
        form: form,
        lang: currentLang,
        domain: currentDomain
      }
    },
    { trackLocalhost: true });
  }

  trackSubmitForm(eventName: string, currentLang: string, currentDomain: string, form: string): void {
    const el = document.getElementsByTagName("listing-form")[0];
    if (!el) return;

    el.addEventListener("subscribed", _ => {
      this.trackEvent(eventName, currentLang, currentDomain, form);
    });
  }
}