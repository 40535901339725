import { Component, Input } from '@angular/core';
import { ListingModel } from '../../../../models/listing.model';
import { YextProgram } from '../../../../enums/yext-program.enum';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-result-special-offer',
  templateUrl: './result-special-offer.component.html',
  styleUrl: './result-special-offer.component.scss'
})
export class ResultSpecialOfferComponent {
  @Input()
  listing: ListingModel;

  YextProgramEnum = YextProgram;

  constructor(public utils: Utils) {}
}
