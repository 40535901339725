import { Injectable } from '@angular/core';
import { SpecialActions } from '../enums/special-actions.enum';
import { ListingModel } from '../models/listing.model';

export interface SpecialActionsStatus {
  disableReviews: boolean;
  disableFinancialInfo: boolean;
  disableParkingInfo: boolean;
  disableMap: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SpecialActionsHelper {
  public hasAction(listing: ListingModel, action: SpecialActions): boolean {
    return listing?.SpecialActions.includes(action) || false;
  }
}