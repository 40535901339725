import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss'
})
export class IconComponent implements OnInit {
  @Input() 
  icon: string;

  @Input() 
  viewBox: string = "";

  @Input()
  ngClass;

  @Input() 
  svgClass: string;

  @Input() 
  svgFill: string = "none";

  svgIcon: string = "";

  ngOnInit() {
    this.svgIcon = `assets/icons/icons.svg#${this.icon}`
  }
}
