<app-skeleton *ngIf="this.loading" style="width: 100%; height: 60rem;"></app-skeleton>
<ng-container *ngIf="detail$ | async">
    <app-breadcrumb [listing]="listing" [isDetail]="true"></app-breadcrumb>
    <app-backlink [listing]="listing"></app-backlink>
    <div [id]="listing?.Id"
        class="mb-10 result-item-poi" 
        itemscope
        itemtype="http://schema.org/LocalBusiness">
        <div class="yp-container">
            <!-- detail content here -->
            
            <!-- free -->
            <app-free-page 
                *ngIf="detailPageType === 'free'"
                [listing]="listing"
                [detailPageType]="detailPageType"
                [pois]="pois"
                [rivals]="rivals"
                [requestForms]="requestForms"
                [adKey]="adKey">
            </app-free-page>

            <!-- basic -->
            <app-basic-page 
                *ngIf="detailPageType === 'basic'"
                [listing]="listing"
                [detailPageType]="detailPageType"
                [pois]="pois"
                [requestForms]="requestForms">
            </app-basic-page>

            <!-- traffic -->
            <app-traffic-page 
                *ngIf="detailPageType === 'traffic'"
                [listing]="listing"
                [detailPageType]="detailPageType"
                [pois]="pois"
                [requestForms]="requestForms"
                [showTrafficDescription]="showTrafficDescription">
            </app-traffic-page>

            <!-- folder -->
            <app-folder-page 
                *ngIf="detailPageType === 'folder'"
                [listing]="listing"
                [childListing]="childListing"
                [detailPageType]="detailPageType"
                [pois]="pois"
                [requestForms]="requestForms"
                [showTrafficDescription]="showTrafficDescription"
                [isChildListing]="isChildListing">
            </app-folder-page>

        </div>
    </div>
</ng-container>

<!-- modal map on detail page opened on map click -->
<ng-template #mapModalDetail>
    <app-map 
        [id]="'modal-map-detail'" 
        [mode]="'modal'" 
        [detailPageType]="detailPageType"
        [pois]="pois">
    </app-map>
</ng-template>