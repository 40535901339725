import { Component, Input } from '@angular/core';
import { ListingModel } from '../../../../models/listing.model';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrl: './payment-methods.component.scss'
})
export class PaymentMethodsComponent {
  @Input('listing')
  listing: ListingModel;
}
