<div *ngIf="displaySpecialOffer"
    class="bg-yp_yellow font-bold inline-flex items-center mb-4 p-2 rounded-r-3xl text-blue-600">
    <app-icon [icon]="'promo-icon'" svgClass="h-5 mr-4 w-5"></app-icon>
    <a [href]="listing?.SpecialOfferUrl"
       class="pr-3 text-sm"
       target="_blank"
       rel="noreferrer"
       data-yext="special-offer-url"
       [trackingAction]="'WebsiteClick'"
       [trackingCategory]="'DETAIL'"
       [trackingListingId]="listing?.Id">
        <span data-yext="special-offer">
            {{ listing?.SpecialOfferMessage }}
        </span>
    </a>
</div>