<div class="yp-container">
    <div id="resultList-main" class="mt-10">

        <h1 class="text-blue-600 text-xl mb-2">{{ 'ShortlistTitle' | translate }}</h1>
        <div id="result-list" class="mb-10">
            <div class="relative">
                <ol class="result-items flex flex-col gap-4 shortlist" itemscope=""
                    itemtype="http://schema.org/ItemList"
                    *ngIf="shortlistResult$ | async as listings">

                    <li app-result-item 
                        *ngFor="let listing of listings" 
                        [listing]="listing"
                        class="result-item">
                    </li>

                </ol>
            </div>
        </div>

    </div>
</div>