import { Component, Input } from '@angular/core';
import { ListingModel } from '../../../../models/listing.model';
import { ShareDataService } from '../../../../services/share-data.service';

@Component({
  selector: 'app-extended-categories',
  templateUrl: './extended-categories.component.html',
  styleUrl: './extended-categories.component.scss'
})
export class ExtendedCategoriesComponent {
  @Input('listing')
  listing: ListingModel;

  keywords: string[];

  constructor(public shareDataService: ShareDataService) {}

  ngOnInit() {
    this.getKeywords();
  }

  getKeywords() {
    if (this.listing) {
      this.keywords = this.listing?.AllKeywords.filter(keyword =>
        !this.listing?.Category.includes(keyword) && keyword !== this.listing?.City
      );
    } else {
      this.keywords = [];
    }
  }
}
