import { Component, Input, OnInit } from '@angular/core';
import { ListingModel } from '../../../../models/listing.model';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-serves-location',
  templateUrl: './serves-location.component.html',
  styleUrl: './serves-location.component.scss'
})
export class ServesLocationComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('data')
  data: any;

  servesLocation: string = "";
  hasNoAddress: boolean = true;

  constructor(private utils: Utils){}

  ngOnInit() {
    this.servesLocation = this.data?.displayServesLocation || "";
    this.hasNoAddress = this.utils.isNullOrEmpty(this.listing?.Address);
  }
}
