import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContactInfo } from '../../helpers/contact.helper';

@Component({
  selector: '[app-phone-number-btn]',
  templateUrl: './phone-number-btn.component.html',
  styleUrl: './phone-number-btn.component.scss'
})
export class PhoneNumberBtnComponent implements OnInit {
  @Input('showIcon')
  showIcon: boolean = true;

  @Input('btnBorder')
  btnBorder: string = "";

  @Input('contacts')
  contacts: ContactInfo[];

  label: string;

  isOpen: boolean = false;
  isLabelChanged: boolean = false;
  phoneNumber: string = '';

  showBtn: boolean = false;
  
  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.getPhoneNumber();
  }

  handleDropdownOpen(): void {
    if (this.contacts[0]?.contact?.length > 1) {
      this.isOpen = !this.isOpen;
    } else {
      if (this.isLabelChanged === false) {
        this.label = this.phoneNumber;
        this.isLabelChanged = true;
      } else {
        window.open('tel:' + this.phoneNumber);
      }
    }
  }

  getPhoneNumber() {
    this.phoneNumber = this.contacts[0]?.hasContact ? this.contacts[0].contact[0].value : '';
  }

}