import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrl: './booking.component.scss'
})
export class BookingComponent implements OnInit, AfterViewInit {
  @ViewChild('bookingContent', { static: false }) 
  bookingContainer: ElementRef;

  @Input('booking')
  booking: string;

  constructor(private renderer: Renderer2){}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.handleBookingUrl();
  }

  separateScript(iframeString) {
    const scriptRegex = /<script[^>]*src=(["']?)([^\s>"']+)\1[^>]*><\/script>/;
    const scriptMatch = iframeString.match(scriptRegex);
  
    if (scriptMatch) {
      return scriptMatch[2];
    } else {
      return null;
    }
  }

  handleBookingUrl() {
    // html
    this.bookingContainer.nativeElement.innerHTML = this.booking;
    // extract script
    const scriptSrc = this.separateScript(this.booking);
    // create script
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'text/javascript');
    this.renderer.setAttribute(script, 'src', scriptSrc);
    this.renderer.appendChild(this.bookingContainer.nativeElement, script);
  }

  closeModal() {
    const event = new Event('closeModal');
    document.dispatchEvent(event);
  }
}