import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, shareReplay, switchMap} from 'rxjs';
import { BLOG_PLATFORM } from '../../../../constants/constants';
import { UrlHelper } from '../../../../helpers/url-builder.helper';
import { BlogPostSearchArguments } from '../../../../models/blog-post-search-arguments.model';
import { BlogPost } from '../../../../models/blog-post.model';
import { ListingDetailViewModel } from '../../../../models/listing-detail-results.model';
import { ListingModel } from '../../../../models/listing.model';
import { SearchArguments } from '../../../../models/search-arguments.model';
import { SearchStateService } from '../../../../services/search-state.service';
import { ShareDataService } from '../../../../services/share-data.service';
import { LangSwitch } from '../../../../services/lang-switch.service';
import { Utils } from '../../../../utils/utils';
import { ArticleSnippetDto } from '../../../../models/blog-post-snippet.model';
import { MetaHelper } from '../../../../helpers/meta.helper';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrl: './blog-detail.component.scss'
})
export class BlogDetailComponent implements OnInit {
  rows: number = 12;
  category: string;
  page: number;
  id: string;

  allBlogPosts: any[];
  allCategories: any[];

  loading: boolean = true;

  listingId: string;

  blogDetail$: Observable<BlogPost>;
  popularBlogPosts$: Observable<ArticleSnippetDto[]>;
  relatedBlogPosts$: Observable<ArticleSnippetDto[]>;
  relatedListings$: Observable<ListingModel[]>;
  listingDetail$: Observable<ListingDetailViewModel>;
  relatedCities$: Observable<string[]>;

  // form group
  form$: Observable<FormGroup>;
  postalCodeForm: FormGroup;
  searchArgs: SearchArguments;
  headingId: string;
  showInvalidPostalCode: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public shareDataService: ShareDataService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private urlHelper: UrlHelper,
    private searchStateService: SearchStateService,
    private langSwitch: LangSwitch,
    public utils: Utils,
    private metaHelper: MetaHelper,
    private title: Title
    ){}

    ngOnInit(): void {
      this.route.paramMap.subscribe((map) => {
        this.category = map.get("category");
        this.id = map.get("id");
        
        this.loadBlogDetail();
      });
  
      this.route.queryParamMap.subscribe((map) => {
        this.page = parseInt(map.get("page"));
      });
    }

  getSearchArguments(): BlogPostSearchArguments {
    
    var args = new BlogPostSearchArguments();
    if (this.category && this.category != "" && this.category != "all") {
      args.category = this.category;
    }
    
    if (this.page && this.page != 0 && this.page > 1) {
      args.skip = this.rows * (this.page -1);
    } else {
      args.skip = 0;
    }
    
    args.take = this.rows;
    args.platform = BLOG_PLATFORM;
    args.id = this.id;

    return args;
  }

  loadBlogDetail() {   
    this.blogDetail$ = this.shareDataService.fetchBlogDetail(this.getSearchArguments())
    .pipe(
      switchMap(blogDetail => {
        this.langSwitch.setBlogDetailPage(blogDetail)
        this.metaHelper.addMetaDescription(blogDetail.metaDescription)
        this.title.setTitle(blogDetail.metaTitle)

        this.headingId = blogDetail?.headings?.length > 0 && blogDetail?.headings[0];
        
        // related Blog posts with title
        const blogRelatedReq = {
          headingId: this.headingId, 
          platform: BLOG_PLATFORM, 
          exclude: [blogDetail?.id],
          limit: 3
        };
        console.log(blogRelatedReq);
        this.relatedBlogPosts$ = this.shareDataService.fetchRelatedBlogPosts(blogRelatedReq).pipe(
          map(relatedBlogPosts => relatedBlogPosts.filter(relatedBlogPosts => relatedBlogPosts?.title))
        );
  
        this.relatedListings$ = this.shareDataService.fetchRelatedListings(this.headingId);

        this.listingId = blogDetail?.uniListingId || null;
        if (this.listingId) {
          const req = new SearchArguments();
          req.id = this.listingId;
          this.listingDetail$ = this.shareDataService.fetchDetail(req).pipe(shareReplay(1));
        } else {
          this.headingId = blogDetail?.headings?.length > 0 ? blogDetail?.headings[0] : null;
          this.postalCodeForm = this.formBuilder.group({
            what: [this.headingId],
            where: [null, [Validators.required]]
          });
        }

        const popularBlogPostsReq = { 
          platform: BLOG_PLATFORM, 
          exclude: [blogDetail?.id],
          limit: 3 
        };
        this.popularBlogPosts$ = this.shareDataService.fetchPopularBlog(popularBlogPostsReq);

        return of(blogDetail);
      })
    );

    this.relatedCities$ = this.shareDataService.fetchRelatedCities(this.headingId);
  }

  onSubmit() {
    if (this.postalCodeForm.invalid) {
      return;
    }
    
    let whatValue = this.postalCodeForm.get("what").value;
    let whereValue = this.postalCodeForm.get("where").value;

    this.searchStateService.setWhat(whatValue);
    this.searchStateService.setWhere(whereValue);

    // navigate
    let routeToSearch = this.urlHelper.getSearchPageRoute(this.translate.instant("Routes.Search.DynamicWhatWhereSearch"), whatValue, whereValue);
    this.router.navigateByUrl(routeToSearch);
  }

}
