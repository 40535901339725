import { Component, Input, OnInit } from '@angular/core';
import { ContactInfo } from '../../../../helpers/contact.helper';
import { ListingModel } from '../../../../models/listing.model';
import { ClaimYourBusinessService } from '../../../../services/claim-your-business.service';
import { Badge, Contacts, DetailPageType, Poi, ShareDataService } from '../../../../services/share-data.service';

@Component({
  selector: 'app-detail-info',
  templateUrl: './detail-info.component.html',
  styleUrl: './detail-info.component.scss'
})
export class DetailInfoComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('childListing')
  childListing: ListingModel;

  @Input('detailPageType')
  detailPageType: DetailPageType;

  @Input('pois')
  pois: Poi[];

  @Input('requestForms')
  requestForms;

  @Input('isChildListing')
  isChildListing: Boolean = false;

  websites: ContactInfo[];
  emails: ContactInfo[];
  webshops: ContactInfo[];

  parent: ListingModel;
  child: ListingModel;

  showClaimLinkAll: boolean = false;

  badges: Badge[] = [];
  contacts: Contacts;

  constructor(private shareDataService: ShareDataService,
    private claimService: ClaimYourBusinessService){}

  ngOnInit(){
    this.parent = this.isChildListing ? this.childListing : this.listing;
    // if folder we need child otherwise normal listing
    this.child = this.childListing || this.listing;

    this.contacts = this.shareDataService.getContacts(this.child);

    this.websites = this.shareDataService.getContactByType(this.contacts, "website");
    this.emails = this.shareDataService.getContactByType(this.contacts, "email");
    this.webshops = this.shareDataService.getContactByType(this.contacts, "webshop");

    this.claimService.initialize(this.parent);
    this.showClaimLinkAll = this.claimService.showClaimLinkAll();

    this.badges = this.shareDataService.getRatingBadges(this.listing);
    if (this.badges.length > 0) {
      this.badges = [this.badges[0]];
    }
  }
}
