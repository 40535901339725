import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalService } from '../../../../components/modal/modal.service';
import { ListingImage } from '../../../../models/listing-image.model';
import { ListingModel } from '../../../../models/listing.model';

@Component({
  selector: 'app-traffic-description',
  templateUrl: './traffic-description.component.html',
  styleUrl: './traffic-description.component.scss'
})
export class TrafficDescriptionComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('childListing')
  childListing: ListingModel;

  @Input('isChildListing')
  isChildListing: Boolean = false;

  trafficDescriptions: string[];
  trafficImages: ListingImage[];
  trafficSubtitle: string[];

  slideIndex: number = 0;
  trafficPageLength: number = 0;

  currentListing: ListingModel;

  // modal
  @ViewChild('galleryModal')
  galleryModal: TemplateRef<Element>;

  constructor(private modalService: ModalService) { }

  ngOnInit() {
    // in case of folder
    this.currentListing = this.isChildListing ? this.childListing : this.listing;

    // traffic description
    this.trafficDescriptions = this.currentListing?.TrafficDescriptions;
    this.trafficSubtitle = this.currentListing?.TrafficSubtitle;
    this.trafficImages = this.currentListing?.TrafficImages;

    // TODO: gallery
    document.addEventListener('closeGallery', this.close.bind(this));
  }

  // Next/previous controls
  plusSlides(move: number): number {
    const lastImage = this.trafficImages?.length - 1 || 0;
    
    this.slideIndex += move;
    
    if (this.slideIndex > lastImage) { 
      return this.slideIndex = 0;
    }
    if (this.slideIndex < 0) { 
      return this.slideIndex = lastImage;
    }

    return this.slideIndex;
  }

  handleOpenGallery(index: number) {
    this.modalService.open(this.galleryModal, {
      classes: [
        'modal-inner',
        'yp-container',
        'p-0',
        'max-h-80'
      ],
      size: {
        width: '80%',
      },
    });

    this.slideIndex = index;
  }

  close() {
    this.modalService.close();
  }
}
