import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-debug-login',
  templateUrl: './debug-login.component.html',
  styleUrl: './debug-login.component.scss'
})
export class DebugLoginComponent {
  passwordForm: FormGroup;

  constructor(
    private fb: FormBuilder, 
    private router: Router,
    private route: ActivatedRoute) {
    this.passwordForm = this.fb.group({
      password: ['']
    });
  }

  checkPassword() {
    const password = this.passwordForm.get('password')?.value;
    if (password === environment?.Debug?.password) {
       // Set debug mode flag
      localStorage.setItem('debugMode', 'true');

      const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      this.navigateToRoute(returnUrl);
    } else {
      alert('Incorrect password!');
    }
  }

  navigateToRoute(routeString: string) {
    const { pathArray, queryParams } = this.parseRouteString(routeString);
    this.router.navigate(pathArray, { queryParams });
  }

  parseRouteString(routeString: string): { pathArray: string[], queryParams: any } {
    // split
    const parts = routeString.replace(/^\//, '').split('/');

    let queryParams = {};
    
    // check for query params
    const lastPart = parts[parts.length - 1];
    if (lastPart.includes('?')) {
      // remove query params from path
      const [ pathPart, queryString ] = lastPart.split('?');
      parts[parts.length - 1] = pathPart;
      // add query params
      queryParams = this.parseQueryParams(queryString);
    }

    return { pathArray: parts, queryParams };
  }

  parseQueryParams(queryString: string): any {
    return queryString.split('&').reduce((acc, part) => {
      const [key, value] = part.split('=');
      acc[key] = value;
      return acc;
    }, {});
  }
}
