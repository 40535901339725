<div class="yp-container card card--flow-y" *ngIf="this.currentLang.includes('fr')">
    <h2 class="text-xl">Désolé pour cette interruption mais ...</h2>

    <p>Quelque chose dans votre comportement de navigation sur www.pagesdor.be nous a donné l’impression que nous avions affaire à un bot.</p>
    <p>Si vous êtes une personne en chair et en os, vous pouvez nous en convaincre en résolvant ce simple puzzle. Votre accès à notre site Web sera alors rétabli immédiatement.</p>

    ​<p>
        <!-- DISTIL CAPTCHA FORM -->
    </p>
</div>

<div class="yp-container card card--flow-y" *ngIf="this.currentLang.includes('nl')">
    <h2 class="text-xl">Sorry voor deze onderbreking maar...</h2>

    <p>Iets in je surfgedrag op www.goudengids.be heeft bij ons de indruk gewekt dat we met een bot te maken hadden.</p>
    <p>Ben je een mens van vlees en bloed, dan kan je ons hiervan overtuigen door deze simpele puzzel op te lossen.   Je toegang op onze website wordt dan direct terug hersteld.</p>

    ​<p>
        <!-- DISTIL CAPTCHA FORM -->
    </p>
</div>

<div class="yp-container card card--flow-y" *ngIf="this.currentLang.includes('en')">
    <h2 class="text-xl">
        Pardon Our Interruption...
    </h2>
    
    <p>As you were browsing www.goldenpages.be  something about your surfing behavior made us think you were a bot.</p>
    <p>Are you a human from flesh and blood, then you can convince us by solving this simple puzzle.  Access to our website is then restored immediately.</p>

    ​<p>
        <!-- DISTIL CAPTCHA FORM -->
    </p>
</div>