<div class="debug-view" *ngIf="isNotEmpty()">  
    <ul *ngIf="openDebug">
        <li *ngFor="let item of debug">
            <a href="{{ item.requestUrl }}" target="_blank">{{ item.requestUrl.substring(0, item.requestUrl.indexOf('?') + 1) }}</a>
            <span class="font-bold">({{ item.duration }}ms)</span>
        </li>
    </ul>
    <h3 (click)="toggleDebugView()">
        <span>DebugView</span>
        <app-icon [icon]="'arrow-down-icon'" [ngClass]="{'rotate-180': openDebug}" svgClass="w-3 h-3 transform translation"></app-icon>
    </h3>
</div>