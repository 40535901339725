<!-- detail-map -->
<!-- result-map -->
<div class="relative"
    *ngIf="disableMap === false"
    [id]="mapId"
    [ngClass]="{
        'pt-64%': isOh === true && (resultPageType !== 'dynamic' && resultPageType !== 'normalized'),
        'pt-34%': isOh === false && (resultPageType !== 'dynamic' && resultPageType !== 'normalized'),
        'pt-162%': (resultPageType === 'dynamic' || resultPageType === 'normalized') && mode !== 'modal',
        'h-full': mode === 'modal'
    }">
    <!-- map goes here -->
    
</div>