import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListingModel } from '../../../../models/listing.model';
import { SearchResults, ShareDataService } from '../../../../services/share-data.service';

@Component({
  selector: 'app-result-group',
  templateUrl: './result-group.component.html',
  styleUrl: './result-group.component.scss'
})
export class ResultGroupComponent implements OnInit {
  @Input('group') 
  group: string;

  @Input('results')
  results: SearchResults;

  @Input('shortlist')
  shortList: boolean = false;

  groupResults: ListingModel[];

  @Input('showWebshopForFree')
  showWebshopForFree: boolean = false;

  showDebug: boolean = false;

  constructor(
    public shareDataService: ShareDataService,
    private route: ActivatedRoute
  ){};

  ngOnInit(): void {
    this.groupResults = this.results?.searchGroups?.[this.group]?.listings;

    this.route.queryParams.subscribe(params => {
      this.showDebug = params['debug'] === 'true';
    });
  }
}
