<li id="shortlist">
    <a routerLink="/shortlist" 
        class="relative block text-white"
        [trackingAction]="'FavouritesButtonClick'"
        [trackingCategory]="'SEARCH'"
        aria-label="Shortlist">
        <app-icon [icon]="'favourite-icon'" svgClass="w-4 h-6"></app-icon>
        <!-- number of shortlisted listings -->

        <span class="badge font-bold text-blue-600"
            *ngIf="(shortlist.favIds$ | async)?.length > 0"
            [innerHTML]="(shortlist?.favIds$ | async)?.length"></span>
    </a>
</li>