import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxTranslateModule } from './translate/translate.module';
import { TermsAndConditionsComponent } from './pages/static/terms-and-conditions/terms-and-conditions.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { ReviewPolicyComponent } from './pages/static/review-policy/review-policy.component';
import { PrivacyPolicyComponent } from './pages/static/privacy-policy/privacy-policy.component';
import { SecurityCaptchaComponent } from './pages/static/security-captcha/security-captcha.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { ShorlistLinkComponent } from './components/shorlist-link/shorlist-link.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { ButtonComponent } from './components/button/button.component';
import { HeadingBoxComponent } from './components/heading-box/heading-box.component';
import { HeadingLinkComponent } from './components/heading-link/heading-link.component';
import { BlogThumbComponent } from './pages/blog/components/blog-thumb/blog-thumb.component';
import { TextImageComponent } from './components/text-image/text-image.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AddYourBusinessComponent } from './pages/static/add-your-business/add-your-business.component';
import { ClaimYourBusinessComponent } from './pages/static/claim-your-business/claim-your-business.component';
import { SearchComponent } from './pages/search/search.component';
import { ResultItemCComponent } from './pages/search/components/result-item-c/result-item-c.component';
import { ResultItemComponent } from './pages/search/components/result-item/result-item.component';
import { ResultGroupComponent } from './pages/search/components/result-group/result-group.component';
import { ResultTopBarComponent } from './pages/search/components/result-top-bar/result-top-bar.component';
import { ResultPopularArticlesComponent } from './pages/search/components/result-popular-articles/result-popular-articles.component';
import { ResultFiltersComponent } from './pages/search/components/result-filters/result-filters.component';
import { PhoneNumberBtnComponent } from './components/phone-number-btn/phone-number-btn.component';
import { WebsiteUrlBtnComponent } from './components/website-url-btn/website-url-btn.component';
import { DetailComponent } from './pages/detail/detail.component';
import { FreePageComponent } from './pages/detail/free-page/free-page.component';
import { BasicPageComponent } from './pages/detail/basic-page/basic-page.component';
import { TrafficPageComponent } from './pages/detail/traffic-page/traffic-page.component';
import { FolderPageComponent } from './pages/detail/folder-page/folder-page.component';
import { ParkingInfoComponent } from './pages/detail/components/parking-info/parking-info.component';
import { FinancialInfoComponent } from './pages/detail/components/financial-info/financial-info.component';
import { CategoriesComponent } from './pages/detail/components/categories/categories.component';
import { SocialLinksComponent } from './pages/detail/components/social-links/social-links.component';
import { ExtraArticlesComponent } from './pages/detail/components/extra-articles/extra-articles.component';
import { MapComponent } from './components/map/map.component';
import { DetailTitleComponent } from './pages/detail/components/detail-title/detail-title.component';
import { BaseInterceptor } from './interceptors/base.interceptor';
import { SpecialOfferComponent } from './pages/detail/components/special-offer/special-offer.component';
import { TrafficDescriptionComponent } from './pages/detail/components/traffic-description/traffic-description.component';
import { LabelsAndBrandsComponent } from './pages/detail/components/labels-and-brands/labels-and-brands.component';
import { OhAndMapComponent } from './pages/detail/components/oh-and-map/oh-and-map.component';
import { VideoComponent } from './pages/detail/components/video/video.component';
import { BadgesComponent } from './pages/detail/components/badges/badges.component';
import { PaymentMethodsComponent } from './pages/detail/components/payment-methods/payment-methods.component';
import { SpokenLanguagesComponent } from './pages/detail/components/spoken-languages/spoken-languages.component';
import { ExtendedCategoriesComponent } from './pages/detail/components/extended-categories/extended-categories.component';
import { UspComponent } from './pages/detail/components/usp/usp.component';
import { DetailInfoComponent } from './pages/detail/components/detail-info/detail-info.component';
import { LogoComponent } from './pages/detail/components/logo/logo.component';
import { RatingsAndReviewsInfoComponent } from './pages/detail/components/ratings-and-reviews-info/ratings-and-reviews-info.component';
import { AddressComponent } from './pages/detail/components/address/address.component';
import { ServesLocationComponent } from './pages/detail/components/serves-location/serves-location.component';
import { DetailActionsComponent } from './pages/detail/components/detail-actions/detail-actions.component';
import { AllAddressesComponent } from './pages/detail/components/all-addresses/all-addresses.component';
import { ClaimButtonComponent } from './components/claim-button/claim-button.component';
import { ShareButtonComponent } from './pages/detail/components/share-button/share-button.component';
import { BlogDetailComponent } from './pages/blog/components/blog-detail/blog-detail.component';
import { InternalLinksComponent } from './components/internal-links/internal-links.component';
import { StickyElementComponent } from './components/sticky-element/sticky-element.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RatingComponent } from './components/rating/rating.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ResultSpecialOfferComponent } from './pages/search/components/result-special-offer/result-special-offer.component';
import { BacklinkComponent } from './components/backlink/backlink.component';
import { EmailBtnComponent } from './components/email-btn/email-btn.component';
import { ContactDropdownComponent } from './components/contact-dropdown/contact-dropdown.component';
import { PlanRouteBtnComponent } from './pages/detail/components/plan-route-btn/plan-route-btn.component';
import { DebugViewComponent } from './components/debug-view/debug-view.component';
import { TrackingDirective } from './directives/tracking.directive';
import { IconComponent } from './components/icon/icon.component';
import { QuoteFormComponent } from './components/quote-form/quote-form.component';
import { AppointmentFormComponent } from './components/appointment-form/appointment-form.component';
import { OpeningHoursComponent } from './pages/detail/components/opening-hours/opening-hours.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SuggesterComponent } from './components/suggester/suggester.component';
import { RelatedListingComponent } from './components/related-listing/related-listing.component';
import { ShortlistComponent } from './pages/shortlist/shortlist.component';
import { ResultInfoComponent } from './pages/search/components/result-info/result-info.component';
import { DebugInfoComponent } from './components/debug-info/debug-info.component';
import { BlogSponsorComponent } from './pages/blog/components/blog-sponsor/blog-sponsor.component';
import { ReviewFormComponent } from './pages/detail/components/review-form/review-form.component';
import { ReviewDisplayComponent } from './pages/detail/components/review-display/review-display.component';
import { TopBannersComponent } from './components/top-banners/top-banners.component';
import { AzIndexComponent } from './pages/static/az-index/az-index.component';
import { KeywordIndexComponent } from './pages/static/keyword-index/keyword-index.component';
import { CategoryIndexComponent } from './pages/static/category-index/category-index.component';
import { BookingComponent } from './components/booking/booking.component';
import { ParkingInfoWrapperComponent } from './pages/detail/components/parking-info/parking-info-wrapper/parking-info-wrapper.component';
import { CookiesComponent } from './pages/static/cookies/cookies.component';
import { FinancialInfoWrapperComponent } from './pages/detail/components/financial-info/financial-info-wrapper/financial-info-wrapper.component';
import { BottomBannersComponent } from './components/bottom-banners/bottom-banners.component';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { TopBannersPlaceholderComponent } from './components/top-banners/top-banners-placeholder/top-banners-placeholder.component';
import { DebugLoginComponent } from './pages/debug/debug-login/debug-login.component';
import { VisualizerComponent } from './pages/debug/visualizer/visualizer.component';


@NgModule({
  declarations: [
    AddressComponent,
    AddYourBusinessComponent,
    AllAddressesComponent,
    AppComponent,
    AppointmentFormComponent,
    AzIndexComponent,
    BacklinkComponent,
    BadgesComponent,
    BasicPageComponent,
    BlogComponent,
    BlogDetailComponent,
    BlogSponsorComponent,
    BlogThumbComponent,
    BookingComponent,
    BreadcrumbComponent,
    ButtonComponent,
    CategoriesComponent,
    CategoryIndexComponent,
    ClaimButtonComponent,
    ClaimYourBusinessComponent,
    ContactDropdownComponent,
    DebugInfoComponent,
    DebugViewComponent,
    DetailActionsComponent,
    DetailComponent,
    DetailInfoComponent,
    DetailTitleComponent,
    EmailBtnComponent,
    ExtendedCategoriesComponent,
    ExtraArticlesComponent,
    FinancialInfoComponent,
    FolderPageComponent,
    FooterComponent,
    FreePageComponent,
    HeaderComponent,
    HeadingBoxComponent,
    HeadingLinkComponent,
    HomeComponent,
    IconComponent,
    InternalLinksComponent,
    KeywordIndexComponent,
    LabelsAndBrandsComponent,
    LanguageSwitchComponent,
    LogoComponent,
    MapComponent,
    OhAndMapComponent,
    OpeningHoursComponent,
    PaginationComponent,
    ParkingInfoComponent,
    PaymentMethodsComponent,
    PhoneNumberBtnComponent,
    PlanRouteBtnComponent,
    PrivacyPolicyComponent,
    QuoteFormComponent,
    RatingComponent,
    RatingsAndReviewsInfoComponent,
    RelatedListingComponent,
    ResultFiltersComponent,
    ResultGroupComponent,
    ResultInfoComponent,
    ResultItemCComponent,
    ResultItemComponent,
    ResultPopularArticlesComponent,
    ResultSpecialOfferComponent,
    ResultTopBarComponent,
    ReviewDisplayComponent,
    ReviewFormComponent,
    ReviewPolicyComponent,
    SearchComponent,
    SecurityCaptchaComponent,
    ServesLocationComponent,
    ShareButtonComponent,
    ShorlistLinkComponent,
    ShortlistComponent,
    SocialLinksComponent,
    SpecialOfferComponent,
    SpokenLanguagesComponent,
    StickyElementComponent,
    SuggesterComponent,
    TermsAndConditionsComponent,
    TextImageComponent,
    TooltipComponent,
    TopBannersComponent,
    TrackingDirective,
    TrafficDescriptionComponent,
    TrafficPageComponent,
    UspComponent,
    VideoComponent,
    WebsiteUrlBtnComponent,
    ParkingInfoWrapperComponent,
    CookiesComponent,
    FinancialInfoWrapperComponent,
    BottomBannersComponent,
    SkeletonComponent,
    TopBannersPlaceholderComponent,
    DebugLoginComponent,
    VisualizerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxTranslateModule,
    ReactiveFormsModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ]
})
export class AppModule {
  constructor(trace: Sentry.TraceService){}
}