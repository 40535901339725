<ul #actionsRef>
    <!-- menu -->
    <li class="flex mb-2.5" *ngIf="menus.length > 0">
        <app-button
            [href]="menus.length > 0 && menus[0]?.contact[0]?.value"
            [target]="'_blank'"
            [rel]="'noreferrer'"
            [label]="'MenuContact' | translate"
            [showIcon]="true"
            [icon]="'menu-contact-icon'"
            [style]="'link'"
            [trackingAction]="'MenuBtnClick'"
            [trackingCategory]="'DETAIL'"
            [trackingListingId]="child?.Id">
        </app-button>
    </li>

    <!-- folder -->
    <li class="flex mb-2.5" *ngIf="folders.length > 0">
        <app-button
            [href]="folders.length > 0 && folders[0].contact[0]?.value"
            [target]="'_blank'"
            [rel]="'noreferrer'"
            [label]="'FolderContact' | translate"
            [showIcon]="true"
            [icon]="'folder-contact-icon'"
            [style]="'link'"
            [trackingAction]="'FolderBtnClick'"
            [trackingCategory]="'DETAIL'"
            [trackingListingId]="child?.Id">
        </app-button>
    </li>
</ul>

<ul id="detail-actions" class="grid gap-2 mt-8 grid-cols-2">
    <!-- phone -->
    <li app-phone-number-btn
        class="basis-42 relative" 
        [showIcon]="true" 
        [contacts]="phones"
        [btnBorder]="'thin'"
        [trackingAction]="'PhoneNumberClick'"
        [trackingCategory]="'DETAIL'"
        [trackingListingId]="child?.Id"
        *ngIf="phones[0].hasContact">
    </li>
    
    <!-- webshop -->
    <li app-website-url-btn 
        *ngIf="showWebshop; else elseWebsite" 
        [btnBorder]="'thin'"
        [contacts]="webshops"
        [icon]="'webshop-icon'"
        [label]="'Webshop' | translate"
        [style]="'outline'"
        class="basis-42"
        [trackingAction]="'WebshopClick'"
        [trackingCategory]="'DETAIL'"
        [trackingListingId]="child?.Id">
    </li>
    <!-- website -->
    <ng-template #elseWebsite>
        <li app-website-url-btn
            *ngIf="showWebsite" 
            [btnBorder]="'thin'"
            [contacts]="websites"
            [style]="'outline'"
            [trafficLink]="listing?.TrafficLink"
            [trackingAction]="'LinkClick'"
            [trackingCategory]="'DETAIL'"
            [trackingListingId]="child?.Id"
            class="basis-42">
        </li>
    </ng-template>

    <!-- plan route -->
    <li app-plan-route-btn 
        [btnBorder]="'thin'"
        [pois]="pois"
        [detailPageType]="detailPageType"
        class="basis-42">
    </li>

    <ng-container *ngIf="requestForms?.enabledRequestForm; else elseEnabledRequestForm">
        <li>
            <!-- isBooking -->
            <ng-container *ngIf="requestForms?.isBooking; else elseQuote">
                <app-button
                    [icon]="'booking-icon'"
                    [label]="requestForms?.bookingLabel !== null ? requestForms?.bookingLabel : 'BookNow' | translate"
                    [style]="'outline'"
                    [btnBorder]="'thin'"
                    [trackingAction]="'BookingBtnClick'"
                    [trackingCategory]="'DETAIL'"
                    [trackingListingId]="child?.Id"
                    (click)="openBookingModa(bookingModal)">
                </app-button>
            </ng-container>
            <ng-template #elseQuote>
                <!-- isQuote -->
                <ng-container *ngIf="requestForms?.isQuote; else elseAppointment">
                    <app-button
                        (click)="openQuoteForm()"
                        [icon]="'quote-icon'"
                        [label]="'Quote' | translate"
                        [style]="'outline'"
                        [btnBorder]="'thin'"
                        [trackingAction]="'QuoteBtnClick'"
                        [trackingCategory]="'DETAIL'"
                        [trackingListingId]="child?.Id">
                    </app-button>
                </ng-container>
                <ng-template #elseAppointment>
                    <!-- isAppointment -->
                    <app-button
                        (click)="openAppointmentForm()"
                        *ngIf="requestForms?.isAppointment"
                        [icon]="'appointment-icon'"
                        [label]="'Appointment' | translate"
                        [style]="'outline'"
                        [btnBorder]="'thin'"
                        [trackingAction]="'AppointmentBtnClick'"
                        [trackingCategory]="'DETAIL'"
                        [trackingListingId]="child?.Id">
                    </app-button>
                    
                </ng-template>
            </ng-template>
        </li>
    </ng-container>
    <ng-template #elseEnabledRequestForm>
        <!-- email -->
        <li app-email-btn 
            *ngIf="emails.length > 0" 
            [contacts]="emails"
            [btnBorder]="'thin'"
            class="basis-42"
            [trackingAction]="'EmailBtnClick'"
            [trackingCategory]="'DETAIL'"
            [trackingListingId]="child?.Id">
        </li>
    </ng-template>
</ul>

<!-- booking modal -->
<ng-template #bookingModal>
    <app-booking 
        [booking]="requestForms?.bookingUrl">
    </app-booking>
</ng-template>

<!-- contact -->
<ng-container *ngIf="checkDeviceService.isMobile$ | async">
    <ng-container *ngIf="listing?.IsTrafficPage || listing?.IsPaid; else notTrafficPageOrNotPaid">
        <a *ngIf="phones[0].hasContact"
            [attr.href]="'tel:' + phones[0].contact[0].value"
            class="bottomMapOpener border-2 border-blue-200 bg-white -mb-8 items-center gap-2 shadow-small pr-4 pt-2 pb-2 pl-2"
            id="phoneNumberHidden" 
            [trackingAction]="'CallCompany'"
            [trackingCategory]="'DETAIL'"
            [trackingListingId]="child?.Id"
            #stickyButtonRef
            style="z-index: 1001;">
            <div class="w-10 h-10 p-3 rounded-full bg-yp_yellow flex items-center">
                <app-icon [icon]="'phone-icon'" [svgClass]="'h-5 left-4 text-blue-600 top-3.5 w-5'"></app-icon>
            </div>
            <div class="text-sm text-black font-medium whitespace-nowrap">{{ 'CallCompany' | translate }}</div>
        </a>
    </ng-container>
    <ng-template #notTrafficPageOrNotPaid>
        <a (click)="navigateBack()"
            class="bottomMapOpener border-2 border-blue-200 bg-white -mb-8 items-center gap-2 shadow-small pr-4 pt-2 pb-2 pl-2"
            id="phoneNumberHidden"
            #stickyButtonRef>
            <div class="w-10 h-10 p-3 rounded-full bg-yp_yellow flex">
                <app-icon [icon]="'search-icon'" [svgClass]="'h-5 left-4 text-blue-600 top-3.5 w-5'"></app-icon>
            </div>
            <div class="text-sm text-black font-medium whitespace-nowrap">{{ 'BackToResultPage' | translate }}</div>
        </a>
    </ng-template>
</ng-container>