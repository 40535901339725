<div class="md:block hidden border-b border-gray-100 py-4">
    <ul class="yp-container flex" itemscope itemtype="https://schema.org/BreadcrumbList">
        <li class="flex items-center text-sm hover:underline" 
            itemprop="itemListElement" 
            itemscope 
            itemtype="https://schema.org/ListItem">
            <a [routerLink]="'/'" itemprop="item">
                <span itemprop="name">Home</span>
            </a>
            <meta itemprop="position" content="1" />
        </li>
        <li class="flex items-center text-sm" 
            itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem"
            *ngFor="let link of links; let index = index"
            [ngClass]="{'font-semibold': index === links.length - 1}">
            <app-icon [icon]="'arrow-down-icon'" svgClass="-rotate-90 h-2 mx-4 text-gray-100 transform w-3"></app-icon>
            <ng-container *ngIf="index < links.length - 1; else elseLabel">
                <a [routerLink]="link.url" itemprop="item">
                    <span itemprop="name">{{ link.label }}</span>
                </a>
                <meta itemprop="position" [attr.content]="(index + 2)" />
            </ng-container>
            <ng-template #elseLabel>
                <span itemprop="item">
                    <span itemprop="name">{{ link.label }}</span>
                </span>
                <meta itemprop="position" [attr.content]="(index + 2)" />
            </ng-template>
        </li>
    </ul>
</div>