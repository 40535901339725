import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SpecialActions } from '../../../enums/special-actions.enum';
import { SpecialActionsHelper, SpecialActionsStatus } from '../../../helpers/special-actions.helper';
import { ListingModel } from '../../../models/listing.model';
import { OpeningHours } from '../../../models/opening-hours.model';
import { Contacts, DetailPageType, Poi, ShareDataService, Socials } from '../../../services/share-data.service';

@Component({
  selector: 'app-basic-page',
  templateUrl: './basic-page.component.html',
  styleUrl: './basic-page.component.scss'
})
export class BasicPageComponent {
  @Input('listing')
  listing: ListingModel;

  @Input('detailPageType')
  detailPageType: DetailPageType;

  @Input('pois')
  pois: Poi[] = [];

  @Input('requestForms')
  requestForms;

  disableFinancialInfo: boolean = false;

  constructor(private specialActionsHelper: SpecialActionsHelper){
    this.disableFinancialInfo = this.specialActionsHelper.hasAction(this.listing, SpecialActions.DisableFinancialInfo);
  }
}
