<app-contact-dropdown 
    (click)="handleDropdownOpen()" 
    [isOpen]="isOpen"
    [contacts]="contacts"
    [showIcon]="showIcon">
    <app-button 
        [label]="label || 'Call' | translate"
        [showIcon]="showIcon"
        [icon]="'phone-icon'"
        [btnBorder]="btnBorder"
        [style]="'filled-dark'">
    </app-button>
</app-contact-dropdown>