import { Injectable } from "@angular/core";
import { CommentedOpeningHoursStatus, CurrentOpeningHoursStatus, DayOfWeek, Hours, OHStatusModel, OpeningHoursTime, OpeningHoursTimeSpan, isValid } from "../models/opening-hours.model";

@Injectable({providedIn: "root"})
export class OpeningHoursHelper {
  ClosingSoonMinutes: number = 30;
  OpeningSoonMinutes: number = 30;

  CurrentTimeProvider: () => Date = () => new Date();

  private static _timezone: string | undefined = undefined;

  private static getTimezone(): string {
    if (!this._timezone) {
      const timezoneConfiguration: string = "Romance Standard Time";
      this._timezone = timezoneConfiguration !== "" ? timezoneConfiguration : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return this._timezone;
  }

  private getOpeningHoursSegments(openingHours: Hours): Hours[] {
    if (!openingHours.closing || (openingHours.closing?.hours === 0 && openingHours.closing?.minutes === 0)) {
      return [{
        dow: openingHours.dow,
        opening: openingHours.opening,
        closing: new OpeningHoursTimeSpan(24, 0)
      }];
    } else if (isBiggerThan(openingHours.opening, openingHours.closing)) {
      return [{
        dow: openingHours.dow,
        opening: openingHours.opening,
        closing: new OpeningHoursTimeSpan(24, 0)
      }, {
        dow: openingHours.dow,
        opening: new OpeningHoursTimeSpan(0, 0),
        closing: openingHours.closing
      }];
    } else {
      return [openingHours];
    }
  }

  getCurrentStatus(openingHours: Hours[]): CurrentOpeningHoursStatus | CommentedOpeningHoursStatus {
    const currentDateTime: Date = this.CurrentTimeProvider();
    openingHours = openingHours.filter(t => t != null && t.closing != null && t.closing != null);

    if (openingHours.length === 0) {
      return new CommentedOpeningHoursStatus();
    }

    const openingHourSegments: Hours[] = openingHours.flatMap(this.getOpeningHoursSegments);
    const currentOpeningHours: Hours | null = openingHourSegments.find(oh =>
      oh.dow === currentDateTime.getDay() &&
      currentDateTime.getTime() >= new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(), oh.opening?.hours, oh.opening?.minutes).getTime() &&
      currentDateTime.getTime() <= new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(), oh.closing?.hours, oh.closing?.minutes).getTime()) ?? null;


    if (currentOpeningHours) {
      var res = new CurrentOpeningHoursStatus();
      res.isCurrentlyOpen = true;
      res.isClosingSoon = isLessThanDateTimeWithMinutes(currentOpeningHours.closing, currentDateTime, this.ClosingSoonMinutes);
      res.isClosingSoonTime = res.isClosingSoon ? new OpeningHoursTime(currentOpeningHours.opening, currentOpeningHours.closing) : null;
      res.day = currentDateTime.getDay();
      res.times = openingHours.filter(oh => oh.dow === currentDateTime.getDay())
      .map(oh => new OpeningHoursTime( oh.opening, oh.closing ));
      return res;
    }
    const upcomingHours = openingHours.map(oh => ({
      SortValue: compareValueWithWeekDay(oh.opening, oh.dow) +
        (oh.dow < currentDateTime.getDay() || (isLessThanDateTime(oh.opening, currentDateTime) && oh.dow === currentDateTime.getDay()) ? 70000 : 0),
      OpeningHours: oh
    })).sort((a, b) => a.SortValue - b.SortValue)[0].OpeningHours;

    const openingSoonTime: Date = new Date(currentDateTime.getTime() + this.OpeningSoonMinutes * 60000);
    let openingHoursDiff: number = compareValueWithWeekDay(new OpeningHoursTimeSpan(openingSoonTime.getHours(), openingSoonTime.getMinutes()), openingSoonTime.getDay());
    const upcomingHoursValue: number = compareValueWithWeekDay(upcomingHours.opening, upcomingHours.dow);

    if (openingSoonTime.getDay() > upcomingHours.dow) {
      openingHoursDiff -= 70000;
    }

    const _isOpeningSoon: OpeningHoursTimeSpan = upcomingHours.opening;

    var res: CurrentOpeningHoursStatus = new CurrentOpeningHoursStatus()

    res.isCurrentlyOpen = false;
    res.isOpeningSoon = compareValueWithWeekDay(_isOpeningSoon, upcomingHours.dow) < openingHoursDiff
    res.day = upcomingHours.dow;
    res.times = [new OpeningHoursTime(upcomingHours.opening, upcomingHours.closing)];
    
    return res;
  }

  getCurrentCommentedStatus(openingHours: Hours[]): CommentedOpeningHoursStatus | null {
    let correctOh = openingHours.filter(l => isValid(l));

    const currentStatus: CurrentOpeningHoursStatus | CommentedOpeningHoursStatus = this.getCurrentStatus(correctOh);
    const currentDate: Date = this.CurrentTimeProvider();
    const commentRows: Hours[] = correctOh.filter(oh => oh.comment !== "");
    const todayComment: Hours | undefined = commentRows.find(oh => oh.dow === currentDate.getDay());

    if (!currentStatus.isCurrentlyOpen) {
      if (todayComment) {
        var res = new CommentedOpeningHoursStatus();
        res.isCurrentlyOpen = false;
        res.day = todayComment?.dow ?? DayOfWeek.Monday;
        res.times = currentStatus.times;
        res.isOpeningSoon = currentStatus.isOpeningSoon;
        res.comment = todayComment?.comment ?? '';
        return res;
      }
    }

    if (!currentStatus) return null;

    var res = new CommentedOpeningHoursStatus();
    res.isCurrentlyOpen = currentStatus.isCurrentlyOpen;
    res.isClosingSoon = currentStatus.isClosingSoon;
    res.isClosingSoonTime = currentStatus.isClosingSoonTime;
    res.isOpeningSoon = currentStatus.isOpeningSoon;
    res.day = currentStatus.day;
    res.times = currentStatus.times;
    res.comment = todayComment?.comment ?? '';
    return res;
  }

  currentOpeningHoursStatus(openingHours: Hours[]): OHStatusModel | null {
    const result: OHStatusModel = new OHStatusModel();
    const currentStatus: CommentedOpeningHoursStatus | null = this.getCurrentCommentedStatus(openingHours);
    if (!currentStatus) return null;

    if (currentStatus.times.length === 0) {
      if (currentStatus.comment !== "") {
        result.CurrentStatus += "CommentOnly";
        result.CommentedOpeningHours = currentStatus;
        return result;
      } else {
        return null;
      }
    }

    result.CommentedOpeningHours = currentStatus;

    if (currentStatus.isClosingSoon) {
      result.CurrentStatus += "ClosingSoon";
      return result;
    }

    if (currentStatus.isCurrentlyOpen) {
      result.CurrentStatus += "Open";
      return result;
    }

    if (currentStatus.isOpeningSoon) {
      result.CurrentStatus += "OpeningSoon";
      return result;
    }

    result.CurrentStatus += "Closed";
    return result;
  }  
}

export function isBiggerThan(ts1: OpeningHoursTimeSpan, ts2: OpeningHoursTimeSpan) : boolean {
  if(compareValue(ts1) > compareValue(ts2)) return true;
  return false;
}

export function compareValue(ts: OpeningHoursTimeSpan): number {
  return ts.hours * 100 + ts.minutes;
}

export function compareValueWithWeekDay(ts: OpeningHoursTimeSpan, dayOfWeek: number): number {
  return dayOfWeek * 10000 + compareValue(ts);
}

export function isZero(ts: OpeningHoursTimeSpan): boolean {
  return compareValue(ts) === 0;
}

export function compareTo(ts1: OpeningHoursTimeSpan, ts2: OpeningHoursTimeSpan): number {
  if (compareValue(ts1) > compareValue(ts2)) return 1;
  if (compareValue(ts1) === compareValue(ts2)) return 0;
  return -1;
}

export function equals(ts1: OpeningHoursTimeSpan, ts2: OpeningHoursTimeSpan): boolean {
  return compareValue(ts1) === compareValue(ts2);
}

export function isMoreThanDateTime(ts1: OpeningHoursTimeSpan, other: Date, minutes: number): boolean {
  other.setMinutes(other.getMinutes() - minutes);
  var comp = other.getHours() * 100 + other.getMinutes();
  if(compareValue(ts1) > comp) return true;
  return false;
}

export function isLessThanDateTime(ts1: OpeningHoursTimeSpan, other: Date): boolean {
  var comp = other.getHours() * 100 + other.getMinutes();

  if(compareValue(ts1) < comp) return true;
  return false;
}

export function formatValueToMinutes(ts: OpeningHoursTimeSpan): number {
  return ts.hours * 60 + ts.minutes;
}

export function isLessThanDateTimeWithMinutes(ts1: OpeningHoursTimeSpan, currentDateTime: Date, minutesDifference: number): boolean {
  const currentMinutes = currentDateTime.getHours() * 60 + currentDateTime.getMinutes();
  return formatValueToMinutes(ts1) - currentMinutes < minutesDifference;
}