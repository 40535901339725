import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

interface ScriptOptions {
  async?: boolean;
  defer?: boolean;
  type?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HeaderScriptsService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // create script tag with content
  insertScriptToHeader(scriptContent: string | object, options?: ScriptOptions): void{
    const script = this.renderer.createElement('script');

    let scriptText = "";
    if (typeof scriptContent === 'string') {
      scriptText = scriptContent;
    } else {
      try {
        scriptText = JSON.stringify(scriptContent);
      } catch (error) {
        return; 
      }
    }
    script.text = scriptText;

    script.type = options?.type || 'text/javascript';

    if (options?.async) {
      script.async = options?.async;
    }

    if (options?.defer) {
      script.defer = options?.defer;
    }

    this.renderer.appendChild(document.head, script);
  }

  // add default values for detalayer
  insertDataLayer(cookieScriptUrl: string, GAId: string) {
    const scriptText = `
      var dataLayer = dataLayer || [];
      dataLayer.push({
        'cookieScriptUrl': '${cookieScriptUrl}',
        'gaMeasurementId': '${GAId}'
      });
    `;
    this.insertScriptToHeader(scriptText);
  }

  // add google tag manager
  insertGoogleTagManager(GTMContainerId: string) {
    const scriptText = `
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${GTMContainerId}');
    `;
    this.insertScriptToHeader(scriptText);
  }

  // add clarity script
  insertClarityScript(clarityId: string) {
    const scriptText = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;t.defer=1;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${clarityId}");
    `;
    const options = {
      async: true,
      defer: true
    }
    this.insertScriptToHeader(scriptText, options);
  }
}
