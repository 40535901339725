import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LangSwitch } from '../../../services/lang-switch.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrl: './cookies.component.scss'
})
export class CookiesComponent {
  @ViewChild('cookieScript')
  cookieScript: ElementRef;

  currentLang: string = '';

  constructor(
    public translate: TranslateService,
    private title: Title,
    private langSwitch: LangSwitch,
    private renderer: Renderer2    
  ){
    this.langSwitch.setCookiePolicy()
    this.currentLang = translate.currentLang;
    this.translate.get("CookiePolicy").subscribe((x : string) => {
      this.title.setTitle(x);
    });
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.addCookieScipt();
  }

  addCookieScipt() {
    console.log("load cookie script")

    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.setAttribute('data-cookiescriptreport', 'report');
    script.src = environment.CookieScript.reportUrl;
    this.renderer.appendChild(this.cookieScript.nativeElement, script);
  }
}
