<div class="yp-container card card--flow-y">
    <div class="grid">

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('fr')">
            <h2 class="text-2xl">Politique de cookie</h2>
            <p>Ce site fait usage de cookies.</p>

            <h2 class="text-2xl">Qu’est-ce qu’un cookie ?</h2>
            <p>
                Un cookie est un petit fichier de chiffres et de lettres qui est placé sur votre ordinateur lorsque vous
                consultez un site. Il contient un code unique permettant au site de reconnaitre un utilisateur sur la
                base de l’appareil qu’il emploie. Lors de la prochaine visite sur le site, ce dernier retiendra des
                informations sur vos préférences grâce aux cookies (ex. langue, entreprises préférées, recherches
                récentes, etc.). Les cookies permettent que l’interaction entre le visiteur et le site soit, de manière
                générale, plus fluide et plus rapide. En utilisant des cookies, le contenu ou la publicité d'un site web
                peuvent également être rendus plus pertinents pour le visiteur, car ils peuvent être adaptés à ses
                préférences personnelles
            </p>
            <h2 class="text-2xl">Quels sont les cookies utilisés et leur utilité ?</h2>

            <h3 class="small text-xl">Cookies nécessaires</h3>
            <p>
                Les cookies nécessaires permettent aux les visiteurs de consulter le site et d’utiliser correctement ses
                fonctionnalités. Sans les cookies nécessaires, le site web ne fonctionnera pas correctement.
            </p>
            <h3 class="small text-xl">Cookies de performance</h3>
            <p>
                Les cookies de performance sont utilisés pour voir comment les visiteurs utilisent le site Web. Ces
                cookies ne peuvent pas être utilisés pour identifier directement un certain visiteur.
            </p>
            <h3 class="small text-xl">Cookies de fonctionnalité</h3>
            <p>
                Les cookies fonctionnels sont utilisés pour mémoriser des informations sur les visiteurs du site web,
                telles que la langue.
            </p>
            <h3 class="small text-xl">
                Cookies de ciblage
            </h3>
            <p>Les cookies de ciblage sont des cookies de tiers utilisés pour suivre les visiteurs lorsqu'ils visitent
                différents sites web. Leur but est de diffuser des annonces personnalisées et pertinentes pour
                l'utilisateur individuel. Ainsi, ces annonces deviennent plus précieuses pour les éditeurs et les
                annonceurs externes.</p>

            <h3 class="small text-xl">Cookies non classés</h3>
            <p>
                Les cookies non classés sont des cookies que nous devons encore attribuer à une certaine catégorie.
            </p>


            <h2 class="text-2xl">Résumé des cookies</h2>
            <p>Vous trouverez ci-dessous un aperçu de tous les cookies que nous plaçons sur ce site web, classés par
                type de cookie.</p>
            <p>Pour chaque cookie, il y a le nom, le domaine, la date d'expiration et une brève description de ce que
                fait ce cookie.</p>

            <div #cookieScript></div>

            <h2 class="text-2xl">Gestion des cookies via votre navigateur</h2>
            <p>Si vous souhaitez empêcher l’installation de certains cookies sur votre ordinateur, vous pouvez
                l’indiquer via les paramètres de confidentialité de votre navigateur. Il est possible de supprimer les
                cookies via les paramètres de confidentialité de votre navigateur.</p>
            <h2 class="text-2xl">Gestion des cookies via le site web via la bannière d'information sur les cookies</h2>
            <p>Vous pouvez à la fois accepter les cookies et révoquer votre consentement à tout moment. Bien entendu,
                les cookies nécessaires, qui sont indispensables pour pouvoir utiliser le site web lors de votre (vos)
                visite(s), seront toujours acceptés automatiquement.</p>
            <h3 class="small text-xl">Autoriser les cookies</h3>
            <p>En tant qu’utilisateur, vous pouvez choisir si vous autorisez uniquement les cookies nécessaires ou
                également les autres cookies (comme les cookies de ciblage,…). Pour ce faire, cliquez sur l’un des deux
                boutons situés sur la bannière de cookies, dans le bas du site :</p>
            <ol class="list-decimal mb-2">
                <li>« Tout Accepter» : si vous cliquez sur ce bouton, vous donnez votre autorisation pour activer aussi
                    bien les cookies nécessaires que tous les autres types de cookies lors de vos visites sur le site.
                </li>
                <li>"Afficher les détails" : si vous, en tant qu'utilisateur, cliquez sur ce bouton, vous verrez un
                    aperçu de tous les types de cookies que nous plaçons. Vous pouvez alors cocher ou décocher les
                    cookies que vous souhaitez accepter ou refuser par catégorie. Les cookies nécessaires, qui sont
                    indispensables pour pouvoir utiliser le site web lors de votre (vos) visite(s), seront acceptés
                    automatiquement. Vous confirmez ensuite votre choix en cliquant sur le bouton "Sauver et fermer".
                </li>
            </ol>
            <h3 class="small text-xl">Révocation de votre autorisation</h3>
            <p>En tant qu'utilisateur, vous pouvez révoquer à tout moment votre consentement (préalablement donné) à
                l'utilisation de cookies tiers. Pour ce faire, il vous suffit de cliquer sur le bouton « Paramètres de
                cookie » en bas du site.</p>
            <p>La prochaine fois que vous visiterez le site web, la bannière d'information sur les cookies s'affichera à
                nouveau et vous pourrez à nouveau sélectionner vos préférences en matière de cookies.</p>
        </article>

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('nl')">
            <h2 class="text-2xl">Cookiebeleid</h2>
            <p>Deze website maakt gebruik van cookies.</p>
            <h2 class="text-2xl">Wat is een cookie?</h2>
            <p>
                Een cookie is een klein bestand van letters en cijfers dat naar je computer wordt verzonden wanneer u de
                website raadpleegt. De cookie bevat een unieke code die een website in staat stelt een gebruiker te
                herkennen op basis van het apparaat dat wordt gebruikt. Bij een volgend bezoek aan de website zal deze,
                op basis van de cookies, informatie onthouden over uw voorkeuren (vb. taalkeuze, favoriete bedrijven,
                recente zoekopdrachten, etc.). Cookies zorgen ervoor dat de interactie tussen de bezoeker en de website
                over het algemeen gemakkelijker en sneller verloopt. Door cookies te gebruiken kan de inhoud of de
                publiciteit op een website tevens relevanter worden gemaakt voor de bezoeker, vermits deze kan worden
                aangepast aan zijn persoonlijke voorkeuren.
            </p>
            <h2 class="text-2xl">
                Welke cookies worden er gebruikt en wat is hun doel?
            </h2>
            <h3 class="small text-xl">
                Noodzakelijke cookies
            </h3>
            <p>
                Noodzakelijke cookies zijn nodig zodat bezoekers alle functies van de website correct kunnen gebruiken.
                Zonder noodzakelijke cookies zal de website niet correct werken.
            </p>
            <h3 class="small text-xl">Prestatie cookies</h3>
            <p>
                Prestatiecookies worden gebruikt om te zien hoe bezoekers de website gebruiken. Deze cookies kunnen niet
                worden gebruikt om een bepaalde bezoeker direct te identificeren.
            </p>
            <h3 class="small text-xl">
                Functionele cookies
            </h3>
            <p>
                Functionele cookies worden gebruikt om bezoekersinformatie op de website te onthouden zoals bijvoorbeeld
                de taal.
            </p>
            <h3 class="small text-xl">Marketing cookies</h3>
            <p>
                Marketingcookies zijn cookies van derden die worden gebruikt om bezoekers te volgen wanneer ze
                verschillende websites bezoeken. Hun doel is advertenties weergeven die zijn toegesneden op en relevant
                zijn voor de individuele gebruiker. Deze advertenties worden zo waardevoller voor uitgevers en externe
                adverteerders.
            </p>
            <h3 class="small text-xl">Niet geclassificeerde cookies</h3>
            <p>
                Niet-geclassificeerde cookies zijn cookies die we nog moeten indelen in een bepaalde categorie.
            </p>
            <h2 class="text-2xl">Overzicht van cookies</h2>
            <p>
                Hieronder vind je een overzicht van alle cookies die we plaatsen op deze website, onderverdeeld per type
                cookies.
                Voor elke cookie staat er de naam, het domein, de vervaldatum en een korte beschrijving wat deze cookie
                doet.
            </p>

            <div #cookieScript></div>

            <h2 class="text-2xl">Beheer van cookies via je browser</h2>
            <p>
                Als je wil vermijden dat bepaalde cookies op jouw computer geïnstalleerd worden, dan kan je dat via de
                Privacy instellingen van je browser aangeven. Cookies verwijderen kan ook via de Privacy instellingen
                van je browser.
            </p>
            <h2 class="text-2xl">
                Beheer van cookies via de website en de cookie-informatiebanner
            </h2>
            <p>Je kan zowel toestemming geven voor cookies, als deze toestemming op elk moment opnieuw intrekken.
                Uiteraard worden de noodzakelijke cookies, die essentieel zijn om de website te kunnen gebruiken tijdens
                jouw bezoek(en), steeds automatisch aanvaard.</p>
            <h3 class="small text-xl">
                Toestemming geven voor cookies
            </h3>
            <p>
                Als gebruiker kan je zelf aangeven of je enkel noodzakelijke cookies of ook andere cookies (zoals
                marketing cookies,…) wil aanvaarden. Dit doe je door te klikken op 1 van de 2 knoppen onderaan op de
                website in de cookiebanner:
            </p>
            <ol class="list-decimal mb-2">
                <li>
                    “Accepteer alles”: als je als gebruiker deze knop aanklikt, geef je de toestemming om zowel de
                    noodzakelijke cookies als alle andere type cookies te activeren tijdens jouw bezoek(en) aan de
                    website.
                </li>
                <li>
                    “Toon details”: als je als gebruiker deze knop aanklikt, zie je een overzicht van alle soorten
                    cookies die we plaatsen. Vervolgens kan je per categorie aan- of afvinken welke cookies je al dan
                    niet wenst te accepteren. De noodzakelijke cookies, die essentieel zijn om de website te kunnen
                    gebruiken tijdens jouw bezoek(en), worden automatisch aanvaard. Vervolgens bevestig je je keuze via
                    de knop “Opslaan en sluiten”.
                </li>
            </ol>
            <h3 class="small text-xl">Intrekken van de toestemming</h3>
            <p>
                Als gebruiker kan je jouw (eerder gegeven) toestemming tot gebruik van cookies op eender welk moment
                weer intrekken. Dit wordt mogelijk gemaakt via een druk op de knop 'Cookie instellingen' die je onderaan
                de website terugvindt.
            </p>
            <p>
                Vervolgens wordt opnieuw de cookie-informatiebanner weergegeven en kan jij je cookievoorkeuren opnieuw
                selecteren.
            </p>
        </article>

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('en')">
            <h2 class="text-2xl">Politique de cookie</h2>
            <p>Ce site fait usage de cookies.</p>

            <h2 class="text-2xl">Qu’est-ce qu’un cookie ?</h2>
            <p>
                Un cookie est un petit fichier de chiffres et de lettres qui est placé sur votre ordinateur lorsque vous
                consultez un site. Il contient un code unique permettant au site de reconnaitre un utilisateur sur la
                base de l’appareil qu’il emploie. Lors de la prochaine visite sur le site, ce dernier retiendra des
                informations sur vos préférences grâce aux cookies (ex. langue, entreprises préférées, recherches
                récentes, etc.). Les cookies permettent que l’interaction entre le visiteur et le site soit, de manière
                générale, plus fluide et plus rapide. En utilisant des cookies, le contenu ou la publicité d'un site web
                peuvent également être rendus plus pertinents pour le visiteur, car ils peuvent être adaptés à ses
                préférences personnelles
            </p>
            <h2 class="text-2xl">Quels sont les cookies utilisés et leur utilité ?</h2>

            <h3 class="small text-xl">Cookies nécessaires</h3>
            <p>
                Les cookies nécessaires permettent aux les visiteurs de consulter le site et d’utiliser correctement ses
                fonctionnalités. Sans les cookies nécessaires, le site web ne fonctionnera pas correctement.
            </p>
            <h3 class="small text-xl">Cookies de performance</h3>
            <p>
                Les cookies de performance sont utilisés pour voir comment les visiteurs utilisent le site Web. Ces
                cookies ne peuvent pas être utilisés pour identifier directement un certain visiteur.
            </p>
            <h3 class="small text-xl">Cookies de fonctionnalité</h3>
            <p>
                Les cookies fonctionnels sont utilisés pour mémoriser des informations sur les visiteurs du site web,
                telles que la langue.
            </p>
            <h3 class="small text-xl">
                Cookies de ciblage
            </h3>
            <p>Les cookies de ciblage sont des cookies de tiers utilisés pour suivre les visiteurs lorsqu'ils visitent
                différents sites web. Leur but est de diffuser des annonces personnalisées et pertinentes pour
                l'utilisateur individuel. Ainsi, ces annonces deviennent plus précieuses pour les éditeurs et les
                annonceurs externes.</p>

            <h3 class="small text-xl">Cookies non classés</h3>
            <p>
                Les cookies non classés sont des cookies que nous devons encore attribuer à une certaine catégorie.
            </p>
            <h2 class="text-2xl">Résumé des cookies</h2>
            <p>Vous trouverez ci-dessous un aperçu de tous les cookies que nous plaçons sur ce site web, classés par
                type de cookie.</p>
            <p>Pour chaque cookie, il y a le nom, le domaine, la date d'expiration et une brève description de ce que
                fait ce cookie.</p>

            <div #cookieScript></div>

            <h2 class="text-2xl">Gestion des cookies via votre navigateur</h2>
            <p>Si vous souhaitez empêcher l’installation de certains cookies sur votre ordinateur, vous pouvez
                l’indiquer via les paramètres de confidentialité de votre navigateur. Il est possible de supprimer les
                cookies via les paramètres de confidentialité de votre navigateur.</p>
            <h2 class="text-2xl">Gestion des cookies via le site web via la bannière d'information sur les cookies</h2>
            <p>Vous pouvez à la fois accepter les cookies et révoquer votre consentement à tout moment. Bien entendu,
                les cookies nécessaires, qui sont indispensables pour pouvoir utiliser le site web lors de votre (vos)
                visite(s), seront toujours acceptés automatiquement.</p>
            <h3 class="small text-xl">Autoriser les cookies</h3>
            <p>En tant qu’utilisateur, vous pouvez choisir si vous autorisez uniquement les cookies nécessaires ou
                également les autres cookies (comme les cookies de ciblage,…). Pour ce faire, cliquez sur l’un des deux
                boutons situés sur la bannière de cookies, dans le bas du site :</p>
            <ol class="list-decimal mb-2">
                <li>« Tout Accepter» : si vous cliquez sur ce bouton, vous donnez votre autorisation pour activer aussi
                    bien les cookies nécessaires que tous les autres types de cookies lors de vos visites sur le site.
                </li>
                <li>"Afficher les détails" : si vous, en tant qu'utilisateur, cliquez sur ce bouton, vous verrez un
                    aperçu de tous les types de cookies que nous plaçons. Vous pouvez alors cocher ou décocher les
                    cookies que vous souhaitez accepter ou refuser par catégorie. Les cookies nécessaires, qui sont
                    indispensables pour pouvoir utiliser le site web lors de votre (vos) visite(s), seront acceptés
                    automatiquement. Vous confirmez ensuite votre choix en cliquant sur le bouton "Sauver et fermer".
                </li>
            </ol>
            <h3 class="small text-xl">Révocation de votre autorisation</h3>
            <p>En tant qu'utilisateur, vous pouvez révoquer à tout moment votre consentement (préalablement donné) à
                l'utilisation de cookies tiers. Pour ce faire, il vous suffit de cliquer sur le bouton « Paramètres de
                cookie » en bas du site.</p>
            <p>La prochaine fois que vous visiterez le site web, la bannière d'information sur les cookies s'affichera à
                nouveau et vous pourrez à nouveau sélectionner vos préférences en matière de cookies.</p>
        </article>

    </div>
</div>