import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BannerService } from '../../services/banner.service';
import { CheckDeviceService } from '../../services/check-device.service';
import { SearchResults } from '../../services/share-data.service';

@Component({
  selector: 'app-bottom-banners',
  templateUrl: './bottom-banners.component.html',
  styleUrl: './bottom-banners.component.scss'
})
export class BottomBannersComponent {
  @Input('searchResults$')
  searchResults$: Observable<SearchResults>;
  
  adKey: string = "";
  adId: string = environment.AdRevive.id;

  bottomBannerId: string = "";
  bottomBannerMobileId: string = "";

  constructor(
    private translate: TranslateService,
    public checkDeviceService: CheckDeviceService,
    private bannerService: BannerService,
  ) {}

  ngOnInit(): void {
    this.searchResults$.subscribe((data) => {
      
      this.adKey = data?.data?.advertsCodeInfo?.adKey || null;
    })
  }

  ngAfterViewInit(): void {
    this.bannerService.refreshRevive();
  }
}
