import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ListingModel } from '../../../../models/listing.model';
import { Contacts, SearchResults, ShareDataService } from '../../../../services/share-data.service';
import { OHStatusModel } from '../../../../models/opening-hours.model';
import { OpeningHoursHelper } from '../../../../helpers/opening-hours.helper';
import { TranslateService } from '@ngx-translate/core';
import { UrlHelper } from '../../../../helpers/url-builder.helper';
import { ContactInfo } from '../../../../helpers/contact.helper';
import { ShortlistService } from '../../../../services/shortlist.service';
import { AddressHelper } from '../../../../helpers/address.helper';
import { SearchStateService } from '../../../../services/search-state.service';
import { SearchType } from '../../../../models/search-arguments.model';
import { ListingImage } from '../../../../models/listing-image.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[app-result-item]',
  templateUrl: './result-item.component.html',
  styleUrl: './result-item.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ResultItemComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('debug')
  debug: SearchResults;
  
  @Input('index')
  index: number;

  contacts: Contacts;
  websites: ContactInfo[];
  phones: ContactInfo[];
  webshops: ContactInfo[];
  hasLogo: boolean = false;
  logoPath: string = '';
  detailUrl: string;
  OHStatus: OHStatusModel;
  ohStatus: string;
  OHhelper: OpeningHoursHelper = new OpeningHoursHelper();
  avgRatingRounded: string;
  address: string;
  isShortlist: boolean = false;
  displayServesLocation: string;

  @Input('showWebshopForFree')
  showWebshopForFree: boolean = false;
  
  showWebshop: boolean = false;
  showWebsite: boolean = false;

  isPaid: boolean = false;
  logo: ListingImage;

  showDebug: boolean = false;

  constructor(
    public shareDataService: ShareDataService,
    private urlHelper: UrlHelper,
    private translate: TranslateService,
    private shortlist: ShortlistService,
    private addressHelper: AddressHelper,
    private searchState: SearchStateService,
    private route: ActivatedRoute
    ) {}

  ngOnInit() {
    // shortlist init
    this.checkShortlist();
    this.getOpeningHoursStatus();

    this.isPaid = this.listing?.IsPaid && this.listing?.TrafficLevel >= 3;
    this.hasLogo = !!this.listing?.Logo && this.isPaid;

    if (this.hasLogo) {
      this.logoPath = this.listing?.Logo?.path;
    }

    this.contacts = this.shareDataService.getContacts(this.listing);
    this.phones = this.shareDataService.getContactByType(this.contacts, "phone");
    this.websites = this.shareDataService.getContactByType(this.contacts, "website");
    this.webshops = this.shareDataService.getContactByType(this.contacts, "webshop");

    this.showWebshop = this.webshops.length > 0 && this.webshops[0].hasContact;
    this.showWebsite = (this.websites.length > 0 && this.websites[0].hasContact) || this.listing?.TrafficLink.length > 0;

    this.avgRatingRounded = this.listing?.RatingAverage.length < 3 ? Math.floor(Number.parseInt(this.listing?.RatingAverage)).toFixed(1) : this.listing?.RatingAverage;

    this.getDisplayServesLocation();
    this.getDetailUrl();
    this.address = this.addressHelper.getAddress(this.listing);

    this.route.queryParams.subscribe(params => {
      this.showDebug = params['debug'] === 'true';
    });
  }

  getOpeningHoursStatus() {
    if(this.listing?.OpeningHours && this.listing?.OpeningHours?.hours?.length > 0) {
      this.OHStatus = this.OHhelper.currentOpeningHoursStatus(this.listing?.OpeningHours?.hours);
      this.ohStatus = this.OHStatus?.CurrentStatus ? this.translate.instant(this.OHStatus?.CurrentStatus) : "";
    }
  }

  isParentListing() {
    const hasChildInfo = this.listing?.FolderAddresses.length > 0;
    return hasChildInfo;
  }

  isChildLiting() {
    const hasParentInfo = this.listing?.folders.length > 0;
    return hasParentInfo;
  }

  getDetailUrl() {
    let parentId = this.listing?.Id;
    let childId = this.listing?.Id;

    const isParent = this.isParentListing();
    const isChild = this.isChildLiting();

    if (isChild || isParent) {

      if (isChild) {
        parentId = this.listing?.folders[0]?.id;
      }
      if (isParent) {
        childId = this.listing?.FolderAddresses[0].id;
      }

      this.detailUrl = this.urlHelper.getFolderDetailPageRoute(this.translate.instant('Routes.Detail.ListingFolderDetail'), childId, parentId, this.listing?.Title);
    } else {
      this.detailUrl = this.urlHelper.getDetailPageRoute(this.translate.instant('Routes.Detail.ListingDetail'), this.listing?.City, parentId, this.listing?.Title);
    }
  }

  checkShortlist() {
    this.isShortlist = this.shortlist.isShortlisted(this.listing.Id);
  }

  toggleShortlist() {
    if(!this.isShortlist){
      this.shortlist.addToShortlist(this.listing.Id);
    }
    else {
      this.shortlist.removeFromShortlist(this.listing.Id);
    }
    this.checkShortlist();
  }

  isAverageBiggerThanZero() {
    return Number.parseInt(this.listing?.RatingAverage) > 0
  }

  getDisplayServesLocation() {
    this.searchState.getSearchType().subscribe(x => {
        if(this.listing?.TaxonomyInfo?.displaysServes && this.debug?.data?.analyzedLocation != null && this.listing?.Path && this.listing?.Path != '' && !this.listing?.Path.startsWith(this.debug?.data?.analyzedLocation.path)){
          if((x == SearchType.Normalized && this.listing?.GeoZoningGoogle?.length > 0) || (x == SearchType.Dynamic && this.listing?.GeoZoning?.length > 0)){
            this.displayServesLocation = this.debug?.data?.analyzedLocation?.name;
          }
        }
    })
  }
}
