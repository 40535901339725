import { Component, Input } from '@angular/core';
import { ListingModel } from '../../../../models/listing.model';

@Component({
  selector: 'app-usp',
  templateUrl: './usp.component.html',
  styleUrl: './usp.component.scss'
})
export class UspComponent {
  @Input('listing')
  listing: ListingModel;
}
