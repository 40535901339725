import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-financial-info-wrapper',
  templateUrl: './financial-info-wrapper.component.html',
  styleUrl: './financial-info-wrapper.component.scss'
})
export class FinancialInfoWrapperComponent {
  @Input('wrap')
  wrap: boolean = false;
}
