import { Injectable } from "@angular/core";
import { SearchArguments } from "../models/search-arguments.model";
import { ShareDataService } from "../services/share-data.service";

@Injectable({
    providedIn: "root"
})
export class UrlHelper {
    domain: string;
    constructor() {
        this.domain = document.location.protocol + '//' + document.location.host;
    }

    getSearchPageRoute(route: string, what: string, where?: string): string | null {
        let res: string = null;
        if (!route || route === '')
            return null

        if (!what || what === '')
            return null

        if (!where || where === '')
            return route.replace(":what", this.customEncodeURIComponent(what)).replace("/:where", '').replace("/:page", '');

        return route.replace(":what", this.customEncodeURIComponent(what)).replace(":where", this.customEncodeURIComponent(where)).replace("/:page", '');
    }

    getSearchPageRouteWithDomain(route: string, what: string, where?: string): string | null {
        let res: string = null;
        if (!route || route === '')
            return null

        if (!what || what === '')
            return null

        if (!where || where === '')
            return this.domain + route.replace(":what", this.customEncodeURIComponent(what)).replace("/:where", '').replace("/:page", '');

        return this.domain + route.replace(":what", this.customEncodeURIComponent(what)).replace(":where", this.customEncodeURIComponent(where)).replace("/:page", '');
    }

    getSearchPageRouteWithPage(route: string, what: string, where: string, page: number): string | null {
        if (!route || route === '')
            return null

        if (!what || what === '')
            return null

        if (!where || where === '')
            return route.replace(":what", this.customEncodeURIComponent(what)).replace("/:where", '').replace("/:page", page > 1 ? '/'+  page.toString() : '');

        return route.replace(":what", this.customEncodeURIComponent(what)).replace(":where",this.customEncodeURIComponent(where)).replace("/:page", page > 1 ? '/'+  page.toString() : '');
    }

    getDetailPageRoute(route: string, city: string, id: string, title: string, withDomain: boolean = false): string | null {
        let res = route.replace(":id", id);
        if (!route || route === '')
            return null

        if (!id || id === '')
            return null

        if ((!city || city === '') && (!title || title === '')) {
            if (withDomain)
                return this.domain + res.replace(":city", "-").replace(":title", "-");
            return res.replace(":city", "-").replace(":title", "-");
        }
        else if (!city || city === '') {
            if (withDomain)
                return this.domain + res.replace(":title", this.customEncodeURIComponent(title)).replace(":city", "-")
            return res.replace(":title", this.customEncodeURIComponent(title)).replace(":city", "-")
        }
        else if (!title || title === '') {
            if (withDomain)
                return this.domain + res.replace(":title", "-").replace(":city", this.customEncodeURIComponent(city))
            return res.replace(":title", "-").replace(":city", this.customEncodeURIComponent(city))
        }
        else {
            if (withDomain)
                return this.domain + res.replace(":title", this.customEncodeURIComponent(title)).replace(":city", this.customEncodeURIComponent(city))
            return res.replace(":title", this.customEncodeURIComponent(title)).replace(":city", this.customEncodeURIComponent(city))
        }


    }

    getFolderDetailPageRoute(route: string, childId: string, id: string, title: string): string | null {
        let res = route.replace(":id", id);

        if (!route || route === '')
            return null

        if (!id || id === '')
            return null

        if ((!childId || childId === '') && (!title || title === '')) {
            return res.replace(":childId", "-").replace(":title", "-");
        }
        else if (!childId || childId === '') {
            return res.replace(":title", this.customEncodeURIComponent(title)).replace(":childId", "-")
        }
        else if (!title || title === '') {
            return res.replace(":title", "-").replace(":childId", childId)
        }
        else

            return res.replace(":title", this.customEncodeURIComponent(title)).replace(":childId", childId)

    }

    getBlogPageRoute(route: string, heading?: string, id?: string, title?: string): string | null {
        let res = route;

        if (!route || route === '')
            return null

        if (!heading || heading === '') {
            return res.replace(":heading", "all").replace("/:id", "").replace("/:title", "");
        }
        else if (!id || id === '') {
            return res.replace(":heading", this.customEncodeURIComponent(heading)).replace("/:id", "").replace("/:title", "");
        }
        else if (!title || title === '') {
            return res.replace(":heading", this.customEncodeURIComponent(heading)).replace("/:id", id).replace("/:title", "-");
        }
        else {
            const routa = route.replace(":heading", this.customEncodeURIComponent(heading)).replace(":id", id).replace(":title", this.customEncodeURIComponent(title)).replace(":page", '1');


            return routa;
        }
    }

    public customEncodeURIComponent(str: string): string {
        const regex = /[\/():;?&=#%+\[\]\\\^\{\}]/g;
        return str.replace(regex, (c) => {
            return '%' + c.charCodeAt(0).toString(16).toUpperCase();
        }).trim();
    }
}