<!-- breadcrumb for normalized search -->
<ng-container *ngIf="resultPageType === 'normalized'">
    <ng-container *ngIf="searchResults$ | async; else showPlaceholderBreadcrumb">
        <app-breadcrumb  
            [normalizedListings]="searchResults$ | async">
        </app-breadcrumb>
    </ng-container>
    <ng-template #showPlaceholderBreadcrumb>
        <div class="md:block hidden border-b border-gray-100 py-4">
            <ul class="yp-container flex" itemprop="breadcrumb">
                <li class="flex items-center text-sm hover:underline">
                    <a [routerLink]="'/'">Home</a>
                </li>
            </ul>
        </div>
    </ng-template>
</ng-container>

<div class="yp-container">

    <!-- TODO: !Model.TitleMatchExists ?? -->
    <ng-container *ngIf="searchResults$ | async; else showPlaceholderTopBanners">
        <app-top-banners 
            [searchResults$]="searchResults$">
        </app-top-banners>
    </ng-container>
    <ng-template #showPlaceholderTopBanners>
        <app-top-banners-placeholder />
    </ng-template>

    <!-- debug -->
    <ng-container *ngIf="searchResults$ | async as searchResults">
        <div *ngIf="searchResults?.data?.debug !== null && showDebug === true"
            class="mt-7 p-3" style="border: 1px solid #EBEBEB; background-color: #F9F9F9;">
            <div class="grid md:grid-cols-3 grid-cols-1">
                <div class="md:mb-0 mb-4">
                    <strong class="block mb-2">Analyzed subject:</strong>
                    Id: {{ searchResults?.data?.analyzedSubject?.id }}<br>
                    Type: {{ searchResults?.data?.analyzedSubject?.type }}<br> 
                    Name Nl: {{ searchResults?.data?.analyzedSubject?.nameNl }}<br>
                    Name Fr: {{ searchResults?.data?.analyzedSubject?.nameFr }}<br> 
                    Name En: {{ searchResults?.data?.analyzedSubject?.nameEn }}<br> 
                    Radius: {{ searchResults?.data?.analyzedSubject?.radius }}<br>
                </div>
                <div>
                    <strong class="block mb-2">Adverts Info:</strong>
                    HeadingId: {{ searchResults?.data?.advertsCodeInfo?.headingId }}<br> 
                    AdHeadingId: {{ searchResults?.data?.advertsCodeInfo?.adHeadingId }}<br>
                    Source: {{ searchResults?.data?.advertsCodeInfo?.source }}<br>
                    AdKey: {{ searchResults?.data?.advertsCodeInfo?.adKey }}<br>
                </div>
            </div>
            <div class="flex gap-4 justify-between mt-4">
                <a (click)="openVizualiser(searchResults)" class="hover:underline text-blue-200 cursor-pointer">
                    Search Criteria Visualizer
                </a><br>
                <a (click)="disableDebugMode()" class="hover:underline text-blue-200 cursor-pointer">
                    Disable Debug Mode
                </a>
            </div>
        </div>
    </ng-container>

    <!-- MainResult -->
    <div id="resultList-main" class="mt-10 mb-10">
        <!-- result info -->
        <ng-container *ngIf="searchResults$ | async as searchResults">
            <app-result-info
                [numFound]="numFound">
            </app-result-info>
        </ng-container>
        <app-skeleton class="mb-5" style="width: 100%; height: 1.75rem;" *ngIf="this.loading"></app-skeleton>
        <!-- /result info -->
        
        <!-- editorial top -->
        <ng-container *ngIf="!this.loading && resultPageType === 'normalized' && editorials$ | async as editorial">
            <div *ngIf="editorial?.top"
                class="border editorial editorial--top mb-6 p-4 rounded-2 shadow-small text-gray-400 text-sm">
                <span class="editorial-label float-right bg-white border border-blue-300 inline-block mb-2 px-2 py-0.5">
                    {{ 'Article' | translate }}
                </span>
                <div [innerHTML]="editorial.top"></div>
            </div>
        </ng-container>
        <app-skeleton class="mb-3" style="width: 100%; height: 10rem;" *ngIf="this.loading && resultPageType == 'normalized'"></app-skeleton>
        <!-- /editorial top -->

        <!-- header results part -->
        <div class="md:grid md:grid-cols-12 md:gap-8 mb-5">
            <!-- left column -->
            <div class="col-span-9">
                <!-- filters -->
                <!-- <ng-container *ngIf="eshop$ | async"></ng-container> -->
                <div app-result-filters 
                    (emitSearchArgs)="loadResults()"
                    [loading]="loading"
                    class="flex flex-col lg:flex-row gap-2">
                </div>
                <!-- /filters -->
            </div>
            <!-- /left column -->

            <!-- right column -->
            <div class="col-span-3 flex">
                <!-- open map modal button -->
                <div id="openMap" 
                    class="md:flex hidden ml-auto modal"
                    (click)="openModalTemplate(resultModal)" 
                    [trackingAction]="'MapClick'" 
                    [trackingCategory]="'SEARCH'" 
                    title="map">
                    <app-icon [icon]="'map-icon'" svgClass="h-5 mr-2 pt-0.5 text-blue-600 w-5"></app-icon>
                    <span class="text-sm text-blue-200 cursor-pointer">
                        {{ 'SeeOnMap' | translate }}
                    </span>
                </div>
                <!-- /open map modal button -->
            </div>
            <!-- /right column -->

        </div>
        <!-- /header results part -->
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        <app-skeleton class="mb-3" style="width: 74%; height: 15rem;" *ngIf="this.loading"></app-skeleton>
        
        <!-- results and map -->
        <div class="lg:grid lg:grid-cols-12 md:gap-8" *ngIf="!this.loading">

            <!-- left column -->
            <div id="result-list" class="col-span-9 flex flex-col gap-4">
                <!-- webshop results -->
                <app-result-group
                    *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('Eshop') && (searchResults$ | async)?.searchGroups['Eshop']?.listings?.length > 0"
                    [group]="'Eshop'" 
                    [results]="(searchResults$ | async)"
                    [showWebshopForFree]="true">
                </app-result-group>
                <!-- /webshop results -->

                <!-- dynamic results -->
                <ng-container *ngIf="resultPageType !== 'normalized'; else elseNormalized">
                    <!-- A1 -->
                    <ng-container
                        *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('A1') && (searchResults$ | async)?.searchGroups['A1']?.listings?.length > 0">
                        <app-result-group [group]="'A1'" [results]="(searchResults$ | async)">
                        </app-result-group>

                        <!-- Display link to a local search if there are more results in the A1 group than we can fit on a page -->
                        <div *ngIf="showFallbackA1" class="flex my-8.5">
                            <app-icon [icon]="'fallback-icon'" svgClass="h-12 mr-3 w-10"></app-icon>
                            <div class="flex flex-col">
                                <span class="font-medium text-blue-600 text-xl">
                                    {{ 'InterestedNearResults' | translate }}
                                </span>
                                <a [routerLink]="localResultsLink"
                                    class="font-normal text-blue-200 underline hover:no-underline" rel="nofollow">
                                    {{ 'AllLocalResults' | translate }}
                                </a>
                            </div>
                        </div>
                    </ng-container>
                    <!-- /A1 -->

                    <!-- A2 -->
                    <app-result-group
                        *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('A2') && (searchResults$ | async)?.searchGroups['A2']?.listings?.length > 0"
                        [group]="'A2'" [results]="(searchResults$ | async)">
                    </app-result-group>
                    <!-- /A2 -->

                    <!-- B -->
                    <ng-container
                        *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('B') && (searchResults$ | async)?.searchGroups['B']?.listings?.length > 0">
                        <h4 class="block font-medium mb-4 text-blue-600 text-sm">
                            {{ 'BListTitle' | translate }}
                        </h4>
                        <app-result-group [group]="'B'" [results]="(searchResults$ | async)">
                        </app-result-group>
                    </ng-container>
                    <!-- /B -->

                    <!-- CF -->
                    <ng-container
                        *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('CF') && (searchResults$ | async)?.searchGroups['CF']?.listings?.length > 0">
                        <h4 class="block font-medium mb-4 text-blue-600 text-sm">
                            {{ 'CategoryFallback' | translate }}
                        </h4>
                        <app-result-group [group]="'CF'" [results]="(searchResults$ | async)">
                        </app-result-group>
                    </ng-container>
                    <!-- /CF -->       
                </ng-container>
                <!-- dynamic results -->

                <!-- normalized results -->
                <ng-template #elseNormalized>                  
                    <!-- N -->
                    <app-result-group
                        *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('N') && (searchResults$ | async)?.searchGroups['N']?.listings?.length > 0"
                        [group]="'N'" [results]="(searchResults$ | async)">
                    </app-result-group>
                    <!-- /N -->
                </ng-template>
                <!-- /normalizes results -->

                <!-- F - Fallbacks now have a separate group -->
                <app-result-group
                    *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('F') && (searchResults$ | async)?.searchGroups['F']?.listings?.length > 0"
                    [group]="'F'" [results]="(searchResults$ | async)">
                </app-result-group>
                <!-- /F -->
            </div>
            <!-- /left column -->

            <!-- right column -->
            <div class="col-span-3 lg:flex gap-4 flex-col hidden">

                <!-- map -->
                <app-map *ngIf="searchResults$ | async as searchResults" 
                    [id]="'result-map'"
                    [resultPageType]="resultPageType" 
                    [pois]="pois"
                    [analyzedLocation]="searchResults.data.analyzedLocation"
                    (handleOpenMapClick)="openModalTemplate(resultModal)">
                </app-map>

                <!-- popular articles -->
                <div app-result-popular-articles 
                    *ngIf="mostPopularArticles$ | async as popular" 
                    [articles]="popular">
                </div>

            </div>
            <!-- right column -->

        </div>
        <!-- /results and map -->

        <!-- pager -->
        <app-pagination 
            *ngIf="searchResults$ | async as searchResults" 
            (emitPageNum)="routeToPage()"
            [numFound]="numFound">
        </app-pagination>
        <!-- /pager -->

        <!-- bottom banner --> 
        <ng-container *ngIf="searchResults$ | async">
            <app-bottom-banners
                [searchResults$]="searchResults$">
            </app-bottom-banners>
        </ng-container>
        <!-- /bottom banner -->

        <!-- normalized - editorial bottom -->
        <ng-container *ngIf="resultPageType === 'normalized' && editorials$ | async as editorial">
            <div *ngIf="editorial?.bottom"
                class="border editorial editorial--bottom mb-6 p-4 rounded-2 shadow-small text-gray-400 text-sm mt-10">
                <span class="editorial-label float-right bg-white border border-blue-300 inline-block mb-2 px-2 py-0.5">
                    {{ 'Article' | translate }}
                </span>
                <div [innerHTML]="editorial.bottom"></div>
            </div>
        </ng-container>
        <!-- /normalized - editorial bottom -->

        <!-- results and map - modal - desktop -->
        <ng-template #resultModal>
            <div class="md:grid grid-cols-12 h-full">

                <!-- map -->
                <div id="modal-map-holder" class="h-full order-last z-40 col-span-5" data-mode="results"
                    data-modal-mode="results">

                    <app-map 
                        [id]="'modal-map'" 
                        [mode]="'modal'" 
                        [resultPageType]="resultPageType" 
                        [pois]="pois">
                    </app-map>

                </div>

                <!-- results and filters -->
                <div class="absolute md:static bottom-0 md:h-full md:p-8 md:pr-3 md:w-auto overflow-hidden w-full z-50 col-span-7">
                    <div class="results-n-filters__inner h-full overflow-hidden md:gap-8 gap-4 flex flex-col pb-3 md:pl-3">

                        <!-- filters -->
                        <div app-result-filters class="h-8.8 horizontal-list mr-2 overflow-hidden"></div>

                        <!-- results -->
                        <div id="map-results-holder" class="overflow-y-hidden h-full">
                            <div id="map-results"
                                class="custom-bar md:mb-no-scrollbar md:h-full md:overflow-y-scroll overflow-y-hidden md:overflow-x-hidden overflow-x-scroll">
                                <ol class="map-results-list h-full">

                                    <!-- webshop resuts -->
                                    <app-result-group
                                        *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('Eshop') && (searchResults$ | async)?.searchGroups['Eshop']?.listings?.length > 0"
                                        [group]="'Eshop'" 
                                        [results]="(searchResults$ | async)"
                                        [showWebshopForFree]="true">
                                    </app-result-group>

                                    <!-- dynamic results -->
                                    <ng-container *ngIf="resultPageType !== 'normalized'; else elseNormalized">
                                        
                                        <!-- A1 -->
                                        <ng-container
                                            *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('A1') && (searchResults$ | async)?.searchGroups['A1']?.listings?.length > 0">
                                            <app-result-group [group]="'A1'" [results]="(searchResults$ | async)">
                                            </app-result-group>

                                            <!-- Display link to a local search if there are more results in the A1 group than we can fit on a page -->
                                            <div *ngIf="fallbackA1$ | async" class="flex my-8.5">
                                                <app-icon [icon]="'fallback-icon'" svgClass="h-12 mr-3 w-10"></app-icon>
                                                <div class="flex flex-col">
                                                    <span class="font-medium text-blue-600 text-xl">
                                                        {{ 'InterestedNearResults' | translate }}
                                                    </span>
                                                    <a href=""
                                                        class="font-normal text-blue-200 underline hover:no-underline"
                                                        rel="nofollow">
                                                        {{ 'AllLocalResults' | translate }}
                                                    </a>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <!-- /A1 -->

                                        <!-- A2 -->
                                        <app-result-group
                                            *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('A2') && (searchResults$ | async)?.searchGroups['A2']?.listings?.length > 0"
                                            [group]="'A2'" [results]="(searchResults$ | async)">
                                        </app-result-group>
                                        <!-- /A2 -->

                                        <!-- B -->
                                        <ng-container
                                            *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('B') && (searchResults$ | async)?.searchGroups['B']?.listings?.length > 0">
                                            <h4 class="block font-medium mb-4 text-blue-600 text-sm">
                                                {{ 'BListTitle' | translate }}
                                            </h4>
                                            <app-result-group [group]="'B'" [results]="(searchResults$ | async)">
                                            </app-result-group>
                                        </ng-container>
                                        <!-- /B -->

                                        <!-- CF -->
                                        <ng-container
                                            *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('CF') && (searchResults$ | async)?.searchGroups['CF']?.listings?.length > 0">
                                            <h4 class="block font-medium mb-4 text-blue-600 text-sm">
                                                CategoryFallback
                                            </h4>
                                            <app-result-group [group]="'CF'" [results]="(searchResults$ | async)">
                                            </app-result-group>
                                        </ng-container>
                                        <!-- /CF -->
                                    </ng-container>

                                    <!-- normalized results -->
                                    <ng-template #elseNormalized>
                                        <!-- N -->
                                        <app-result-group
                                            *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('N') && (searchResults$ | async)?.searchGroups['N']?.listings?.length > 0"
                                            [group]="'N'" [results]="(searchResults$ | async)">
                                        </app-result-group>
                                        <!-- /N -->
                                    </ng-template>

                                    <!-- F - Fallbacks now have a separate group -->
                                    <app-result-group
                                        *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('F') && (searchResults$ | async)?.searchGroups['F']?.listings?.length > 0"
                                        [group]="'F'" [results]="(searchResults$ | async)">
                                    </app-result-group>
                                    <!-- /F -->

                                </ol>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </ng-template>
        <!-- /results and map - modal - desktop -->
    </div>

    <!-- C list results -->
    <app-result-group
        *ngIf="(searchResults$ | async)?.searchGroups?.hasOwnProperty('C') && (searchResults$ | async)?.searchGroups['C']?.listings?.length > 0"
        [group]="'C'" [results]="(searchResults$ | async)">
    </app-result-group>
    <!-- /C list results -->
</div>

<!-- Bottom section -->
<!-- internal links - normalized search -->
<ng-container *ngIf="resultPageType === 'normalized' && relatedCities$ | async as relatedCities">
    <app-internal-links 
        *ngIf="relatedCities?.length > 0"
        [relatedCities]="relatedCities">
    </app-internal-links>
</ng-container>
<!-- /internal links - normalized search -->

<!-- floatin map button -->
<!-- <a href="#" class="bottomMapOpener modal" id="openMapHidden" [trackingAction]="'MapClick'" [trackingCategory]="'SEARCH'"
        data-modal-type="element" data-modal-element="modal-map" data-modal-class="modal-dialog"
        data-modal-open="ResultList.initResultsMap" title="map">
        <app-icon [icon]="'map-icon'" svgClass="h-5 mr-2 pt-0.5 text-blue-600 w-5"></app-icon>
        <span class="text-sm text-blue-200">
            {{ 'SeeMap' | translate }}
        </span>
    </a> -->

<ng-container *ngIf="(searchResults$ | async) as searchData">
    <app-debug-view 
        *ngIf="showDebug === true"
        [debug]="searchData?.data?.debug">
    </app-debug-view>
</ng-container>