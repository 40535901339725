import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UrlHelper } from '../../helpers/url-builder.helper';
import { ListingModel } from '../../models/listing.model';
import { SearchStateService } from '../../services/search-state.service';

@Component({
  selector: 'app-backlink',
  templateUrl: './backlink.component.html',
  styleUrl: './backlink.component.scss'
})
export class BacklinkComponent implements OnInit {
  @Input('isBlog')
  isBlog: boolean = false;

  @Input('heading')
  heading: string;

  @Input('listing')
  listing: ListingModel;

  label: string;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private searchStateService: SearchStateService,
    private urlHelper: UrlHelper){}

  ngOnInit() {
    this.getLabel();
  }

  getLabel() {
    this.label = this.isBlog ? this.translate.instant('Back') :  this.translate.instant('BackToResults');
  }

  navigateBack() {
    if (this.isBlog) {
      this.translate.get("Routes.Blog.BlogDetail").subscribe((url: string) => {
        const routeToBlogs = this.urlHelper.getBlogPageRoute(url, this.heading);
        this.router.navigateByUrl(routeToBlogs);
      });
    } else {
      this.translate.get("Routes.Search.DynamicWhatWhereSearch").subscribe((url: string) => {
        this.searchStateService.getPage().subscribe(page => {
          const { what } = this.searchStateService.getCurrentValues();

          // if user went directly to DETAIL
          let routeToSearch = this.urlHelper.getSearchPageRoute(url, this.listing?.Category?.[0], this.listing?.City);

          // if user went from SERP
          if (what !== null) {
            routeToSearch = this.urlHelper.getSearchPageRoute(url, what);
          }

          if (page > 1) {
            routeToSearch = this.urlHelper.getSearchPageRouteWithPage(url, this.listing?.Category?.[0], this.listing?.City, page)
          }

          this.router.navigateByUrl(routeToSearch);
        });        
      });
    }
  }
}
