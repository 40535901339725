import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError, throwError } from "rxjs";

@Injectable({providedIn: "root"})
export class DataService {
    constructor(
        private http: HttpClient,
        private router: Router
    ){}

    post<T>(
        url: string,
        body: any
    ): Observable<T> {
        return this.http.post<T>(url, body).pipe(
            catchError(error => {
                this.router.navigate(['/']);
                //this.router.navigateByUrl('PageNotFound');
                return throwError(() => new Error(error));
            })
        )
    }

    get<T>(
        url: string
    ): Observable<T> {
        return this.http.get<T>(url).pipe(
            catchError(error => {
                this.router.navigate(['/']);
                //this.router.navigateByUrl('PageNotFound');
                return throwError(() => new Error(error));
            })
        )
    }
}