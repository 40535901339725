import { Component, Input, OnInit } from '@angular/core';
import { ShareDataService } from '../../services/share-data.service';
import { DebugItem } from '../../models/debug-item.model';

@Component({
  selector: 'app-debug-view',
  templateUrl: './debug-view.component.html',
  styleUrl: './debug-view.component.scss'
})
export class DebugViewComponent implements OnInit {

  @Input('debug')
  debug: DebugItem[] = [];

  openDebug: boolean = true;

  constructor(private shareData: ShareDataService){}

  ngOnInit(): void {
    console.log('DEBUG', this.debug)
  }

  isNotEmpty(): boolean {
    return this.debug?.length > 0
  }

  toggleDebugView() {
    this.openDebug = !this.openDebug;
  }
}
