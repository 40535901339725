<ul id="pagination" class="flex text-sm mt-10 justify-center">
    <li>
      <span (click)="prevPage()" class="flex h-8 items-center justify-center p-2 rounded-full w-8 mr-2 cursor-pointer">
        <app-icon [icon]="'arrow-down-icon'" svgClass="h-2 rotate-90 text-gray-400 transform translation w-3"></app-icon>
      </span>
    </li>
  
    <!-- Render page numbers dynamically -->
    <ng-container *ngFor="let page of pages">
      <li>
        <span
           (click)="goToPage(page.pageNumber)"
           [ngClass]="{'bg-blue-600 text-white': page.pageNumber === currentPage}" 
           class="flex h-8 items-center justify-center not-clickable p-2 rounded-full w-8 hover:bg-blue-600 hover:text-white mr-2 cursor-pointer">
           {{ page.pageLabel }}
        </span>
      </li>
    </ng-container>
  
    <li>
      <span (click)="nextPage()" class="flex h-8 items-center justify-center p-2 rounded-full w-8 cursor-pointer">
        <app-icon [icon]="'arrow-down-icon'" svgClass="h-2 -rotate-90 text-gray-400 transform translation w-3"></app-icon>
      </span>
    </li>
  </ul>  