import { Component, Input, OnInit } from '@angular/core';
import { ListingModel } from '../../../../models/listing.model';
import { ShareDataService, Socials } from '../../../../services/share-data.service';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrl: './social-links.component.scss'
})
export class SocialLinksComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  socialLinks: { value: string, type: string }[] = [];

  constructor(private shareDataService: ShareDataService){}

  ngOnInit() {
    const socials = this.shareDataService.getSocials(this.listing);
    this.socialLinks = this.getSocialsArr(socials);
  }

  getSocialsArr(socialContacts) {
    const tempArr = []
    Object.values(socialContacts)
        .filter(contact => contact['hasContact'])
        .flatMap(contact => contact['contact']
        .map(c => tempArr.push({ value: c.value, type: c.type.toLowerCase() })));

    return tempArr;
  }
}
