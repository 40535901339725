import { Injectable } from '@angular/core';
import { ContactHelper, ContactInfo } from '../helpers/contact.helper';
import { ListingSearchResultsModel } from '../models/listing-search-results.model';
import { ListingModel } from '../models/listing.model';
import { ListingDetailViewModel } from '../models/listing-detail-results.model';
import { BlogPostSearchResultsModel } from '../models/blog-post-search-results.model';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { Endpoints } from '../constants/endpoints';
import { SearchArguments } from '../models/search-arguments.model';
import { ContactType } from '../enums/contact-type.enum';
import { ListingAddress } from '../models/listing-address.model';
import { BlogPostSearchArguments } from '../models/blog-post-search-arguments.model';
import { SocialType } from '../enums/social-type.enum';
import { FormGroup } from '@angular/forms';
import { SuggesterRequest } from '../models/suggester-request.model';
import { SuggersterResponse } from '../models/suggester-response.model';
import { UrlHelper } from '../helpers/url-builder.helper';
import { BlogPostDetailResult } from '../models/blog-post-detail-result.model';
import { BlogPost } from '../models/blog-post.model';
import { CityEditorial } from '../models/city-editorial.model';
import { ParkingInfoData } from '../helpers/parking-info.helper';
import { Utils } from '../utils/utils';
import { BookingHelper } from '../helpers/booking.helper';
import { ExtraArticles } from '../pages/detail/components/extra-articles/extra-articles.component';
import { ArticleSnippetDto } from '../models/blog-post-snippet.model';

export type Contacts = {
  [key in keyof typeof ContactType]: ContactInfo;
};

export type Socials = {
  [key in keyof typeof SocialType]: ContactInfo;
};

export interface Badge {
  badge: string,
  year: number,
  type: string,
  index: number
}

export interface LocationObject {
  latitude: number;
  longitude: number;
}

export interface Poi {
  location: LocationObject;
  index: number;
  paid: boolean;
  title: string;
};

export interface SearchGroup {
  [key: string]: {
    listings: ListingModel[];
    numFound: number;
  };
}

export interface SearchResults {
  data: ListingSearchResultsModel;
  searchGroups: SearchGroup;
}

export interface BlogPostsRequest {
  platform: string;
  headingId?: string;
  exclude?: string[];
  category?: string;
  segments?: string[]
  limit?: number;
  uniListingId?: string;
}

export type DetailPageType = 'traffic' | 'folder' | 'basic' | 'free';
export type SearchPageType = 'dynamic' | 'normalized';
export type ButtonStyle = 'filled-light' | 'filled-dark' | 'outline' | 'link';
export type SuggestType = 'what' | 'where';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  //
  private allListings: any;

  //
  private bookingUrl: any;
  private bookingLabel: any;
  private isBooking: boolean = false;
  private isQuote: boolean = false;
  private isAppointment: boolean = false;
  private enabledRequestForm = false;

  //
  private locationPath: string[];
  public selectValue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  contactHelper: ContactHelper = new ContactHelper();

  constructor(
    private bookingHelper: BookingHelper,
    private http: HttpClient,
    private translate: TranslateService,
    private urlHelper: UrlHelper,
    private utils: Utils,
  ) { }

  // cache

  fetchResults(req: SearchArguments): Observable<SearchResults> {
    const res = this.http.post<ListingSearchResultsModel>(Endpoints.SEARCH_LISTINGS(this.translate.currentLang), req)
      .pipe(
        map((data) => {
          return {
            data,
            searchGroups: this.filterByGroup(data)
          }
        }),
        shareReplay(1)
      )
    return res;
  }

  fetchDetail(req: SearchArguments): Observable<ListingDetailViewModel> {
    const res = this.http.post<ListingDetailViewModel>(Endpoints.LISTING_DETAIL(this.translate.currentLang), req)
    return res;
  }

  fetchBlog(req: BlogPostSearchArguments): Observable<BlogPostSearchResultsModel> {
    const res = this.http.post<BlogPostSearchResultsModel>(Endpoints.SEARCH_BLOGS(this.translate.currentLang), req)
      .pipe(
        switchMap(data => {
          // add detail url
          let blogPosts = data?.blogPosts;
          blogPosts = blogPosts.map(post => {
            let headings = [];
            if (post?.headings !== undefined) {
              headings = post?.headings;
            }
            let heading = "";
            if (headings?.length > 0) {
              heading = headings[0];
            }
            const route = this.translate.instant("Routes.Blog.BlogDetail");
            post.customUrl = post?.customUrl ?? this.urlHelper.getBlogPageRoute(route, heading, post?.id, post?.title);
            return post;
          });
          return of({
            blogPosts,
            categories: data?.categories,
            debug: data?.debug,
            found: data?.found
          })
        }),
        shareReplay(1)
      );
    return res;
  }

  fetchBlogDetail(req: BlogPostSearchArguments): Observable<BlogPost> {
    const res = this.http.post<BlogPostDetailResult>(Endpoints.SEARCH_BLOGS(this.translate.currentLang), req)
      .pipe(
        switchMap((data) => {
          const blogPost = data?.blogPosts.length > 0 && data?.blogPosts[0];
          const modifiedBlogPost: BlogPost = { ...blogPost };
          modifiedBlogPost.publishDate = this.formatDate(blogPost?.publishDate);
          modifiedBlogPost.modifiedDate = this.formatDate(blogPost?.modifiedDate);

          return of(modifiedBlogPost);
        }),
        shareReplay(1)
      );

    return res;
  }

  fetchPopularBlog(req: BlogPostsRequest): Observable<ArticleSnippetDto[]> {
    const res = this.http.post<ArticleSnippetDto[]>(Endpoints.SEARCH_BLOG_POPULAR(this.translate.currentLang), req)
      .pipe(shareReplay(1));
    return res;
  }

  fetchExtraBlogPosts(req: BlogPostsRequest): Observable<ExtraArticles> {
    const res = this.http.post<ExtraArticles>(Endpoints.SEARCH_BLOG_EXTRA(this.translate.currentLang), req)
      .pipe(shareReplay(1));
    return res;
  }

  fetchRelatedBlogPosts(req: BlogPostsRequest): Observable<ArticleSnippetDto[]> {
    const res = this.http.post<ArticleSnippetDto[]>(Endpoints.SEARCH_BLOG_RELATED(this.translate.currentLang), req)
      .pipe(shareReplay(1));
    return res;
  }

  fetchRelatedListings(category: string): Observable<ListingModel[]> {
    const res = this.http.post<ListingModel[]>(Endpoints.SEARCH_BLOG_LISTINGS(this.translate.currentLang), { category, limit: 3 })
      .pipe(shareReplay(1));
    return res;
  }

  fetchRelatedCities(where: string): Observable<string[]> {
    const res = this.http.post<string[]>(Endpoints.SEARCH_BLOG_CITIES(this.translate.currentLang), { where })
      .pipe(shareReplay(1));
    return res;
  }

  fetchEditorial(city: string, headingId: string): Observable<CityEditorial[]> {
    const res = this.http.post<any>(Endpoints.SEARCH_EDITORIAL(this.translate.currentLang), { city, headingId })
      .pipe(shareReplay(1));
    return res;
  }

  fetchParkingInfo(lat: number, lng: number): Observable<ParkingInfoData> {
    const res = this.http.get<ParkingInfoData>(Endpoints.PARKING_INFO(lat, lng))
      .pipe(shareReplay(1));
    return res;
  }

  fetchSuggestion(type: SuggestType, searchForm: FormGroup): Observable<SuggersterResponse[]> {
    const suggestion = searchForm.get(type)
      .valueChanges
      .pipe(
        debounceTime(300), // wait after keypress
        distinctUntilChanged(), // call if input changed
        switchMap((input: string) => {
          if (this.selectValue$.getValue()) {
            this.selectValue$.next(false); // Reset the flag
            return [];
          }
          const params: SuggesterRequest = { type, input };
          return this.http.post<SuggersterResponse[]>(Endpoints.SUGGESTER(this.translate.currentLang), params);
        }));

    return suggestion;
  }

  // cache

  filterByGroup(data: ListingSearchResultsModel): SearchGroup {
    const groups = {};
    
    data?.listings?.forEach(listing => {
      // get group key
      const key = listing?.requestName.replace("GetListings", "").trim();
      
      // init group if does not exist
      if (!groups[key]) {
        groups[key] = {
          listings: [...listing?.value],
          numFound: listing?.numFound || 0,
        };
      }
    });
  
    return groups;
  }

  isLatLng(value: string): boolean {
    if (!value) {
      return false;
    }
    const latLngRegex = /^[-+]?([1-8]?\d(?:\.\d{1,})?|90(?:\.0{1,6})?),\s*[-+]?(180(?:\.0{1,6})?|((1[0-7]\d)|([1-9]?\d))(?:\.\d{1,})?)$/;
    return latLngRegex.test(value.trim());
  }

  serializeQueryParams(params: any): string {
    return "?" + Object.keys(params)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join('&');
  }

  getWhereValue(value: string): string {
    if (this.isLatLng(value)) {
      return "";
    }

    return value;
  }

  getWherePlaceholder(value: string, isHome: boolean = false): string {
    if (this.isLatLng(value)) {
      return this.translate.instant('AroundMe');
    }

    return isHome ? "" : this.translate.instant('WhereSearchDefault');
  }

  setLocationPath(data: ListingSearchResultsModel) {
    this.locationPath = [data?.analyzedLocation?.province || null, data?.analyzedLocation?.name || null];
  }

  getLocationPath() {
    return this.locationPath;
  }

  hasGroupListings(groupName: string) {
    return this.getListingsByGroup(groupName)?.listings.length > 0;
  }

  getListingsByGroup(groupName: string) {
    return this.allListings && this.allListings[groupName] || { listings: [], numFound: 0 };
  }

  getDetailPageType(listing: ListingModel): DetailPageType {
    let pageType: DetailPageType = "free";

    if (listing?.IsFolder || listing?.folders.length > 0 || listing?.FolderAddresses.length > 0) {
      pageType = "folder";
    } else if (listing?.IsTrafficPage) {
      pageType = "traffic";
    } else if (listing?.IsPaid) {
      pageType = "basic";
    }

    return pageType;
  }

  getDetailUrl(listing: ListingModel) {
    return this.urlHelper.getDetailPageRoute(this.translate.instant('Routes.Detail.ListingDetail'), listing.City, listing.Id, listing.Title, true);
  }

  getBlogDetailUrl(heading: string, id: string, title: string) {
    return `/blog/${heading}/${id}/${title}/`;
  }

  // pois
  getResultsPois(data: ListingModel[]): Poi[] {
    //
    const pois = [] as Poi[];
    data.map((item, i) => {
      if (item.Latitude && item.Longitude) {
        pois.push({
          location: { latitude: item.Latitude, longitude: item.Longitude },
          index: i,
          title: item.Title,
          paid: item.IsPaid
        });
      }
    });

    return pois;
  }

  getDetailPois(data: ListingModel): Poi[] {
    if (!data) {
      return [];
    }

    const poi: Poi = {
      index: 0,
      location: {
        latitude: data?.Latitude,
        longitude: data?.Longitude
      },
      paid: data?.IsPaid,
      title: data?.Title
    }

    return [poi];
  }

  getFolderPois(data: ListingAddress[]) {
    const pois = [] as Poi[];
    data.map((item, i) => {
      if (item.latitude && item.longitude) {
        pois.push({
          location: { latitude: item.latitude, longitude: item.longitude },
          index: i,
          title: "",
          paid: false
        });
      }
    });
    return pois;
  }

  // contacts
  getContacts(listing: ListingModel): Contacts {
    const contacts: Contacts = {} as Contacts;

    Object.keys(ContactType).forEach(key => {
      const contactType = ContactType[key];
      contacts[contactType] = this.contactHelper.getContactInfo(listing, key);
    });

    return contacts;
  }

  // socials
  getSocials(listing: ListingModel): Socials {
    const socials: Socials = {} as Socials;

    Object.keys(SocialType).forEach(key => {
      const socialType = SocialType[key];
      socials[socialType] = this.contactHelper.getContactInfo(listing, key);
    });

    return socials;
  }

  // website, webshop
  getLinkUrl(contacts: ContactInfo[], trafficLink?: string): string {
    let link: string = "";
    if (trafficLink?.length > 0) {
      link = trafficLink[0];
    } else if (contacts.length > 0 && contacts[0].hasContact) {
      link = contacts[0]?.contact[0]?.value;
    }

    const rgx: RegExp = new RegExp(/\?.+=/, 'i');
    const matches: RegExpMatchArray | null = link.match(rgx);
    const mark: string = (matches && matches.length > 0) ? "&" : "?";

    if (link.length > 0) {
      return `${link}${mark}utm_source=fcrmedia&utm_medium=internet&utm_campaign=goudengidspagesdor`;
    }

    return "";
  }

  // get contact by type
  getContactByType(contacts: Contacts, type: string): ContactInfo[] {
    let contactsByType: ContactInfo[];
    switch (type) {
      case "phone":
      case "phoneFax":
      case "fax":
      case "mobile":
        const filterArr = Object.entries(contacts)
          .filter(([key, value]) => (key === "phone" || key === "mobile" || key === "phoneFax" || key === "fax") && value.hasContact)
          .flatMap(item => item[1])
          .reduce((acc, item) => {
            if (item.contact) {
              acc.push(...item.contact);
            }
            return acc;
          }, []);

        contactsByType = [{
          contact: filterArr,
          hasContact: filterArr.length > 0
        }];

        break;
      case "website":
      case "webshop":
      case "email":
      case "menu":
      case "folder":
        contactsByType = Object.entries(contacts)
          .filter(([key, value]) => (key === type) && value.hasContact)
          .map(([_, value]) => value);
        break;
      default:
        contactsByType = [];
    }

    return contactsByType;
  }

  // booking quote appointment
  getRequestForm(listing: ListingModel) {
    this.bookingUrl = this.bookingHelper.getBookingUrl(listing?.BookingEmbedCode);
    this.bookingLabel = this.bookingHelper.getBookingLabel(listing?.BookingEmbedCode);
    this.isBooking = listing?.BookingEnabled && !this.utils.isNullOrEmpty(this.bookingUrl);
    this.isQuote = listing?.QuoteEnabled && !this.utils.isNullOrEmpty(listing?.QuoteEmail);
    this.isAppointment = listing?.AppointmentEnabled && !this.utils.isNullOrEmpty(listing?.AppointmentEmail);
    this.enabledRequestForm = this.isBooking || this.isQuote || this.isAppointment;

    return {
      bookingUrl: this.bookingUrl,
      bookingLabel: this.bookingLabel,
      isBooking: this.isBooking,
      isQuote: this.isQuote,
      isAppointment: this.isAppointment,
      enabledRequestForm: this.enabledRequestForm
    }
  }

  // badges
  getRatingBadges(listing: ListingModel): Badge[] {
    const ratingBadges = listing?.Badges.filter(b => b.startsWith("rating")) || [];
    const badgeInfoArr: Badge[] = [];

    for (const badge of ratingBadges) {
      const badgeType = badge.substring(0, badge.length - 4);
      const year = parseInt(badge.substring(badge.length - 4), 10);

      if (!isNaN(year)) {
        let index = new Date().getFullYear() - year;
        index = index > 2 ? 2 : index;
        const badgeInfo: Badge = {
          badge,
          year,
          type: badgeType,
          index
        }
        badgeInfoArr.push(badgeInfo);
      }
    }

    const sortedBadgeInfoList = badgeInfoArr.sort((a, b) => b.year - a.year).slice(0, 3);
    return sortedBadgeInfoList;
  }

  formatDate(date: string): string {
    if (!date) {
      return null;
    }
    const dateValue = new Date(`${date}`);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const currentMonth = dateValue.getMonth();
    const month = this.translate.instant(months[currentMonth]);
    const day = dateValue.getDate();
    const year = dateValue.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  getCategoryLink(category: string, city: string = null): string {
    return this.urlHelper.getSearchPageRoute(this.translate.instant("Routes.Search.NormalizedWhatWhereSearch"), category, city);
  }

  getLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {


            resolve(`${position.coords.latitude},${position.coords.longitude}`);
          },
          (error) => {
            console.error('Error getting location:', error);
            reject(error);
          }
        );
      } else {

        reject('Geolocation is not supported by this browser.');
      }
    });
  }

  getTotalCount(searchResults: SearchGroup): number {
    const pageSize = 20;
    let totalNumFromAllGroups = 0;
    let a2NumFound = 0;

    if (searchResults && Object.keys(searchResults).length > 0) {
      for (let group in searchResults) {
        if (searchResults.hasOwnProperty(group)) {
          if (group !== "C") {
            totalNumFromAllGroups += searchResults[group].numFound;
          }
          if (group === "A2") {
            a2NumFound = searchResults[group].numFound;
          }
          if (group === "F") {
            if (totalNumFromAllGroups > 20) {
              totalNumFromAllGroups = 20;
            }
          }
        }
      }
    }

    if (a2NumFound > pageSize) {
      return a2NumFound;
    }

    return totalNumFromAllGroups;
  }

  getListings(searchGroup: SearchGroup): ListingModel[] {
    const listings: ListingModel[] = [];

    for (const key in searchGroup) {
      if (searchGroup.hasOwnProperty(key)) {
        if (key !== "C") {
          const value = searchGroup[key];

          if (value && Array.isArray(value.listings)) {
            listings.push(...value.listings);
          }
        }
      }
    }

    return listings;
  }
}