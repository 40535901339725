import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class Utils {

    public isNullOrEmpty(string: string | null | undefined): boolean {
        return !string || string.trim() === '';
    }

    public hasProtocol(url) {
        const protocols = ["http://", "https://", "ftp://", "ftps://"];
        return protocols.some(protocol => url.startsWith(protocol));
    }

    public getUrlWithProtocol(url: string): string {
        if (!this.hasProtocol(url)) {
            return "//" + url;
        }

        return url;
    }

    public capitalizedString(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    public isObjectEmpty(obj: any): boolean {
        return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
    }
}