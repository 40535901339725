import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-heading-link',
  templateUrl: './heading-link.component.html',
  styleUrl: './heading-link.component.scss'
})
export class HeadingLinkComponent {
  @Input("href")
  linkHref: string = "";

  @Input("label")
  linkLabel: string = "";
}
