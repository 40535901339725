<div class="yp-container card card--flow-y">
    <div class="grid">

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('fr')">
            <h1 class="text-2xl">
                Politique de confidentialité FCR Media
            </h1>
            <p>
                La présente politique prend effet le 25 mai 2018.
            </p>
            <p>
                Aux yeux de FCR, la confidentialité de ses clients est essentielle. Nous voulons donc faire preuve de transparence dans les données personnelles que nous collectons à votre sujet, pour quelle raison et ce que nous en faisons. En outre, nous voulons aussi que vous connaissiez vos droits en la matière. Nous vous recommandons donc de lire la présente Politique de confidentialité et de nous contacter en cas de questions éventuelles.
            </p>
            
            <h2 class="text-xl">
                1. Définitions
            </h2>
            <p>
                Les définitions suivantes vous aideront à mieux comprendre la Politique de confidentialité :
            </p>
            <ul>
                <li>
                    "Nous", "notre" renvoie à
                    <br />FCR Media Belgium SA, ou "FCR"
                    <br />Uitbreidingstraat 82, 2600 Berchem
                    <br />0807.677.428.
                    <br /><a href="https://fcrmedia.be">https://fcrmedia.be</a>
                    <br /><a href="mailto:info@fcrmedia.be">info&#64;fcrmedia.be</a>;
                </li>
                <li>
                    "Vous", "votre" fait référence à un visiteur du site, un candidat, un client ou un client potentiel ;
                </li>
                <li>
                    "Données personnelles" renvoie à toutes les informations qui vous identifient (directement ou indirectement) comme un individu ;
                </li>
                <li>
                    "Site(s) Web" fait référence à https://www.fcrmedia.be, https://dexville.be, https://www.pagesdor.be, https://www.pagesblanches.be et/ou http://careers.fcrmediabelgium.be, de même qu'à toutes les pages Web, plateformes, fonctions interactives, applications, widgets, blogs, réseaux sociaux, onglets de médias sociaux ou autres offres qui renvoient à la présente Politique de confidentialité.
                </li>
            </ul>
            
            <h2 class="text-xl">
                2. Quel est le champ d'application de cette Politique de confidentialité ?
            </h2>
            <p>
                La présente Politique de confidentialité s'applique à toutes les données personnelles des visiteurs du site Web, candidats, clients et potentiels clients, qui pourraient être collectées et utilisées par FCR. Il s'agit notamment d'informations que FCR récolte par le biais de ses sites Web, que ses clients (potentiels) fournissent spontanément, ou encore de données qui sont rassemblées hors ligne à des fins commerciales et dont nous avons la gestion.
            </p>
            <p>
                La présente Politique de confidentialité a été établie et est mise à jour conformément aux dispositions et exigences de la nouvelle Règlementation européenne sur la vie privée, le Règlement général sur la Protection des Données (RGPD en français et General Data Protection Regulation, GDPR, en anglais). En d'autres termes, FCR traite les données personnelles conformément aux principes décrits par le RGPD.
            </p>
            <h2 class="text-xl">
                3. Qui est responsable de vos données personnelles ?
            </h2>
            <p>
                FCR Media Belgium SA, Uitbreidingstraat 82, 2600 Berchem, Belgique, est responsable du traitement de vos données personnelles.
            </p>
            <h2  class="text-xl">
                4. Quelles sont les données que nous collectons à votre sujet et dans quel but ?
            </h2>
            <h3 class="font-semibold">
                4.1. Traitement des données personnelles reprises dans les pages d'or et les pages blanches
            </h3>
            <p>
                L'un des services de FCR consiste à publier et à distribuer les pages d'or et les pages blanches, tant au format papier (les guides papier) que digital (Internet, smartphones, etc.). Les données suivantes peuvent être collectées : nom et prénom, adresse, adresse e-mail, numéro de téléphone, photos et données de paiement. Les données que nous utilisons pour la publication des pages d'or et des pages blanches proviennent de notre banque de données, où elles sont complétées par des informations issues d'autres sources, notamment la Banque Carrefour des Entreprises.
            </p>
            <p>
                Dans le cadre des publicités commerciales dans les pages d'or, le traitement des données personnelles est légal moyennant une convention entre vous et FCR. Cette convention peut aussi être conclu par téléphone. Nous vous demanderons alors explicitement si l'entretien peut être repris comme preuve de l'existence de la convention. Si vous refusez de donner votre autorisation, l'entretien ne sera pas repris et la convention vous sera envoyée par e-mail. Pour les publicités gratuites, la publication est légale en raison d'un intérêt légitime de FCR. À tout moment, vous pouvez décider de ne plus faire usage de ces publicités gratuites, voir le point 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <p>
                La publication des pages blanches et des données personnelles qui y sont reprises est légale parce qu'il s'agit d'une tâche d'intérêt général pour FCR. Les données personnelles présentes dans les pages blanches seront uniquement utilisées pour leur publication. À tout moment, vous pouvez décider de ne plus être repris dans les pages blanches, voir le point 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <p>
                Si vous ne désirez plus recevoir la version papier des pages blanches ou des pages d'or, nous aurons besoin de vos données personnelles afin de nous assurer que nous traitons la demande de la bonne personne et que nous n'effaçons pas la mauvaise personne de notre base de données. Nous vous demandons donc de nous partager les données suivantes : nom et prénom, adresse, numéro de téléphone et adresse e-mail. Pour vous désinscrire, cliquez ici.
            </p>
            <h3 class="font-semibold">
                4.2. Traitement des données personnelles dans le cadre de nos services de marketing en ligne
            </h3>
            <p>
                Outre la publication des pages d'or et pages blanches, FCR propose aussi des services de marketing digital. Nous concevons des sites Web, nous améliorons la visibilité en ligne et permettons à votre entreprise d'être trouvée plus facilement, nous veillons à ce que vous soyez visibles au moyen des données adéquates, nous plaçons vos publicités sur les médias sociaux et nous faisons votre publicité via les moteurs de recherche tels que Google, de manière à ce que votre société apparaisse plus haut dans les résultats de recherche. Les données personnelles suivantes peuvent donc être traitées : nom et prénom, adresse, adresse e-mail, photos, numéro de téléphone et données de paiement. Selon le service et le support publicitaire que vous avez choisis et les informations que nous avons reçues, nous publierons plus ou moins d'informations. Il s'agit notamment des logos, des sites Web et autres informations liées à l'entreprise. Le traitement de ces données personnelles pour nos services est nécessaire afin que nous puissions afficher en ligne les informations correctes concernant votre société, mais aussi les gérer correctement, dans le respect de votre contrat avec FCR.
            </p>
            <p>
                Le traitement de données est légal en raison de l'existence de ce contrat. Pour certains des services énumérés ci-dessus, vous avez la possibilité de gérer vous-même le produit que vous avez acheté et de modifier certaines données. Vous bénéficiez d'un accès par le biais d'un nom d'utilisateur et d'un mot de passe, et ce uniquement pour les services Websites et NetSync. Vous pouvez prendre contact avec notre service clientèle si vous désirez malgré tout modifier des données pour des services que vous ne pouvez pas modifier par vous-même.
            </p>
            <h3 class="font-semibold">
                4.3. Traitement des données personnelles en fournissant les données à d'autres entreprises qui les commercialisent (data partners)
            </h3>
            <p>
                FCR peut commercialiser les données issues de sa banque de données et les fournir à des data partners aux fins suivantes :
            </p>
            <ul>
                <li>
                    Marketing direct des data partners : vente de données, utilisation des données à des fins de marketing ou pour l'élaboration d'un fichier à la demande de tiers, utilisation des données pour validation et pour enrichissement des fichiers ;
                </li>
                <li>
                    Offre de produits d'analyse statistique et publication ou vente des analyses en question par les data partners ;
                </li>
                <li>
                    Commercialisation par les data partners des éléments de données qui résulteraient du croisement des données provenant de notre banque de données avec d'autres sources appartenant à ces data partners.
                </li>
            </ul>
            <p>
                Les données personnelles suivantes peuvent donc être traitées : nom et prénom, adresse, adresse e-mail, numéro de téléphone, sexe, données financières et informations spécifiques à l'entreprise. Il est contractuellement spécifié à ces data partners qu'ils peuvent uniquement utiliser les données aux fins mentionnées ci-dessus.
            </p>
            <p>
                Pour les clients, la transmission de ces données à des data partners fait intégralement partie du service qu'ils ont choisi et des conditions générales de vente de FCR : son objectif est d'accroître la visibilité et la notoriété de votre entreprise. Le traitement est légal en raison du contrat que vous avez conclu avec FCR. Vous pouvez toujours demander à FCR de ne plus partager vos données avec les data partners. Pour les sociétés qui n'ont pas conclu de contrat avec FCR et qui utilisent uniquement les mentions gratuites, il est également possible de mettre un terme à la transmission des données à nos partenaires, comme repris dans le point 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3 class="font-semibold">
                4.4. Traitement des données personnelles dans MyMedia
            </h3>
            <p>
                Depuis peu, FCR propose la plateforme MyMedia. Pour l'instant, seuls les nouveaux clients peuvent y accéder, mais à terme, l'objectif est que chacun de nos clients puisse l'utiliser. Sur la plateforme, vous trouverez un aperçu des services auxquels vous avez souscrit chez FCR, actuellement ou par le passé, mais aussi des statistiques concernant le fonctionnement de ces services pour votre entreprise. Les données personnelles suivantes seront également affichées sur votre profil : nom et prénom, adresse, adresse e-mail, numéro de téléphone, données de paiement et aperçu de vos factures, de votre statut de paiement, ainsi que vos contrats. Nous utilisons ces données pour vous fournir une vue d'ensemble des données vous concernant que nous utilisons pour honorer le contrat que nous avons conclu avec vous. Vous pouvez modifier vous-même les informations sur MyMedia si elles sont incorrectes ou incomplètes. L'accès à votre compte sur MyMedia sera clôturé une fois que votre contrat arrive à expiration. La plateforme MyMedia utilise également des cookies.
            </p>
            <h3 class="font-semibold">
                4.5. Traitement des données personnelles des utilisateurs de nos sites Web
            </h3>
            <p>
                FCR traite des données personnelles à différents moments sur ses sites Web. C'est par exemple le cas lorsque vous surfez sur nos pages ou lorsque vous utilisez la fonction de recherche incluse sur le site. Par le biais des cookies notamment, FCR peut donc traiter les données suivantes : adresse IP, localisation, type d'appareil, type de navigateur et/ou système d'exploitation, et profil de recherche, de navigation et de clics. FCR utilise ces données afin que vous exploitiez nos sites Web au maximum.
            </p>
            <h3 class="font-semibold">
                4.6. Traitement des données personnelles par FCR à des fins de marketing direct
            </h3>
            <p>
                Vous pouvez toujours vous inscrire à l'une de nos newsletters, ce qui nous permet de vous tenir au courant des services de FCR, mais aussi des changements et des évolutions du marché. Nous informons également les clients existants sur les produits et services similaires à ceux qu'ils ont achetés chez nous. Pour ce faire, nous utilisons les données personnelles suivantes : nom et prénom, adresse, adresse e-mail, numéro de téléphone et informations relatives aux services auxquels vous avait souscrit chez FCR. Ce faisant, nous vous transmettons des informations mieux personnalisées et plus intéressantes. Nous pouvons également utiliser vos données pour vous inviter à des événements ou des séances de formation que nous organisons. Nous utilisons aussi les réseaux sociaux pour promouvoir ces services et événements. À tout moment, vous pouvez décider de ne plus recevoir ces communications, voir le point 9.7. Droit de retirer votre autorisation et 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3 class="font-semibold">
                4.7. Traitement des données personnelles via le rapportage
            </h3>
            <p>
                FCR utilise vos données pour effectuer des rapports et des analyses afin de récolter des informations sur ses services et sur la manière de les améliorer. Dans cette optique, nous utiliserons les données personnelles suivantes : nom et prénom, adresse, adresse e-mail, numéro de téléphone, données financières et informations relatives aux services que vous utilisez chez FCR ou qui vous intéressent. Le traitement de ces données afin d'améliorer nos services et notre offre de services est légal en raison de l'intérêt légitime de FCR pour l'amélioration de ses services.
            </p>
            <h3 class="font-semibold">
                4.8. Traitement de données personnelles en cas de candidature spontanée
            </h3>
            <p>
                Vous pouvez postuler spontanément pour un poste vacant via notre site <a href="http://careers.fcrmediabelgium.be">http://careers.fcrmediabelgium.be</a>. Pour ce faire, nous vous demanderons les données suivantes : nom et prénom, sexe, langue maternelle, date de naissance, commune de résidence, adresse e-mail, numéro de téléphone ou de portable, votre préférence concernant le département où vous souhaitez travailler, CV et lettre de motivation. Si aucun poste ne vous intéresse pour l'instant, vous pouvez nous laisser les données ci-dessus afin d'être contacté par la suite pour un nouveau poste. Le traitement de ces données personnelles est légal en raison de l'autorisation que vous nous avez donnée à ce sujet. Les données seront uniquement utilisées à des fins de recrutement et de candidature. À tout moment, vous avez le droit de retirer votre autorisation, voir le point 9.7. Droit de retirer votre autorisation et 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3 class="font-semibold">
                4.9. Traitement des données personnelles à des fins de CRM et data quality
            </h3>
            <p>
                FCR reçoit des données provenant de tiers dans le but d'enrichir ses bases de données. Cela lui permet de vérifier la justesse des informations contenues dans ses banques de données et d'en garantir la qualité. Ce processus est nécessaire pour publier des informations correctes et pour mentionner les bonnes informations au sujet de nos produits (ex. sur le site Web que nous avons conçu pour vous). Nous utilisons aussi les données dans le but d'informer les clients potentiels de nos produits et services. Les clients potentiels peuvent aussi prendre l'initiative de nous informer qu'ils sont intéressés par nos produits et services. Pour ce faire, ils peuvent nous laisser leurs données grâce à l'option "Ajouter votre entreprise". Les données personnelles suivantes pourront dès lors être traitées : nom et prénom, adresse, adresse e-mail, numéro de téléphone, sexe, langue, données financières et informations spécifiques à la société, comme son nom et son numéro d'entreprise.
            </p>
            <p>
                FCR reçoit uniquement des données personnelles pour lesquelles les tiers ont un motif légitime de les transférer (ex. l'autorisation de la personne concernée ou une obligation légale). Le traitement de ces données est nécessaire afin que FCR puisse remplir ses obligations en vertu du contrat que nous avons passé avec vous. Le traitement des données que nous recevons via "Ajouter votre entreprise" est légal parce qu'il se fonde sur votre autorisation. À tout moment, vous pouvez retirer votre autorisation, voir le 9.7 Droit de retirer votre autorisation et 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <p>
                FCR utilise aussi vos données personnelles pour certaines activités CRM, notamment pour conserver les clients ou pour mener des enquêtes de satisfaction. Dans le premier cas, nous traiterons les données personnelles suivantes : nom et prénom, numéro de téléphone, adresse e-mail et données de paiement. Pour les enquêtes de satisfaction, nous utiliserons les données personnelles suivantes : nom et prénom, numéro de téléphone et adresse e-mail. L'usage de ces données personnelles à ces deux fins est justifié en raison des intérêts légitimes de FCR.
            </p>
            <h3 class="font-semibold">
                4.10. Traitement de données à caractère personnel pour notre service clientèle
            </h3>
            <p>
                Vous pouvez contacter notre service clientèle pour toutes vos questions, remarques ou plaintes concernant le service que nous vous offrons. Vous pouvez aussi le contacter si vous désirez modifier des informations précises concernant votre entreprise (ou vous-même) par rapport aux services que vous avez achetés chez nous. Pour ce faire, veuillez remplir un formulaire de contact que vous trouverez ici. Ce dernier sera ensuite transmis à notre service clientèle. Vous pouvez aussi appeler ou envoyer un e-mail au service clientèle. Nous pouvons donc collecter les données suivantes dans cette optique : nom et prénom, adresse e-mail, numéro de téléphone, adresse, information sur l'entreprise et informations relatives à votre question. FCR n'utilisera ces données que pour réagir au mieux à votre question ou à votre problème. L'usage de ces données personnelles à cette fin est donc justifié en raison des intérêts légitimes de FCR.
            </p>
            <p>
                Les communications téléphoniques entrantes avec notre service clientèle seront utilisées à des fins de coaching et de formation. Les enregistrements ne seront pas conservés plus longtemps que le strict nécessaire et ne seront pas accessibles aux personnes non autorisées.
            </p>
            <h3 class="font-semibold">
                4.11. Traitement de données personnelles à des fins de facturation
            </h3>
            <p>
                Si vous avez souscrit un service payant chez FCR, nous aurons besoin de vos données personnelles pour la facturation. Il s'agit de vos : nom et prénom, adresse e-mail, siège de l'entreprise, adresse de facturation, données de paiement, numéro de compte bancaire, et parfois votre numéro de téléphone ou de portable. L'usage de ces données personnelles à des fins de facturation est donc justifié par le contrat que vous avez conclu avec FCR.
            </p>
            <h3 class="font-semibold">
                4.12.Traitement de données personnelles pour répondre à une obligation légale
            </h3>
            <p>
                FCR traite également des données personnelles afin de répondre aux règlementations et législations auxquelles la société est soumise. Il s'agit par exemple de la règlementation relative à la comptabilité et aux impôts, à la législation sur le travail... Il est possible que nous devions partager vos données personnelles avec d'autres organismes afin de répondre à ces dispositions légales.
            </p>
            
            <h2  class="text-xl">
                5. Transfert de données à des tiers
            </h2>
            <p>
                FCR partage vos données à des tiers, notamment aux fins indiquées dans le point 4.3 Traitement des données personnelles en fournissant les données à des data partners. À tout moment, vous pouvez vous opposer à ce traitement, voir le point 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité. Par ailleurs, vos données ne seront transmises à des tiers que si FCR est tenu de le faire en vertu des lois et des règlementations qui s'appliquent, par exemple à la police dans le cadre d'une enquête criminelle.
            </p>
            <p>
                FCR ne transmet aucune donnée financière (comme les informations liées à une carte de crédit) à des tiers, à moins que ce ne soit nécessaire pour gérer votre demande de service, pour notre facturation, ou pour éviter ou lutter contre la fraude.
            </p>
            <p>
                Nos sites Web contiennent parfois des liens vers d'autres sites. Vous devrez lire leur politique de confidentialité avec soin, parce qu'elle peut différer de notre Politique de confidentialité.
            </p>
            
            <h2 class="text-xl">
                6. Responsables du traitement
            </h2>
            <p>
                Pour le traitement des données personnelles, FCR peut faire appel à d'autres prestataires de services qui s'occuperont du traitement uniquement à la demande de FCR. Ces derniers ne pourront donc pas les utiliser à des fins (commerciales) personnelles. FCR sélectionnera ses partenaires avec soin et attend d'eux qu'ils se montrent aussi consciencieux dans le traitement de ces données personnelles que FCR l'est. Nous passons un contrat de traitement avec ces partenaires, ce qui signifie qu'ils sont dans l'obligation de respecter les dispositions de cette Politique de confidentialité, mais aussi celles du RGPD.
            </p>
            
            <h2 class="text-xl">
                7. Protection de vos données personnelles
            </h2>
            <p>
                Nous reconnaissons notre responsabilité dans le fait de protéger suffisamment les informations que vous nous confiez. FCR a donc pris différentes mesures pour protéger les données personnelles contre le vol, l'utilisation abusive et l'accès non autorisé, la divulgation, la modification ou la destruction. Les mesures organisationnelles comprennent un accès limité aux bâtiments, aux systèmes et aux fichiers et leur surveillance ; des mesures techniques, y compris des pare-feu, la protection par des mots de passe personnels et la vérification, ainsi que des exigences de vérification pour l'accès aux données personnelles sur la base du need-to-know, sont également prévues.
            </p>
            <p>
                Nous attendons également de nos sous-traitants qu'ils soient en mesure de garantir un niveau de sécurité approprié lors du traitement des données à caractère personnel pour les besoins de FCR. Il s'agit d'une obligation contractuelle.
            </p>
            
            <h2 class="text-xl">
                8. Combien de temps conservons-nous vos données ?
            </h2>
            <p>
                FCR a une politique concernant la conservation des données à caractère personnel. Nous veillons à ce qu'elles soient conservées de manière sûre et pas plus longtemps que nécessaire pour remplir les fins mentionnées ci-dessus. Le délai de conservation des données dépend donc de l'objectif pour lesquelles elles sont collectées. Si vous souhaitez que FCR ne conserve plus vos données ou si vous aimeriez davantage d'informations sur les délais de conservation que nous appliquons, vous pouvez contacter notre service clientèle. Pour savoir comment procéder, veuillez lire le point 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            
            <h2 class="text-xl">
                9. Quels sont vos droits et comment les faire valoir ?
            </h2>
            <p>
                FCR utilise les données personnelles de ses clients, de ses clients potentiels, de ses candidats et des visiteurs de ses sites Web à des fins précises et récolte uniquement les données personnelles dont elle a véritablement besoin à ces fins. Nous aimerions faire preuve d'un maximum de clarté et de transparence par rapport aux activités pour lesquelles nous collectons, utilisons et protégeons vos données. C'est pourquoi FCR a élaboré une politique afin que vous puissiez faire valoir vos droits concernant les données à caractère personnel que vous nous confiez. Vous disposez en effet de plusieurs droits, conformément à la législation en vigueur sur la vie privée.
            </p>
            <h3  class="font-semibold">
                9.1. Droit de consulter vos données
            </h3>
            <p>
                À tout moment, vous avez le droit de demander à FCR quelles sont les données en sa possession, pour quelle raison elle les utilise et de vous en fournir une copie. Vous pouvez aussi demander combien de temps nous conservons vos données, sur quelle base juridique nous traitons vos données (autorisation, contrat, obligation légale, intérêt légitime, intérêt général, intérêt vital), etc. Dans le cadre de ce droit, vous pouvez également examiner les données pour voir si elles sont correctes. Pour savoir comment faire valoir votre droit, vous pouvez lire le point 10. Où m’adresser pour mes questions ou plaintes ? de cette Politique de confidentialité. Si vous possédez un compte MyMedia, Presence Management ou sur nos sites Web, vous y trouverez toujours un aperçu des données que nous possédons à votre sujet.
            </p>
            <h3  class="font-semibold">
                9.2. Droit d'améliorer vos données
            </h3>
            <p>
                Si vous constatez que les données personnelles que nous possédons à votre sujet sont incorrectes ou incomplètes, vous pouvez les faire compléter ou corriger. Pour ce faire, vous pouvez prendre contact avec le service clientèle par e-mail ou par le biais d'un formulaire de contact. Si vous possédez un compte en ligne sur MyMedia, sur un de nos sites Web ou si vous avez acheté un produit NetSync, vous pouvez corriger ou compléter vous-même certaines données à caractère personnel.
            </p>
            <h3  class="font-semibold">
                9.3. Droit de faire supprimer vos données
            </h3>
            <p>
                Vous avez le droit de demander à FCR de supprimer les données à caractère personnel qu'elle possède. Attention toutefois : il ne s'agit pas d'un droit absolu. Le RGPD stipule sous quelles conditions ce droit peut être exercé. C'est le service clientèle qui déterminera si vous remplissez l'une des conditions. Si c'est le cas, nous nous occuperons de la suppression de vos données à caractère personnel. Si vos données ont été transmises à des sous-traitants à des fins précises, FCR les informera également que ces données doivent être supprimées. Sachez toutefois que si vous choisissez d'exercer ce droit, toutes vos données personnelles seront supprimées chez FCR. Si vous souhaitez faire appel à nos services par la suite, vous devrez à nouveau partager vos données.
            </p>
            <p>
                Pour savoir comment faire valoir votre droit, vous pouvez lire le point 10. Où m’adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3  class="font-semibold">
                9.4. Droit de demander un traitement limité de vos données
            </h3>
            <p>
                En invoquant le droit à un traitement limité de vos données personnelles, FCR a toujours le droit de les conserver, mais doit les utiliser selon certaines restrictions. Ce droit n'est applicable que dans certaines situations. Si FCR souhaite utiliser vos données à d'autres fins que la simple conservation, ce ne sera possible que dans certains cas précis (par exemple si vous fournissez à nouveau l'autorisation d'utiliser vos données). Pour savoir comment faire valoir votre droit, vous pouvez lire le point 10. Où m’adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3  class="font-semibold">
                9.5. Droit de s'opposer au traitement de vos données
            </h3>
            <p>
                Vous avez le droit de vous opposer au traitement de vos données si ce traitement est fondé sur un intérêt général ou légitime de FCR. Dans le point 4. Quelles sont les données que nous collectons à votre sujet et dans quel but ?, vous trouverez une liste des activités pour lesquelles FCR dispose d'un intérêt légitime ou général. Si vous faites valoir ce droit, FCR mènera une enquête pour savoir si ses raisons légitimes de traiter vos données sont plus importantes que vos intérêts, droits et libertés. Dans le cas contraire, FCR ne traitera plus vos données à cette fin.
            </p>
            <p>
                Vous pouvez également vous opposer au traitement de vos données personnelles à des fins de marketing direct. Si vous exercez ce droit, FCR n'utilisera plus vos données à des fins de marketing direct.
            </p>
            <p>
                Pour savoir comment faire valoir votre droit, vous pouvez lire le point 10. Où m’adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3  class="font-semibold">
                9.6. Droit à la transférabilité des données
            </h3>
            <p>
                Ce droit signifie que vous avez le droit de récupérer les données que vous avez fournies à FCR. Il porte uniquement sur le traitement des données qui reposent sur votre autorisation (ex. autorisation d'envoyer une newsletter) ou sur un contrat (ex. contrat que vous avez conclu au moment de faire appel aux services de marketing digital   de FCR). Autre aspect de ce droit : vous avez le droit de vous adresser à un autre responsable de traitement pour les données que vous avez récupérées sans que FCR puisse s'y opposer. Notez toutefois qu'il s'agit uniquement des données que vous avez directement fournies à FCR. Pour savoir comment faire valoir votre droit, vous pouvez lire le point 10. Où m’adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3  class="font-semibold">
                9.7. Droit de retirer votre autorisation
            </h3>
            <p>
                Comme nous l'avons mentionné, vous pouvez à tout moment retirer votre autorisation pour tous les traitements que vous avez autorisés à FCR. Sachez cependant que le retrait de votre autorisation n'a aucun impact sur le traitement réalisé par FCR au moment où la société avait encore votre autorisation. Vous pouvez retirer votre autorisation de plusieurs manières :
            </p>
            <ul>
                <li>
                    Vous pouvez vous désinscrire des newsletters, des actions de marketing direct et autres informations sur mesure via le lien qui se trouve toujours en bas des newsletters et autres communications envoyées.
                </li>
                <li>
                    Vous pouvez vous désinscrire ici afin de ne plus recevoir les pages d'or et les pages blanches.
                </li>
                <li>
                    Vous pouvez remplir le formulaire de contact qui sera traité par notre service clientèle. Ce formulaire vous permet de retirer votre autorisation pour la réception des communications relatives aux postes vacants si vous avez spontanément posé votre candidature chez FCR. Mais vous pouvez aussi vous désinscrire de newsletters, du marketing direct ou autres informations via le formulaire de contact.
                </li>
            </ul>
            <p>
                Sachez toutefois qu'un délai de traitement est nécessaire pour traiter votre demande et qu'entretemps, il est fort probable que vous receviez les informations une dernière fois. Nous limiterons ce délai au maximum.
            </p>
            
            <h2  class="text-xl">
                10. Où m'adresser pour mes questions ou plaintes ?
            </h2>
            <p>
                Comme indiqué dans la déclaration de vos droits, vous pouvez faire valoir vos droits en remplissant un formulaire de contact qui sera ensuite traité par notre service clientèle. Bien sûr, vous pouvez toujours contacter le service clientèle par e-mail pour des questions ou des plaintes relatives au traitement de vos données personnelles ou pour des questions sur cette Politique de confidentialité. Vous pouvez aussi envoyer un e-mail à <a href="mailto:info@fcrmedia.be">info&#64;fcrmedia.be</a> pour toute question ou problème. Nous vous demanderons quelques informations ainsi que le droit que vous souhaitez faire valoir, ou encore la question ou le problème relatifs au traitement de vos données personnelles.
            </p>
            <p>
                Nous vous demandons vos données personnelles afin que nous puissions vous identifier (et uniquement vous) dans notre système, et surtout, que nous ne partagions pas vos données personnelles avec des personnes qui prétendent être quelqu'un d'autre. Nous vous demanderons ainsi les données suivantes : nom et prénom, adresse, entreprise, numéro de TVA et numéro de client (si vous avez fait appel aux services de FCR en tant qu'entreprise), numéro de téléphone, adresse e-mail, le droit que vous souhaitez faire valoir et des informations complémentaires au sujet de votre question ou problème. Toutes les demandes sont envoyées directement au service clientèle. Une fois que nous aurons reçu votre demande, nous vous enverrons un e-mail de confirmation pour vous informer qu'elle est en cours de traitement. Dans le mois suivant la réception de votre demande au service clientèle, nous vous communiquerons une réponse finale.
            </p>
            <p>
                Nous mettrons tout en œuvre pour répondre à votre question ou résoudre votre problème de manière optimale. Si malgré tout vous n'êtes pas satisfait de la manière dont nous avons géré vos données personnelles, vous trouverez sur le site Web de la Comission sur la protection de la vie privée davantage d'informations sur les étapes que vous pouvez entreprendre.
            </p>
            
            <h2  class="text-xl">
                11. Droit applicable
            </h2>
            <p>
                La présente politique de confidentialité est régie par le droit belge. Tous les litiges découlant de ou en relation avec la présente Politique de confidentialité seront exclusivement soumis aux tribunaux compétents d'Anvers.
            </p>
            
            <h2  class="text-xl">
                12. Modification de la Politique de confidentialité
            </h2>
            <p>
                FCR se réserve le droit d'apporter des modifications à la présente Politique de confidentialité. Tout changement sera publié sur cette page. Nous vous recommandons de consulter régulièrement cette Politique de confidentialité afin d'être toujours au courant du contenu de la Politique de confidentialité en vigueur. FCR collabore avec d'autres partenaires pour divers services. FCR n'est pas responsable des politiques de confidentialité de ses partenaires et n'est pas responsable du contenu de leur Politique de confidentialité. Nous vous recommandons donc de consulter la Politique de confidentialité de ces partenaires pour plus d'informations.
            </p>    
        </article>

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('nl')">
            <h1 class="text-2xl">
                Privacyverklaring FCR Media
            </h1>
            <p>
                Deze verklaring is van kracht met ingang van 25 mei 2018.
            </p>
            <p>
                Voor FCR is de privacy van haar klanten erg belangrijk. We willen dan ook transparant zijn over welke persoonsgegevens wij van u verzamelen, waarom wij die verzamelen en wat we er mee doen. Bovendien willen wij dat u uw rechten kent met betrekking tot deze persoonsgegevens. Om deze redenen raden wij u sterk aan om deze Privacyverklaring door te nemen en om ons te contacteren bij eventuele vragen.
            </p>
            
            <h2 class="text-xl">
                1. Definities
            </h2>
            
            <p>
                De volgende definities helpen u de Privacyverklaring beter te begrijpen:
            </p>
            <ul>
                <li>
                    “Wij”, “Onze”, “Ons”, verwijst naar
                    <br/>FCR Media Belgium NV, oftewel “FCR”
                    <br />Uitbreidingstraat 82, 2600 Berchem
                    <br />0807.677.428.
                    <br /><a href="https://fcrmedia.be">https://fcrmedia.be</a>
                    <br /><a href="mailto:info@fcrmedia.be">info&#64;fcrmedia.be</a>;
                </li>
                <li>
                    “U”, “Uw” verwijst naar een website bezoeker, een sollicitant, een klant of een potentiële klant;
                </li>
                <li>
                    “Persoonsgegevens” verwijst naar elke informatie die u (rechtstreeks of onrechtstreeks) identificeert als een individu;
                </li>
                <li>
                    “Website(s)”: verwijst naar https://fcrmedia.be, https://www.goudengids.be, https://www.wittegids.be, https://dexville.be en/of http://careers.fcrmediabelgium.be alsook alle webpagina’s, platformen, interactieve functies, applicaties, widgets, blogs, sociale netwerken, sociale media tabbladen of andere aanbiedingen die een link plaatsen naar deze Privacyverklaring.
                </li>
            </ul>
            
            <h2 class="text-xl">
                2. Wat is het toepassingsgebied van deze Privacyverklaring?
            </h2>
            <p>
                Deze Privacyverklaring is van toepassing op alle persoonsgegevens van websitebezoekers, sollicitanten, klanten en potentiële klanten die worden verzameld en gebruikt door FCR. Het gaat hier onder meer om informatie die FCR verzamelt via haar Websites, om informatie die onze (potentiële) klanten spontaan geven, alsmede om gegevens die offline worden verzameld voor commercieel gebruik, die door ons worden beheerd.
            </p>
            <p>
                Deze Privacyverklaring werd opgesteld en wordt up-to-date gehouden in overeenstemming met de bepalingen en vereisten van de nieuwe Europese privacyregelgeving, de Algemene Verordening Gegevensbescherming (afgekort ‘AVG’, in het Engels General Data Protection Regulation, afgekort ‘GDPR’). Dit wilt zeggen dat FCR persoonsgegevens verwerkt in overeenstemming met de principes voor verwerking zoals omschreven in de GDPR.
            </p>
            
            <h2 class="text-xl">
                3. Wie is verantwoordelijk voor uw persoonsgegevens?
            </h2>
            <p>
                FCR Media Belgium NV, Uitbreidingstraat 82, 2600 Berchem, België, is de verantwoordelijke voor de verwerking van uw persoonsgegevens.
            </p>
            
            <h2 class="text-xl">
                4. Welke gegevens verzamelen wij van u en met welk doel verzamelen wij die gegevens?
            </h2>
            <h3 class="font-semibold">
                4.1. Verwerking van persoonsgegevens opgenomen in de gouden gids en witte gids
            </h3>
            
            <p>
                Eén van de diensten van FCR is de publicatie en verdeling van de gouden gids en de witte gids. Het gaat hierbij zowel over de verspreiding van gidsen in gedrukte vorm (papieren gidsen) als in elektronische vorm (internet, smartphones, enz.). Volgende persoonsgegevens kunnen hierbij verwerkt worden: naam en voornaam, adres, e-mailadres, telefoonnummer, foto’s en betaalgegevens. De gegevens die we gebruiken voor de publicatie van de gouden en de witte gids zijn afkomstig uit onze databank, waar ze aangevuld worden met informatie afkomstig van andere bronnen waaronder de Kruispuntbank van Ondernemingen.
            </p>
            <p>
                Voor de commerciële advertenties in de gouden gids is de verwerking van persoonsgegevens rechtmatig vanwege het bestaan van een overeenkomst tussen uzelf en FCR. Deze overeenkomst kan ook telefonisch afgesloten worden. Hierbij wordt er expliciet gevraagd of het gesprek opgenomen mag worden als bewijs van het bestaan van de overeenkomst. Indien u geen toestemming wilt geven, wordt het gesprek niet opgenomen en wordt de overeenkomst u per mail verstuurd voor ondertekening. Voor de gratis meldingen is de publicatie rechtmatig vanwege een gerechtvaardigd belang van FCR. U kan op elk ogenblik beslissen om niet langer gebruik te maken van deze gratis melding, zie punt ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.
            </p>
            <p>
                De publicatie van de witte gids en de persoonsgegevens hierin opgenomen is rechtmatig omdat FCR hiermee een taak van algemeen belang behartigt. De persoonsgegevens opgenomen in de witte gids worden enkel gebruikt voor de publicatie ervan. U kan op elk ogenblik beslissen om niet langer vermeld te staan in de witte gids, zie punt ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.
            </p>
            <p>
                Indien u de papieren-versie van de gouden- en /of witte gids niet langer wenst te ontvangen, hebben wij uw persoonsgegevens nodig, zodat wij ons ervan kunnen vergewissen dat wij de aanvraag van de juiste persoon verwerken en niet de verkeerde persoon uit ons bestand wissen. Hiervoor vragen wij u volgende gegevens mee te delen: naam en voornaam, adres, telefoonnummer en e-mailadres. U kan zich hier uitschrijven.
            </p>
            <h3 class="font-semibold">
                4.2 Verwerking van persoonsgegevens bij het leveren van onze digitale marketing diensten
            </h3>
            <p>
                Naast de publicatie van de gouden en witte gids, levert FCR ook heel wat digitale marketing diensten. We ontwerpen websites, vergroten de online zichtbaarheid en vindbaarheid van uw bedrijf, zorgen ervoor dat u zichtbaar bent met de juiste gegevens, plaatsen uw advertenties op social mediasites en adverteren via zoekmachines zoals Google waardoor uw bedrijf boven andere zoekresultaten verschijnt. Volgende persoonsgegevens kunnen hierbij verwerkt worden: naam en voornaam, adres, e-mailadres, foto’s,  telefoonnummer en betaalgegevens. Afhankelijk van de dienst en het advertentiemedium dat u gekozen heeft en de informatie die u ons aangeleverd heeft, kan er meer of minder informatie gepubliceerd worden. Het gaat hier onder meer over logo’s, websites en andere bedrijfsgerelateerde informatie.  De verwerking van de persoonsgegevens bij de uitvoering van deze diensten is nodig zodat we de juiste informatie over uw bedrijf online kunnen tonen en om de overeenkomst die u met FCR afgesloten hebt, correct te kunnen beheren.
            </p>
            <p>
                De verwerking van de gegevens is rechtmatig vanwege het bestaan van deze overeenkomst. Bij bepaalde van de hierboven vermelde diensten, heeft u de mogelijkheid om uw aangekocht product zelf te beheren en bepaalde gegevens te wijzigen. U krijgt toegang door het ingeven van een gebruiksnaam en wachtwoord. Deze mogelijkheid is enkel mogelijk voor onze diensten Websites en Presence Management. Voor de diensten waar u de gegevens niet zelf kan aanpassen en toch gewijzigd wilt zien, kunt u contact opnemen met onze klantendienst.
            </p>
            
            <h3 class="font-semibold">
                4.3 Verwerking van persoonsgegevens door de verstrekking van gegevens aan andere bedrijven die data commercialiseren (data partners)
            </h3>
            <p>
                FCR kan de gegevens uit haar databanken commercialiseren en verstrekken aan data partners voor volgende doeleinden:
            </p>
            
            <ul>
                <li>
                    Direct marketing doeleinden van data partners: verkoop van de data, gebruik van de data voor marketing doeleinden of bestandsopbouw in opdracht van derden, het gebruik van de gegevens voor validatie ervan en voor verrijking van bestanden;
                </li>
                <li>
                    Aanbieden van statistische analyseproducten en publicatie of verkoop van deze analyses door data partners;
                </li>
                <li>
                    Commercialisering van gegevenselementen door data partners die tot stand gekomen zijn door de kruising van de gegevens uit onze databank met andere gegevensbronnen van die data partners.
                </li>
            </ul>
            <p>
                Volgende persoonsgegevens kunnen hierbij verwerkt worden: naam en voornaam, adres, e-mailadres, telefoonnummer, geslacht, financiële gegevens en bedrijfsspecifieke informatie. Met deze data partners wordt contractueel bedongen dat zij deze gegevens enkel voor de hierboven vermelde welbepaalde doeleinden mogen gebruiken.
            </p>
            <p>
                Voor klanten maakt het verstrekken van deze gegevens aan data partners integraal deel uit van de door u gekozen dienstverlening en algemene verkoopsvoorwaarden van FCR en heeft deze doorgifte mede tot doel de zichtbaarheid en bekendheid van uw bedrijf te vergroten. De verwerking is hier rechtmatig vanwege de overeenkomst die u met FCR afgesloten heeft. U kan steeds aan FCR vragen dat we uw gegevens niet langer delen met data partners. Ook voor de bedrijven die geen overeenkomst hebben met FCR en enkel gebruik maken van de gratis meldingen, is er de mogelijkheid om te verzoeken dat uw gegevens niet langer verstrekt worden aan onze partners, zie punt ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.
            </p>
            <h3 class="font-semibold">
                4.4. Verwerking van persoonsgegevens in MyMedia
            </h3>
            <p>
                Sinds kort biedt FCR een MyMedia platform aan. Momenteel wordt de toegang tot het platform enkel aangeboden aan nieuwe klanten. Op termijn is het de bedoeling dat al onze klanten hier gebruik van kunnen maken. Op het MyMedia platform krijgt u een overzicht van de diensten die u afneemt en in het verleden afgenomen hebt van FCR en krijgt u statistieken te zien omtrent de werking van deze dienst voor uw bedrijf. Daarnaast worden volgende persoonsgegevens van u getoond op uw profielpagina: naam en voornaam, adres, e-mailadres, telefoonnummer, betaalgegevens en een overzicht van uw facturen, betaalstatus en een overzicht van uw afgesloten contracten. We gebruiken deze gegevens om u een goed overzicht te geven van welke gegevens we van u gebruiken voor de uitvoering van de overeenkomst die we met u gesloten hebben. U kan deze informatie in MyMedia ook zelf aanpassen indien deze onvolledig of niet correct is. De toegang tot uw account op MyMedia wordt afgesloten na afloop van de overeenkomst. Er zijn ook cookies geplaatst op het MyMedia platform.
            </p>
            <h3 class="font-semibold">
                4.5. Verwerking van persoonsgegevens van gebruikers van onze Websites
            </h3>
            <p>
                Via de Websites verwerkt FCR op verschillende momenten persoonsgegevens. Dit is het geval wanneer u bijvoorbeeld surft op onze Websites of gebruik maakt van de zoekmachine op de Websites. Door middel van onder meer cookies kan FCR daarbij de volgende gegevens verwerken: IP adres, locatiegegevens, type toestel, browser type en/of besturingssyteem en zoek-, surf en clickgedrag. FCR gebruikt deze gegevens zodat u ten volle gebruik kan maken van onze Websites.
            </p>
            <h3 class="font-semibold">
                4.6. Verwerking van persoonsgegevens voor direct marketing doeleinden van FCR
            </h3>
            
            <p>
                U kan zich steeds inschrijven op één van onze nieuwsbrieven. Via onze nieuwsbrieven kunnen we u op de hoogte houden van de diensten van FCR alsook van veranderingen en evoluties die zich voordoen in de markt. Bestaande klanten informeren we ook graag over producten en diensten die gelijkaardig zijn aan degene die ze bij ons gekocht hebben. We gebruiken hiervoor volgende persoonsgegevens: naam en voornaam, adres, e-mailadres, telefoonnummer en informatie omtrent de diensten die u bij FCR afneemt. Op deze manier kunnen we de informatie interessanter en persoonlijker voor u maken. Deze gegevens kunnen we dan ook gebruiken om u uit te nodigen voor evenementen en trainingssessies die we organiseren. We maken ook gebruik van social media kanalen om onze diensten en evenementen te promoten.  U kan op elk gewenst ogenblik beslissen om deze communicatie niet langer te ontvangen, zie punt ‘9.7. Recht om uw toestemming in te trekken’ en ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.
            </p>
            <h3 class="font-semibold">
                4.7. Verwerking van persoonsgegevens door rapportering
            </h3>
            <p>
                FCR gebruikt uw gegevens ook om rapporten te maken en analyses uit te voeren om meer inzichten te verkrijgen omtrent haar diensten en om haar manier van werken te verbeteren. Volgende persoonsgegevens worden hierbij verwerkt: naam en voornaam, adres, e-mailadres, telefoonnummer, financiële gegevens en informatie omtrent de diensten die u van FCR gebruikt of waar u in geïnteresseerd bent. De verwerking van deze gegevens om onze dienstverlening en ons dienstenaanbod te verbeteren is rechtmatig vanwege het gerechtvaardigd belang dat FCR heeft bij deze verbetering van de dienstverlening.
            </p>
            <h3 class="font-semibold">
                4.8. Verwerking van persoonsgegevens bij spontane sollicitaties
            </h3>
            <p>
                Via onze website <a href="http://careers.fcrmediabelgium.be">http://careers.fcrmediabelgium.be</a> kunt u spontaan solliciteren voor één van onze openstaande vacatures. Hierbij vragen wij u volgende persoonsgegevens mee te delen: naam en voornaam, geslacht, moedertaal, geboortedatum, de gemeente waar u woont, e-mailadres, telefoon- of gsm-nummer, uw voorkeur wat betreft het departement waar u tewerk gesteld wilt worden, uw CV en een motivatiebrief. Indien er tijdelijk geen vacatures zijn die u interesseren, kan u de bovenvermelde gegevens ook achterlaten om nadien gecontacteerd te worden wanneer er een nieuwe vacature is. De verwerking van deze persoonsgegevens is rechtmatig vanwege de toestemming die u hiervoor gegeven heeft. De gegevens worden enkel gebruikt voor sollicitatie- en recruteringsdoeleinden. U kan op elk ogenblik uw toestemming intrekken, zie punt  ‘9.7. Recht om uw toestemming in te trekken’ en ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.
            </p>
            <h3 class="font-semibold">
                4.9. Verwerking van persoonsgegevens voor doeleinden van data quality en CRM-doeleinden
            </h3>
            <p>
                FCR ontvangt data van derden met het oog op het verrijken van haar eigen databanken. Op deze manier kan FCR de juistheid van de gegevens in de databanken verifiëren en de kwaliteit van de databanken garanderen. Dit is nodig zodat we de juiste informatie kunnen publiceren en de juiste informatie in onze producten kunnen vermelden (bv. op de website die we voor u maken). We gebruiken de data ook om potentiële klanten te informeren over onze diensten en producten. Potentiële klanten kunnen ook zelf het initiatief nemen en ons laten weten dat ze geïnteresseerd zijn in onze diensten en producten. Ze kunnen dit doen door hun gegevens achter te laten via “uw bedrijf toevoegen”. Volgende persoonsgegevens kunnen hier verwerkt worden: naam en voornaam, adres, e-mailadres, telefoonnummer, geslacht, taal, financiële gegevens en bedrijfsspecifieke informatie zoals bedrijfsnaam en ondernemingsnummer.
            </p>
            <p>
                FCR ontvangt enkel persoonsgegevens waarvoor de derde een rechtmatige verwerkingsgrond heeft om deze gegevens door te geven (bv. toestemming van de betrokkene of een wettelijke verplichting). De verwerking van deze gegevens is nodig zodat FCR haar verplichtingen onder de overeenkomst tussen uzelf en FCR kan nakomen. De verwerking van de gegevens die we ontvangen via “uw bedrijf toevoegen” is rechtmatig omdat deze gebaseerd is op uw toestemming. U kan te allen tijde deze toestemming terug intrekken zie punt  ‘9.7. Recht om uw toestemming in te trekken’ en ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.
            </p>
            <p>
                FCR gebruikt uw persoonsgegevens ook voor bepaalde CRM-activiteiten, namelijk in het kader van klantenbehoud en bij het versturen van tevredenheidssurveys. In het kader van klantenbehoud worden volgende persoonsgegevens verwerkt: naam en voornaam, telefoonnummer, e-mailadres en betalingsgegevens. Voor het afnemen van tevredenheidssurveys gebruiken we volgende persoonsgegevens: naam en voornaam, telefoonnummer en e-mailadres. Het gebruik van deze persoonsgegevens voor beide doeleinden is gerechtvaardigd door het bestaan van gerechtvaardigde belangen voor FCR.
            </p>
            
            <h3 class="font-semibold">
                4.10. Verwerking van persoonsgegevens door onze klantendienst
            </h3>
            
            <p>
                Voor al uw vragen, opmerkingen of klachten omtrent onze dienstverlening kunt u contact opnemen met onze klantendienst. U kan ook contact opnemen met de klantendienst als u bepaalde informatie omtrent uw bedrijf (of uzelf) wilt aanpassen in de dienst die u bij ons gekocht heeft. U kan een contactformulier invullen dat u  hier kan terugvinden. Dit formulier wordt vervolgens verstuurd naar onze klantendienst. Of u kan gewoon bellen of e-mailen naar de klantendienst. Volgende gegevens kunnen hierbij verzameld worden: naam en voornaam, e-mailadres, telefoonnummer, adres, bedrijfsinformatie en informatie omtrent uw vraag. FCR gebruikt deze gegevens enkel om zo goed mogelijk te kunnen reageren op uw vraag of klacht. Het gebruiken van deze persoonsgegevens voor dit doeleinde is gerechtvaardigd door het bestaan van een gerechtvaardigd belang voor FCR.
            </p>
            <p>
                Inkomende telefoongesprekken met onze klantendienst worden opgenomen voor trainings- en coachingsdoeleinden. De opnames worden niet langer bewaard dan strikt noodzakelijk en zijn niet toegankelijk voor onbevoegden.
            </p>
            
            <h3 class="font-semibold">
                4.11. Verwerking van persoonsgegevens voor facturatiedoeleinden
            </h3>
            
            <p>
                Als u een betaalde dienst bij ons hebt afgenomen, hebben wij persoonsgegevens van u nodig om te factureren. Deze persoonsgegevens zijn naam en voornaam, e-mailadres, bedrijfsadres, facturatieadres, betaalgegevens en bankrekeningnummer en soms uw telefoon-of gsmnummer. De verwerking van deze gegevens voor facturatiedoeleinden is rechtmatig vanwege de overeenkomst die u afgesloten heeft met FCR.
            </p>
            <h3 class="font-semibold">
                4.12. Verwerking van persoonsgegevens om te voldoen aan wettelijke verplichtingen
            </h3>
            <p>
                FCR verwerkt ook persoonsgegevens om te voldoen aan de op FCR van toepassing zijnde wet- en regelgeving. Het gaat onder meer onder regelgeving rond boekhouding en belastingen, arbeidswetgeving, … Het is mogelijk dat, om te voldoen aan deze wettelijke bepalingen, uw persoonsgegevens gedeeld worden met andere instanties.
            </p>
            <h2 class="text-xl">
                5. Gegevensverstrekking aan derden
            </h2>
            <p>
                FCR deelt uw gegevens met derden, onder meer voor de doeleinden omschreven in ‘4.3. Verwerking van persoonsgegevens door de verstrekking van gegevens aan data partners’. U kan u op elk ogenblik verzetten tegen deze verwerking zie ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring. Daarnaast worden uw gegevens enkel aan derden verstrekt  indien FCR op grond van geldende wet- en regelgeving daartoe verplicht wordt, bijvoorbeeld aan de politie in het kader van een opsporingsonderzoek.
            </p>
            <p>
                FCR verstrekt geen persoonlijke financiële gegevens (zoals informatie over een creditcard) aan andere partijen, tenzij dit noodzakelijk is om uw dienstenaanvraag te behandelen, onze factuur te verwerken, of fraude te voorkomen of te bestrijden.
            </p>
            <p>
                Soms bevatten onze Websites links naar andere websites. U dient het privacybeleid op die sites zorgvuldig door te nemen; het kan afwijken van onze Privacyverklaring.
            </p>
            
            <h2 class="text-xl">
                6. Verwerkers
            </h2>
            <p>
                FCR kan voor het verwerken van de persoonsgegevens gebruik maken van andere dienstverleners die als verwerker, uitsluitend in opdracht van FCR, zullen optreden. Deze verwerkers mogen deze persoonsgegevens dus niet gebruiken voor hun eigen (commerciële) doeleinden. FCR selecteert deze partners zorgvuldig en verwacht dat zij even plichtsbewust met uw persoonsgegevens omgaan als FCR zelf.  Met deze verwerker(s) sluiten wij een verwerkersovereenkomst af waarin de verwerker wordt verplicht om de verplichtingen uit deze Privacyverklaring, alsook de bepalingen uit de GDPR, na te leven.
            </p>
            
            <h2 class="text-xl">
                7. Beveiliging van uw persoonsgegevens
            </h2>
            <p>
                Wij erkennen onze verantwoordelijkheid om voor de informatie die u ons toevertrouwt een gepast beveiligingsniveau te voorzien. FCR heeft dan ook verschillende maatregelen genomen om persoonsgegevens te beschermen tegen verlies, misbruik en ongeoorloofde toegang, bekendmaking, wijziging of vernietiging. Op organisatorisch vlak worden onder meer maatregelen met betrekking tot de beperkte toegang tot en monitoring van de gebouwen, systemen en bestanden geïmplementeerd; Maar ook technische maatregelen waaronder firewalls, bescherming door middel van persoonlijke wachtwoorden en verificatie, alsmede verificatievereisten voor toegang tot persoonsgegevens op een ‘need-to-know’-basis worden voorzien.
            </p>
            <p>
                Ook van onze verwerkers verwachten wij dat zij een gepast beveiligingsniveau kunnen garanderen wanneer ze persoonsgegevens ten behoeve van FCR verwerken. Wij leggen dit ook met al onze verwerkers contractueel vast.
            </p>
            
            <h2 class="text-xl">
                8. Hoe lang bewaren wij uw gegevens?
            </h2>
            
            <p>
                FCR heeft een beleid uitgewerkt rond het bewaren van persoonsgegevens. We zorgen dat de gegevens op een veilige manier bewaard blijven en dat we ze niet langer bijhouden dan nodig om de hierboven vermelde doeleinden te bereiken. De bewaartermijn van de gegevens hangt dus af van het doel waarvoor ze zijn verzameld. Als u wilt dat FCR je persoonsgegevens niet langer bijhoudt of u wilt graag meer informatie over de bewaartermijnen die we hanteren, kan je hiervoor contact opnemen met onze klantendienst. Hoe u dit precies moet doen, kunt u lezen onder punt ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.
            </p>
            
            <h2 class="text-xl">
                9. Welke rechten hebt u en hoe kunt u deze uitoefenen?
            </h2>
            <p>
                FCR gebruikt de persoonsgegevens van haar klanten, potentiële klanten, sollicitanten en websitebezoekers voor welbepaalde doeleinden en verzamelt enkel de persoonsgegevens die het werkelijk nodig heeft om die doeleinden te kunnen bereiken. Wij willen zo transparant en duidelijk mogelijk zijn omtrent de activiteiten waarvoor wij uw persoonsgegevens verzamelen, gebruiken en beveiligen. Bijgevolg heeft FCR een beleid uitgewerkt opdat u uw rechten met betrekking tot de persoonsgegevens die u ons toevertrouwd hebt, zou kunnen uitoefenen. In overeenstemming met de geldende privacywetgeving hebt u verschillende rechten.
            </p>
            
            <h3 class="font-semibold">
                9.1. Recht om uw gegevens in te kijken
            </h3>
            
            <p>
                U hebt het recht om op elk ogenblik aan FCR te vragen welke gegevens wij van u bezitten en waarvoor wij deze juist gebruiken en u kunt hiervan een kopie vragen. U kunt op deze manier ook vragen hoelang wij uw gegevens bewaren, op basis van welke rechtsgrond (toestemming, overeenkomst, wettelijke verplichting, gerechtvaardigd belang, algemeen belang, vitaal belang) wij uw gegevens verwerken, enz. Door de uitoefening van dit recht kunt u ook nagaan of de gegevens die wij van u hebben wel correct zijn. Hoe u dit recht kan uitoefenen, kan u lezen onder punt ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.  Als u een MyMedia-, Websites- of Presence Management-account heeft, kunt u daar ook altijd al een overzicht vinden van de gegevens die wij van u hebben.
            </p>
            <h3 class="font-semibold">
                9.2. Recht om uw gegevens te laten verbeteren
            </h3>
            <p>
                Als u merkt dat de persoonsgegevens die wij van u hebben onjuist of onvolledig zijn, kunt u ze laten corrigeren of aanvullen. U kan hiervoor contact opnemen met de klantendienst per e-mail of door een contactformulier in te vullen. Als u een online account hebt op MyMedia, of ons Website of Presence Management product heeft aangekocht, kunt u bepaalde persoonsgegevens ook zelf verbeteren of aanvullen.
            </p>
            <h3 class="font-semibold">
                9.3. Recht om uw gegevens te laten wissen
            </h3>
            <p>
                U hebt het recht om te vragen dat FCR de persoonsgegevens die het van u heeft, verwijdert. Merk wel op, dit recht geldt niet absoluut. De GDPR bepaalt onder welke voorwaarden dit recht uitgeoefend kan worden. De klantendienst zal beoordelen of aan één van de voorwaarden voldaan wordt. Als er wordt geoordeeld dat één van deze voorwaarden van toepassing is, wordt er overgegaan tot het wissen van uw persoonsgegevens. Indien uw gegevens voor bepaalde doeleinden aan onze verwerkers werden doorgegeven, zal FCR al deze verwerkers informeren dat de gegevens ook bij hen verwijderd moeten worden. Merk wel op dat als u verzoekt om dit recht uit te oefenen, al uw persoonsgegevens die FCR heeft, zullen verdwijnen. Als u nadien opnieuw gebruik wenst te maken van onze diensten, zal u bepaalde van deze gegevens opnieuw moeten meedelen.
            </p>
            <p>
                Hoe u dit recht kan uitoefenen, kan u lezen onder punt ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.
            </p>
            <h3 class="font-semibold">
                9.4. Recht om de beperkte verwerking van uw gegevens te vragen
            </h3>
            <p>
                Door om de beperkte verwerking van uw persoonsgegevens te verzoeken, blijft het voor FCR mogelijk om de gegevens bij te houden, maar wordt het gebruik van de gegevens beperkt. De uitoefening van dit recht is slechts in beperkte gevallen van toepassing. Als FCR toch nog andere zaken met uw gegevens wilt doen dan het louter bewaren van de gegevens, zal dit slechts in beperkte gevallen mogelijk zijn (bijvoorbeeld door opnieuw uw toestemming te vragen voor de verwerking van uw gegevens). Hoe u dit recht kan uitoefenen, kan u lezen onder punt ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.
            </p>
            
            <h3 class="font-semibold">
                9.5. Recht om bezwaar te maken tegen de verwerking van uw gegevens
            </h3>
            
            <p>
                U hebt het recht om bezwaar te maken tegen de verwerking van uw gegevens wanneer deze verwerking berust op een algemeen belang of het gerechtvaardigd belang van FCR. Onder hoofdstuk ‘4. Welke gegevens verzamelen wij van u en met welk doel verzamelen wij die gegevens?’ kunt u de activiteiten terugvinden waarvoor FCR een gerechtvaardigd belang of een algemeen belang heeft. Als u dit recht inroept, zal FCR onderzoeken of haar gerechtvaardigde gronden voor die verwerking zwaarder wegen dan uw belangen, rechten en vrijheden. Als dit niet het geval is, zal FCR uw gegevens niet langer verwerken voor dat doeleinde.
            </p>
            <p>
                U kunt ook bezwaar maken tegen de verwerking van uw persoonsgegevens voor direct marketingdoeleinden. Als u dit recht inroept, zal FCR uw gegevens niet langer verwerken voor marketingdoeleinden.
            </p>
            <p>
                Hoe u dit recht kan uitoefenen, kan u lezen onder punt ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.
            </p>
            
            <h3 class="font-semibold">
                9.6. Recht op overdraagbaarheid van uw gegevens
            </h3>
            <p>
                Dit recht houdt in dat u de gegevens die u aan FCR hebt gegeven, terugkrijgt. Dit recht geldt wel enkel voor de verwerkingen van gegevens die berusten op uw toestemming (bv. toestemming voor het versturen van de nieuwsbrief) of op een overeenkomst (bv. overeenkomst die u sluit wanneer u een digitale marketing dienst van FCR afneemt). Een bijkomend aspect van dit recht is dat u met de gegevens die u terugkrijgt naar een andere verwerkingsverantwoordelijke kan gaan zonder dat FCR zich daartegen kan verzeten. Merk wel op, het gaat enkel over de gegevens die u rechtstreeks aan FCR hebt bezorgd. Hoe u dit recht kan uitoefenen, kan u lezen onder punt ‘10. Waar kunt u terecht met vragen of klachten?’ van deze Privacyverklaring.
            </p>
            
            <h3 class="font-semibold">
                9.7. Recht om uw toestemming in te trekken
            </h3>
            <p>
                Zoals eerder vermeld werd, kunt u voor alle verwerkingen waarvoor FCR uw toestemming vraagt, op elk ogenblik deze toestemming opnieuw intrekken. Merk wel op, het intrekken van uw toestemming heeft geen impact op en doet geen afbreuk aan de verwerkingen die FCR deed toen uw toestemming nog niet ingetrokken was. U kunt uw toestemming op verschillende manieren intrekken:
            </p>
            <ul>
                <li>
                    U kan zich uitschrijven voor nieuwsbrieven, direct marketing acties en andere informatie op maat via de uitschrijflink die telkens onderaan de verstuurde nieuwsbrieven en andere communicatie vermeld staat.
                </li>
                <li>
                    U kan zich  hier online uitschrijven voor het ontvangen van de papieren gouden en witte gids.
                </li>
                <li>
                    U kan het contactformulier invullen dat behandeld wordt door onze klantendienst. U kan via dit contactformulier verzoeken om u toestemming in te trekken voor het ontvangen van onder meer communicatie omtrent openstaande vacatures als u spontaan gesolliciteerd hebt bij FCR. Maar u kan zich ook via het contactformulier uitschrijven voor nieuwsbrieven, direct marketing of andere informatie op maat.
                </li>
            </ul>
            <p>
                Houd er wel rekening mee dat er een bepaalde verwerkingstijd nodig is om uw verzoek te behandelen en dat in de tussentijd de kans bestaat dat u nog een laatste keer de informatie ontvangt. Deze verwerkingstijd wordt tot een minimum beperkt.
            </p>
            
            <h2 class="text-xl">
                10. Waar kunt u terecht met vragen of klachten?
            </h2>
            <p>
                Zoals eerder bij de verklaring van uw rechten al werd aangegeven, kunt u uw rechten uitoefenen door een contactformulier in te vullen dat vervolgens behandeld wordt door onze klantendienst. Daarnaast kunt u ook steeds per mail contact opnemen met de klantendienst voor vragen of klachten met betrekking tot de verwerking van uw persoonsgegevens of vragen over deze Privacyverklaring. U kan ook een e-mail sturen naar <a href="info@fcrmedia.be">info&#64;fcrmedia.be</a> met uw vraag of klacht.  Wij vragen u een aantal gegevens mee te delen alsook welk recht u wilt uitoefenen of welke vraag of klacht u heeft met betrekking tot de verwerking van uw persoonsgegevens.
            </p>
            <p>
                Wij vragen hier uw persoonsgegevens zodat wij u uniek kunnen identificeren in onze systemen en zeker geen persoonsgegevens delen met personen die zich voordoen als iemand anders.  Wij zullen u om volgende informatie vragen: naam en voornaam, adres, bedrijf, BTW-nummer en klantennummer (in het geval u met uw bedrijf diensten van FCR afneemt), telefoonnummer, e-mailadres, welk recht u wenst uit te oefenen en bijkomende informatie omtrent uw vraag of klacht. Alle verzoeken komen bij de klantendienst terecht. Zodra zij uw verzoek ontvangen hebben, sturen zij u een bevestigingsmail dat uw verzoek in behandeling wordt genomen. Binnen een maand na ontvangst van uw vraag door de klantendienst, zal u een finaal antwoord krijgen.
            </p>
            <p>
                Wij zullen alles in het werk stellen om uw vragen en klachten  zo goed mogelijk te beantwoorden of op te lossen. Mocht u daarna alsnog ontevreden zijn over de manier waarop wij met uw persoonsgegevens omgaan, dan kunt u op de website van de Autoriteit Persoonsgegevens meer informatie vinden over de stappen die u kunt nemen.
            </p>
            
            <h2 class="text-xl">
                11. Toepasselijk recht
            </h2>
            <p>
                Op deze Privacyverklaring is het Belgisch recht van toepassing. Alle geschillen uit hoofde van of in verband met deze Privacyverklaring zullen bij uitsluiting worden voorgelegd aan de bevoegde rechtbanken van Antwerpen.
            </p>
            
            <h2 class="text-xl">
                12. Wijzigingen aan deze Privacyverklaring
            </h2>
            <p>
                FCR behoudt zich het recht voor om wijzigingen aan te brengen in deze Privacyverklaring. Elke aanpassing zal op deze pagina worden gepubliceerd. Wij raden u aan deze Privacyverklaring geregeld te raadplegen, zodat u altijd van de inhoud van de geldende Privacyverklaring op de hoogte bent. FCR werkt voor verschillende diensten samen met andere partners. FCR is niet verantwoordelijk voor het privacybeleid van deze partners en staat niet in voor de inhoud van hun privacy verklaring. Wij raden u bijgevolg aan de privacy verklaring van deze partners dan ook te raadplegen voor meer informatie.
            </p>
            
        </article>

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('en')">
            <h1 class="text-2xl">
                Politique de confidentialité FCR Media
            </h1>
            <p>
                La présente politique prend effet le 25 mai 2018.
            </p>
            <p>
                Aux yeux de FCR, la confidentialité de ses clients est essentielle. Nous voulons donc faire preuve de transparence dans les données personnelles que nous collectons à votre sujet, pour quelle raison et ce que nous en faisons. En outre, nous voulons aussi que vous connaissiez vos droits en la matière. Nous vous recommandons donc de lire la présente Politique de confidentialité et de nous contacter en cas de questions éventuelles.
            </p>
            
            <h2 class="text-xl">
                1. Définitions
            </h2>
            <p>
                Les définitions suivantes vous aideront à mieux comprendre la Politique de confidentialité :
            </p>
            <ul>
                <li>
                    "Nous", "notre" renvoie à
                    <br />FCR Media Belgium SA, ou "FCR"
                    <br />Uitbreidingstraat 82, 2600 Berchem
                    <br />0807.677.428.
                    <br /><a href="https://fcrmedia.be">https://fcrmedia.be</a>
                    <br /><a href="mailto:info@fcrmedia.be">info&#64;fcrmedia.be</a>;
                </li>
                <li>
                    "Vous", "votre" fait référence à un visiteur du site, un candidat, un client ou un client potentiel ;
                </li>
                <li>
                    "Données personnelles" renvoie à toutes les informations qui vous identifient (directement ou indirectement) comme un individu ;
                </li>
                <li>
                    "Site(s) Web" fait référence à https://www.fcrmedia.be, https://dexville.be, https://www.pagesdor.be, https://www.pagesblanches.be et/ou http://careers.fcrmediabelgium.be, de même qu'à toutes les pages Web, plateformes, fonctions interactives, applications, widgets, blogs, réseaux sociaux, onglets de médias sociaux ou autres offres qui renvoient à la présente Politique de confidentialité.
                </li>
            </ul>
            
            <h2 class="text-xl">
                2. Quel est le champ d'application de cette Politique de confidentialité ?
            </h2>
            <p>
                La présente Politique de confidentialité s'applique à toutes les données personnelles des visiteurs du site Web, candidats, clients et potentiels clients, qui pourraient être collectées et utilisées par FCR. Il s'agit notamment d'informations que FCR récolte par le biais de ses sites Web, que ses clients (potentiels) fournissent spontanément, ou encore de données qui sont rassemblées hors ligne à des fins commerciales et dont nous avons la gestion.
            </p>
            <p>
                La présente Politique de confidentialité a été établie et est mise à jour conformément aux dispositions et exigences de la nouvelle Règlementation européenne sur la vie privée, le Règlement général sur la Protection des Données (RGPD en français et General Data Protection Regulation, GDPR, en anglais). En d'autres termes, FCR traite les données personnelles conformément aux principes décrits par le RGPD.
            </p>
            <h2 class="text-xl">
                3. Qui est responsable de vos données personnelles ?
            </h2>
            <p>
                FCR Media Belgium SA, Uitbreidingstraat 82, 2600 Berchem, Belgique, est responsable du traitement de vos données personnelles.
            </p>
            <h2 class="text-xl">
                4. Quelles sont les données que nous collectons à votre sujet et dans quel but ?
            </h2>
            <h3>
                4.1. Traitement des données personnelles reprises dans les pages d'or et les pages blanches
            </h3>
            <p>
                L'un des services de FCR consiste à publier et à distribuer les pages d'or et les pages blanches, tant au format papier (les guides papier) que digital (Internet, smartphones, etc.). Les données suivantes peuvent être collectées : nom et prénom, adresse, adresse e-mail, numéro de téléphone, photos et données de paiement. Les données que nous utilisons pour la publication des pages d'or et des pages blanches proviennent de notre banque de données, où elles sont complétées par des informations issues d'autres sources, notamment la Banque Carrefour des Entreprises.
            </p>
            <p>
                Dans le cadre des publicités commerciales dans les pages d'or, le traitement des données personnelles est légal moyennant une convention entre vous et FCR. Cette convention peut aussi être conclu par téléphone. Nous vous demanderons alors explicitement si l'entretien peut être repris comme preuve de l'existence de la convention. Si vous refusez de donner votre autorisation, l'entretien ne sera pas repris et la convention vous sera envoyée par e-mail. Pour les publicités gratuites, la publication est légale en raison d'un intérêt légitime de FCR. À tout moment, vous pouvez décider de ne plus faire usage de ces publicités gratuites, voir le point 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <p>
                La publication des pages blanches et des données personnelles qui y sont reprises est légale parce qu'il s'agit d'une tâche d'intérêt général pour FCR. Les données personnelles présentes dans les pages blanches seront uniquement utilisées pour leur publication. À tout moment, vous pouvez décider de ne plus être repris dans les pages blanches, voir le point 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <p>
                Si vous ne désirez plus recevoir la version papier des pages blanches ou des pages d'or, nous aurons besoin de vos données personnelles afin de nous assurer que nous traitons la demande de la bonne personne et que nous n'effaçons pas la mauvaise personne de notre base de données. Nous vous demandons donc de nous partager les données suivantes : nom et prénom, adresse, numéro de téléphone et adresse e-mail. Pour vous désinscrire, cliquez ici.
            </p>
            <h3>
                4.2. Traitement des données personnelles dans le cadre de nos services de marketing en ligne
            </h3>
            <p>
                Outre la publication des pages d'or et pages blanches, FCR propose aussi des services de marketing digital. Nous concevons des sites Web, nous améliorons la visibilité en ligne et permettons à votre entreprise d'être trouvée plus facilement, nous veillons à ce que vous soyez visibles au moyen des données adéquates, nous plaçons vos publicités sur les médias sociaux et nous faisons votre publicité via les moteurs de recherche tels que Google, de manière à ce que votre société apparaisse plus haut dans les résultats de recherche. Les données personnelles suivantes peuvent donc être traitées : nom et prénom, adresse, adresse e-mail, photos, numéro de téléphone et données de paiement. Selon le service et le support publicitaire que vous avez choisis et les informations que nous avons reçues, nous publierons plus ou moins d'informations. Il s'agit notamment des logos, des sites Web et autres informations liées à l'entreprise. Le traitement de ces données personnelles pour nos services est nécessaire afin que nous puissions afficher en ligne les informations correctes concernant votre société, mais aussi les gérer correctement, dans le respect de votre contrat avec FCR.
            </p>
            <p>
                Le traitement de données est légal en raison de l'existence de ce contrat. Pour certains des services énumérés ci-dessus, vous avez la possibilité de gérer vous-même le produit que vous avez acheté et de modifier certaines données. Vous bénéficiez d'un accès par le biais d'un nom d'utilisateur et d'un mot de passe, et ce uniquement pour les services Websites et NetSync. Vous pouvez prendre contact avec notre service clientèle si vous désirez malgré tout modifier des données pour des services que vous ne pouvez pas modifier par vous-même.
            </p>
            <h3>
                4.3. Traitement des données personnelles en fournissant les données à d'autres entreprises qui les commercialisent (data partners)
            </h3>
            <p>
                FCR peut commercialiser les données issues de sa banque de données et les fournir à des data partners aux fins suivantes :
            </p>
            <ul>
                <li>
                    Marketing direct des data partners : vente de données, utilisation des données à des fins de marketing ou pour l'élaboration d'un fichier à la demande de tiers, utilisation des données pour validation et pour enrichissement des fichiers ;
                </li>
                <li>
                    Offre de produits d'analyse statistique et publication ou vente des analyses en question par les data partners ;
                </li>
                <li>
                    Commercialisation par les data partners des éléments de données qui résulteraient du croisement des données provenant de notre banque de données avec d'autres sources appartenant à ces data partners.
                </li>
            </ul>
            <p>
                Les données personnelles suivantes peuvent donc être traitées : nom et prénom, adresse, adresse e-mail, numéro de téléphone, sexe, données financières et informations spécifiques à l'entreprise. Il est contractuellement spécifié à ces data partners qu'ils peuvent uniquement utiliser les données aux fins mentionnées ci-dessus.
            </p>
            <p>
                Pour les clients, la transmission de ces données à des data partners fait intégralement partie du service qu'ils ont choisi et des conditions générales de vente de FCR : son objectif est d'accroître la visibilité et la notoriété de votre entreprise. Le traitement est légal en raison du contrat que vous avez conclu avec FCR. Vous pouvez toujours demander à FCR de ne plus partager vos données avec les data partners. Pour les sociétés qui n'ont pas conclu de contrat avec FCR et qui utilisent uniquement les mentions gratuites, il est également possible de mettre un terme à la transmission des données à nos partenaires, comme repris dans le point 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3>
                4.4. Traitement des données personnelles dans MyMedia
            </h3>
            <p>
                Depuis peu, FCR propose la plateforme MyMedia. Pour l'instant, seuls les nouveaux clients peuvent y accéder, mais à terme, l'objectif est que chacun de nos clients puisse l'utiliser. Sur la plateforme, vous trouverez un aperçu des services auxquels vous avez souscrit chez FCR, actuellement ou par le passé, mais aussi des statistiques concernant le fonctionnement de ces services pour votre entreprise. Les données personnelles suivantes seront également affichées sur votre profil : nom et prénom, adresse, adresse e-mail, numéro de téléphone, données de paiement et aperçu de vos factures, de votre statut de paiement, ainsi que vos contrats. Nous utilisons ces données pour vous fournir une vue d'ensemble des données vous concernant que nous utilisons pour honorer le contrat que nous avons conclu avec vous. Vous pouvez modifier vous-même les informations sur MyMedia si elles sont incorrectes ou incomplètes. L'accès à votre compte sur MyMedia sera clôturé une fois que votre contrat arrive à expiration. La plateforme MyMedia utilise également des cookies.
            </p>
            <h3>
                4.5. Traitement des données personnelles des utilisateurs de nos sites Web
            </h3>
            <p>
                FCR traite des données personnelles à différents moments sur ses sites Web. C'est par exemple le cas lorsque vous surfez sur nos pages ou lorsque vous utilisez la fonction de recherche incluse sur le site. Par le biais des cookies notamment, FCR peut donc traiter les données suivantes : adresse IP, localisation, type d'appareil, type de navigateur et/ou système d'exploitation, et profil de recherche, de navigation et de clics. FCR utilise ces données afin que vous exploitiez nos sites Web au maximum.
            </p>
            <h3>
                4.6. Traitement des données personnelles par FCR à des fins de marketing direct
            </h3>
            <p>
                Vous pouvez toujours vous inscrire à l'une de nos newsletters, ce qui nous permet de vous tenir au courant des services de FCR, mais aussi des changements et des évolutions du marché. Nous informons également les clients existants sur les produits et services similaires à ceux qu'ils ont achetés chez nous. Pour ce faire, nous utilisons les données personnelles suivantes : nom et prénom, adresse, adresse e-mail, numéro de téléphone et informations relatives aux services auxquels vous avait souscrit chez FCR. Ce faisant, nous vous transmettons des informations mieux personnalisées et plus intéressantes. Nous pouvons également utiliser vos données pour vous inviter à des événements ou des séances de formation que nous organisons. Nous utilisons aussi les réseaux sociaux pour promouvoir ces services et événements. À tout moment, vous pouvez décider de ne plus recevoir ces communications, voir le point 9.7. Droit de retirer votre autorisation et 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3>
                4.7. Traitement des données personnelles via le rapportage
            </h3>
            <p>
                FCR utilise vos données pour effectuer des rapports et des analyses afin de récolter des informations sur ses services et sur la manière de les améliorer. Dans cette optique, nous utiliserons les données personnelles suivantes : nom et prénom, adresse, adresse e-mail, numéro de téléphone, données financières et informations relatives aux services que vous utilisez chez FCR ou qui vous intéressent. Le traitement de ces données afin d'améliorer nos services et notre offre de services est légal en raison de l'intérêt légitime de FCR pour l'amélioration de ses services.
            </p>
            <h3>
                4.8. Traitement de données personnelles en cas de candidature spontanée
            </h3>
            <p>
                Vous pouvez postuler spontanément pour un poste vacant via notre site <a href="http://careers.fcrmediabelgium.be">http://careers.fcrmediabelgium.be</a>. Pour ce faire, nous vous demanderons les données suivantes : nom et prénom, sexe, langue maternelle, date de naissance, commune de résidence, adresse e-mail, numéro de téléphone ou de portable, votre préférence concernant le département où vous souhaitez travailler, CV et lettre de motivation. Si aucun poste ne vous intéresse pour l'instant, vous pouvez nous laisser les données ci-dessus afin d'être contacté par la suite pour un nouveau poste. Le traitement de ces données personnelles est légal en raison de l'autorisation que vous nous avez donnée à ce sujet. Les données seront uniquement utilisées à des fins de recrutement et de candidature. À tout moment, vous avez le droit de retirer votre autorisation, voir le point 9.7. Droit de retirer votre autorisation et 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3>
                4.9. Traitement des données personnelles à des fins de CRM et data quality
            </h3>
            <p>
                FCR reçoit des données provenant de tiers dans le but d'enrichir ses bases de données. Cela lui permet de vérifier la justesse des informations contenues dans ses banques de données et d'en garantir la qualité. Ce processus est nécessaire pour publier des informations correctes et pour mentionner les bonnes informations au sujet de nos produits (ex. sur le site Web que nous avons conçu pour vous). Nous utilisons aussi les données dans le but d'informer les clients potentiels de nos produits et services. Les clients potentiels peuvent aussi prendre l'initiative de nous informer qu'ils sont intéressés par nos produits et services. Pour ce faire, ils peuvent nous laisser leurs données grâce à l'option "Ajouter votre entreprise". Les données personnelles suivantes pourront dès lors être traitées : nom et prénom, adresse, adresse e-mail, numéro de téléphone, sexe, langue, données financières et informations spécifiques à la société, comme son nom et son numéro d'entreprise.
            </p>
            <p>
                FCR reçoit uniquement des données personnelles pour lesquelles les tiers ont un motif légitime de les transférer (ex. l'autorisation de la personne concernée ou une obligation légale). Le traitement de ces données est nécessaire afin que FCR puisse remplir ses obligations en vertu du contrat que nous avons passé avec vous. Le traitement des données que nous recevons via "Ajouter votre entreprise" est légal parce qu'il se fonde sur votre autorisation. À tout moment, vous pouvez retirer votre autorisation, voir le 9.7 Droit de retirer votre autorisation et 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <p>
                FCR utilise aussi vos données personnelles pour certaines activités CRM, notamment pour conserver les clients ou pour mener des enquêtes de satisfaction. Dans le premier cas, nous traiterons les données personnelles suivantes : nom et prénom, numéro de téléphone, adresse e-mail et données de paiement. Pour les enquêtes de satisfaction, nous utiliserons les données personnelles suivantes : nom et prénom, numéro de téléphone et adresse e-mail. L'usage de ces données personnelles à ces deux fins est justifié en raison des intérêts légitimes de FCR.
            </p>
            <h3>
                4.10. Traitement de données à caractère personnel pour notre service clientèle
            </h3>
            <p>
                Vous pouvez contacter notre service clientèle pour toutes vos questions, remarques ou plaintes concernant le service que nous vous offrons. Vous pouvez aussi le contacter si vous désirez modifier des informations précises concernant votre entreprise (ou vous-même) par rapport aux services que vous avez achetés chez nous. Pour ce faire, veuillez remplir un formulaire de contact que vous trouverez ici. Ce dernier sera ensuite transmis à notre service clientèle. Vous pouvez aussi appeler ou envoyer un e-mail au service clientèle. Nous pouvons donc collecter les données suivantes dans cette optique : nom et prénom, adresse e-mail, numéro de téléphone, adresse, information sur l'entreprise et informations relatives à votre question. FCR n'utilisera ces données que pour réagir au mieux à votre question ou à votre problème. L'usage de ces données personnelles à cette fin est donc justifié en raison des intérêts légitimes de FCR.
            </p>
            <p>
                Les communications téléphoniques entrantes avec notre service clientèle seront utilisées à des fins de coaching et de formation. Les enregistrements ne seront pas conservés plus longtemps que le strict nécessaire et ne seront pas accessibles aux personnes non autorisées.
            </p>
            <h3>
                4.11. Traitement de données personnelles à des fins de facturation
            </h3>
            <p>
                Si vous avez souscrit un service payant chez FCR, nous aurons besoin de vos données personnelles pour la facturation. Il s'agit de vos : nom et prénom, adresse e-mail, siège de l'entreprise, adresse de facturation, données de paiement, numéro de compte bancaire, et parfois votre numéro de téléphone ou de portable. L'usage de ces données personnelles à des fins de facturation est donc justifié par le contrat que vous avez conclu avec FCR.
            </p>
            <h3>
                4.12.Traitement de données personnelles pour répondre à une obligation légale
            </h3>
            <p>
                FCR traite également des données personnelles afin de répondre aux règlementations et législations auxquelles la société est soumise. Il s'agit par exemple de la règlementation relative à la comptabilité et aux impôts, à la législation sur le travail... Il est possible que nous devions partager vos données personnelles avec d'autres organismes afin de répondre à ces dispositions légales.
            </p>
            
            <h2 class="text-xl">
                5. Transfert de données à des tiers
            </h2>
            <p>
                FCR partage vos données à des tiers, notamment aux fins indiquées dans le point 4.3 Traitement des données personnelles en fournissant les données à des data partners. À tout moment, vous pouvez vous opposer à ce traitement, voir le point 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité. Par ailleurs, vos données ne seront transmises à des tiers que si FCR est tenu de le faire en vertu des lois et des règlementations qui s'appliquent, par exemple à la police dans le cadre d'une enquête criminelle.
            </p>
            <p>
                FCR ne transmet aucune donnée financière (comme les informations liées à une carte de crédit) à des tiers, à moins que ce ne soit nécessaire pour gérer votre demande de service, pour notre facturation, ou pour éviter ou lutter contre la fraude.
            </p>
            <p>
                Nos sites Web contiennent parfois des liens vers d'autres sites. Vous devrez lire leur politique de confidentialité avec soin, parce qu'elle peut différer de notre Politique de confidentialité.
            </p>
            
            <h2 class="text-xl">
                6. Responsables du traitement
            </h2>
            <p>
                Pour le traitement des données personnelles, FCR peut faire appel à d'autres prestataires de services qui s'occuperont du traitement uniquement à la demande de FCR. Ces derniers ne pourront donc pas les utiliser à des fins (commerciales) personnelles. FCR sélectionnera ses partenaires avec soin et attend d'eux qu'ils se montrent aussi consciencieux dans le traitement de ces données personnelles que FCR l'est. Nous passons un contrat de traitement avec ces partenaires, ce qui signifie qu'ils sont dans l'obligation de respecter les dispositions de cette Politique de confidentialité, mais aussi celles du RGPD.
            </p>
            
            <h2 class="text-xl">
                7. Protection de vos données personnelles
            </h2>
            <p>
                Nous reconnaissons notre responsabilité dans le fait de protéger suffisamment les informations que vous nous confiez. FCR a donc pris différentes mesures pour protéger les données personnelles contre le vol, l'utilisation abusive et l'accès non autorisé, la divulgation, la modification ou la destruction. Les mesures organisationnelles comprennent un accès limité aux bâtiments, aux systèmes et aux fichiers et leur surveillance ; des mesures techniques, y compris des pare-feu, la protection par des mots de passe personnels et la vérification, ainsi que des exigences de vérification pour l'accès aux données personnelles sur la base du need-to-know, sont également prévues.
            </p>
            <p>
                Nous attendons également de nos sous-traitants qu'ils soient en mesure de garantir un niveau de sécurité approprié lors du traitement des données à caractère personnel pour les besoins de FCR. Il s'agit d'une obligation contractuelle.
            </p>
            
            <h2 class="text-xl">
                8. Combien de temps conservons-nous vos données ?
            </h2>
            <p>
                FCR a une politique concernant la conservation des données à caractère personnel. Nous veillons à ce qu'elles soient conservées de manière sûre et pas plus longtemps que nécessaire pour remplir les fins mentionnées ci-dessus. Le délai de conservation des données dépend donc de l'objectif pour lesquelles elles sont collectées. Si vous souhaitez que FCR ne conserve plus vos données ou si vous aimeriez davantage d'informations sur les délais de conservation que nous appliquons, vous pouvez contacter notre service clientèle. Pour savoir comment procéder, veuillez lire le point 10. Où m'adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            
            <h2 class="text-xl">
                9. Quels sont vos droits et comment les faire valoir ?
            </h2>
            <p>
                FCR utilise les données personnelles de ses clients, de ses clients potentiels, de ses candidats et des visiteurs de ses sites Web à des fins précises et récolte uniquement les données personnelles dont elle a véritablement besoin à ces fins. Nous aimerions faire preuve d'un maximum de clarté et de transparence par rapport aux activités pour lesquelles nous collectons, utilisons et protégeons vos données. C'est pourquoi FCR a élaboré une politique afin que vous puissiez faire valoir vos droits concernant les données à caractère personnel que vous nous confiez. Vous disposez en effet de plusieurs droits, conformément à la législation en vigueur sur la vie privée.
            </p>
            <h3>
                9.1. Droit de consulter vos données
            </h3>
            <p>
                À tout moment, vous avez le droit de demander à FCR quelles sont les données en sa possession, pour quelle raison elle les utilise et de vous en fournir une copie. Vous pouvez aussi demander combien de temps nous conservons vos données, sur quelle base juridique nous traitons vos données (autorisation, contrat, obligation légale, intérêt légitime, intérêt général, intérêt vital), etc. Dans le cadre de ce droit, vous pouvez également examiner les données pour voir si elles sont correctes. Pour savoir comment faire valoir votre droit, vous pouvez lire le point 10. Où m’adresser pour mes questions ou plaintes ? de cette Politique de confidentialité. Si vous possédez un compte MyMedia, Presence Management ou sur nos sites Web, vous y trouverez toujours un aperçu des données que nous possédons à votre sujet.
            </p>
            <h3>
                9.2. Droit d'améliorer vos données
            </h3>
            <p>
                Si vous constatez que les données personnelles que nous possédons à votre sujet sont incorrectes ou incomplètes, vous pouvez les faire compléter ou corriger. Pour ce faire, vous pouvez prendre contact avec le service clientèle par e-mail ou par le biais d'un formulaire de contact. Si vous possédez un compte en ligne sur MyMedia, sur un de nos sites Web ou si vous avez acheté un produit NetSync, vous pouvez corriger ou compléter vous-même certaines données à caractère personnel.
            </p>
            <h3>
                9.3. Droit de faire supprimer vos données
            </h3>
            <p>
                Vous avez le droit de demander à FCR de supprimer les données à caractère personnel qu'elle possède. Attention toutefois : il ne s'agit pas d'un droit absolu. Le RGPD stipule sous quelles conditions ce droit peut être exercé. C'est le service clientèle qui déterminera si vous remplissez l'une des conditions. Si c'est le cas, nous nous occuperons de la suppression de vos données à caractère personnel. Si vos données ont été transmises à des sous-traitants à des fins précises, FCR les informera également que ces données doivent être supprimées. Sachez toutefois que si vous choisissez d'exercer ce droit, toutes vos données personnelles seront supprimées chez FCR. Si vous souhaitez faire appel à nos services par la suite, vous devrez à nouveau partager vos données.
            </p>
            <p>
                Pour savoir comment faire valoir votre droit, vous pouvez lire le point 10. Où m’adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3>
                9.4. Droit de demander un traitement limité de vos données
            </h3>
            <p>
                En invoquant le droit à un traitement limité de vos données personnelles, FCR a toujours le droit de les conserver, mais doit les utiliser selon certaines restrictions. Ce droit n'est applicable que dans certaines situations. Si FCR souhaite utiliser vos données à d'autres fins que la simple conservation, ce ne sera possible que dans certains cas précis (par exemple si vous fournissez à nouveau l'autorisation d'utiliser vos données). Pour savoir comment faire valoir votre droit, vous pouvez lire le point 10. Où m’adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3>
                9.5. Droit de s'opposer au traitement de vos données
            </h3>
            <p>
                Vous avez le droit de vous opposer au traitement de vos données si ce traitement est fondé sur un intérêt général ou légitime de FCR. Dans le point 4. Quelles sont les données que nous collectons à votre sujet et dans quel but ?, vous trouverez une liste des activités pour lesquelles FCR dispose d'un intérêt légitime ou général. Si vous faites valoir ce droit, FCR mènera une enquête pour savoir si ses raisons légitimes de traiter vos données sont plus importantes que vos intérêts, droits et libertés. Dans le cas contraire, FCR ne traitera plus vos données à cette fin.
            </p>
            <p>
                Vous pouvez également vous opposer au traitement de vos données personnelles à des fins de marketing direct. Si vous exercez ce droit, FCR n'utilisera plus vos données à des fins de marketing direct.
            </p>
            <p>
                Pour savoir comment faire valoir votre droit, vous pouvez lire le point 10. Où m’adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3>
                9.6. Droit à la transférabilité des données
            </h3>
            <p>
                Ce droit signifie que vous avez le droit de récupérer les données que vous avez fournies à FCR. Il porte uniquement sur le traitement des données qui reposent sur votre autorisation (ex. autorisation d'envoyer une newsletter) ou sur un contrat (ex. contrat que vous avez conclu au moment de faire appel aux services de marketing digital   de FCR). Autre aspect de ce droit : vous avez le droit de vous adresser à un autre responsable de traitement pour les données que vous avez récupérées sans que FCR puisse s'y opposer. Notez toutefois qu'il s'agit uniquement des données que vous avez directement fournies à FCR. Pour savoir comment faire valoir votre droit, vous pouvez lire le point 10. Où m’adresser pour mes questions ou plaintes ? de cette Politique de confidentialité.
            </p>
            <h3>
                9.7. Droit de retirer votre autorisation
            </h3>
            <p>
                Comme nous l'avons mentionné, vous pouvez à tout moment retirer votre autorisation pour tous les traitements que vous avez autorisés à FCR. Sachez cependant que le retrait de votre autorisation n'a aucun impact sur le traitement réalisé par FCR au moment où la société avait encore votre autorisation. Vous pouvez retirer votre autorisation de plusieurs manières :
            </p>
            <ul>
                <li>
                    Vous pouvez vous désinscrire des newsletters, des actions de marketing direct et autres informations sur mesure via le lien qui se trouve toujours en bas des newsletters et autres communications envoyées.
                </li>
                <li>
                    Vous pouvez vous désinscrire ici afin de ne plus recevoir les pages d'or et les pages blanches.
                </li>
                <li>
                    Vous pouvez remplir le formulaire de contact qui sera traité par notre service clientèle. Ce formulaire vous permet de retirer votre autorisation pour la réception des communications relatives aux postes vacants si vous avez spontanément posé votre candidature chez FCR. Mais vous pouvez aussi vous désinscrire de newsletters, du marketing direct ou autres informations via le formulaire de contact.
                </li>
            </ul>
            <p>
                Sachez toutefois qu'un délai de traitement est nécessaire pour traiter votre demande et qu'entretemps, il est fort probable que vous receviez les informations une dernière fois. Nous limiterons ce délai au maximum.
            </p>
            
            <h2 class="text-xl">
                10. Où m'adresser pour mes questions ou plaintes ?
            </h2>
            <p>
                Comme indiqué dans la déclaration de vos droits, vous pouvez faire valoir vos droits en remplissant un formulaire de contact qui sera ensuite traité par notre service clientèle. Bien sûr, vous pouvez toujours contacter le service clientèle par e-mail pour des questions ou des plaintes relatives au traitement de vos données personnelles ou pour des questions sur cette Politique de confidentialité. Vous pouvez aussi envoyer un e-mail à <a href="mailto:info@fcrmedia.be">info&#64;fcrmedia.be</a> pour toute question ou problème. Nous vous demanderons quelques informations ainsi que le droit que vous souhaitez faire valoir, ou encore la question ou le problème relatifs au traitement de vos données personnelles.
            </p>
            <p>
                Nous vous demandons vos données personnelles afin que nous puissions vous identifier (et uniquement vous) dans notre système, et surtout, que nous ne partagions pas vos données personnelles avec des personnes qui prétendent être quelqu'un d'autre. Nous vous demanderons ainsi les données suivantes : nom et prénom, adresse, entreprise, numéro de TVA et numéro de client (si vous avez fait appel aux services de FCR en tant qu'entreprise), numéro de téléphone, adresse e-mail, le droit que vous souhaitez faire valoir et des informations complémentaires au sujet de votre question ou problème. Toutes les demandes sont envoyées directement au service clientèle. Une fois que nous aurons reçu votre demande, nous vous enverrons un e-mail de confirmation pour vous informer qu'elle est en cours de traitement. Dans le mois suivant la réception de votre demande au service clientèle, nous vous communiquerons une réponse finale.
            </p>
            <p>
                Nous mettrons tout en œuvre pour répondre à votre question ou résoudre votre problème de manière optimale. Si malgré tout vous n'êtes pas satisfait de la manière dont nous avons géré vos données personnelles, vous trouverez sur le site Web de la Comission sur la protection de la vie privée davantage d'informations sur les étapes que vous pouvez entreprendre.
            </p>
            
            <h2 class="text-xl">
                11. Droit applicable
            </h2>
            <p>
                La présente politique de confidentialité est régie par le droit belge. Tous les litiges découlant de ou en relation avec la présente Politique de confidentialité seront exclusivement soumis aux tribunaux compétents d'Anvers.
            </p>
            
            <h2 class="text-xl">
                12. Modification de la Politique de confidentialité
            </h2>
            <p>
                FCR se réserve le droit d'apporter des modifications à la présente Politique de confidentialité. Tout changement sera publié sur cette page. Nous vous recommandons de consulter régulièrement cette Politique de confidentialité afin d'être toujours au courant du contenu de la Politique de confidentialité en vigueur. FCR collabore avec d'autres partenaires pour divers services. FCR n'est pas responsable des politiques de confidentialité de ses partenaires et n'est pas responsable du contenu de leur Politique de confidentialité. Nous vous recommandons donc de consulter la Politique de confidentialité de ces partenaires pour plus d'informations.
            </p>    
        </article>

    </div>
</div>