<div class="mt-7 p-3" style="border: 1px solid #EBEBEB; background-color: #F9F9F9;">
    <div class="grid md:grid-cols-3 grid-cols-1">
        <div>
        <strong>Uni ID:</strong> <a href="" target="_blank"> {{ listing?.Id }}</a><br />
        <strong>Score:</strong> {{ listing?.NormalizedScore }} ({{ listing?.Score }})<br />
        <strong>Traffic Level:</strong> {{ listing?.TrafficLevel }}<br />
        <strong>Is paid:</strong> {{ listing?.IsPaid }}<br />
        <strong>Is local:</strong> {{ listing?.IsLocal }}<br />
        <strong>Keyword category:</strong> {{ listing?.IsKwCategory }}<br />
        <strong>Is shortlisted:</strong> {{ listing?.IsShortlisted }}<br />
    </div>
    <div class="col-span-2">
        <ng-container *ngIf="!utils?.isNullOrEmpty(listing?.ZoningFlag)">
            <strong>Zoning flag:</strong> {{ listing?.ZoningFlag }}<br />
        </ng-container>
        <ng-container *ngIf="listing?.ZoningLevel > 0">
            <strong>Zoning level:</strong> {{ listing?.ZoningLevel }}<br />
        </ng-container>
        <ng-container *ngIf="listing?.GeoDistance !== null">
            <strong>GeoDistance:</strong> {{ listing?.GeoDistance }}<br />
        </ng-container>
        <strong>GeoExpansion:</strong> {{ listing?.GeoExpansion }}<br />
        <strong>Dynamic rank:</strong> {{ listing?.rankLabel }} ({{ listing?.Ranking }}) <br />
        <ng-container *ngIf="listing?.matchedBuckets?.length > 0">
            <strong>Buckets:</strong> {{ listing?.matchedBuckets.join("; ") }} <br />
        </ng-container>
        <ng-container *ngIf="!utils.isNullOrEmpty(data?.data?.fallbackName)">
            <strong>Fallback:</strong> {{ data?.data?.fallbackName }} <br />
        </ng-container>
    </div>
</div>