<header id="header">

    <!-- top-bar -->
    <div class="top-bar" *ngIf="currentLocale !== 'en'">
        <div class="yp-container p-3.5 flex">
            <ul class="ml-auto flex gap-3">
                <li>
                    <app-button [routeSegments]="['/blog']" [label]="'Blog'" class="font-medium"></app-button>
                </li>
            </ul>
        </div>
    </div>

    <!-- header with form -->
    <app-sticky-element>
        <div class="header">
            <div class="yp-container flex">

                <!-- header for homepage -->
                <ng-container *ngIf="isHome(); else elseHeader">

                    <!-- logo -->
                    <a class="flex items-end w-24" [routerLink]="'/'">
                        <app-icon [icon]="'logo_symbol_border'" svgClass="w-12 h-12"></app-icon>
                        <app-icon [icon]="'logo_txt_' + translate.currentLang" svgClass="w-12 h-4"></app-icon>
                    </a>

                    <!-- links -->
                    <div class="flex gap-4 items-center ml-auto">
                        <app-button 
                            [href]="'Routes.Static.AddYourBusiness' | translate" 
                            [target]="'_blank'"
                            [rel]="'noopener noreferrer'" 
                            [label]="'AddYourBusiness' | translate" 
                            [style]="'outline'"
                            [trackingAction]="'AddYourBusinessClick'">
                        </app-button>

                        <ul class="hidden md:block">
                            <app-language-switch></app-language-switch>
                        </ul>

                        <ul class="hidden md:block">
                            <app-shorlist-link></app-shorlist-link>
                        </ul>
                    </div>

                </ng-container>

                <!-- header for other pages -->
                <ng-template #elseHeader>

                    <div class="flex w-full gap-4">
                        <!-- logo -->
                        <a [routerLink]="'/'" class="flex items-baseline md:items-end md:static mb-auto" [ariaLabel]="'GoldenPages' | translate">
                            <app-icon [icon]="'logo_symbol_border'" svgClass="w-12 h-12"></app-icon>
                            <app-icon [icon]="'logo_txt_' + translate.currentLang" svgClass="w-12 h-4 hidden md:block"></app-icon>
                        </a>

                        <!-- form -->
                        <div class="flex w-full md:flex-row gap-2 flex-col" data-js="search-form-collapse">
                            <!-- mobile version of header -->
                            <div class="reduced-search-form relative lg:hidden md:flex-grow"
                                *ngIf="!isNoSearch()"
                                style="margin-bottom: -3.375rem;"
                                [ngClass]="{ 'hidden': reducedFormOpen === true }">
                                <app-icon 
                                    [icon]="'search-icon'"
                                    svgClass="absolute block h-5 left-4 lg:hidden text-blue-600 top-3.5 w-5">
                                </app-icon>
                                <div (click)="handleRaducedSearchForm()"
                                    class="form-item bg-white lg:mb-0 lg:pl-2.5 lg:w-auto overflow-ellipsis overflow-hidden pl-12 w-full h-12 flex gap-2">
                                    <span class="what" *ngIf="!utils.isNullOrEmpty(whatValue)">{{ whatValue }}</span>
                                    <span class="separator"
                                        *ngIf="!utils.isNullOrEmpty(whatValue) && !utils.isNullOrEmpty(whereValue)">In</span>
                                    <span class="where" *ngIf="!utils.isNullOrEmpty(whatValue)">{{ whereValue }}</span>
                                    <span class="text-gray-400"
                                        *ngIf="utils.isNullOrEmpty(whatValue) && utils.isNullOrEmpty(whereValue)">{{ whatDefaultValue }}</span>
                                </div>
                            </div>

                            <!-- form: should be minify on mobile -->
                            <form id="search-form"
                                class="lg:flex flex-col lg:flex-grow-0 flex-grow lg:flex-row md:items-center lg:mx-auto gap-2 relative search-form"
                                [ngClass]="{ 'hidden': reducedFormOpen === false }"
                                *ngIf="!isNoSearch()"
                                [formGroup]="searchForm">
                                <!-- what -->

                                <div class="flex relative w-full lg:w-auto mb-2 lg:mb-0">
                                    <app-icon [icon]="'search-icon'" svgClass="absolute block h-5 left-4 lg:hidden text-blue-600 top-3.5 w-5"></app-icon>
                                    <input class="form-item md:mb-0 lg:pl-2.5 md:w-auto pl-12 flex-grow h-12 w-full"
                                        formControlName="what"
                                        type="text" 
                                        autocomplete="off"
                                        [placeholder]="'WhatDefault' | translate"
                                        required
                                        (focus)="showWhatSuggestionsOnFocus()"
                                        #whatField />
                                    <app-suggester 
                                        #suggesterWhat
                                        [field]="whatField"
                                        (suggestionSelected)="selectSuggestion($event, 'what')"    
                                        [suggestions]="whatSuggestions">
                                    </app-suggester>
                                </div>

                                <!-- where and submit btn -->
                                <div class="flex gap-2 w-full lg:w-auto lg:flex-row flex-col">
                                    <!-- where -->
                                    <div class="flex relative w-full">
                                        <app-icon [icon]="'poi-icon'" svgClass="absolute block h-5 left-4 lg:hidden text-blue-600 top-3.5 w-5"></app-icon>
                                        <input class="form-item lg:pl-2.5 md:w-auto pl-12 flex-grow h-12 w-full"
                                            formControlName="where"
                                            type="text" 
                                            autocomplete="off"
                                            [placeholder]="!this.isHome() ? ('WhereSearchDefault' | translate) : ''"          
                                            (focus)="showWhereSuggestionsOnFocus()"
                                            #whereField />
                                        <div class="autocomplete-suggestions show" *ngIf="showMyLocation">
                                            <div class="autocomplete-suggestion" (click)="handleMyLocation()">
                                                {{ 'AroundMe' | translate }}
                                            </div>
                                        </div>
                                        <app-suggester 
                                            #suggesterWhere
                                            [field]="whereField"
                                            (suggestionSelected)="selectSuggestion($event, 'where')" 
                                            [suggestions]="whereSuggestions">
                                        </app-suggester>
                                        
                                    </div>

                                    <!-- submit button -->
                                    <app-button 
                                        [label]="'SearchButton' | translate" 
                                        [style]="'filled-dark'"
                                        [type]="'submit'" 
                                        (click)="onSubmit()"
                                        class="lg:mr-0 mr-auto">
                                    </app-button>
                                </div>
                            </form>

                            <!-- parts unnecessary on mobile -->
                            <div class="md:flex gap-4 items-start hidden" [ngClass]="{'ml-auto': isNoSearch()}">
                                <app-button *ngIf="!isNoSearch()"
                                    [href]="'Routes.Static.AddYourBusiness' | translate" 
                                    [target]="'_blank'"
                                    [rel]="'noopener noreferrer'" 
                                    [label]="'AddYourBusiness' | translate"
                                    [fakeBorder]="true" [style]="'outline'"
                                    [trackingAction]="'AddYourBusinessClick'">
                                </app-button>

                                <ul class="hidden md:block h-12">
                                    <app-language-switch></app-language-switch>
                                </ul>
                                <ul class="absolute flex h-12 items-center md:static right-4">
                                    <app-shorlist-link></app-shorlist-link>
                                </ul>
                            </div>
                            <!-- .parts unnecessary on mobile -->

                        </div>
                        
                    </div>

                </ng-template>

            </div>
        </div>
    </app-sticky-element>

    <!-- home page part -->
    <div class="default-gradient border-b border-gray-100" *ngIf="isHome()">
        <div class="yp-container default-bg pt-20 pb-56 md:pb-20 bg-contain md:bg-800 bg-bottom md:bg-100"
            style="background-color: #fff">
            <h1 class="font-semibold mb-14 text-4xl md:text-5xl md:w-144 sm:w-full text-shadow">
                {{ 'MainHeading' | translate }}
            </h1>

            <!-- search form -->
            <form class="flex flex-col justify-start"
                [formGroup]="searchForm">
                <!-- what field -->
                <div class="flex flex-col mb-3.5 mr-auto">
                    <label class="mb-4 mr-auto text-blue-600 text-shadow label-shadow" for="what">{{ 'WhatDefault' | translate }}</label>
                    <div class="relative">
                        <input class="form-item md:w-96 h-12 w-full" 
                            formControlName="what"
                            type="text" 
                            autocomplete="off"
                            placeholder=""
                            required
                            (focus)="showWhatSuggestionsOnFocus()"
                            #whatField />
                        <app-suggester 
                            #suggesterWhat
                            [field]="whatField"
                            (suggestionSelected)="selectSuggestion($event, 'what')" 
                            [suggestions]="whatSuggestions">
                        </app-suggester>
                    </div>
                </div>

                <!-- where field -->
                <div class="flex flex-col mb-3.5 mr-auto">
                    <label class="mb-4 mr-auto text-blue-600 text-shadow label-shadow" for="where">{{ 'WhereDefault' | translate }}</label>
                    <div class="relative">
                        <input class="form-item md:w-96 h-12 w-full" 
                            formControlName="where"
                            type="text" 
                            autocomplete="off"
                            [placeholder]=""
                            (focus)="showWhereSuggestionsOnFocus()"
                            #whereField />
                        <div class="autocomplete-suggestions show" *ngIf="showMyLocation">
                            <div class="autocomplete-suggestion" (click)="handleMyLocation()">
                                {{ 'AroundMe' | translate }}
                            </div>
                        </div>
                        <app-suggester 
                            #suggesterWhere
                            [field]="whereField"
                            (suggestionSelected)="selectSuggestion($event, 'where')" 
                            [suggestions]="whereSuggestions">
                        </app-suggester>
                        
                    </div>
                </div>

                <!-- submit button -->
                <app-button 
                    [label]="'SearchButton' | translate" 
                    [style]="'filled-dark'" 
                    [type]="'submit'"
                    (click)="onSubmit()"
                    class="mr-auto">
                </app-button>
            </form>

        </div>
    </div>

    <!-- animated map button - result page -->
    <div class="bg-gray-300 border-b flex h-14 items-center justify-end md:hidden ml-auto px-4" *ngIf="isSearch()">
        <app-icon [icon]="'map-icon'" svgClass="h-5 mr-2 pt-0.5 text-blue-600 w-5"></app-icon>
        <span 
            (click)="handleMapModal()"
            class="text-sm text-blue-200 cursor-pointer modal" 
            id="openMapMobile" 
            data-modal-type="element"
            data-modal-element="modal-map" 
            data-modal-class="modal-dialog" 
            data-modal-open="ResultList.initResultsMap"
            title="map">
            <span>{{ 'SeeOnMap' | translate }}</span>
        </span>
    </div>

    <!-- ? puvodne asi geo location ? -->
    <div id="my-location" class="hidden"></div>
</header>