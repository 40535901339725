<section id="labels-and-brands" class="border-t mb-10 mt-4" *ngIf="labelsAndBrandsSorted.length > 0">
    <h2 class="font-medium mb-4 pt-8 text-1xl">
        {{ 'LabelsAndBrands' | translate }}
    </h2>
    <div class="grid md:grid-cols-2 grid-cols-1 gap-4">

            <div class="flex flex-col gap-2" *ngFor="let lab of labelsAndBrandsSorted">
                <div *ngIf="!utils.isNullOrEmpty(lab.logoPath)">
                    <img [src]="lab.logoPath" />
                </div>
                <div>
                    <h3 class="" *ngIf="!utils.isNullOrEmpty(lab.title)">{{ lab.title }}</h3>
                    <div class="text-xs text-gray-400 leading-21" *ngIf="!utils.isNullOrEmpty(lab.description)">
                        {{ lab.description }}
                    </div>
                    <a *ngIf="!utils.isNullOrEmpty(lab.clickthroughUrl) || !utils.isNullOrEmpty(lab.anchorText)"
                        [href]="lab.clickthroughUrl"
                        class="text-blue-600 underline text-sm hover:no-underline"
                        target="_blank">
                        {{ lab.anchorText }}
                    </a>
                </div>
            </div>
    </div>
</section>
