import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContactInfo } from '../../helpers/contact.helper';

@Component({
  selector: '[app-email-btn]',
  templateUrl: './email-btn.component.html',
  styleUrl: './email-btn.component.scss'
})
export class EmailBtnComponent implements OnInit {
  @Input() 
  showIcon: boolean = true;

  @Input() 
  btnBorder: string = '';

  link: string = '';

  @Input()
  contacts: ContactInfo[];

  constructor(
    private translate: TranslateService){}

  ngOnInit(){
    this.link = this.getEmailLink();
  }

  getEmailLink() {
    const emails = this.contacts.length > 0 && this.contacts[0].contact;
    const firstEmail = emails.length > 0 && emails[0];
    const subject = this.translate.instant('EmailSubject');

    if (this.contacts.length > 0 && this.contacts[0]?.hasContact) {
      return `mailto:${firstEmail.value}?subject=${subject}`;
    }

    return '';
  }
}
