<div class="flex flex-col items-baseline" *ngIf="detailPageType === 'free'">

    <div class="flex items-center gap-2">
        <h1 id="listing-title" data-yext="name" class="font-bold text-lg" #titleRef>
            <span class="words-break">
                {{ listing?.Title }}
            </span>
        </h1>
        <app-claim-button [listing]="listing"></app-claim-button>
    </div>

    <!-- CYB -->
    <a [routerLink]="'Routes.Static.ClaimYourBusiness' | translate"
        [queryParams]="{ listingId: listing?.Id}" 
        *ngIf="showClaimLinkAll"
        target="_blank" 
        class="font-normal hover:no-underline md:block text-blue-200 underline no-redirect mb-1 md:mb-3" 
        [trackingAction]="'ClaimYourListingClick'" 
        [trackingCategory]="'DETAIL'" 
        [trackingListingId]="listing?.Id">
        {{ "CompleteProfile" | translate }}
    </a>
    <!-- /CYB -->
</div>

<div *ngIf="detailPageType !== 'free'" 
    class="flex items-baseline gap-2 mb-2" 
    [class.mt-2]="!(listing?.Logo?.path !== null && listing?.TrafficLevel >= 3 || listing?.IsTrafficPage == false && showClaimLinkAll)"
    [class.mt-6]="listing?.Logo?.path !== null && listing?.TrafficLevel >= 3 || listing?.IsTrafficPage == false && showClaimLinkAll">
    <div class="flex items-baseline gap-2">
        <h1 id="listing-title" data-yext="name" class="font-bold text-lg" #titleRef>
            <span class="words-break">
                {{ listing?.Title }}
            </span>
        </h1>
        
        <!-- claim button -->
        <app-claim-button [listing]="listing"></app-claim-button>

    </div>
    <div class="flex ml-auto">

        <!-- share button -->
        <app-share-button
            [listing]="listing">
        </app-share-button>

    </div>
</div>

<!-- sticky title -->
<ng-container *ngIf="checkDeviceService.isMobile$ | async">
    <div class="sticky-title hidden fixed top-0 left-0 w-full" 
        style="top: 5.05rem;background: #fff;padding: 1rem;border-bottom: 1px solid #ddd;z-index: 999999;"
        #stickyButtonRef
        (click)="scrollToTop()">
        <div class="flex justify-center items-center">
            <span class="text-base font-bold">
                {{ listing?.Title }}
            </span>

            <app-claim-button [listing]="listing" class="ml-2"></app-claim-button>
        </div>
    </div>
</ng-container>