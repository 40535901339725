import { Component, Input, OnInit } from '@angular/core';
import { SpecialActions } from '../../../enums/special-actions.enum';
import { SpecialActionsHelper } from '../../../helpers/special-actions.helper';
import { DebugItem } from '../../../models/debug-item.model';
import { ListingModel } from '../../../models/listing.model';
import { CheckDeviceService } from '../../../services/check-device.service';
import { ClaimYourBusinessService } from '../../../services/claim-your-business.service';
import { Contacts, DetailPageType, Poi, ShareDataService } from '../../../services/share-data.service';

@Component({
  selector: 'app-folder-page',
  templateUrl: './folder-page.component.html',
  styleUrl: './folder-page.component.scss'
})
export class FolderPageComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('childListing')
  childListing: ListingModel;

  @Input('detailPageType')
  detailPageType: DetailPageType;

  @Input('pois')
  pois: Poi[] = [];

  @Input('requestForms')
  requestForms;

  @Input('showTrafficDescription')
  showTrafficDescription;

  @Input('isChildListing')
  isChildListing: boolean = false;

  childListingId: string;
  childPois: Poi[] = new Array<Poi>();
  childContacts: Contacts;

  debug: DebugItem[];

  disableParkingInfo: boolean = false;

  constructor(
    public shareDataService: ShareDataService,
    public claimService: ClaimYourBusinessService,
    public checkDeviceService: CheckDeviceService,
    private specialActionsHelper: SpecialActionsHelper) {
  }

  ngOnInit () {
    this.claimService.initialize(this.childListing);
    this.showTrafficDescription = this.showTrafficDescription && this.claimService.displayFreeClaimBadge() === false;
    this.disableParkingInfo = this.specialActionsHelper.hasAction(this.listing, SpecialActions.DisableFinancialInfo);
  }
}