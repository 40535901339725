import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StructuredDataService {

  private structuredDataScript: HTMLScriptElement;

  addStructuredData(data: object): void {
    this.structuredDataScript = document.createElement('script');
    this.structuredDataScript.type = 'application/ld+json';
    this.structuredDataScript.text = JSON.stringify(data);
    document.body.appendChild(this.structuredDataScript);
  }

  removeStructuredData() {
    if (this.structuredDataScript) {
      document.body.removeChild(this.structuredDataScript);
      this.structuredDataScript = null;
    }
  }
}
