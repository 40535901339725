import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogPostSearchArguments } from '../../models/blog-post-search-arguments.model';
import { BlogPostSearchResultsModel } from '../../models/blog-post-search-results.model';
import { BLOG_PLATFORM } from '../../constants/constants';
import { ShareDataService } from '../../services/share-data.service';
import { BlogPost } from '../../models/blog-post.model';
import { map, Observable } from 'rxjs';
import { LangSwitch } from '../../services/lang-switch.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTitleService } from '../../services/translate-title.service';
import { MetaHelper } from '../../helpers/meta.helper';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss'
})
export class BlogComponent implements OnInit {
  rows: number = 12;
  category: string;
  page: number = 1;

  allBlogPosts: any[];
  allCategories: any[];

  index: number;

  blogsAndCategories$: Observable<BlogPostSearchResultsModel>;

  totalPages: number;
  displayPages: number[] = [];

  loading: boolean = false;

  numFound: number = 0;

  constructor( 
    private route: ActivatedRoute,
    private shareDataService: ShareDataService,
    private langSwitch: LangSwitch,
    private translate: TranslateService,
    private title: TranslateTitleService,
    private metaHelper: MetaHelper){}

  ngOnInit(): void {
    this.route.paramMap.subscribe((map) => {
      this.category = map.get("category");

      this.loadBlogAndCategories();
    });

    this.route.queryParamMap.subscribe((map) => {
      this.page = parseInt(map.get("page"));
    });

    this.translate.get("init").subscribe(x => {
      this.title.setBasicTitle("BlogMetaTitle");
      this.metaHelper.addMetaDescription(this.translate.instant("BlogMetaDescription"))
    });
  }

  getSearchArguments(): BlogPostSearchArguments {
    
    var args = new BlogPostSearchArguments();
    if (this.category && this.category !== "" && this.category !== "all") {
      args.category = this.category;
    }
    
    if (this.page && this.page !== 0 && this.page > 1) {
      args.skip = this.rows * (this.page -1);
    } else {
      args.skip = 0;
    }
    
    args.take = this.rows;
    args.platform = BLOG_PLATFORM;

    return args;
  }

  loadBlogAndCategories() {
    const req = this.getSearchArguments();
    this.blogsAndCategories$ = this.shareDataService.fetchBlog(req).pipe( 
      map(data => {
        this.numFound = data.found;
        this.loadPager(this.numFound);
        this.setLangSwitch(data)
        return data;
      })
    );
  }

  setLangSwitch(data: BlogPostSearchResultsModel) {
    if(!this.category || this.category == 'all')
      this.langSwitch.setBlogPage(false)
    else{
      this.langSwitch.setBlogPage(true, data.blogPosts[0].headingsEn[0], data.blogPosts[0].headingsFr[0], data.blogPosts[0].headingsNl[0])
    }
  }

  switchPage(event) {
    if (event && event.page) {
      this.page = event.page;
    }
    this.loadBlogAndCategories();
  }

  escapeURLString(string: string): string {
    return encodeURIComponent(string);
  }

  getBlogDetailUrl(blogPost: BlogPost) {
    let headings = [];
    if (blogPost?.headings) {
      headings = blogPost?.headings;
    }

    let heading = "";
    if (headings?.length > 0) { 
      heading = headings[0];
    }
    return `/blog/${heading}/${blogPost?.id}/${encodeURIComponent(blogPost?.title)}`;
  }

  loadPager(numFound: number) {
    this.displayPages = [];
    for (let index = 1; index <= numFound; index++) {
      this.displayPages.push(index);      
    }
  }
}
