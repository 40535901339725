import { Component, Input, OnInit } from '@angular/core';
import { LabelsAndBrands } from '../../../../models/labels-and-brands.model';
import { ListingModel } from '../../../../models/listing.model';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-labels-and-brands',
  templateUrl: './labels-and-brands.component.html',
  styleUrl: './labels-and-brands.component.scss'
})
export class LabelsAndBrandsComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  labelsAndBrands: LabelsAndBrands[];
  labelsAndBrandsSorted: LabelsAndBrands[];

  constructor(public utils: Utils) { }

  ngOnInit() {
    const showLocalList = this.listing?.TrafficLevel >= 1;
    const nationalList = this.listing?.NationalLabelsAndBrands;
    const localList = this.listing?.LocalLabelsAndBrands;

    this.labelsAndBrands = showLocalList ? [...nationalList, ...localList] : nationalList;
    this.labelsAndBrandsSorted = this.labelsAndBrands
      .sort((a, b) => a.title.localeCompare(b.title))
      .map(lab => {
        const clickthroughUrl: string = this.utils.getUrlWithProtocol(lab.clickthroughUrl);
        return { ...lab, clickthroughUrl };
      });
  }
}