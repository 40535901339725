import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangSwitch } from '../../services/lang-switch.service';

type CurrentLocale = 'nl' | 'fr' | 'en';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent {
  languages: Array<CurrentLocale>;
  filteredLanguages: Array<CurrentLocale>;
  selectedLanguage: CurrentLocale;
  isOpen: boolean = false;

  constructor(public translate: TranslateService, private langSwitch: LangSwitch) {
    this.languages = this.languages = ['nl', 'fr', 'en'];
    this.selectedLanguage = this.translate.currentLang as CurrentLocale;
    this.filteredLanguages = this.languages.filter(lang => lang !== this.selectedLanguage);
  }

  setSelectedLanguage(locale: CurrentLocale): void {
    if(locale == "nl")
      window.location.href = this.langSwitch.linkNL;
    if(locale == "fr")
      window.location.href = this.langSwitch.linkFR;   
    if(locale == "en")
      window.location.href = this.langSwitch.linkEN;      
  }

  handleOpenLanguageSwitch(): void {
    this.isOpen = !this.isOpen;
  }

  handleSelectLanguage(selectedLocale: CurrentLocale): void {
    this.setSelectedLanguage(selectedLocale);
    this.filteredLanguages = this.languages.filter(lang => lang !== this.selectedLanguage);
    this.handleOpenLanguageSwitch();
  }
}