import { Injectable } from "@angular/core";
import { Utils } from "../utils/utils";

@Injectable({
    providedIn: 'root',
})
export class BookingHelper {

  constructor(private utils: Utils) {}

  public getBookingUrl(bookingEmbedCode: string): string | null {
    if (this.utils.isNullOrEmpty(bookingEmbedCode)) {
      return null;
    }

    const regexPatternIframe = /<iframe/i;
    const regexIframe = new RegExp(regexPatternIframe);
    const matchIframe = bookingEmbedCode.match(regexIframe);

    // extract iframe src value and return it
    if (matchIframe) {
      const regexPatternScript = /<iframe\b[^>]*src=(['"])(?<src1>.+?)\1|<iframe\b[^>]*src=(?<src2>[^\s>]+)/i;
      const regexScript = new RegExp(regexPatternScript);
      const matchScript = bookingEmbedCode.match(regexScript);
      if (matchScript) {
        const srcValue = matchScript.groups['src'];
        if (srcValue) {
          return `<iframe src='${srcValue}' height='500' class='w-full'></iframe>`;
        }
        return bookingEmbedCode;
      }
    } else {
      // extract href value and return it
      const regexPatternHref = /<a[^>]*\bhref=(['"])?(?<href>[^\s"'<>]+)\1?/i;
      const regexHref = new RegExp(regexPatternHref);
      const matchHref = bookingEmbedCode.match(regexHref);
      if (matchHref) {
        const hrefValue = matchHref.groups['href'] || '';

        // if a tag doesn't contain href value try to extract it from embed code itself
        if (hrefValue === '#') {
          const pattern = /data-url\s*=\s*"([^"]+)"/i;

          // Use RegExp.prototype.exec to find the first match in the HTML code
          const match = pattern.exec(bookingEmbedCode);

          if (match && match[1]) {
            return `<iframe src='${match[1]}' height='500' class='w-full'></iframe>`;
          }
          return null;
        }

        return `<iframe src='${hrefValue}' height='500' class='w-full'></iframe>`;
      }
    }

    // return entire embed code
    return bookingEmbedCode;
  }

  getBookingLabel(bookingEmbedCode: string): string | null {
    if (this.utils.isNullOrEmpty(bookingEmbedCode)) {
      return null;
    }

    // Define the regular expression pattern to find the data-title attribute
    const pattern = /data-title\s*=\s*"([^"]+)"/;

    // Use RegExp.prototype.exec to find the first match in the HTML code
    const match = pattern.exec(bookingEmbedCode);

    if (match && match[1]) {
      // Extract the value of the data-title attribute (index 1 contains the value)
      const bookingLabelValue = match[1];
      return bookingLabelValue;
    } else {
      return null; // data-title attribute not found
    }
  }
}