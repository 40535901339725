<div class="yp-container card card--flow-y">
    <h1 class="text-xl font-semibold mb-4">{{ "Companies" | translate }}:</h1>
    <ul class="gap-x-4 grid grid-cols-1 mb20 md:grid-cols-2" *ngIf="this.result?.indexes">
        <li *ngFor="let item of this.result.indexes">
            <app-button 
                [routerLink]="item.key"
                [label]="item.key"
                [showIcon]="false">
            </app-button>
        </li>
    </ul>
    <ul class="gap-x-4 grid grid-cols-1 mb20 md:grid-cols-2" *ngIf="this.result?.listings">
        <li *ngFor="let item of this.result.listings">
            <app-button 
                [routerLink]="getDetailUrl(item)"
                [label]="item.Title"
                [showIcon]="false">
            </app-button>
        </li>
    </ul>
</div>