import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Poi, SearchGroup, ShareDataService } from '../../../../services/share-data.service';
import { SearchArguments } from '../../../../models/search-arguments.model';
import { ListingSearchResultsModel } from '../../../../models/listing-search-results.model';
import { OpeningType } from '../../../../enums/opening-type.enum';
import { SearchSort } from '../../../../enums/search-sort.enum';
import { EventService } from '../../../../services/event.service';
import { SearchStateService, SearchStateValues } from '../../../../services/search-state.service';
import { Observable, of, switchMap } from 'rxjs';

@Component({
  selector: '[app-result-filters]',
  templateUrl: './result-filters.component.html',
  styleUrl: './result-filters.component.scss'
})
export class ResultFiltersComponent implements OnInit {
  @Output()
  emitSearchArgs: EventEmitter<SearchStateValues> = new EventEmitter();

  @Input('loading')
  loading: boolean = true;

  eshop$: Observable<boolean>;

  rating: SearchSort = SearchSort.Rating;
  distance: SearchSort = SearchSort.Distance;

  searchArgs: SearchArguments = new SearchArguments();

  searchResults: SearchGroup = null;
  data: ListingSearchResultsModel;
  pois: Poi[] = new Array<Poi>();

  // filter state
  isRatingActive: boolean = false;
  isDistanceActive: boolean = false;
  isOpenNowActive: boolean = false;
  isEshopActive: boolean = false;

  constructor(
    public shareDataService: ShareDataService,
    private eventService: EventService,
    private searchStateService: SearchStateService
  ) {}

  ngOnInit() {
    // reset filters on new search
    this.eventService.handleSearchClick.subscribe((data) => {
      this.resetSearchArgs();
      this.isEshopAvialable();
    });
  }

  ngAfterViewInit() {
    this.isEshopAvialable();
    this.initFilters();
  }

  // check availability of eshop
  isEshopAvialable() {
    let eshopSearchArgs = this.searchStateService.getCurrentValues();
    eshopSearchArgs.eshop = true;
    this.eshop$ = this.shareDataService.fetchResults(eshopSearchArgs)
      .pipe(
        switchMap((data) => {
          return of(this.shareDataService.getListings(data?.searchGroups)?.length > 0);
        })
      );
  }

  updateSort(filter: SearchSort) {
    this.searchStateService.setGeoLocation(null);
    this.searchArgs = this.searchStateService.getCurrentValues();

    if (this.searchArgs.sort === filter) {
      this.isRatingActive = false;
      this.isDistanceActive = false;
      this.searchStateService.setSort(null);
      this.applyFilter();
    } else {
      this.isRatingActive = filter === SearchSort.Rating;
      this.isDistanceActive = filter === SearchSort.Distance;
      this.searchStateService.setSort(filter);

      if (this.isRatingActive) {
        this.applyFilter();
      }

      if (this.isDistanceActive) {
        this.shareDataService.getLocation().then((geolocation: string) => {
          if (geolocation) {
            this.searchStateService.setGeoLocation(geolocation);
            //this.searchStateService.setWhere(null);
          }
          this.applyFilter();
        }).catch(err => {

        });
      }
    }
  }

  toggleOpenNow() {
    this.searchArgs = this.searchStateService.getCurrentValues();

    if (this.searchArgs.openingType === OpeningType.Now) {
      this.isOpenNowActive = false;
      this.searchStateService.setOpeningType(null);
    } else {
      this.isOpenNowActive = !this.isOpenNowActive;
      this.searchStateService.setOpeningType(OpeningType.Now);
    }

    this.applyFilter();
  }

  toggleShopNow() {
    this.eshop$.subscribe(available => {
      if (available === false) {
        return;
      }

      this.searchArgs = this.searchStateService.getCurrentValues();

      if (this.searchArgs.eshop === true) {
        this.isEshopActive = false;
        this.searchStateService.setShopNow(false);
      } else {
        this.isEshopActive = true;
        this.searchStateService.setShopNow(true);
      }

      this.applyFilter();
    });
  }

  resetFilters() {
    this.resetSearchArgs();
    this.searchStateService.setGeoLocation(null);
    this.applyFilter();
  }

  resetSearchArgs() {
    this.isRatingActive = false;
    this.isDistanceActive = false;
    this.isOpenNowActive = false;
    this.isEshopActive = false;

    this.searchStateService.setFilterDefaults();
  }

  initFilters() {
    const { eshop, sort, openingType } = this.searchStateService.getCurrentValues();

    this.isRatingActive = sort === SearchSort.Rating;
    this.isDistanceActive = sort === SearchSort.Distance;
    this.isOpenNowActive = openingType === OpeningType.Now;
    this.isEshopActive = eshop;
  }

  applyFilter() {
    this.searchStateService.setPage(1);
    this.isEshopAvialable();
    this.emitSearchArgs.emit(this.searchStateService.getCurrentValues());
  }
}
