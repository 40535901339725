import { Component, HostListener, Input } from '@angular/core';
import { ListingModel } from '../../../../models/listing.model';
import 'sharer.js';
import { CheckDeviceService } from '../../../../services/check-device.service';
import { UrlHelper } from '../../../../helpers/url-builder.helper';
import { TranslateService } from '@ngx-translate/core';
declare var Sharer: any;

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrl: './share-button.component.scss'
})
export class ShareButtonComponent {
  @Input()
  listing: ListingModel;
  
  isOpen: boolean = false;

  title: string;
  subject: string;
  detailUrl: string;

  constructor(
    public checkDeviceService: CheckDeviceService,
    private urlHelper: UrlHelper,
    private translate: TranslateService){}

  ngOnInit() {
    this.initListingInfo();
  }

  ngAfterViewInit() {
    Sharer.init();
  }

  initListingInfo() {
    this.title = this.listing?.Title;
    this.subject = `${this.listing?.Title}, ${this.translate.instant('Goldenpages')}`;
    this.detailUrl = this.urlHelper.getDetailPageRoute(this.translate.instant('Routes.Detail.ListingDetail'), this.listing?.City, this.listing?.Id, this.listing?.Title, true);
  }

  handleOpen() {
    this.isOpen = !this.isOpen;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (this.isOpen && !(event.target as HTMLElement).closest('.share-button')) {
      this.isOpen = false;
    }
  }
}
