<!-- logo -->
<app-logo 
    [listingId]="parent?.Id"
    [logo]="parent?.Logo"
    [isPaid]="parent?.IsPaid"
    [isTrafficPage]="parent?.IsTrafficPage"
    [trafficExtraContent]="parent?.TrafficLevel >= 3"
    [badges]="badges"
    [showClaimLinkAll]="showClaimLinkAll">
</app-logo>

<!-- title + share, shortlist -->
<app-detail-title 
    [listing]="child" 
    [detailPageType]="detailPageType">
</app-detail-title>

<!-- ratings and reviews info -->
<app-ratings-and-reviews-info
    [listing]="child">
</app-ratings-and-reviews-info>

<ul class="text-gray-400">
    <!-- address -->
    <app-address
        [detailPageType]="detailPageType"
        [pois]="pois"
        [listing]="child">
    </app-address>

    <!-- display serves location -->
    <app-serves-location
        [listing]="child"
    ></app-serves-location>

    <!-- opening hours here -->
    <app-opening-hours
        [listing]="child"
        [open]="false">
    </app-opening-hours>

    <!-- PROC TY PICOVINY MAM V POLI -->
    <!-- website -->
    <app-button
        *ngIf="(webshops.length > 0 && webshops[0]?.hasContact) && (websites.length > 0 && websites[0]?.hasContact)"
        [href]="websites[0].contact[0].value + '?utm_source=fcrmedia&utm_medium=internet&utm_campaign=goudengidspagesdor'"
        [target]="'_blank'"
        [rel]="'noreferrer'"
        [label]="'Website' | translate"
        [icon]="'website-icon'"
        [style]="'link'"
        [trackingAction]="'LinkClick'"
        [trackingCategory]="'DETAIL'"
        [trackingListingId]="child?.Id">
    </app-button>
    
    <!-- email -->
    <app-button 
        *ngIf="requestForms?.enabledRequestForm && (emails.length > 0 && emails[0]?.hasContact)"
        [icon]="'email-icon'"
        [href]="'mailto:' + emails[0].contact[0].value"
        [target]="'_blank'"
        [rel]="'noreferrer'"
        [label]="'Email' | translate"
        [style]="'link'"
        [trackingAction]="'EmailBtnClick'"
        [trackingCategory]="'DETAIL'"
        [trackingListingId]="child?.Id">
    </app-button>
</ul>