<ng-container *ngIf="(blogDetail$ | async) as blogDetail">
    <app-breadcrumb 
        [isBlog]="true" 
        [blogPost]="blogDetail">
    </app-breadcrumb>
    <app-backlink 
        [isBlog]="true"
        [heading]="blogDetail?.headings?.[0]">
    </app-backlink>

    <div class="yp-container md:pb-20 pb-8">

        <div class="grid md:grid-cols-7 md:gap-4 grid-cols-1">

            <!-- left column -->
            <div class="col-span-5">

                <h1 class="md:text-5xl text-3xl text-blue-600 font-bold mb-9">
                    {{ blogDetail?.title }}
                </h1>

                <!-- blog article meta data -->
                <ul class="md:text-sm text-xs flex flex-wrap font-medium mb-10">
                    <li class="flex items-center">
                        <span class="font-normal italic text-gray-250 pr-2">{{ 'Publicate' | translate }}</span>
                        <span>{{ blogDetail?.publishDate }}</span>
                    </li>
                    <li class="flex items-center">
                        <span class="dot-space"></span>
                        <span class="font-normal italic text-gray-250 pr-2">{{ 'Updated' | translate }}</span>
                        <span>{{ blogDetail?.modifiedDate }}</span>
                    </li>
                </ul>

                <!-- blog article cover img -->
                <div class="mb-8">
                    <img [src]="blogDetail?.thumbnailPath"
                        alt="chassis belgique ramen belgie isolatie" class="w-full">
                </div>

                <!-- blog article content -->
                <div class="article__content" 
                    [innerHTML]="blogDetail?.documentBody">
                    <!-- content goes here -->           
                </div>

                <!-- preview of result item (here we can try to use existing component) -->
                <ng-container *ngIf="blogDetail?.uniListingId !== null; else elseNoListingId">
                    <ol>
                        <li app-result-item
                            *ngIf="listingDetail$ | async as listing"
                            [listing]="listing?.listing"
                            [trackingAction]="'MoreInfoClick'"
                            [trackingCategory]="'BLOG'"
                            [trackingListingId]="listing?.listing?.Id"
                            class="result-item">
                        </li>
                    </ol>
                </ng-container>
                <ng-template #elseNoListingId>
                    
                    <div class="bg-blue-100 p-5 flex flex-col items-center rounded-md my-8">
                        <div class="mb-2 text-1xl text-blue-600">
                            <span>
                                {{ 'LookingFor' | translate }} <span class="font-bold">{{ blogDetail?.headings[0] }}</span>
                            </span>
                        </div>
                        <div class="mb-4 text-sm">
                            {{ 'PostalCodeMessage' | translate }}
                        </div>
                        <form [formGroup]="postalCodeForm">
                            <input type="hidden" formControlName="what" />

                            <div class="flex xs:flex-col items-center gap-2">

                                <input class="form-item mb-4 md:mb-0 md:w-auto h-12"
                                    type="text" formControlName="where" id="where"
                                    autocomplete="off"
                                    [placeholder]="'PostalCodePlaceholder' | translate" />

                                <app-button 
                                    [label]="'SearchButton' | translate" 
                                    [style]="'filled-dark'"
                                    [type]="'submit'" 
                                    (click)="onSubmit()"
                                    [trackingAction]="'BlogPostalCodeSearchClick'"
                                    [trackingCategory]="'BLOG'"
                                    [trackingArticleId]="blogDetail?.id"
                                    class="md:ml-2">
                                </app-button>

                            </div>
                            <!-- <div *ngIf="postalCodeForm.controls['where'].invalid && (postalCodeForm.controls['where'].dirty || postalCodeForm.controls['where'].touched)" style="color: red;">
                                {{ 'InvalidPostalCode' | translate }}
                            </div> -->
                        </form>
                    </div>

                </ng-template>

                <div class="flex my-8 xs:flex-col">

                    <!-- author -->
                    <div class="flex items-center" *ngIf="blogDetail?.authorName">
                        <span class="mr-2 text-gray-500 italic">by</span>
                        <span class="font-semibold">{{ blogDetail?.authorName }}</span>
                    </div>

                    <!-- share - social media links -->
                    <!-- TODO: do not have data -->
                    <!-- <div class="flex items-center md:ml-auto mt-2">
                        <span class="ml-2 mr-2 text-gray-500 italic">share on</span>
                        <a onclick="JavaScript:void(0);" class="cursor-pointer overflow-hidden rounded-full"
                            data-sharer="email" data-title="Raamisolatie: wat is het beste type kozijn?"
                            data-subject="Raamisolatie: wat is het beste type kozijn?, Goudengids.be" data-to=""
                            data-url="http://www.goudengids.be:8081/blog/Ramen/428519559/Raamisolatie+wat+is+het+beste+type+kozijn/"
                            [trackingAction]="'BlogShareEmailClick'" [trackingCategory]="'BLOG'" data-tl="[object Object]">
                            <img class="w-10 h-10 p-2 pointer-events-none" style="background-color: #ddd;"
                                src="assets/icons/icons.svg#email-icon_64x64.png" alt="Email">
                        </a>
                        <a onclick="JavaScript:void(0);" class="cursor-pointer ml-2" data-sharer="facebook"
                            data-url="http://www.goudengids.be:8081/blog/Ramen/428519559/Raamisolatie+wat+is+het+beste+type+kozijn/"
                            [trackingAction]="'BlogShareFacebookClick'" [trackingCategory]="'BLOG'" data-tl="[object Object]">
                            <app-icon [icon]="'facebook-rounded-icon'" svgClass="w-10 h-10 rounded-full pointer-events-none"></app-icon>
                        </a>
                        <a onclick="JavaScript:void(0);" class="cursor-pointer ml-2" data-sharer="twitter"
                            data-title="Raamisolatie: wat is het beste type kozijn?" data-hashtags="Goudengids.be"
                            data-url="http://www.goudengids.be:8081/blog/Ramen/428519559/Raamisolatie+wat+is+het+beste+type+kozijn/"
                            [trackingAction]="'BlogShareTwitterClick'" [trackingCategory]="'BLOG'" data-tl="[object Object]">
                            <img class="w-10 h-10 rounded-full p-2 pointer-events-none" style="background-color: #1da1f1;"
                                src="assets/icons/icons.svg#twitter-icon_64x64.png" alt="Twitter">
                        </a>
                        <a onclick="JavaScript:void(0);" class="cursor-pointer ml-2" data-sharer="linkedin"
                            data-url="http://www.goudengids.be:8081/blog/Ramen/428519559/Raamisolatie+wat+is+het+beste+type+kozijn/"
                            [trackingAction]="'BlogShareLinkedinClick'" [trackingCategory]="'BLOG'" data-tl="[object Object]">
                            <app-icon [icon]="'linkedin-rounded-icon'" svgClass="w-10 h-10 rounded-full pointer-events-none"></app-icon>
                        </a>
                    </div> -->

                </div>
            </div>
            
            <!-- right column -->
            <div class="col-span-2 md:pl-20">

                <!-- POPULAR -->
                <ng-container *ngIf="(popularBlogPosts$ | async) as popularBlogPosts">
                    <ng-container *ngIf="popularBlogPosts.length > 0">
                        <!-- popular articles -->
                        <h2 class="text-xl text-blue-600 mb-5 font-medium">
                            {{ 'MostPopular' | translate }}
                        </h2>
                        <div id="popular-post" class="horizontal-list mb-12">
                            <ul class="horizontal-list__items xs:blog-horizontal">

                                <!-- article -->
                                <li class="xs:blog-horizontal__item border-gray-200 mb-4 " *ngFor="let popular of popularBlogPosts">
                                    <a [routerLink]="'/blog/' + popular?.headings?.[0] + '/' + popular?.id + '/' + popular?.title"
                                        [trackingAction]="'BlogMostPopularArticleClick'" 
                                        [trackingCategory]="'BLOG'"
                                        [trackingArticleId]="popular?.id">
                                        <div class="pointer-events-none">
                                            <div class="article__thumbnail mb-3">
                                                <img [src]="popular?.thumbnailPath"
                                                    alt="professionele tuinaanleg">
                                                <span class="headingCategory">{{ popular?.headings?.[0] }}</span>
                                            </div>
                                            <h3 class="xs:whitespace-normal text-blue-600 font-medium text-base">
                                                {{ popular?.title }}
                                            </h3>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </ng-container>
                </ng-container>

                <!-- article sponsor -->
                <ng-container *ngIf="blogDetail?.uniListingId !== null; else noListingPreview">
                    <h2 class="text-xl text-blue-600 mb-5 font-medium">
                        {{ 'SponsorArticle' | translate }}
                    </h2>

                    <div id="blog-sponsor" class="horizontal-list mb-12">
                        <ul class="horizontal-list__items xs:blog-horizontal">

                            <!-- listing -->
                            <app-related-listing
                                *ngIf="listingDetail$ | async as listing"
                                [listing]="listing?.listing"
                                [showLogo]="listing?.listing?.Logo?.path !== null"
                                [customTrackingAction]="'BlogRelatedAdvertiserClick'"
                                [customTrackingCategory]="'BLOG'">
                            </app-related-listing>
                            
                        </ul>

                        <div class="article-sponsor__banner" 
                            *ngIf="!utils.isNullOrEmpty(blogDetail?.bannerClickthroughUrl) && !utils.isNullOrEmpty(blogDetail?.bannerImagePath)">
                            <a [attr.href]="blogDetail?.bannerClickthroughUrl">
                                <img *ngIf="!utils.isNullOrEmpty(blogDetail?.bannerImagePath)" 
                                    [attr.src]="blogDetail?.bannerImagePath" />
                            </a>
                        </div>
                    </div>

                </ng-container>
                
                <!-- related listings -->
                <ng-template #noListingPreview>
                    <ng-container *ngIf="(relatedListings$ | async) as relatedListings">
                        <ng-container *ngIf="relatedListings?.length > 0">
                            <!-- related listings -->
                            <h2 class="text-xl text-blue-600 mb-5 font-medium">
                                {{ 'MightInterested' | translate }}
                            </h2>
                            <div id="blog-sponsor" class="horizontal-list mb-12">
                                <ul class="horizontal-list__items xs:blog-horizontal">

                                    <!-- listing -->
                                    <app-related-listing
                                        *ngFor="let relatedListing of relatedListings"
                                        [listing]="relatedListing"
                                        [customTrackingAction]="'BlogRelatedAdvertiserClick'"
                                        [customTrackingCategory]="'BLOG'">
                                    </app-related-listing>

                                </ul>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-template>

            </div>
        </div>

        <!-- related posts -->
        <div class="border-t border-blue-300 pt-8" 
            *ngIf="(relatedBlogPosts$ | async) as relatedBlogPosts">

            <h2 class="text-xl text-blue-600 mb-5 font-medium">
                {{ 'RelatedArticles' | translate }}
            </h2>

            <ul class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-12">
                
                <article 
                    *ngFor="let article of relatedBlogPosts">
                    <a [routerLink]="shareDataService.getBlogDetailUrl('-', article?.id, article?.title)"
                        [trackingAction]="'BlogArticleClick'"
                        [trackingCategory]="'BLOG'"
                        [trackingArticleId]="article?.id">
                        <div class="pointer-events-none">
                            <div class="article__thumbnail">
                                <img [src]="article?.thumbnailPath" [alt]="'Cover image of' + article?.title">
                            </div>
                            <h1 class="text-1xl font-medium mb-2.5 block">
                                {{ article?.title }}
                            </h1>
                            <div class="text-xs font-semibold mb-2.5">
                                {{ shareDataService.formatDate(article?.publishedDate) }}
                            </div>
                            <div class="text-sm font-normal text-gray-400">
                                <span>{{ article?.thumbnailText }}</span>
                            </div>
                        </div>
                    </a>
                </article>

            </ul>
        </div>
    </div>

    <ng-container *ngIf="relatedCities$ | async as relatedCities">
        <app-internal-links 
            *ngIf="relatedCities?.length > 0"
            [heading]="headingId"
            [relatedCities]="relatedCities">
        </app-internal-links>
    </ng-container>
</ng-container>