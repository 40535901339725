<div class="flex items-center flex-col md:flex-row mb-14" [ngClass]="{'md:flex-row-reverse': type === 'reverse'}">
    <div class="mb-4 md:mb-0 md:w-6/12">
        <img [src]="imageUrl" alt="title" title="title" style="width: 80%; margin: 0 auto;">
    </div>
    <div class="md:ml-auto md:w-6/12">
        <h3 class="font-medium mb-3 text-1xl text-blue-600">
            {{ title }}
        </h3>
        <div class="leading-4 text-sm" [innerHTML]="description"></div>
       <app-button 
            [href]="href"
            [target]="'_blank'"
            [rel]="'noreferrer'"
            [label]="label"
            [style]="'outline'"
            class="inline-block">
        </app-button>
    </div>
</div>