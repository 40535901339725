<!-- header -->
<app-header></app-header>

<main id="main" role="main">

    <!-- pages -->
    <router-outlet />
</main>

<!-- footer -->
<app-footer></app-footer>