export class BlogPostSearchArguments {
    public blogType: BlogType;
    public category: string;
    public id: string;
    public platform: string;
    public skip: number
    public take: number;
}

export enum BlogType {
    AdvertorialFree,
    AdvertorialPaid,
    Article,
    Sponsor
}
