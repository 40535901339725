<div *ngIf="disableRaviews === false" 
    (click)="openReviewForm()"
    class="flex items-center mb-2 cursor-pointer" 
    [trackingAction]="'ReviewClick'"
    [trackingCategory]="'DETAIL'" 
    [trackingListingId]="listing?.Id"
    [title]="'RateThisBusiness' | translate">

    <!-- stars -->
    <app-rating
        [ratingAverage]="avgRatingRounded">
    </app-rating>

    <!-- avg -->
    <div class="active font-semibold ml-2 mr-2 text-blue-200" *ngIf="this.isAverageBiggerThanZero()">
        <span>{{ avgRatingRounded }}</span>
    </div>

    <!-- count -->
    <span class="text-sm text-gray-400" *ngIf="listing?.RatingCount > 0">({{ listing?.RatingCount }})</span>
</div>