import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ParkingType } from "../enums/parking-info.enum";

export interface ParkingInfo {
    type?: string;
    maxStay?: string;
    tarif?: string;
    hours?: string;
}

export interface ParkingRules {
    days: number[];
    maxStay: number | null;
    hours: string[] | null;
    type: ParkingType;
    displayNotPayable: boolean;
    prices: {
        [key: string]: number;
        freeTime: number;
    } | null;
    hasException: boolean;
    canPayWithSeety: boolean;
}

export interface ParkingInfoData {
    rules?: ParkingRules | null;
    mapURL?: string | null;
    status: string;
}

@Injectable({
    providedIn: 'root',
})
export class ParkingInfoHelper {

    constructor(private translate: TranslateService) { }

    public translateType(type: ParkingType): string {
        if (!type) {
            return null;
        }

        switch (type) {
            case ParkingType.Paid:
                return this.translate.instant('ParkingInfoTypePaid');
            case ParkingType.Disc:
                return this.translate.instant('ParkingInfoTypeDisc');
            case ParkingType.Free:
                return this.translate.instant('ParkingInfoTypeFree');
            case ParkingType.Resident:
                return this.translate.instant('ParkingInfoTypeResident');
            case ParkingType.Pedestrian:
                return this.translate.instant('ParkingInfoTypePedestrian');
            default:
                return this.translate.instant('ParkingInfoTypeNoparking');
        }
    }

    public getMaxStay(maxStay: number): string {
        const hour: number = 60;
        if (!maxStay)
            return this.translate.instant('ParkingInfoMaxUnlimited');

        return maxStay < hour ? maxStay + "m" : maxStay / hour + "h";
    }

    public getTarif(pricesObj: any): string {
        if (!pricesObj) {
            return null;
        }

        let prices: string = "";
        Object.keys(pricesObj).forEach((key) => {
            const time: number = parseInt(key);
            prices += (!isNaN(time)) ? " " + pricesObj[key] + " euro (" + time / 60 + "h)" : "";
        });

        return prices;
    }

    public getHours(hoursArr: string[]): string | null {
        if (!hoursArr || hoursArr.length === 0) {
            return null;
        }

        const hours: string[] = [];
        for (const hour of hoursArr) {
            if (hour.charAt(0) === "0") {
                hours.push(hour.substring(1));
            } else {
                hours.push(hour);
            }
        }

        if (hours.length > 2) {
            const first = `${hours[0]}-${hours[1]}`;
            const second = `${hours[2]}-${hours[3]}`;
            return `${first} ${second}`;
        }

        return `${hours[0]}-${hours[1]}`;
    }
}