import { Component, Injector, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Route } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LangSwitch } from '../../../services/lang-switch.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss'
})
export class TermsAndConditionsComponent {

  currentLang: string = '';

  constructor(
    public translate: TranslateService,
    private title: Title,
    private langSwitch: LangSwitch
  ){
    this.langSwitch.setTermsAndConditions()
    this.currentLang = translate.currentLang;
    
    this.translate.get("TermsOfUse").subscribe((x : string) => {
      
      this.title.setTitle(x);
    });
  }
}
