<app-breadcrumb [isBlog]="true"></app-breadcrumb>
<div class="yp-container md:pb-20 pb-8 relative md:pt-12 pt-6">
  <div class="grid md:grid-cols-7 grid-cols-1 md:gap-4" *ngIf="blogsAndCategories$ | async as data">
    <div class="col-span-5">

      <div class="flex md:mb-14 mb-8">

        <h1 class="text-5xl font-semibold text-blue-600">Blog</h1>
        <a id="blog-categories__btn" class="flex items-center filled-light-btn justify-between ml-auto block md:hidden">
          {{ 'Categories' | translate }}
        </a>

      </div>

      <!-- articles -->
      <div id="blog-articles" class="grid md:grid-cols-2 grid-cols-1 md:gap-x-12 gap-y-8">

        <app-blog-thumb *ngFor="let blogPost of data.blogPosts;" [article]="blogPost" [date]="blogPost?.modifiedDate"
          [excerpt]="blogPost?.thumbnailText">
        </app-blog-thumb>

      </div>

      <app-pagination 
        *ngIf="data.found > 0" 
        (emitPageNum)="switchPage($event)"
        [pageSize]="rows" 
        [numFound]="data.found">
      </app-pagination>

    </div>

    <!-- categories -->
    <div id="blog-categories" class="col-span-2 md:pl-20 hidden md:block">

      <h2 class="text-xl font-medium mt-6 mb-2.5 text-blue-600">
        {{ 'Categories' | translate}}
      </h2>
      <ul class="mb-11">

        <li class="border-gray-100 text-sm py-1.5 border-b-1">
          <a class="link-btn" routerLink="/blog/all"
          [trackingAction]="'BlogCategoryClick'"
          [trackingCategory]="'BLOG'">{{'AllCategories' | translate}}</a>
        </li>

        <li class="border-gray-100 text-sm py-1.5 border-b-1" *ngFor="let category of data.categories">
          <a class="link-btn" [routerLink]="'/blog/' + escapeURLString(category)"
          [trackingAction]="'BlogCategoryClick'"
          [trackingCategory]="'BLOG'"> {{ category }}</a>
        </li>

      </ul>

    </div>

  </div>
</div>