<section id="social-media" class="border-t mb-10 mt-4" *ngIf="socialLinks.length > 0">
    <h2 class="font-medium mb-4 pt-8 text-1xl">
        {{ 'SocialMedia' | translate }}
    </h2>
    <div class="flex flex-wrap social-media-wrap">
        <ng-container *ngFor="let social of socialLinks">
            <a class="flex h-8 mr-2 p-2 rounded-2 social-icon--{{ social.type }} w-8" 
                [trackingAction]="'SocialLinkClick'"
                [trackingCategory]="'DETAIL'"
                [trackingListingId]="listing?.Id"
                [href]="social.value" 
                [title]="social.type">
                    <img src="assets/icons/shareIcons/{{ social.type }}-icon_64x64.png" 
                        [alt]="social.type" />
            </a>
        </ng-container>
    </div>
</section>