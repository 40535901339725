import { Component, Input, OnInit } from '@angular/core';
import { DebugItem } from '../../models/debug-item.model';
import { ListingModel } from '../../models/listing.model';
import { SearchResults, ShareDataService } from '../../services/share-data.service';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-debug-info',
  templateUrl: './debug-info.component.html',
  styleUrl: './debug-info.component.scss'
})
export class DebugInfoComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('debug')
  data: SearchResults;

  openDebug: boolean = true;

  constructor(
    public shareDataService: ShareDataService,
    public utils: Utils){}

  ngOnInit(): void {
    console.log('DEBUG', this.data)
    console.log("DEBUG", this.listing)
  }
}
