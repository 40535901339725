import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
  products: Product[] = [];

  constructor(public translate: TranslateService){}

  ngOnInit(): void {
    this.fillProducts();
  }

  getFooterMessage() {
    const message : string = this.translate.instant('FooterMessage');
    var date = new Date(); 
    return message.replace('{year}',date.getFullYear().toString())
  }

  fillProducts() {
    this.products.push(new Product('Products_Website','Products_Website_Url'));
    this.products.push(new Product('Products_Sea','Products_Sea_Url'));
    this.products.push(new Product('Products_Seo','Products_Seo_Url'));
    this.products.push(new Product('Products_NetSync','Products_NetSync_Url'));
    this.products.push(new Product('Products_GoudenGids','Products_GoudenGids_Url'));
    this.products.push(new Product('Products_SocialAds','Products_SocialAds_Url'));
    this.products.push(new Product('Products_Sitee_Header','Products_Sitee_Url'));
  }

  
}

class Product {
    title: string = '';
    url: string = '';
    
    constructor(_title: any, _url: any){
      this.title = _title;
      this.url = _url;
    }
}