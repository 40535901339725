import { Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LangTagService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setLanguage(lang: string) {
    this.document.documentElement.lang = lang;
  }
}
