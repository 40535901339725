<div class="border border-gray-200 rounded-2 shadow-small">
    <div class="flex flex-col items-center p-6">
        <app-icon [icon]="headingIcon" viewBox="0 0 50 51" svgClass="w-12 h-12"></app-icon>
        <h2 class="font-medium text-xl">
            {{ this.title }}
        </h2>
    </div>
    <ul class="border-blue-300 border-t p-6">
        <app-heading-link 
            *ngFor="let item of items"
            [href]="this.getUrl(item)"
            [label]="item">
        </app-heading-link>
    </ul>
</div>