import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DebugAuthService {

  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const debug = next.queryParams['debug'];
    const debugMode = localStorage.getItem('debugMode'); // flag in localstorage (maybe seassion is better?)
    if (debug === 'true' && (!debugMode || debugMode === 'false')) {
      this.router.navigate(['/debug'], { queryParams: { returnUrl: state.url } }); // { queryParams: { debug: 'true' } } // returnUrl: state.url, 
      return false;
    }
    return true;
  }
}
