<div class="map-wrapper">
    <div id="canvas"></div>
    <div class="panel">
        <form [formGroup]="listingForm">
            <label>Listing:</label>
            <input type="text" size="10" formControlName="listingId" (keypress)="listingKeyPress($event)" />
        </form>
        <div class="legend">
            <div class="box"><span style="color:#990000">zonings</span></div>
            <div class="box"><span style="color:#000099">locality area (range)</span></div>
            <div class="box"><span style="color:#009900">heading range</span></div>
            <div class="box"><img src="assets/images/wicket/map_centre.png" /><span>searched location centre</span></div>
            <div class="box"><img src="assets/images/wicket/map_zone.png" /><span>listing location</span></div>
        </div>
    </div>
</div>