import { Component } from '@angular/core';
import { CheckDeviceService } from '../../../services/check-device.service';

@Component({
  selector: 'app-top-banners-placeholder',
  templateUrl: './top-banners-placeholder.component.html',
  styleUrl: './top-banners-placeholder.component.scss'
})
export class TopBannersPlaceholderComponent {
  constructor(public checkDeviceService: CheckDeviceService){}
}
