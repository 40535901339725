<div class="md:grid grid-cols-1 md:gap-4 md:grid-cols-12">
    <!-- left column -->
    <div id="left-column" class="col-span-4 flex flex-col relative">
        <div id="listing-info" class="listing-info bg-white sticky-box">
            <div class="listing-info__inner">

                <div class="md:border border-gray-200 p-4 rounded-2 md:shadow-small text-sm">

                    <!-- left column content -->
                    <app-detail-info 
                        [listing]="listing"
                        [detailPageType]="detailPageType"
                        [pois]="pois"
                        [requestForms]="requestForms">
                    </app-detail-info>

                    <!-- buttons -->
                    <app-detail-actions 
                        [listing]="listing"
                        [pois]="pois"
                        [requestForms]="requestForms"
                        [detailPageType]="detailPageType">
                    </app-detail-actions>

                </div>

                <!-- USP -->
                <app-usp [listing]="listing"></app-usp>

                <!-- economic info -->
                <app-financial-info 
                    [listing]="listing"
                    [detailPageType]="detailPageType"
                    [wrap]="true">
                </app-financial-info>

            </div>
        </div>
    </div>

    <!-- Right column -->
    <section id="right-column" class="col-span-8 lg:pl-28 mt-10 md:mt-0">

        <!-- common for all -->
        <app-special-offer
            [listing]="listing">
        </app-special-offer>
        <!-- /common for all -->

        <!-- /could be different per detail type -->
        <app-labels-and-brands 
            [listing]="listing">
        </app-labels-and-brands>
        
        <app-oh-and-map 
            [listing]="listing"
            [detailPageType]="detailPageType"
            [pois]="pois">
        </app-oh-and-map>

        <app-extra-articles 
            [listing]="listing"
            [detailPageType]="detailPageType">
        </app-extra-articles>

        <app-review-display 
            [listing]="listing">
        </app-review-display>

        <app-badges [listing]="listing"></app-badges>
        <app-social-links [listing]="listing"></app-social-links>
        <app-payment-methods [listing]="listing"></app-payment-methods>
        <!-- /could be different per detail type -->

        <!-- common for all -->
        <app-spoken-languages [listing]="listing"></app-spoken-languages>
        <app-extended-categories [listing]="listing"></app-extended-categories>

        <app-parking-info
            [listing]="listing"
            [wrap]="true">
        </app-parking-info>
        <!-- /common for all -->

    </section>

</div>