<section id="categories" class="border-t mb-10 mt-4">
    <h2 class="font-medium mb-4 pt-8 text-1xl">
        {{ 'Categories' | translate }}
    </h2>
    <ul class="flex flex-wrap">
        <li class="mr-2 mb-2.5" *ngFor="let category of categories">
            <a class="category group" [routerLink]="shareDataService.getCategoryLink(category)">
                <span class="group-hover:underline">{{ category }}</span>
            </a>
        </li>
    </ul>
</section>