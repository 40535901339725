import { Component } from '@angular/core';
import { ShortlistService } from '../../services/shortlist.service';

@Component({
  selector: 'app-shorlist-link',
  templateUrl: './shorlist-link.component.html',
  styleUrl: './shorlist-link.component.scss'
})
export class ShorlistLinkComponent {
  constructor(public shortlist: ShortlistService){}
}
