<div id="back-button" class="bg-gray-300 border-b flex h-14 items-center mb-4 md:bg-white md:border-0 md:h-auto md:mb-0">
    <div class="md:py-7 yp-container flex">
        <span class="inline-flex items-center"
            (click)="navigateBack()">
            <app-icon icon="arrow-down-icon" viewBox="0 0 12 9" svgClass="h-2 mr-2 rotate-90 text-blue-200 transform w-2"></app-icon>
            <span class="text-sm text-blue-200 cursor-pointer hover:underline whitespace-nowrap">
                {{ label }}
            </span>
        </span>
    </div>
</div>