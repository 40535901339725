import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Endpoints } from '../../../constants/endpoints';
import { UrlHelper } from '../../../helpers/url-builder.helper';
import { KeyValuePair } from '../../../models/query-results.model';
import { DataService } from '../../../services/data.service';
import { LangSwitch } from '../../../services/lang-switch.service';

export class KeywordRequest {
  letter: string = '';
  take: number = 0;
  skip: number = 0;
}

@Component({
  selector: 'app-keyword-index',
  templateUrl: './keyword-index.component.html',
  styleUrl: './keyword-index.component.scss'
})
export class KeywordIndexComponent implements OnInit {
  take: number = 100;
  letter: string = null;
  page: number;

  result: KeyValuePair<string,number>[];
  request: KeywordRequest = new KeywordRequest();

  alphabet: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  constructor(
    private route: ActivatedRoute,
    private langSwitch: LangSwitch,
    private data: DataService,
    private translate: TranslateService,
    private urlHelper: UrlHelper
  ){}

  ngOnInit(): void {
    this.route.paramMap.subscribe((map) => {
      this.letter = map.get("letter");
      this.page = parseInt(map.get("page"));
      
      this.loadIndex();
      this.langSwitch.setKeywordIndex(this.letter, this.page)
    });
  }

  loadIndex() {
    this.request.letter = this.letter 
    this.request.skip = this.page > 1 ? (this.page - 1) * this.take : 0;
    this.request.take = this.take;
    this.data.post<KeyValuePair<string,number>[]>(Endpoints.KEYWORDS(this.translate.currentLang), this.request).subscribe( x => {
      this.result = x;
      console.log(x);
    })
  }

  getSearchUrl(what: string): string {
    return this.urlHelper.getSearchPageRoute(this.translate.instant('Routes.Search.NormalizedWhatWhereSearch'), what)
  }

  getKeywordUrl(key: string) {
    return this.translate.instant('Routes.Index.KeywordIndex') + `/${key}/1/`;
  }
}