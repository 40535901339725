import { Injectable } from '@angular/core';
import { VerifiedStatus } from '../enums/verified-status.enum';
import { ListingModel } from '../models/listing.model';

@Injectable({
  providedIn: 'root' // This makes the service available throughout the application
})
export class ClaimYourBusinessService {
  private status: VerifiedStatus;
  private isPaid: boolean = false;
  private isClaimed: boolean = false;
  private isManageable: boolean = false;

  constructor() { }

  initialize(listing: ListingModel): void {
    this.status = listing?.VerifiedStatus;
    this.isPaid = listing?.IsPaid;
    this.isClaimed = this.status === VerifiedStatus.VerifiedOpen || this.status === VerifiedStatus.VerifiedClosed;
    this.isManageable = this.status === VerifiedStatus.ManageableBusiness;
  }

  isListingManageable(): boolean {
    return this.isManageable;
  }

  isListingClaimed(): boolean {
    return this.isClaimed;
  }

  showManageLink(): boolean {
    return this.isManageable && !this.isPaid;
  }

  showClaimLink(): boolean {
    return !this.isClaimed && !this.isPaid;
  }

  showClaimLinkAll(): boolean {
    return this.isClaimed || this.showClaimLink() || this.showManageLink();
  }

  displayClaimBadge(): boolean {
    return (this.isPaid || this.isClaimed || this.isManageable);
  }

  displayFreeClaimBadge(): boolean {
    return !this.isPaid && (this.isClaimed || this.isManageable);
  }
}
