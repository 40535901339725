import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListingModel } from '../../../../models/listing.model';
import { CheckDeviceService } from '../../../../services/check-device.service';
import { ClaimYourBusinessService } from '../../../../services/claim-your-business.service';
import { DetailPageType } from '../../../../services/share-data.service';

@Component({
  selector: 'app-detail-title',
  templateUrl: './detail-title.component.html',
  styleUrl: './detail-title.component.scss'
})
export class DetailTitleComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('parent')
  parentListing: ListingModel;

  @Input('detailPageType')
  detailPageType: DetailPageType;

  showClaimLinkAll: boolean = false;

  // sticky title button
  @ViewChild('titleRef') 
  titleRef: ElementRef;

  @ViewChild('stickyButtonRef') 
  stickyButtonRef: ElementRef;

  constructor(
    private claimService: ClaimYourBusinessService,
    public checkDeviceService: CheckDeviceService) {}

  ngOnInit(): void {
    this.claimService.initialize(this.listing);
    this.showClaimLinkAll = this.claimService.showClaimLinkAll();
  }

  ngAfterViewInit() {
    this.setupScrollListener();
  } 

  setupScrollListener() {
    if (!this.titleRef || !this.stickyButtonRef) {
      return;
    }

    const HEADER_HEIGHT = 81;
    const el = this.titleRef.nativeElement as HTMLElement;
    const stickyButton = this.stickyButtonRef.nativeElement;
    const elBoundingBox = el.getBoundingClientRect();
    const elHeight = elBoundingBox.height;
    const elPosition = elBoundingBox.top - HEADER_HEIGHT;

    if (window.innerWidth <= 767) {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset >= elPosition + elHeight) {
          if (stickyButton.classList.contains('hidden')) {
            stickyButton.classList.remove('hidden');
          }
        } else {
          if (!stickyButton.classList.contains('hidden')) {
            stickyButton.classList.add('hidden');
          }
        }
      });
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}