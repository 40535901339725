import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-text-image',
  templateUrl: './text-image.component.html',
  styleUrl: './text-image.component.scss'
})
export class TextImageComponent {
  @Input('imageUrl')
  imageUrl: string = "";

  @Input('title') 
  title: string = "";

  @Input('description') 
  description: string = "";

  @Input('label') 
  label: string = "";

  @Input('href') 
  href: string = "";

  @Input('type') 
  type: 'reverse' | "" = "";

  constructor(translate: TranslateService) { }
}
