import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from './modal.service';
import { Options } from './modal-options';
import { Observable, fromEvent, zip } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class ModalComponent implements AfterViewInit {
  @ViewChild('modal') modal!: ElementRef<HTMLDivElement>;
  @ViewChild('overlay') overlay!: ElementRef<HTMLDivElement>;

  @Output() 
  viewInitialized = new EventEmitter<void>();

  options!: Options | undefined;
  modalAnimationEnd!: Observable<Event>;
  modalLeaveAnimation!: string;
  overlayLeaveAnimation!: string;
  overlayAnimationEnd!: Observable<Event>;
  modalLeaveTiming!: number;
  overlayLeaveTiming!: number;
  @Input('listingId')
  listingId: string;

  constructor(
    private modalService: ModalService,
    private element: ElementRef
  ) { }

  @HostListener('document:keydown.escape')
  onEscape() {
    this.modalService.close();
  }

  onClose() {
    // outside click
    this.modalService.close();
  }

  ngAfterViewInit() {
    this.options = this.modalService.options;
    this.listingId = this.modalService.getListingId();
    this.addOptions();
    this.viewInitialized.emit();
  }

  addOptions() {
    // Add classes
    if (this.options?.classes) {
      for (const className of this.options.classes) {
        this.modal.nativeElement.classList.add(className);
      }
    }

    // Add styles
    const modalStyle = this.modal.nativeElement.style;
    const size = this.options?.size;

    modalStyle.minWidth = size && size.minWidth !== '' ? size.minWidth : 'auto';
    modalStyle.width = size && size.width !== '' ? size.width : 'auto';
    modalStyle.maxWidth = size && size.maxWidth !== '' ? size.maxWidth : 'auto';
    modalStyle.minHeight = size && size.minHeight !== '' ? size.minHeight : 'auto';
    modalStyle.height = size && size.height !== '' ? size.height : 'auto';
    modalStyle.maxHeight = size && size.maxHeight !== '' ? size.maxHeight : 'auto';
  }

  close() {
    this.modalService.options = undefined;
    this.element.nativeElement.remove();
  }
}