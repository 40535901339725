import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListingModel } from '../../../../models/listing.model';
import { Badge, ShareDataService } from '../../../../services/share-data.service';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrl: './badges.component.scss'
})
export class BadgesComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('singleBadge')
  singleBadge: boolean = false;

  badges: Badge[];
  currentLang: string;
  hasBadges: boolean = false;

  constructor(
    private translate: TranslateService,
    private shareDataService: ShareDataService){}

  ngOnInit(){
    this.badges = this.shareDataService.getRatingBadges(this.listing);
    this.hasBadges = this.badges?.length > 0;

    if (this.singleBadge && this.hasBadges) {
      this.badges = [this.badges[0]];
    }
    this.currentLang = this.translate.currentLang;
  }
}
