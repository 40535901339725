import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListingModel } from '../../../../models/listing.model';

@Component({
  selector: 'app-spoken-languages',
  templateUrl: './spoken-languages.component.html',
  styleUrl: './spoken-languages.component.scss'
})
export class SpokenLanguagesComponent {
  @Input('listing')
  listing: ListingModel;

  translateLanguages: { [key: string]: string };
  languages: { [key: string]: string };

  constructor(private translate: TranslateService) {
    this.translateLanguages = {
      "Dutch": "Nederlands",
      "French": "Français",
      "English": "English",
      "German": "Deutsch",
      "Polish": "Polski",
      "Italian": "Italiano",
      "Ukrainian": "Українська",
      "Spanish": "Español",
      "Turkish": "Türkçe",
      "Chinese": "中国人",
      "Russian": "русский",
      "Arabic": "عربي",
      "Hebrew": "עִברִית"
    };

    this.languages = {
      "Arabic": this.translate.instant('spokenLanguage_Arabic'),
      "Chinese": this.translate.instant('spokenLanguage_Chinese'),
      "Dutch": this.translate.instant('spokenLanguage_Dutch'),
      "English": this.translate.instant('spokenLanguage_English'),
      "French": this.translate.instant('spokenLanguage_French'),
      "German": this.translate.instant('spokenLanguage_German'),
      "Hebrew": this.translate.instant('spokenLanguage_Hebrew'),
      "Italian": this.translate.instant('spokenLanguage_Italian'),
      "Polish": this.translate.instant('spokenLanguage_Polish'),
      "Russian": this.translate.instant('spokenLanguage_Russian'),
      "Spanish": this.translate.instant('spokenLanguage_Spanish'),
      "Turkish": this.translate.instant('spokenLanguage_Turkish'),
      "Ukrainian": this.translate.instant('spokenLanguage_Ukrainian')
    }

  }
}
