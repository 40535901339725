import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { TermsAndConditionsComponent } from './pages/static/terms-and-conditions/terms-and-conditions.component';
import { ReviewPolicyComponent } from './pages/static/review-policy/review-policy.component';
import { PrivacyPolicyComponent } from './pages/static/privacy-policy/privacy-policy.component';
import { SecurityCaptchaComponent } from './pages/static/security-captcha/security-captcha.component';
import { BlogComponent } from './pages/blog/blog.component';
import { AddYourBusinessComponent } from './pages/static/add-your-business/add-your-business.component';
import { ClaimYourBusinessComponent } from './pages/static/claim-your-business/claim-your-business.component';
import { SearchComponent } from './pages/search/search.component';
import { DetailComponent } from './pages/detail/detail.component';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import { BlogDetailComponent } from './pages/blog/components/blog-detail/blog-detail.component';
import { blogDetailMatcher, dynamicSearchMatcher, listingFolderDetailMatcher, normalizedSearchMatcher } from './matchers/regex.matcher';
import { ShortlistComponent } from './pages/shortlist/shortlist.component';
import { AzIndexComponent } from './pages/static/az-index/az-index.component';
import { KeywordIndexComponent } from './pages/static/keyword-index/keyword-index.component';
import { CategoryIndexComponent } from './pages/static/category-index/category-index.component';
import { CookiesComponent } from './pages/static/cookies/cookies.component';
import { AuthGuard } from './pages/debug/debug-login/debug-guard.guard';
import { DebugLoginComponent } from './pages/debug/debug-login/debug-login.component';
import { VisualizerComponent } from './pages/debug/visualizer/visualizer.component';

const routes: Routes = [
  // Homepage
  { path: '', component: HomeComponent, pathMatch: 'full', data: { pageType: 'home'}},
  // ListingFolderDetail
  { matcher: listingFolderDetailMatcher, component: DetailComponent, canActivate: [AuthGuard] },
  // ListingDetail
  { path: 'bedrijf/:city/:id/:title', component: DetailComponent, canActivate: [AuthGuard] },
  { path: 'company/:city/:id/:title', component: DetailComponent, canActivate: [AuthGuard] },
  { path: 'entreprise/:city/:id/:title', component: DetailComponent, canActivate: [AuthGuard] },
  // BlogDetail
  { matcher: blogDetailMatcher, component: BlogDetailComponent, canActivate: [AuthGuard] },
  //BlogIndex
  { path: 'blog', component: BlogComponent, data: { pageType: 'noSearch'}, canActivate: [AuthGuard] },
  { path: 'blog/:category', component: BlogComponent, data: { pageType: 'noSearch'}, canActivate: [AuthGuard] },
  
  // DynamicSearch
  // WhatWhereSearch
  { matcher: dynamicSearchMatcher, component: SearchComponent, data: { pageType: 'dynamic'}, canActivate: [AuthGuard]},

  // Normalized search
  // WhatWhereNormalizedSearch
  { matcher: normalizedSearchMatcher, component: SearchComponent, data: { pageType: 'normalized'}, canActivate: [AuthGuard]},

  // ShortListSearch
  { path: 'shortlist', component: ShortlistComponent, canActivate: [AuthGuard]},

  // LocalSearch
  { path: 'Search/LokaleResultaten/:what/:where', component: SearchComponent, canActivate: [AuthGuard]},
  { path: 'Search/LocalResults/:what/:where', component: SearchComponent, canActivate: [AuthGuard]},
  { path: 'Search/ResultatsLocaux/:what/:where', component: SearchComponent, canActivate: [AuthGuard]},
  { path: 'Search/LokaleResultaten/:what/:where/:page', component: SearchComponent, canActivate: [AuthGuard]},
  { path: 'Search/LocalResults/:what/:where/:page', component: SearchComponent, canActivate: [AuthGuard]},
  { path: 'Search/ResultatsLocaux/:what/:where/:page', component: SearchComponent, canActivate: [AuthGuard]},

  // Index
  // KeywordIndex
  { path: 'trefwoorden/:letter/:page', component: KeywordIndexComponent},
  { path: 'keywords/:letter/:page', component: KeywordIndexComponent},
  { path: 'mots-cles/:letter/:page', component: KeywordIndexComponent},
  { path: 'trefwoorden', redirectTo: 'trefwoorden/A/1'},
  { path: 'keywords', redirectTo: 'keywords/A/1'},
  { path: 'mots-cles', redirectTo: 'mots-cles/A/1'},
  // CategoryIndex
  { path: 'categorieen/:letter/:page', component: CategoryIndexComponent},
  { path: 'categories/:letter/:page', component: CategoryIndexComponent},
  { path: 'les-categories/:letter/:page', component: CategoryIndexComponent},
  { path: 'categorieen', redirectTo: 'categorieen/A/1'},
  { path: 'categories', redirectTo: 'categories/A/1'},
  { path: 'les-categories', redirectTo: 'les-categories/A/1'},
  // CompaniesIndex
  { path: 'index-az', component: AzIndexComponent},
  { path: 'index-az/:level1', component: AzIndexComponent},
  { path: 'index-az/:level1/:level2', component: AzIndexComponent},

  // Static pages
  // PrivacyPolicy
  { path: 'privacy', component: PrivacyPolicyComponent},
  // TermsAndConditions
  { path: 'juridisch', component: TermsAndConditionsComponent, data: {lang: 'nl'}},
  { path: 'legal', component: TermsAndConditionsComponent, data: {lang: 'en'}},
  { path: 'juridique', component: TermsAndConditionsComponent, data: {lang: 'fr'}},
  // CookiePolicy
  { path: 'cookie', component: CookiesComponent, data: {lang: 'nl'}},
  { path: 'cookie', component: CookiesComponent, data: {lang: 'en'}},
  { path: 'cookie', component: CookiesComponent, data: {lang: 'fr'}},
  // ReviewPolicy
  { path: 'reviewbeleid', component: ReviewPolicyComponent, data: {lang: 'nl'}},
  { path: 'reviewpolicy', component: ReviewPolicyComponent, data: {lang: 'en'}},
  { path: 'politique-de-gestion-des-avis', component: ReviewPolicyComponent, data: {lang: 'fr'}},
  // AddYourBusiness
  { path: 'bedrijftoevoegen', component: AddYourBusinessComponent, data: {lang: 'nl', pageType: 'noSearch'}},
  { path: 'addyourbusiness', component: AddYourBusinessComponent, data: {lang: 'en', pageType: 'noSearch'}},
  { path: 'ajouterentreprise', component: AddYourBusinessComponent, data: {lang: 'fr', pageType: 'noSearch'}},
  // ClaimYourBusiness
  { path: 'mijnbedrijfsprofiel', component: ClaimYourBusinessComponent, data: {lang: 'nl', pageType: 'noSearch'}},
  { path: 'claimyourbusiness', component: ClaimYourBusinessComponent, data: {lang: 'en', pageType: 'noSearch'}},
  { path: 'monprofilprofessionnel', component: ClaimYourBusinessComponent, data: {lang: 'fr', pageType: 'noSearch'}},
  // ManageYourBusiness
  //{ path: 'mijnbedrijfbeheren', component: , data: {lang: 'nl'}},
  //{ path: 'manageyourbusiness', component: , data: {lang: 'en'}},
  //{ path: 'gerermonentreprise', component: , data: {lang: 'fr'}},
  //SecurityCaptcha
  { path: 'captcha', component: SecurityCaptchaComponent},

  //404 Not found
  //{ path: "PageNotFound', component: ,}

  // debug
  { path: 'debug', component: DebugLoginComponent },

  // visualizer
  { path: 'visualizer', component: VisualizerComponent },

  { path: '**', redirectTo: '/'  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }), CommonModule, ModalComponent],
  exports: [RouterModule]
})
export class AppRoutingModule { }
