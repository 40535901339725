import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ListingModel } from '../../../../models/listing.model';
import { modalOptions } from '../../../../components/modal/modal-options-values';
import { ModalService } from '../../../../components/modal/modal.service';
import { ReviewFormComponent } from '../review-form/review-form.component';
import { SpecialActions } from '../../../../enums/special-actions.enum';
import { SpecialActionsHelper } from '../../../../helpers/special-actions.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-review-display',
  templateUrl: './review-display.component.html',
  styleUrl: './review-display.component.scss'
})
export class ReviewDisplayComponent implements OnInit {
  @Input("listing")
  listing: ListingModel;

  @Input("childListing")
  childListing: ListingModel;

  ratingAverage: string;
  avgRatingRounded: string;
  ratingCount: number;
  listingId: string;

  locale: string = 'be_nl';

  stars: string[] = [];

  modalOptions = {};

  disableReviews: boolean = false;

  constructor(
    public translate: TranslateService,
    private modalService: ModalService,
    private specialActionsHelper: SpecialActionsHelper) {
  }

  ngOnInit(): void {
    this.disableReviews = this.specialActionsHelper.hasAction(this.listing, SpecialActions.DisableReviews);
    //dummy call to wait for translations
    this.translate.get('init').subscribe((text: string) => {
      this.locale = `be_${this.translate.currentLang}`;
      if (this.translate.currentLang === 'en')
        this.locale = 'en'
      this.load();

      this.stars = this.getStarClass();
    });
  }

  load(): void {
    this.listingId = this.listing?.Id;
    this.modalOptions = {...modalOptions, listingId: this.listingId };
    this.ratingAverage = this.listing?.RatingAverage;
    this.ratingCount = this.listing?.RatingCount;
    this.avgRatingRounded = parseFloat(this.listing?.RatingAverage).toFixed(1);

    const configItem = environment.MFE.RatingReviewDisplay;
    const content = document.getElementById('review-display-inner');

    const script = document.createElement('script');
    script.src = configItem.path;
    script.onerror = () => console.error('error loading <span class="hljs-subst">', configItem.path, '</span>');
    content.appendChild(script);

    const element: HTMLElement = document.createElement(configItem.name);
    //element.addEventListener('message', msg => this.handleMessage(msg));
    // Set attributes
    element.setAttribute('id', 'review-display')
    element.setAttribute('lid', this.listingId);
    element.setAttribute('platform', 'Yellow');
    element.setAttribute('enable', (this.disableReviews === false).toString());
    element.setAttribute('locale', this.locale);
    content.appendChild(element);
  }

  openReviewForm() {
    this.modalService.open(ReviewFormComponent, this.modalOptions);
  }

  getStarClass(): string[] {
    for (let i = 0; i < 5; i++) {
      const rating = Number.parseInt(this.ratingAverage) - i;
      this.stars.push(rating > 0.95 ? 'text-blue-200' : 'text-gray-100');
    }

    return this.stars;
  }
}
