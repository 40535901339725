<li id="language-switch" class="relative h-full">
    
    <!-- selected lang -->
    <div (click)="handleOpenLanguageSwitch()" 
        class="btn btn--filled btn--light flex gap-2 items-center justify-between lg:px-4 px-1.5 h-full">
        <span class="lg:text-base text-sm uppercase">{{ selectedLanguage }}</span>
        <app-icon [icon]="'arrow-down-icon'" [ngClass]="{'rotate-180': isOpen}" svgClass="w-3 h-2 transform translation"></app-icon>
    </div>

    <!-- possible languages -->
    <ul class="absolute border-t-0 btn btn--filled btn--light block items-start pt-0 top-3/4 w-full"
        *ngIf="isOpen">
        <li *ngFor="let language of filteredLanguages">
            <span 
                (click)="handleSelectLanguage(language)"
                class="block hover:underline uppercase" 
                rel="nofollow">
                {{ language }}
            </span>
        </li>
    </ul>

</li>