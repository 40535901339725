import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { SuggersterResponse } from '../../models/suggester-response.model';

@Component({
  selector: 'app-suggester',
  templateUrl: './suggester.component.html',
  styleUrl: './suggester.component.scss'
})
export class SuggesterComponent {
  @Input('suggestions')
  suggestions: SuggersterResponse[];

  @Input('field')
  field: HTMLInputElement

  @Output() 
  suggestionSelected = new EventEmitter<string>();

  @ViewChild('whatField') 
  whatField: ElementRef;

  showSuggestions: boolean = false;
  highlightedIndex: number = -1;

  constructor() { }

  selectSuggestion(suggestion: string): void {
    this.suggestionSelected.emit(suggestion);
  }

  showSuggestion() {
    this.showSuggestions = true;
  }

  hideSuggestion() {
    this.showSuggestions = false;
    this.highlightedIndex = -1;
    this.field.blur();
  }

  scrollOnArrowClick(index: number, event: KeyboardEvent) {
    this.highlightedIndex = index;
    this.scrollToHighlighted();
    this.selectSuggestion(this.suggestions?.[this.highlightedIndex]?.term);
    event.preventDefault();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.showSuggestions || !this.suggestions || this.suggestions.length === 0) {
      return;
    }

    if (event.key === 'ArrowDown') {
      const index = (this.highlightedIndex + 1) % this.suggestions.length;
      this.scrollOnArrowClick(index, event);
    } else if (event.key === 'ArrowUp') {
      const index = (this.highlightedIndex - 1 + this.suggestions.length) % this.suggestions.length;
      this.scrollOnArrowClick(index, event);
    } else if (event.key === 'Enter') {
      if (this.highlightedIndex >= 0 && this.highlightedIndex < this.suggestions.length) {
        this.selectSuggestion(this.suggestions[this.highlightedIndex].term);
        this.hideSuggestion();
        event.preventDefault();
      }
    }
  }

  scrollToHighlighted() {
    const list = document.querySelector('.autocomplete-suggestions');
    const item = list?.children[this.highlightedIndex] as HTMLElement;
    if (item) {
      item.scrollIntoView({ block: 'nearest' });
    }
  }
}
