<app-button 
    (click)="openModalTemplate(planRouteModal)"
    [target]="'_blank'"
    [rel]="'noreferrer'"
    [label]="label || 'ShowMap' | translate"
    [showIcon]="showIcon"
    [icon]="'plan-route-icon'"
    [btnBorder]="btnBorder"
    [style]="style || 'outline'">
</app-button>
<ng-template #planRouteModal>
    <app-map 
        [id]="'modal-map'" 
        [mode]="'modal'" 
        [detailPageType]="detailPageType"
        [pois]="pois"></app-map>
</ng-template>
