const BASE = "https://www.goudengids.be";
const BASE_API = "https://search-api.fcrmedia.com";

export const BASE_URL = `${BASE_API}/api/v1`;

export class  Endpoints {
    
    static SPA_ASSETS = () => `${BASE}/assets`;

    /* SEARCH ------------------------------ */
    static SEARCH_LISTINGS = (lang: string) => `/be_${lang}/listings`;
    static SEARCH_EDITORIAL = (lang: string) => `/be_${lang}/cityHeadings`;

    /* DETAIL --------------------------------*/
    static LISTING_DETAIL = (lang: string) => `/be_${lang}/listings/detail`;

    /* BLOGS ------------------------------ */
    static SEARCH_BLOGS = (lang: string) => `/be_${lang}/blogs`;
    static SEARCH_BLOG_POSTS = (lang: string) => `/be_${lang}/blogPosts`;
    static SEARCH_BLOG_SNIPPETS = (lang: string) => `/be_${lang}/blogs/snippets`;
    static SEARCH_BLOG_POPULAR = (lang: string) => `/be_${lang}/blogPosts/popular`;
    static SEARCH_BLOG_EXTRA = (lang: string) => `/be_${lang}/blogPosts/extra`;
    static SEARCH_BLOG_LISTINGS = (lang: string) => `/be_${lang}/listings/related`;
    static SEARCH_BLOG_RELATED = (lang: string) => `/be_${lang}/blogPosts/related`;
    static SEARCH_BLOG_CITIES = (lang: string) => `/be_${lang}/listings/relatedCities`;
    
    /* SUGGESTER ------------------------------ */
    static SUGGESTER = (lang: string) => `/be_${lang}/listings/suggest`;

    static SHORTLIST = (lang: string) => `/be_${lang}/shortlist`;

    /* STATIC PAGES ----------------------------*/
    static AZ_INDEX = (lang: string) => `/be_${lang}/index`;
    static CATEGORIES = (lang: string) => `/be_${lang}/categories`;
    static KEYWORDS = (lang: string) => `/be_${lang}/keywords`;

    /* PARKING INFO ------------------------------ */
    static PARKING_INFO = (lat: number, lng: number) => `/api/seety/parking/?lat=${lat}&lng=${lng}`;
}