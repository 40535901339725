<div class="flex flex-col h-full" [routerLink]="detailUrl">
    <div class="flex justify-between mb-2.5">
        <h2 class="font-bold truncate w-56">
            {{ listing?.Title }}
        </h2>

        <span class="bg-white border border-blue-300 inline-block px-2 rounded-2 text-sm">
            {{ 'Sponsored' | translate }}
        </span>
    </div>

    <div class="mb-5">
        <img style="max-height: 89px;" [src]="listing?.Logo?.path" *ngIf="listing?.Logo?.path !== undefined && listing?.Logo?.path !== null" [alt]="listing?.Title + 'Logo'" />
    </div>

    <div class="inline-flex mr-auto mt-auto btn btn--outline p-1 md:w-36 w-28">
        <span class="text-sm truncate">
            {{ 'Contact' | translate }}
        </span>
    </div>
</div>