<section id="payment-methods" class="border-t mb-10 mt-4" *ngIf="listing?.PaymentMethods?.length > 0">
    <h2 class="font-medium mb-4 pt-8 text-1xl">
        {{ 'PaymentMethods' | translate }}
    </h2>
    <ul class="flex flex-wrap" data-yext="payment-methods">
        <li *ngFor="let pm of listing?.PaymentMethods" 
            [ngClass]="'payment-icon payment-icon--' + pm.icon.toLowerCase()" 
            [title]="pm?.label">
        </li>
    </ul>
</section>