import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { BASE_URL } from '../constants/endpoints';
import { ActivatedRoute } from "@angular/router";


@Injectable()
export class BaseInterceptor implements HttpInterceptor {
    
    constructor(private route: ActivatedRoute){}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if(req.url.startsWith("http") || req.url.startsWith("https")) {
            return next.handle(req);
        }

        let reqUrl = req.url;
        if (reqUrl.startsWith("/api")) {
            reqUrl = reqUrl.substring(4);
          }

          
        var modifiedReq;
        if(this.route.snapshot.queryParams["debug"] === 'true')
            return next.handle(req.clone({url: `${BASE_URL}${reqUrl}?debug=true`}))

        modifiedReq = req.clone({url: `${BASE_URL}${reqUrl}`})

        return next.handle(modifiedReq);
    }    
}