import { UrlMatchResult, UrlSegment } from "@angular/router";

export function listingFolderDetailMatcher(url: UrlSegment[]): UrlMatchResult {

    if(url.length < 3)
        return null;

    if(!url[0].toString().match("(bedrijf|company|entreprise)"))
        return null;

    if(!url[1].toString().match(/^L\d+$/))
        return null;

    if(!url[2].toString().match(/^L\d+$/))
        return null

    return {consumed: url, posParams: {
        id: url[1],
        childId: url[2],
        title: url[3] ?? null
    }};
}

export function blogDetailMatcher(url: UrlSegment[]): UrlMatchResult {
    if(url.length < 3)
        return null;

    if(!url[0].toString().match("(blog)"))
        return null;

    if(!url[2].toString().match(/\d+/))
        return null;

    const title = url.length == 4 ? url[3] : null;
    if(title)
        return {consumed: url, posParams: {
            category: url[1],
            id: url[2],
            title: url[3] ?? null
        }};

    return {consumed: url, posParams: {
        category: url[1],
        id: url[2]
    }};
}

export function whatWhereNormalizedSearchRedirectMatcher(url: UrlSegment[]): UrlMatchResult {
    if(url.length < 3)
        return null;

    if(!url[0].toString().match("(bedrijven|companies|entreprises)"))
        return null;

    if(!url[2].toString().match(/q_[\w\s]+/))
        return null;

    const page = url.length == 4 ? url[3] : null;
    if(page)
        return {consumed: url, posParams: {
            where: url[1],
            what: url[2],
            page: url[3] ?? null
        }};

    return {consumed: url, posParams: {
        where: url[1],
        what: url[2]
    }};
}

export function whatNormalizedSearchRedirectMatcher(url: UrlSegment[]): UrlMatchResult {
    if(url.length < 3)
        return null;

    if(!url[0].toString().match("(bedrijven|companies|entreprises)"))
        return null;

    if(!url[1].toString().match(/[-]/))
        return null;

    if(!url[2].toString().match(/q_[\w\s]+/))
        return null;

    const page = url.length == 4 ? url[3] : null;
    if(page)
        return {consumed: url, posParams: {
            what: url[2],
            page: url[3] ?? null
        }};

    return {consumed: url, posParams: {
        what: url[2]
    }};
}

export function localSearchRedirectMatcher(url: UrlSegment[]): UrlMatchResult {
    if(url.length < 3)
        return null;

    if(!url[0].toString().match("(bedrijven|companies|entreprises)"))
        return null;

    if(!url[2].toString().match(/qn_[\w\s]+/))
        return null;

    const page = url.length == 4 ? url[3] : null;
    if(page)
        return {consumed: url, posParams: {
            where: url[1],
            what: url[2],
            page: url[3] ?? null
        }};

    return {consumed: url, posParams: {
        where: url[1],
        what: url[2]
    }};
}

export function dynamicSearchMatcher(url: UrlSegment[]): UrlMatchResult {
    if(url.length < 2)
        return null;

    if(!url[0].toString().match("(zoeken|search|trouvez)"))
        return null;

    if(url.length == 2) { // what
        return {consumed: url, posParams: {
            what: url[1]
        }};
    }

    if(url[2].toString().match(/^\d{1,3}$/)) { // what/1
        return {consumed: url, posParams: {
            what: url[1],
            page: url[2] ?? null
        }};
    }

    if(url.length == 3) { // what/where
        return {consumed: url, posParams: {
            what: url[1],
            where: url[2]
        }};
    }

    if(url[3].toString().match(/^\d{1,3}$/)) { // what/where/1
        return {consumed: url, posParams: {
            what: url[1],
            where: url[2],
            page: url[3] ?? null
        }};
    }

    return null
}

export function normalizedSearchMatcher(url: UrlSegment[]): UrlMatchResult {
    if(url.length < 2)
        return null;

    if(!url[0].toString().match("(bedrijven|companies|entreprises)"))
        return null;

    if(url.length == 2) { // what
        return {consumed: url, posParams: {
            what: url[1]
        }};
    }

    if(url[2].toString().match(/^\d+$/)) { // what/1
        return {consumed: url, posParams: {
            what: url[1],
            page: url[2] ?? null
        }};
    }

    if(url.length == 3) { // where/what
        return {consumed: url, posParams: {
            where: url[1],
            what: url[2]
        }};
    }

    if(url[3].toString().match(/^\d+$/)) { // where/what/1
        return {consumed: url, posParams: {
            where: url[1],
            what: url[2],
            page: url[3] ?? null
        }};
    }

    return null
}