import { Component, Input, OnInit, input } from '@angular/core';
import { RelatedCitySearchItem } from '../../models/related-city-search-item.model';
import { TranslateService } from '@ngx-translate/core';
import { ShareDataService } from '../../services/share-data.service';
import { UrlHelper } from '../../helpers/url-builder.helper';
import { SearchArguments } from '../../models/search-arguments.model';
import { SearchStateService } from '../../services/search-state.service';

@Component({
  selector: 'app-internal-links',
  templateUrl: './internal-links.component.html',
  styleUrl: './internal-links.component.scss'
})
export class InternalLinksComponent implements OnInit {
  @Input('relatedCities')
  relatedCities: string[] = [];

  @Input('heading')
  heading: string;

  relatedCityItems: RelatedCitySearchItem[][] = [];

  showRelatedCities: boolean = true;

  whatValue: string;

  searchArgs: SearchArguments = new SearchArguments();

  constructor(
    private translate: TranslateService, 
    private shareDataService: ShareDataService,
    private searchStateService: SearchStateService,
    private urlHelper: UrlHelper) {}

  ngOnInit(): void {
    this.getRelatedCitySearches();
  }

  getRelatedCitySearches() {
    this.searchArgs = this.searchStateService.getCurrentValues();
    this.whatValue = this.searchArgs.what || this.heading;

    if (!this.whatValue && !Array.isArray(this.relatedCities)) {
      this.showRelatedCities = false;
      return;
    }
    
    let url = this.translate.instant("Routes.Search.NormalizedWhatWhereSearch")
    let inWord = this.translate.instant("In");
    let links: RelatedCitySearchItem[] = [];
    
    this.relatedCities?.forEach(x => {
      let item = new RelatedCitySearchItem()
      item.label = `${this.whatValue} ${inWord} ${x}`;
      item.url = this.urlHelper.getSearchPageRoute(url, this.whatValue, x);
      links.push(item);
    });

    const relatedCitiesItems = this.relatedCities?.map(rc => {
      const item = new RelatedCitySearchItem();
      item.label = `${this.whatValue} ${inWord} ${rc}`;
      item.url = this.urlHelper.getSearchPageRoute(url, this.whatValue, rc);
      return item;
    });

    this.relatedCityItems = relatedCitiesItems?.reduce((resultArr, item, index) => { 
        const columnIndex = Math.floor(index / 10);
    
        if (!resultArr[columnIndex]) {
          resultArr[columnIndex] = [];
        }
    
        resultArr[columnIndex].push(item);
        return resultArr;
    }, []);

  }
}
