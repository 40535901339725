<div class="p-8">
    <app-icon (click)="closeModal()" [icon]="'close-icon'"
        svgClass="modal-dialog__close absolute right-2 top-2 z-10 w-4 h-4" style="position: absolute;
    top: 2rem;
    right: 3rem;
    z-index: 1;">
    </app-icon>
    <h4 class="request-message-title text-xl text-blue-600 pb-4 mb-8 border-b">Booking</h4>

    <div #bookingContent></div>
</div>