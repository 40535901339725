
<div class="h-8 horizontal-list overflow-hidden flex-1">
    <div id="filter-list" class="filter-list horizontal-list__items flex overflow-x-scroll gap-2" data-modal-mode="results">

        <div class="item">

            <div
                [ngClass]="{ 'review-filter--active': isRatingActive }"
                (click)="updateSort(rating)" 
                class="review-filter t-f" 
                data-filter="sort=rating" 
                data-type="radio"
                [trackingAction]="'SearchFilterClick'" 
                [trackingCategory]="'SEARCH'">
                <span class="text-center block truncate">
                    {{ 'FilterRating' | translate }}
                </span>
            </div>

        </div>

        <div class="item">

            <div [ngClass]="{ 'review-filter--active': isDistanceActive }" 
                (click)="updateSort(distance)"  
                class="review-filter t-f" 
                [trackingAction]="'SearchFilterClick'" 
                [trackingCategory]="'SEARCH'">
                <span class="text-center block truncate">
                    {{ 'FilterDistance' | translate }}
                </span>
            </div> 

        </div>

        <div class="item">

            <div [ngClass]="{ 'review-filter--active': isOpenNowActive }" 
                (click)="toggleOpenNow()"  
                class="review-filter t-f"
                data-filter="open=now" 
                [trackingAction]="'SearchFilterClick'" 
                [trackingCategory]="'SEARCH'">
                <span class="text-center block truncate">
                    {{ 'FilterOpen' | translate }}
                </span>
            </div> 

        </div>

        <div class="item">

            <div (click)="resetFilters()"  
                class="review-filter t-f"
                [trackingAction]="'SearchFilterClick'" 
                [trackingCategory]="'SEARCH'">
                <span class="text-center block truncate">
                    {{ 'FilterReset' | translate }}
                </span>
            </div> 

        </div>

    </div>
</div>

<div class="item flex gap-2 items-center">
    <app-icon icon="webshop-icon" viewBox="0 0 12 9" svgClass="text-blue-200 w-6 h-5"></app-icon>
    <span class="shrink-0">{{ "ShopNow" | translate }}</span>
    <div (click)="toggleShopNow()"
        [ngClass]="{
            'checkbox-item--active': isEshopActive && (eshop$ | async) === true,
            'disableEshop': (eshop$ | async) === false
        }"
        class="checkbox-item rounded-full flex justify-between shrink-0"
        [trackingAction]="'WebshopFilterClick'"
        [trackingCategory]="'SEARCH'">
        <app-icon [icon]="'check-icon'" svgClass="w-4 h-4 pl-1 text-white"></app-icon>
        <div class="checkbox-item__slider rounded-full"></div>
        <app-icon [icon]="'close-icon'" svgClass="w-3.5 h-4 pr-1 text-white"></app-icon>
        <svg class="pulse" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            *ngIf="loading == false">
            <circle id="Oval" cx="512" cy="512" r="512"></circle>
        </svg>
    </div>

</div>