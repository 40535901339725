import { Injectable, EventEmitter } from '@angular/core';
import { SearchArguments } from '../models/search-arguments.model';
import { SearchStateValues } from './search-state.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  // click on search button
  public handleSearchClick: EventEmitter<SearchStateValues> = new EventEmitter<SearchStateValues>();

  // click on filter button
  public handleFilterClick: EventEmitter<SearchStateValues> = new EventEmitter<SearchStateValues>();

  // click on folder child
  public handleSelectChild: EventEmitter<string> = new EventEmitter<string>();

  // open mobile map
  public handleMobileMapModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  // open map by click on button inside map view
  public handleMapBtnClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }
}