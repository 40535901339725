import { Injectable } from "@angular/core";
import { SearchStateService } from "../services/search-state.service";
import { Meta } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root',
})
export class MetaHelper {

    public addMetaDescription(description: string): void {
        let metaDescription = document.querySelector('meta[name="description"]');
        if (!metaDescription) {
            metaDescription = document.createElement('meta');
            metaDescription.setAttribute('name', 'description');
            document.head.appendChild(metaDescription);
        }
        metaDescription.setAttribute('content', description);
    }
    
    public addCanonicalTag(url: string): void {
        let canonicalLink = document.querySelector('link[rel="canonical"]');
        if (!canonicalLink) {
            canonicalLink = document.createElement('link');
            canonicalLink.setAttribute('rel', 'canonical');
            document.head.appendChild(canonicalLink);
        }
        canonicalLink.setAttribute('href', url);
    }

    public removeMetaDescription(): void {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            document.head.removeChild(metaDescription);
        }
    }
    
    public removeCanonicalTag(): void {
        const canonicalLink = document.querySelector('link[rel="canonical"]');
        if (canonicalLink) {
            document.head.removeChild(canonicalLink);
        }
    }

    removeHtmlTags(text: string): string {
        const div = document.createElement("div");
        div.innerHTML = text;
        return div.textContent || div.innerText || "";
      }
    
      encodeQuotes(text: string): string {
        return text.replaceAll("\"", "&quot;")
      }
}