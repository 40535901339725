import { Injectable } from "@angular/core";
import { BlogPost } from "../models/blog-post.model";
import { BreadcrumbLink } from "../models/breadcrumb-link.model";
import { ListingModel } from "../models/listing.model";
import { UrlHelper } from "./url-builder.helper";

@Injectable({
    providedIn: "root"
})
export class BreadcrumbHelper {
    constructor(private urlHelper: UrlHelper){}
   /**
   * @remarks
   * Returns breadcrumbs for normalized search results
   *
   * @param searchUrl - Translated normalized search url
   * @returns Breadcrumb links
   *
   * @beta
   */
    public getLinks(searchUrl: string, listing: ListingModel, blogPost: BlogPost, keyword: string, blog: boolean, detail: boolean, location: string[]): BreadcrumbLink[] {

        const links: BreadcrumbLink[] = [];

        let category: string | null = null;

        if (listing && listing?.Category.length > 0 ) {
            category = listing?.Category?.[0];
            links.push({ url: this.urlHelper.getSearchPageRoute(searchUrl, category, null), label: category });
        }
    
        if (keyword && (!category ||
            (keyword.toLowerCase() !== category.toLowerCase() &&
                keyword !== this.normalize(category)))) {
            links.push({ url: this.urlHelper.getSearchPageRoute(searchUrl, keyword, null), label: keyword });
        }

        if (detail) {
            if (listing?.Locality2) {
                links.push({ url: this.urlHelper.getSearchPageRoute(searchUrl, keyword, listing?.Locality2), label: listing?.Locality2});
            }
            if (listing?.City) {
                links.push({ url: this.urlHelper.getSearchPageRoute(searchUrl, keyword, listing?.City), label: listing?.City});
            }
            links.push({ url: null, label: listing?.Title });

            return links;
        }

        if (blog) {
            links.push({ url: this.urlHelper.getBlogPageRoute(searchUrl), label: "Blog" });
        }

        if (blogPost) {
            const heading = blogPost?.headings?.[0];
            if (heading) {
                links.push({ url: this.urlHelper.getBlogPageRoute(searchUrl, heading), label: heading });
            }
            links.push({ url: null, label: blogPost?.title });
        }
 
        if (location) {
            const existingUrls = new Set(links.map(link => link.url));
            location
                .filter(l => l)
                .forEach(l => {
                    const url = this.urlHelper.getSearchPageRoute(searchUrl, keyword, l);
                    if (!existingUrls.has(url)) {
                        links.push({ url, label: l });
                        existingUrls.add(url);
                    }
                })
        }
    
        return links;
    }

    private normalize(term: string): string {
        if (!term || term.trim() === '') {
            return term;
        }
    
        return term.toLowerCase();
    }
    
}