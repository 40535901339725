<div class="border border-gray-200 p-4 rounded-2 shadow-small text-sm mt-4"
    *ngIf="listing?.USP?.length > 0">
    <section id="usp">
        <ul class="flex flex-col gap-4">
            <li class="flex gap-4"
                *ngFor="let usp of listing?.USP">
                <app-icon [icon]="'thumbup-icon'" svgClass="h-6 w-10 shrink-0"></app-icon>
                <span class="text-gray-400 words-break">
                    {{ usp }}
                </span>
            </li>
        </ul>
    </section>
</div>