<div class="yp-container card card--flow-y">
    <div class="grid">

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('en')">
            <h1 class="text-2xl">Conditions générales d’utilisation des sites web appartenant à FCR Media Belgium SA</h1>
            <h2>Définitions</h2>
            <p><strong>Contenu</strong>: avis, texte, images, photos, fichiers audio ou vidéo, liens et toute autre forme d’information accessible via le Site. </p>
            <p><strong>Site</strong>: sites Web conçus par FCR, y compris mais sans s’y limiter, <a href="http://www.goudengids.be/">www.goudengids.be</a>, <a href="http://www.pagesdor.be/">www.pagesdor.be</a> et <a href="http://www.goldenpages.be/">www.goldenpages.be</a>. </p>
            <p><strong>Contenu tiers</strong>: contenu mis à disposition sur le Site par une quelconque autre entité que FCR ou les Utilisateurs, telles que les données cédées sous licence par des fournisseurs de données en vue de leur utilisation sur le Site. </p>
            <p><strong>Utilisateurs</strong>: toute personne qui visite, explore ou indexe le Site, ou l’utilise d’une quelconque autre façon. </p>
            <p><strong>Contenu généré par les utilisateurs (UGC)</strong>: contenu ajouté au Site par les Utilisateurs. </p>
            <h2 class="text-xl">Acceptation des conditions</h2>
            <p>En visitant, explorant ou indexant le Site, ou en l’utilisant d’une quelconque autre façon, vous marquez votre accord avec les présentes Conditions générales d’utilisation. </p>
            <p>Le Site est opéré par FCR Media Belgium SA (« FCR »), ayant son siège social à Uitbreidingstraat 82, 2600 Berchem, Belgique. Pour plus d’informations, veuillez utiliser le formulaire de contact disponible sur le site Web <a href="http://www.pagesdor.be/">http://www.pagesdor.be</a>. </p>
            <h2 class="text-xl">Territorialité</h2>
            <p>Vous avez connaissance du fait que les informations fournies sur le Site sont destinées aux Utilisateurs se trouvant sur le territoire belge. </p>
            <h2 class="text-xl">Accès au site</h2>
            <p>Vous reconnaissez et acceptez le fait que le Site fournisse des informations en l’état et en fonction de leur disponibilité, et ce sans garantie expresse ou implicite d’aucune sorte, dans les limites permises par la loi, y compris toute garantie implicite quant à la qualité marchande, à l’adéquation à un usage particulier, à l’absence d’infractions, à la compatibilité, à la sécurité et à la précision du Site. </p>
            <p>Le téléchargement ou l’obtention de données par quelque moyen que ce soit via le Site se font à vos propres risques. </p>
            <p>Vous reconnaissez que le fonctionnement du Site puisse de temps à autre être interrompu pour des raisons indépendantes de la volonté de FCR. Le fonctionnement du Site est subordonné aux limites des technologies sous-jacentes et peut être négativement influencé par les performances du réseau et d’autres agents opérationnels y compris, mais sans s’y limiter, une surcharge, la portée du réseau, l’interruption de connexion, le fonctionnement de votre appareil (mobile) et le maintien de connexions réseau sécurisées. FCR ne pourra être tenue responsable de quelque erreur ou manquement que ce soit, lié à l’utilisation ou au fonctionnement du Site. FCR prévoit de mettre le Site à jour régulièrement et se réserve le droit d’en adapter le contenu à tout moment. Au besoin, FCR pourra interrompre l’accès au Site et à ses services ou y mettre fin pour une durée indéterminée. </p>
            <p>FCR a apporté le plus grand soin à la collecte et à la mise à jour du Contenu fourni sur le Site. Si vous deviez malgré tout constater la présence d’erreurs dans ces informations, nous vous invitons à contacter notre service clientèle par courrier électronique à l’adresse <a href="mailto:info@fcrmedia.be">info&#64;fcrmedia.be</a>. </p>
            <h2 class="text-xl">Utilisation</h2>
            <p>Le Site est mis gratuitement à votre disposition aux seules fins de consultation en ligne et de génération de contenu (UGC). Les informations figurant sur le Site, y compris le contenu généré par les utilisateurs, peuvent uniquement être utilisées à titre personnel. Il est strictement interdit de copier ou d’utiliser le Contenu à d’autres fins, notamment commerciales, telles que l’envoi de publipostages, la création de bases de données, etc., ou de l’exploiter à des fins non personnelles, indépendamment de sa forme, sans le consentement écrit préalable de FCR. </p>
            <p>Vous acceptez d’utiliser ce Site de manière légale et de ne pas enfreindre les présentes Conditions générales d’utilisation, ni les lois applicables ou les droits de tiers. </p>
            <p>En utilisant le Site, vous vous engagez à ne pas utiliser les informations mises à votre disposition sur le Site et/ou par les services de FCR de manière illégale ou susceptible de nuire à FCR ou à ses partenaires.</p>
            <p>Le Site vous permet de rechercher des biens et services et de contacter les entreprises offrant ces biens et services. Vous êtes autorisé à utiliser le Site et les résultats de recherche dans ce but, sauf : </p>
            <ul>
                <li>à des fins illicites ou illégales ;</li>
                <li>dans le but de nuire à, de menacer, d’insulter ou de harceler autrui d’une manière qui porte atteinte à la vie privée de cette personne ou soit (à notre avis) offensante, inacceptable ou dommageable pour FCR ou les entités qui lui sont liées ;</li>
                <li>pour créer, contrôler, valider, actualiser ou adapter vos propres bases de données, rapports, annuaires, listes de clients ou prospects, listes de diffusion, etc., ou ceux de tiers ;</li>
                <li>pour manipuler, actualiser ou modifier le Site de manière à en affecter le fonctionnement ou ; </li>
                <li>d’une manière qui, à notre avis, surcharge de manière déraisonnable ou disproportionnée les systèmes de communication et/ou techniques de FCR ou qui, par des mécanismes automatisés, contrôle ou copie le contenu ou modifie ou tente de modifier le mode de fonctionnement du Site.</li>
            </ul>
            <h2 class="text-xl">Contenu généré par l'utilisateur (UGC)</h2>
            <p>L’Utilisateur consent à n’utiliser sous aucun prétexte la section UGC du Site à des fins illégales ou inappropriées. Il s’engage entre autres : (i) à respecter tous les droits (de propriété intellectuelle) de FCR et de tiers ; (ii) à respecter les droits à la vie privée de tiers (et par conséquent à s’abstenir de mentionner des informations personnelles concernant des tiers) ; (iii) à ne pas enfreindre les lois nationales et internationales ; (iv) à ne pas fournir d’informations illégales, préjudiciables, racistes, offensantes, obscènes, sexistes ou contraires à l’ordre ou à la moralité publics, ou répréhensibles pour quelque autre raison que ce soit ; (v) à ne pas faire de promotion ou de publicité ; et (vi) à ne pas télécharger de fichiers contenant des virus ou tout autre logiciel ou programme nuisible, et à s’abstenir de pirater ou d’endommager le système. </p>
            <p>FCR se réserve le droit de supprimer des informations ou des messages du Site à tout moment et pour quelque raison que ce soit, sans en avertir préalablement l’Utilisateur concerné. FCR n’est pas tenue de motiver sa décision ni d’en informer l’Utilisateur. L’Utilisateur ne peut pas s’opposer à une telle décision. </p>
            <h2 class="text-xl">Droits de propriété intellectuelle</h2>
            <p>Aucune partie ou contenu de ce Site ne peut être reproduit, diffusé ou publié par impression, microfilm, support magnétique, e-mail ou par quelque autre moyen que ce soit sans l’autorisation écrite préalable de FCR (et/ou de ses partenaires dans ce projet), sauf à titre personnel. </p>
            <h1 class="text-2xl">Responsabilité et indemnisation</h1>
            <h2 class="text-xl">Clauses de non-responsabilité générales</h2>
            <p>FCR et ses partenaires ne pourront en aucun cas être tenus responsables des omissions ou autres défauts présents sur le Site ou survenant lors du traitement des données.</p>
            <p>Vous reconnaissez et acceptez que FCR, ses actionnaires, cadres et collaborateurs, ainsi que les entreprises ou organisations liées à FCR, ne sont en aucun cas (dans les limites permises par la loi) responsables des pertes ou dommages suivants, sauf en cas de négligence grave ou d’erreur délibérée de la part de FCR, de ses employés, de ses intermédiaires ou de ses mandataires :</p>
            <ul>
                <li>perte de données</li>
                <li>perte de revenus, de chiffre d’affaires ou de bénéfices escomptés</li>
                <li>perte d’emplois ou de contrats</li>
                <li>perte d’opportunités</li>
                <li>perte de clientèle ou de réputation</li>
                <li>perte subie par des tiers</li>
                <li>perte de temps ou d’efforts</li>
                <li>tout dommage indirect, consécutif, spécifique ou typique découlant de l’utilisation ou de l’incapacité à utiliser le Site, de défaillances ou de retards liés au Site</li>
            </ul>
            <p>Le fait que FCR propose une plate-forme de création de contenu ne signifie pas que FCR est propriétaire de l’UGC, qu’elle l’approuve ou qu’elle accepte ou prétend être l’auteur de l’UGC.</p>
            <p>La responsabilité du Contenu tiers incombe exclusivement au fournisseur de service et/ou à la personne qui a publié le contenu sur le Site. FCR se refuse en outre à cautionner ou à s’associer à un(e) quelconque personne, entreprise ou produit suggéré(e) sur le Site. </p>
            <p>FCR ne peut en aucun cas être tenue responsable de l’exactitude, de l’exhaustivité ou de l’adéquation des informations figurant dans la section UGC. Par ailleurs, FCR décline toute responsabilité quant aux dommages qui pourraient résulter de ou être liés à l’utilisation de la section UGC et/ou à l’impossibilité de l’utiliser. L’Utilisateur est exclusivement responsable pour toute plainte déposée par des tiers et devra à ce titre intégralement indemniser et dédommager FCR (frais juridiques et d’avocat inclus). </p>
            <h2 class="text-xl">Clauses de non-responsabilité relatives aux données de géolocalisation fournies par le Site</h2>
            <p>Les endroits renseignés sur les cartes du Site sont basés sur les codes postaux ou une combinaison d’informations d’adresse que FCR reçoit de la part de particuliers, d’entreprises et d’organisations que l’Utilisateur cherche à localiser. Les codes postaux renvoient parfois à des lieux généraux et peu précis, qui ne correspondent donc pas à l’adresse d’un particulier, d’une entreprise ou d’une organisation pouvant être visité(e). FCR recommande par conséquent aux Utilisateurs de vérifier l’adresse exacte auprès de la personne, de l’entreprise ou de l’organisation concernée.</p>
            <p>L’exactitude des itinéraires et planificateurs d’itinéraires proposés sur le Site est tributaire de la précision des technologies employées pour le calcul et la planification d’itinéraires.</p>
            <p>Les itinéraires et planificateurs d’itinéraires sont fournis en l’état. FCR ne garantit pas que les recherches effectuées sur le Site aboutissent au résultat le plus précis, rapide, court ou facile. </p>
            <p>EN UTILISANT CE SERVICE, VOUS RECONNAISSEZ QUE FCR, SES EMPLOYÉS, INTERMÉDIAIRES OU PRESTATAIRES DE SERVICES NE SONT EN AUCUN CAS RESPONSABLES ENVERS VOUS OU DES TIERS DES AMENDES, INFRACTIONS ROUTIÈRES, DOMMAGES, PERTES OU PLAINTES DÉCOULANT DE OU LIÉES À L’UTILISATION DES DONNÉES DE GÉOLOCALISATION FOURNIES SUR LE SITE, SAUF EN CAS DE NÉGLIGENCE GRAVE OU D’ERREUR DÉLIBÉRÉE DE LA PART DE FCR, DE SES EMPLOYÉS, DE SES INTERMÉDIAIRES OU DE SES MANDATAIRES.</p>
            <h2 class="text-xl">Modification des présentes conditions d’utilisation</h2>
            <p>FCR se réserve le droit de modifier les présentes Conditions générales d’utilisation à tout moment, sans préavis et à sa seule discrétion. FCR informera les Utilisateurs des modifications apportées aux Conditions générales d'utilisation par le biais d'avis sur le Site. </p>
            <h2 class="text-xl">Autonomie des dispositions</h2>
            <p>Le caractère illégal, nul ou non applicable de l’une des dispositions des présentes Conditions générales d’utilisation n’affecte en rien la validité ou la force exécutoire des autres dispositions.</p>
            <h2 class="text-xl">Litiges</h2>
            <p>L’application de ces Conditions générales d’utilisation et l’utilisation du Site sont régies par le droit belge. Les cours et tribunaux de l’arrondissement judiciaire d’Anvers (Belgique) sont seuls compétents pour régler les litiges résultant de l’utilisation du Site ou de l’application des présentes Conditions générales d’utilisation. </p>
        </article>

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('fr')">
            <h1 class="text-2xl">Conditions générales d’utilisation des sites web appartenant à FCR Media Belgium SA</h1>
            <h2 class="text-xl">Définitions</h2>
            <p><strong>Contenu</strong>: avis, texte, images, photos, fichiers audio ou vidéo, liens et toute autre forme d’information accessible via le Site. </p>
            <p><strong>Site</strong>: sites Web conçus par FCR, y compris mais sans s’y limiter, <a href="http://www.goudengids.be/">www.goudengids.be</a>, <a href="http://www.pagesdor.be/">www.pagesdor.be</a> et <a href="http://www.goldenpages.be/">www.goldenpages.be</a>. </p>
            <p><strong>Contenu tiers</strong>: contenu mis à disposition sur le Site par une quelconque autre entité que FCR ou les Utilisateurs, telles que les données cédées sous licence par des fournisseurs de données en vue de leur utilisation sur le Site. </p>
            <p><strong>Utilisateurs</strong>: toute personne qui visite, explore ou indexe le Site, ou l’utilise d’une quelconque autre façon. </p>
            <p><strong>Contenu généré par les utilisateurs (UGC)</strong>: contenu ajouté au Site par les Utilisateurs. </p>
            <h2 class="text-xl">Acceptation des conditions</h2>
            <p>En visitant, explorant ou indexant le Site, ou en l’utilisant d’une quelconque autre façon, vous marquez votre accord avec les présentes Conditions générales d’utilisation. </p>
            <p>Le Site est opéré par FCR Media Belgium SA (« FCR »), ayant son siège social à Uitbreidingstraat 82, 2600 Berchem, Belgique. Pour plus d’informations, veuillez utiliser le formulaire de contact disponible sur le site Web <a href="http://www.pagesdor.be/">http://www.pagesdor.be</a>. </p>
            <h2 class="text-xl">Territorialité</h2>
            <p>Vous avez connaissance du fait que les informations fournies sur le Site sont destinées aux Utilisateurs se trouvant sur le territoire belge. </p>
            <h2 class="text-xl">Accès au site</h2>
            <p>Vous reconnaissez et acceptez le fait que le Site fournisse des informations en l’état et en fonction de leur disponibilité, et ce sans garantie expresse ou implicite d’aucune sorte, dans les limites permises par la loi, y compris toute garantie implicite quant à la qualité marchande, à l’adéquation à un usage particulier, à l’absence d’infractions, à la compatibilité, à la sécurité et à la précision du Site. </p>
            <p>Le téléchargement ou l’obtention de données par quelque moyen que ce soit via le Site se font à vos propres risques. </p>
            <p>Vous reconnaissez que le fonctionnement du Site puisse de temps à autre être interrompu pour des raisons indépendantes de la volonté de FCR. Le fonctionnement du Site est subordonné aux limites des technologies sous-jacentes et peut être négativement influencé par les performances du réseau et d’autres agents opérationnels y compris, mais sans s’y limiter, une surcharge, la portée du réseau, l’interruption de connexion, le fonctionnement de votre appareil (mobile) et le maintien de connexions réseau sécurisées. FCR ne pourra être tenue responsable de quelque erreur ou manquement que ce soit, lié à l’utilisation ou au fonctionnement du Site. FCR prévoit de mettre le Site à jour régulièrement et se réserve le droit d’en adapter le contenu à tout moment. Au besoin, FCR pourra interrompre l’accès au Site et à ses services ou y mettre fin pour une durée indéterminée. </p>
            <p>FCR a apporté le plus grand soin à la collecte et à la mise à jour du Contenu fourni sur le Site. Si vous deviez malgré tout constater la présence d’erreurs dans ces informations, nous vous invitons à contacter notre service clientèle par courrier électronique à l’adresse <a href="mailto:info@fcrmedia.be">info&#64;fcrmedia.be</a>. </p>
            <h2 class="text-xl">Utilisation</h2>
            <p>Le Site est mis gratuitement à votre disposition aux seules fins de consultation en ligne et de génération de contenu (UGC). Les informations figurant sur le Site, y compris le contenu généré par les utilisateurs, peuvent uniquement être utilisées à titre personnel. Il est strictement interdit de copier ou d’utiliser le Contenu à d’autres fins, notamment commerciales, telles que l’envoi de publipostages, la création de bases de données, etc., ou de l’exploiter à des fins non personnelles, indépendamment de sa forme, sans le consentement écrit préalable de FCR. </p>
            <p>Vous acceptez d’utiliser ce Site de manière légale et de ne pas enfreindre les présentes Conditions générales d’utilisation, ni les lois applicables ou les droits de tiers. </p>
            <p>En utilisant le Site, vous vous engagez à ne pas utiliser les informations mises à votre disposition sur le Site et/ou par les services de FCR de manière illégale ou susceptible de nuire à FCR ou à ses partenaires.</p>
            <p>Le Site vous permet de rechercher des biens et services et de contacter les entreprises offrant ces biens et services. Vous êtes autorisé à utiliser le Site et les résultats de recherche dans ce but, sauf : </p>
            <ul>
                <li>à des fins illicites ou illégales ;</li>
                <li>dans le but de nuire à, de menacer, d’insulter ou de harceler autrui d’une manière qui porte atteinte à la vie privée de cette personne ou soit (à notre avis) offensante, inacceptable ou dommageable pour FCR ou les entités qui lui sont liées ;</li>
                <li>pour créer, contrôler, valider, actualiser ou adapter vos propres bases de données, rapports, annuaires, listes de clients ou prospects, listes de diffusion, etc., ou ceux de tiers ;</li>
                <li>pour manipuler, actualiser ou modifier le Site de manière à en affecter le fonctionnement ou ; </li>
                <li>d’une manière qui, à notre avis, surcharge de manière déraisonnable ou disproportionnée les systèmes de communication et/ou techniques de FCR ou qui, par des mécanismes automatisés, contrôle ou copie le contenu ou modifie ou tente de modifier le mode de fonctionnement du Site.</li>
            </ul>
            <h2 class="text-xl">Contenu généré par l'utilisateur (UGC)</h2>
            <p>L’Utilisateur consent à n’utiliser sous aucun prétexte la section UGC du Site à des fins illégales ou inappropriées. Il s’engage entre autres : (i) à respecter tous les droits (de propriété intellectuelle) de FCR et de tiers ; (ii) à respecter les droits à la vie privée de tiers (et par conséquent à s’abstenir de mentionner des informations personnelles concernant des tiers) ; (iii) à ne pas enfreindre les lois nationales et internationales ; (iv) à ne pas fournir d’informations illégales, préjudiciables, racistes, offensantes, obscènes, sexistes ou contraires à l’ordre ou à la moralité publics, ou répréhensibles pour quelque autre raison que ce soit ; (v) à ne pas faire de promotion ou de publicité ; et (vi) à ne pas télécharger de fichiers contenant des virus ou tout autre logiciel ou programme nuisible, et à s’abstenir de pirater ou d’endommager le système. </p>
            <p>FCR se réserve le droit de supprimer des informations ou des messages du Site à tout moment et pour quelque raison que ce soit, sans en avertir préalablement l’Utilisateur concerné. FCR n’est pas tenue de motiver sa décision ni d’en informer l’Utilisateur. L’Utilisateur ne peut pas s’opposer à une telle décision. </p>
            <h2 class="text-xl">Droits de propriété intellectuelle</h2>
            <p>Aucune partie ou contenu de ce Site ne peut être reproduit, diffusé ou publié par impression, microfilm, support magnétique, e-mail ou par quelque autre moyen que ce soit sans l’autorisation écrite préalable de FCR (et/ou de ses partenaires dans ce projet), sauf à titre personnel. </p>
            <h1 class="text-2xl">Responsabilité et indemnisation</h1>
            <h2 class="text-xl">Clauses de non-responsabilité générales</h2>
            <p>FCR et ses partenaires ne pourront en aucun cas être tenus responsables des omissions ou autres défauts présents sur le Site ou survenant lors du traitement des données.</p>
            <p>Vous reconnaissez et acceptez que FCR, ses actionnaires, cadres et collaborateurs, ainsi que les entreprises ou organisations liées à FCR, ne sont en aucun cas (dans les limites permises par la loi) responsables des pertes ou dommages suivants, sauf en cas de négligence grave ou d’erreur délibérée de la part de FCR, de ses employés, de ses intermédiaires ou de ses mandataires :</p>
            <ul>
                <li>perte de données</li>
                <li>perte de revenus, de chiffre d’affaires ou de bénéfices escomptés</li>
                <li>perte d’emplois ou de contrats</li>
                <li>perte d’opportunités</li>
                <li>perte de clientèle ou de réputation</li>
                <li>perte subie par des tiers</li>
                <li>perte de temps ou d’efforts</li>
                <li>tout dommage indirect, consécutif, spécifique ou typique découlant de l’utilisation ou de l’incapacité à utiliser le Site, de défaillances ou de retards liés au Site</li>
            </ul>
            <p>Le fait que FCR propose une plate-forme de création de contenu ne signifie pas que FCR est propriétaire de l’UGC, qu’elle l’approuve ou qu’elle accepte ou prétend être l’auteur de l’UGC.</p>
            <p>La responsabilité du Contenu tiers incombe exclusivement au fournisseur de service et/ou à la personne qui a publié le contenu sur le Site. FCR se refuse en outre à cautionner ou à s’associer à un(e) quelconque personne, entreprise ou produit suggéré(e) sur le Site. </p>
            <p>FCR ne peut en aucun cas être tenue responsable de l’exactitude, de l’exhaustivité ou de l’adéquation des informations figurant dans la section UGC. Par ailleurs, FCR décline toute responsabilité quant aux dommages qui pourraient résulter de ou être liés à l’utilisation de la section UGC et/ou à l’impossibilité de l’utiliser. L’Utilisateur est exclusivement responsable pour toute plainte déposée par des tiers et devra à ce titre intégralement indemniser et dédommager FCR (frais juridiques et d’avocat inclus). </p>
            <h2 class="text-xl">Clauses de non-responsabilité relatives aux données de géolocalisation fournies par le Site</h2>
            <p>Les endroits renseignés sur les cartes du Site sont basés sur les codes postaux ou une combinaison d’informations d’adresse que FCR reçoit de la part de particuliers, d’entreprises et d’organisations que l’Utilisateur cherche à localiser. Les codes postaux renvoient parfois à des lieux généraux et peu précis, qui ne correspondent donc pas à l’adresse d’un particulier, d’une entreprise ou d’une organisation pouvant être visité(e). FCR recommande par conséquent aux Utilisateurs de vérifier l’adresse exacte auprès de la personne, de l’entreprise ou de l’organisation concernée.</p>
            <p>L’exactitude des itinéraires et planificateurs d’itinéraires proposés sur le Site est tributaire de la précision des technologies employées pour le calcul et la planification d’itinéraires.</p>
            <p>Les itinéraires et planificateurs d’itinéraires sont fournis en l’état. FCR ne garantit pas que les recherches effectuées sur le Site aboutissent au résultat le plus précis, rapide, court ou facile. </p>
            <p>EN UTILISANT CE SERVICE, VOUS RECONNAISSEZ QUE FCR, SES EMPLOYÉS, INTERMÉDIAIRES OU PRESTATAIRES DE SERVICES NE SONT EN AUCUN CAS RESPONSABLES ENVERS VOUS OU DES TIERS DES AMENDES, INFRACTIONS ROUTIÈRES, DOMMAGES, PERTES OU PLAINTES DÉCOULANT DE OU LIÉES À L’UTILISATION DES DONNÉES DE GÉOLOCALISATION FOURNIES SUR LE SITE, SAUF EN CAS DE NÉGLIGENCE GRAVE OU D’ERREUR DÉLIBÉRÉE DE LA PART DE FCR, DE SES EMPLOYÉS, DE SES INTERMÉDIAIRES OU DE SES MANDATAIRES.</p>
            <h2 class="text-xl">Modification des présentes conditions d’utilisation</h2>
            <p>FCR se réserve le droit de modifier les présentes Conditions générales d’utilisation à tout moment, sans préavis et à sa seule discrétion. FCR informera les Utilisateurs des modifications apportées aux Conditions générales d'utilisation par le biais d'avis sur le Site. </p>
            <h2 class="text-xl">Autonomie des dispositions</h2>
            <p>Le caractère illégal, nul ou non applicable de l’une des dispositions des présentes Conditions générales d’utilisation n’affecte en rien la validité ou la force exécutoire des autres dispositions.</p>
            <h2 class="text-xl">Litiges</h2>
            <p>L’application de ces Conditions générales d’utilisation et l’utilisation du Site sont régies par le droit belge. Les cours et tribunaux de l’arrondissement judiciaire d’Anvers (Belgique) sont seuls compétents pour régler les litiges résultant de l’utilisation du Site ou de l’application des présentes Conditions générales d’utilisation. </p>
        </article>

        <article class="default-article text-sm py-6" *ngIf="this.currentLang.includes('nl')">
            <h1 class="text-2xl">Bepalingen en voorwaarden voor de websites van FCR Media Belgium NV</h1>
            <h2 class="text-xl">Definities </h2>
            <p><strong>Inhoud</strong>: beoordelingen, teksten, beelden, foto’s, audio- of videobestanden, links en elke andere vorm van informatie die op de site ter beschikking wordt gesteld. </p>
            <p><strong>Site</strong>: websites die FCR ontwikkeld heeft, met inbegrip van maar niet beperkt tot <a href="http://www.goudengids.be/">www.goudengids.be</a>, <a href="http://www.pagesdor.be/">www.pagesdor.be</a> en <a href="http://www.goldenpages.be/">www.goldenpages.be</a>. </p>
            <p><strong>Inhoud van derde partijen</strong>: inhoud die op de site door elke andere eenheid dan FCR of de gebruikers ter beschikking wordt gesteld, bijv. gegevens die door de informatieverstrekkers vrijgegeven zijn (licentie) voor gebruik op de site. </p>
            <p><strong>Gebruikers</strong>: elke persoon die de site bezoekt en doorbladert of de site op gelijk welke andere manier gebruikt. </p>
            <p><strong>Door gebruikers gecreëerde inhoud (UGC)</strong>: inhoud die gebruikers aan de site bijdragen. </p>
            <h2 class="text-xl">Toestemming</h2>
            <p>Door de site te bezoeken of te doorbladeren, of door de site op een andere manier te gebruiken, gaat u ermee akkoord om deze 'Bepalingen en voorwaarden' te respecteren. </p>
            <p>De site wordt beheerd door FCR Media Belgium NV (“FCR”), met maatschappelijke zetel in de Uitbreidingstraat 82, 2600 Berchem, België. Voor meer informatie gebruikt u het contactformulier op de website <a href="http://www.goudengids.be/">www.goudengids.be</a>. </p>
            <h2 class="text-xl">Grondgebied</h2>
            <p>U bent er zich van bewust dat de informatie op de site bestemd is voor gebruikers op Belgisch grondgebied. </p>
            <h2 class="text-xl">Toegang tot de site</h2>
            <p>U gaat ermee akkoord en aanvaardt dat de site informatie levert in de vorm waarin de informatie zich op dat moment bevindt, en in de mate waarin de informatie beschikbaar is. U aanvaardt tevens dat de informatie geleverd wordt zonder enige expliciete of impliciete garantie, zover als wettelijk toegelaten, met inbegrip van de impliciete garantie op een voldoende kwaliteit, op de geschiktheid voor een bepaald doel, op de afwezigheid van inbreuken, en op de compatibiliteit, de veiligheid en de nauwkeurigheid van de site. </p>
            <p>Alle gegevens die u van op de site gedownload of op een andere manier verworven hebt, gebruikt u op eigen risico. </p>
            <p>U aanvaardt dat het gebruik van de site af en toe verstoord kan worden door gebeurtenissen die buiten de controle van FCR vallen. De site is onderworpen aan de limieten van de ondersteunende technologie en de werking van de site kan negatief beïnvloed worden door de prestaties van het netwerk en van andere operationele factoren. Dergelijke factoren zijn o.a. overbelasting, netwerkbereik, onderbroken verbindingen, de werking van uw (mobiel) toestel en het onderhoud van beveiligde netwerkverbindingen. FCR is niet verantwoordelijk voor vergissingen of tekortkomingen bij het gebruik of de werking van de site. FCR zal de site geregeld updaten en behoudt zich het recht voor om op elk moment de inhoud ervan aan te passen. Indien nodig kan FCR de toegang tot de site of de diensten ervan onderbreken of de site en de diensten ervan voor een onbepaalde periode stopzetten. </p>
            <p>FCR besteedt de grootste zorg aan het verzamelen en up to date houden van de inhoud op de site. Wanneer u echter een vergissing opmerkt, gelieve dan contact op te nemen met onze klantenafdeling. Dat kan via e-mail op <a href="mailto:info@fcrmedia.be">info&#64;fcrmedia.be</a>. </p>
            <h2 class="text-xl">Gebruik</h2>
            <p>De site is gratis beschikbaar en is uitsluitend bedoeld om online UGC te raadplegen of toe te voegen. De informatie op de site, met inbegrip van UGC, mag alleen voor persoonlijke doeleinden gebruikt worden. Het is strikt verboden om voor andere doeleinden - met inbegrip van, maar niet beperkt tot, commerciële doeleinden zoals mailings, het samenstellen van gegevensbanken, enz. - inhoud te kopiëren of te gebruiken, of om de informatie voor niet-persoonlijke doeleinden, ongeacht de vorm daarvan, te gebruiken zonder een voorafgaande schriftelijke toestemming van FCR. </p>
            <p>U gaat ermee akkoord om deze site op een wettige manier te gebruiken en geen inbreuk te plegen op deze ‘Bepalingen en voorwaarden’, op de van kracht zijnde wetten en op de rechten van derde partijen. </p>
            <p>Door de site te gebruiken, gaat u ermee akkoord om de informatie op de site en/of de informatie die via de diensten van FCR geleverd wordt, niet te gebruiken op een manier die verboden is of die FCR of zijn partners schade kan berokkenen. </p>
            <p>De site stelt u in staat om goederen en diensten op te zoeken en om contact op te nemen met de organisaties die deze goederen en diensten aanbieden. U kunt de site en de resultaten van elke zoekopdracht voor dat doel gebruiken op voorwaarde dat u de site niet gebruikt: </p>
            <ul>
                <li>voor ongeoorloofde of illegale doeleinden; </li>
                <li>om andere personen schade toe te brengen, te bedreigen, te beledigen of lastig te vallen op een manier die een inbreuk vormt op de privacy van die personen of (in onze redelijke mening) uitdagend, onaanvaardbaar of beschadigend is voor FCR of voor bedrijven die met FCR banden hebben; </li>
                <li>om uw eigen gegevensbanken, verslagen, telefoonboeken, lijsten met klanten en potentiële klanten, mailinglijsten of die van anderen aan te leggen, te beheren, uit te breiden, te updaten of aan te passen. </li>
                <li>om gelijk welk onderdeel van de site te manipuleren, te updaten of te wijzigen zodat de werking ervan aangetast wordt; </li>
                <li>op een manier die, in onze ogen, de communicatie- en/of technische systemen van FCR onredelijk of buiten verhouding overbelast, of die via geautomatiseerde middelen de inhoud stuurt of kopieert, of de werking van de site probeert te veranderen. </li>
            </ul>
            <h2 class="text-xl">Door de gebruikers gecreëerde inhoud (UGC) </h2>
            <p>De gebruiker gaat ermee akkoord dat hij onder geen beding het UGC-onderdeel van de site zal gebruiken voor illegale of ongeoorloofde doeleinden. De gebruiker gaat meer bepaald met het volgende akkoord: (i) alle (intellectuele eigendoms)rechten zowel van FCR als van derde partijen te respecteren; (ii) de privacy van derde partijen te respecteren (en er zich dus van te onthouden persoonsgegevens van derde partijen te vermelden); (iii) geen inbreuk op nationale of internationale wetten te plegen; (iv) geen illegale, schadelijke, racistische, agressieve, obscene, seksistische informatie te verspreiden, of informatie die ingaat tegen de openbare orde of de goede zeden, of die om nog andere redenen laakbaar is; (v) geen reclame te voeren en geen advertenties te plaatsen; en (vi) geen bestanden te uploaden die virussen of andere schadelijke software of programma’s bevatten, en het systeem niet te hacken of te beschadigen. </p>
            <p>FCR heeft op elk moment en voor gelijk welke reden het recht om informatie of berichten van zijn site te verwijderen zonder de gebruiker in kwestie op voorhand te verwittigen. FCR hoeft zijn beslissing niet te rechtvaardigen of de verwijdering aan welke gebruiker dan ook te melden. De gebruiker kan niet protesteren tegen de beslissing om een bericht te verwijderen. </p>
            <h2 class="text-xl">Intellectuele eigendomsrechten</h2>
            <p>Onderdelen of inhouden van deze site mogen niet gereproduceerd, overgedragen of openbaar gemaakt worden via druk, microfilm, magnetische dragers, e-mail of andere middelen zonder de voorafgaande schriftelijke toestemming van FCR (en/of zijn partners in dit project), behalve voor persoonlijke doeleinden. </p>
            <h1 class="text-2xl">Aansprakelijkheid en schadevergoeding</h1>
            <h2 class="text-xl">Algemene bepalingen betreffende de aansprakelijkheid </h2>
            <p>FCR en zijn partrners kunnen onder geen enkele voorwaarde aansprakelijk gesteld worden voor weglatingen of andere tekortkomingen in de site of die tijdens de verwerking van gegevens voorkomen. </p>
            <p>U erkent en aanvaardt dat FCR, zijn aandeelhouders, directeurs en personeelsleden, en de bedrijven of organisaties die met FCR banden hebben, onder geen enkele voorwaarde (voor zover wettelijk toegelaten) aansprakelijk zijn voor onderstaande gevallen van verlies of schade, behalve in gevallen van grote nalatigheid of bewuste fouten door FCR of zijn medewerkers, tussenpersonen en vertegenwoordigers: </p>
            <ul>
                <li>gegevensverlies </li>
                <li>verlies van inkomsten, omzet of verwachte winst </li>
                <li>verlies van werk of contracten </li>
                <li>verlies van kansen </li>
                <li>verlies van goodwill of reputatie </li>
                <li>verlies door derde partijen </li>
                <li>verlies van tijd of moeite </li>
                <li>elke indirecte schade, gevolgschade, specifieke of kenmerkende schade voortkomend uit het gebruik of het niet-gebruik van de site, of uit tekortkomingen of vertragingen van de site. </li>
            </ul>
            <p>Het is niet omdat FCR een platform voor UGC aanbiedt, dat FCR de eigenaar van de UGC is, de UGC ondersteunt of aanvaardt, of beweert de redacteur van de UGC te zijn. </p>
            <p>De verantwoordelijkheid voor de inhoud van derde partijen ligt uitsluitend bij de dienst en/of persoon die de inhoud op de site geplaatst heeft. Bovendien ondersteunt FCR geen personen, bedrijven of materiaal die via de site weergegeven worden, en associeert FCR zich daar niet mee. </p>
            <p>FCR is onder geen enkele voorwaarde aansprakelijk voor de nauwkeurigheid, de volledigheid of de geschiktheid van de informatie in de UGC-onderdelen. FCR is evenmin aansprakelijk voor schade die kan voortkomen uit of te maken heeft met het gebruik van het UGC-onderdeel en/of de onmogelijkheid om daar gebruik van te maken. Alleen de gebruiker is aansprakelijk voor claims die derde partijen indienen. De gebruiker zal FCR op dat vlak volledig schadeloos stellen (met inbegrip van gerechtskosten en advocatenlonen). </p>
            <h2 class="text-xl">Bepalingen met betrekking tot de aansprakelijkheid over locatiegegevens die door de site verstrekt worden </h2>
            <p>Locaties op kaarten op de site zijn gebaseerd op postcodes of op een combinatie van adresgegevens die FCR ontvangen heeft van personen, bedrijven of organisaties waarvan de details opgezocht worden. Postcodes kunnen soms alleen maar naar algemene locaties verwijzen. De nauwkeurigheid daarvan is beperkt zodat ze niet altijd overeenstemmen met het feitelijke adres van een persoon, bedrijf of organisatie. FCR raadt daarom zijn gebruikers aan om de precieze ligging te controleren bij de persoon, onderneming of organisatie in kwestie. </p>
            <p>Reisroutes en routeplanners die via de site beschikbaar zijn, zijn maar zo nauwkeurig als de technologie die voor de routes en de routeplanners gebruikt wordt. </p>
            <p>Routes en routeplanners worden uitsluitend aangeboden in de staat waarin ze zich bevinden, en FCR garandeert niet dat de zoektocht die op de site uitgevoerd wordt, de snelste, kortste of gemakkelijkste weg oplevert. </p>
            <p>DOOR DE DIENST TE GEBRUIKEN, GAAT U ERMEE AKKOORD DAT FCR, ZIJN WERKNEMERS, tussenpersonen of dienstverleners op geen enkele manier, ten opzichte van u of gelijk welke derde partij, aansprakelijk zijn voor boetes, verkeersovertredingen, schade, verlies of andere claims die voortvloeien uit uw gebruik van de locatiegegevens die de site geleverd heeft, behalve bij grove nalatigheid of een bewuste fout door FCR, zijn werknemers, tussenpersonen en vertegenwoordigers. </p>
            <h2 class="text-xl">Wijzigingen aan deze ‘bepalingen en voorwaarden’</h2>
            <p>FCR behoudt zich het recht voor om deze ‘Bepalingen en voorwaarden’ op elk moment aan te passen, zonder enige kennisgeving en volgens zijn eigen inzichten. FCR zal de gebruikers over wijzigingen aan de inhoud op de hoogte brengen via aankondigingen op de site. </p>
            <h2 class="text-xl">Opsplitsing</h2>
            <p>Indien een artikel van deze ‘Bepalingen en voorwaarden' ongeldig of onbruikbaar wordt of niet afgedwongen kan worden, tast dat de geldigheid of de afdwingbaarheid van de andere artikels van deze ‘Bepalingen en voorwaarden’ niet aan. </p>
            <h2 class="text-xl">Geschillen</h2>
            <p>De toepassing van deze ‘Bepalingen en voorwaarden’ en het gebruik van de site vallen onder de Belgische wetten. Alleen de rechtbank van Antwerpen (België) is bevoegd om een oordeel te vellen over geschillen die ontstaan over het gebruik van de site of over de toepassing van deze ‘Bepalingen en voorwaarden’.</p>
        </article>
    
    </div>
</div>