import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlHelper } from '../../../../helpers/url-builder.helper';
import { ListingModel } from '../../../../models/listing.model';

@Component({
  selector: 'app-result-item-c',
  templateUrl: './result-item-c.component.html',
  styleUrl: './result-item-c.component.scss'
})
export class ResultItemCComponent implements OnInit {
  @Input() 
  listing: ListingModel;

  detailUrl: string;

  constructor(private urlHelper: UrlHelper,
    private translate: TranslateService) {}
  
  ngOnInit() {
    this.getDetailUrl();
  }

  getDetailUrl() {
    this.detailUrl = this.urlHelper.getDetailPageRoute(this.translate.instant('Routes.Detail.ListingDetail'), this.listing.City, this.listing.Id, this.listing.Title);
  }
}