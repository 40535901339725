import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LangSwitch } from '../../../services/lang-switch.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {

  currentLang: string = '';

  constructor(
    public translate: TranslateService,
    private title: Title,
    private langSwitch: LangSwitch    
  ){
    this.langSwitch.setPrivacyPolicy()
    this.currentLang = translate.currentLang;
    this.translate.get("PrivacyPolicy").subscribe((x : string) => {
      
      this.title.setTitle(x);
    });
  }
}
