<li class="border border-gray-200 rounded-2 shadow-small mb-8 xs:blog-horizontal__item">
    <div class="border-gray-200 border-t p-4">
        <a [routerLink]="detailUrl" class="card__heading"
            [trackingAction]="customTrackingAction || 'CompetitorClick'" 
            [trackingCategory]="customTrackingCategory || 'DETAIL'"
            [trackingListingId]="listing?.Id">

            <!-- hide logo since they think we should not display it -->
            <ng-container *ngIf="showLogo">
                <div class="flex flex-col items-center p-4" *ngIf="listing?.Logo?.path !== null">
                    <div class="flex font-bold items-center justify-center w-full">
                        <img [attr.src]="listing?.Logo?.path" [attr.alt]="listing?.Title + ' Logo'" itemprop="logo"
                                style="height: 73px; overflow: hidden; white-space: normal;">
                    </div>
                </div>
            </ng-container>

            <p class="font-bold">
                {{ listing.Title }}
            </p>

            <div class="font-normal text-gray-400 text-sm">
                <span>{{ listing.Zip }} {{ listing.City }}</span>
            </div>

            <span class="inline-block mt-4 btn btn--outline py-0.5"
                [trackingAction]="customTrackingAction || 'CompetitorClick'"
                [trackingCategory]="customTrackingCategory || 'DETAIL'"
                [trackingListingId]="listing?.Id">{{ 'Contact' | translate }}</span>
        </a>

    </div>
</li>