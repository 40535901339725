import { Component, ElementRef, HostListener, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BannerService } from '../../services/banner.service';
import { CheckDeviceService } from '../../services/check-device.service';
import { SearchResults } from '../../services/share-data.service';

declare global {
  interface Window {
      reviveAsync: any;
  }
}

@Component({
  selector: 'app-top-banners',
  templateUrl: './top-banners.component.html',
  styleUrls: ['./top-banners.component.scss']
})
export class TopBannersComponent implements OnInit {
  @ViewChildren('desktopTopBanner') 
  desktopTopBanners!: QueryList<ElementRef>;
  @ViewChildren('mobileTopBanner') 
  mobileTopBanner!: QueryList<ElementRef>;

  observers: MutationObserver[] = [];
  @ViewChildren('resizeBanner') 
  observingElements: QueryList<ElementRef>;
  
  @Input('searchResults$') 
  searchResults$: Observable<SearchResults>;
  adKey: string = "";
  adId: string = environment.AdRevive.id;
  
  topBannerIds: string[] = [];
  topBannerMobileId = "";

  constructor(
    private translate: TranslateService,
    public checkDeviceService: CheckDeviceService,
    private bannerService: BannerService,
  ) {}

  ngOnInit(): void {
    this.translate.get('init').subscribe(_ => {
      this.getTopBannerIds();
    });

    this.searchResults$.subscribe((data) => {
      this.adKey = data?.data?.advertsCodeInfo?.adKey || null;
    })
  }

  ngAfterViewInit(): void {
    this.bannerService.initMutationObservers(this.observers, this.observingElements);
    this.bannerService.refreshRevive();
  }

  ngOnDestroy() {
    this.observers.forEach(observer => observer.disconnect());
  }

  getRandomAd(ids: string[]): void {
    const randomIndex = Math.floor(Math.random() * ids.length);
    this.topBannerMobileId = ids[randomIndex];
  }

  getTopBannerIds() {
    for(let i = 0; i < 3; i++) {
      const topBannerId = this.translate.instant("banners.TOP_" + i);
      this.topBannerIds.push(topBannerId);
    }

    this.getRandomAd(this.topBannerIds);
  }
}