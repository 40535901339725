import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";

@Injectable({ providedIn: "root" })
export class TranslateTitleService {
    constructor(
        private title: Title,
        private translate: TranslateService,
    ) { }

    /**
   * Sets title via translate pipe.
   *
   * @param value - prop name from i18n json
   */
    setBasicTitle(value: string) {
        this.translate.get(value).subscribe((x: string) => {
            this.title.setTitle(x);
        });
        this.translate.onLangChange.subscribe(x => {
            this.translate.get(value).subscribe((x: string) => {
                this.title.setTitle(x);
            });
        })
    }

    setDetailPageTitle(title: string, location: string, category: string) {
        let sb: string[] = [];

        if (title && title.trim()) {
            sb.push(title.trim());
        }

        if (location && location.trim()) {
            sb.push(location.trim());
        }

        if (category && category.trim()) {
            sb.push(category.trim());
        }

        let result = sb.join(' - ');

        if (result.length > 75) {
            this.title.setTitle(result.substring(0, 70) + '...')
        }

        const hostname = ' | ' + document.location.host;

        if ((hostname.length + result.length) > 75) {
            this.title.setTitle(result);
        }

        this.title.setTitle(result + hostname);
    }

    public setResultPageTitle(what: string, where: string) {
        let sb: string[] = [];

        if (what && what.trim()) {
            sb.push(what.trim());
        }

        if (where && where.trim()) {
            if (sb.length > 0) {
                sb.push(' - ');
            }
            sb.push(where.trim());
        }

        let result = sb.join('');

        if (result.length > 75) {
            this.title.setTitle(result.substring(0, 70) + '...')
        }

        const hostname = ' | ' + document.location.host;

        if ((hostname.length + result.length) > 75) {
            this.title.setTitle(result);
        }
        this.title.setTitle(result + hostname);
    }
}