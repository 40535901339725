<footer id="footer" class="bg-gray-300">
    <div class="yp-container pb-20 pt-10">
        <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
            <div class="flex flex-col md:col-span-2">
                <h4 class="mb-3 text-xl font-medium">{{'OurProductsAndServices' | translate}}</h4>
                <ul class="">
                    <li *ngFor="let product of this.products">
                        <a href="{{product.url | translate}}" class="hover:underline text-blue-200 text-sm" target="_blank" rel="noopener noreferrer">{{product.title | translate}}</a>
                    </li>
                </ul>
            </div>
            <div class="flex flex-col">
                <div class="flex flex-col mb-4">
                    <h4 class="mb-3 text-xl font-medium">{{ 'OurOrganization' | translate }}</h4>
                    <ul class="">
                        <li>
                            <a href="{{'ExternalLinks.AboutUs' | translate}}" class="hover:underline text-blue-200 text-sm" target="_blank" rel="noopener noreferrer">{{'AboutUs' | translate}}</a>
                        </li>
                        <li>
                            <a href="{{'TermsAndConditionsPDF' | translate}}" class="hover:underline text-blue-200 text-sm" target="_blank" rel="noopener noreferrer">{{'TermsAndConditions' | translate }}</a>
                        </li>
                        <li>
                            <a href="{{'PrivacyPolicyPDF' | translate}}" class="hover:underline text-blue-200 text-sm" target="_blank" rel="noopener noreferrer">{{'PrivacyPolicy' | translate}}</a>
                        </li>
                        <li>
                            <a [routerLink]="'Routes.Static.CookiePolicy' | translate" class="hover:underline text-blue-200 text-sm">{{'CookiePolicy' | translate}}</a>
                        </li>
                        <li>
                            <a [routerLink]="'Routes.Static.TermsAndConditions' | translate"  class="hover:underline text-blue-200 text-sm">{{'TermsOfUse' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="'Routes.Static.ReviewPolicy' | translate" class="hover:underline text-blue-200 text-sm">{{'ReviewPolicy' | translate}}</a>
                        </li>
                    </ul>
                </div>
                <div class="flex flex-col">
                    <h4 class="mb-3 text-xl font-medium">{{'ForBusiness' | translate}}</h4>
                    <ul class="">
                        <li>
                            <a href="{{'ExternalLinks.CustomerService' | translate}}" class="hover:underline text-blue-200 text-sm" target="_blank" rel="noopener noreferrer">{{'CustomerService' | translate}}</a>
                        </li>
                        <li>
                            <a routerLink="{{'Routes.Static.AddYourCompany' | translate}}" class="hover:underline text-blue-200 text-sm" target="_blank" rel="noopener noreferrer">{{'AddYourCompany' | translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            
            <div class="flex flex-col">
                <h4 class="mb-3 text-xl font-medium">{{'Search' | translate}}</h4>
                <ul class="">
                    <li>
                        <a routerLink="{{'Routes.Index.CompaniesIndex' | translate}}" class="hover:underline text-blue-200 text-sm">{{'CompanyIndex' | translate}}</a>
                    </li>
                    <li>
                        <a routerLink="{{'Routes.Index.CategoryIndex' | translate}}/A/1" class="hover:underline text-blue-200 text-sm">{{'CategoryIndex' | translate}}</a>
                    </li>
                    <li>
                        <a routerLink="{{'Routes.Index.KeywordIndex' | translate}}/A/1" class="hover:underline text-blue-200 text-sm">{{'KeywordIndex' | translate}}</a>
                    </li>
                </ul>
            </div>

            <div class="flex flex-col">
                <h4 class="mb-3 text-xl font-medium whitespace-nowrap">FCR Media Belgium NV</h4>
                <p class="text-sm mb-1">Uitbreidingstraat 82<br>2600 Antwerpen</p>
                <a class="text-sm text-blue-200 hover:underline mb-1" href="//www.fcrmedia.be" target="_blank">www.fcrmedia.be</a>
            </div>
        </div>

        <div class="border-blue-600 border-t brder-t flex md:items-center md:flex-row mt-10 pt-10 text-blue-600 text-sm flex-col items-start">
            <!-- mobile language -->
            <ul class="block mb-4 md:hidden md:mb-0">                      
                <app-language-switch></app-language-switch>
            </ul>
            
            <!-- fcr logo -->
            <a href="{{'Fcrmedia' | translate}}" class="mb-4 md:mb-0" target="_blank" rel="noopener noreferrer" [ariaLabel]="'Fcrmedia' | translate">
                <app-icon [icon]="'fcr-footer-logo'" svgClass="mr-5 w-24 h-10"></app-icon>
            </a>

            <!-- message -->
            <div class="text-sm text-blue-600">
                <p class="mb-0">{{ getFooterMessage() }}</p>
            </div>

            <!-- bottom -->
            <div class="md:ml-auto flex border-blue-600 border-t items-center items-start md:flex-row mt-2 pt-4 text-blue-600 text-sm w-full md:border-0 md:mt-0 md:pt-0 md:w-auto">
                <p class="text-sm text-blue-600 mr-3 mb-0">{{'SocialTitle' | translate}}</p>
                <div class="flex">
                    <a href="https://www.facebook.com/FCRMediaBelgium/" class="bg-blue-600 mr-3 p-2 rounded-full w-8 h-8" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                        <app-icon [icon]="'facebook-icon'" svgClass="w-4 h-4" svgFill="#fff"></app-icon>
                    </a>
                    <a href="https://www.linkedin.com/company/fcr-media-belgium/" class="bg-blue-600 mr-3 p-2 rounded-full w-8 h-8" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                        <app-icon [icon]="'linkedin-icon'" svgClass="w-4 h-4" svgFill="#fff"></app-icon>
                    </a>
                    <a href="https://www.instagram.com/fcrmedia.be/" class="bg-blue-600 mr-3 p-2 rounded-full w-8 h-8" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                        <app-icon [icon]="'instagram-icon'" svgClass="w-4 h-4" svgFill="#fff"></app-icon>
                    </a>
                    <a href="https://www.youtube.com/channel/UCk0ZN8F86873c0pt560U9ow/videos" class="bg-blue-600 mr-3 p-2 rounded-full w-8 h-8" target="_blank" rel="noopener noreferrer" aria-label="Youtube">
                        <app-icon [icon]="'youtube-icon'" svgClass="w-4 h-4" svgFill="#fff"></app-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>