import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OpeningType } from '../enums/opening-type.enum';
import { SearchSort } from '../enums/search-sort.enum';
import { SearchArguments, SearchType } from '../models/search-arguments.model';

export interface SearchStateValues {
  what: string;
  where: string;
  page: number;
  geoLocation: string;
  sort: SearchSort;
  openingType: OpeningType;
  searchType: SearchType;
}

@Injectable({
  providedIn: 'root',
})
export class SearchStateService {
  private whatSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private whereSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private geoLocationSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private sortSubject: BehaviorSubject<SearchSort> = new BehaviorSubject<SearchSort>(null);
  private openingTypeSubject: BehaviorSubject<OpeningType> = new BehaviorSubject<OpeningType>(null);
  private searchTypeSubject: BehaviorSubject<SearchType> = new BehaviorSubject<SearchType>(SearchType.Dynamic);
  private eshopSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  setDefaults() {
    this.setWhat(null);
    this.setWhere(null);
    this.setSearchType(SearchType.Dynamic);

    this.setFilterDefaults();
  }

  setFilterDefaults() {
    this.setPage(0);
    this.setSort(null);
    this.setOpeningType(null);
    this.setShopNow(false);
  }

  setWhat(value: string) {
    this.whatSubject.next(value);
  }

  getWhat(): Observable<string> {
    return this.whatSubject.asObservable();
  }

  setWhere(value: string) {
    this.whereSubject.next(value);
  }

  getWhere(): Observable<string> {
    return this.whereSubject.asObservable();
  }

  setPage(value: number) {
    this.pageSubject.next(value);
  }

  getPage(): Observable<number> {
    return this.pageSubject.asObservable();
  }

  setSort(value: SearchSort) {
    this.sortSubject.next(value);
  }

  getSort(): Observable<SearchSort> {
    return this.sortSubject.asObservable();
  }

  setGeoLocation(value: string) {
    this.geoLocationSubject.next(value);
  }

  getGeoLocation(): Observable<string> {
    return this.geoLocationSubject.asObservable();
  }

  setOpeningType(value: OpeningType) {
    this.openingTypeSubject.next(value);
  }

  getOpeningType(): Observable<OpeningType> {
    return this.openingTypeSubject.asObservable();
  }

  setShopNow(value: boolean) {
    this.eshopSubject.next(value);
  }

  getShopNow(): Observable<boolean> {
    return this.eshopSubject.asObservable();
  }

  setSearchType(value: SearchType) {
    this.searchTypeSubject.next(value);
  }

  getSearchType(): Observable<SearchType> {
    return this.searchTypeSubject.asObservable();
  }

  getCurrentValues(): SearchArguments {
    const searchArgs = new SearchArguments();
    searchArgs.what = this.whatSubject.value;
    searchArgs.where = this.whereSubject.value;
    searchArgs.page = this.pageSubject.value;
    searchArgs.geoLocation = this.geoLocationSubject.value;
    searchArgs.sort = this.sortSubject.value;
    searchArgs.openingType = this.openingTypeSubject.value;
    searchArgs.searchType = this.searchTypeSubject.value;
    searchArgs.eshop = this.eshopSubject.value

    return searchArgs;
  }
}