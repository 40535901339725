import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { BannerService } from './services/banner.service';
import { HeaderScriptsService } from './services/header-scripts.service';
import { LangTagService } from './services/lang-tag.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { MetaHelper } from './helpers/meta.helper';
import * as Sentry from "@sentry/browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private translate: TranslateService,
    private langTagService: LangTagService,
    private headerScript: HeaderScriptsService,
    private bannerService: BannerService,
    private router: Router,
    private route: ActivatedRoute,
    private metaHelper: MetaHelper
  ) {
    let domain = document.location.hostname;

    if(domain.includes("goldenpages"))
      this.translate.use('en');
    else if(domain.includes("goudengids"))
      this.translate.use('nl');
    else if(domain.includes("pagesdor"))
      this.translate.use('fr');
    else
      this.translate.use('nl');
  }

  ngOnInit() {
    Sentry.init({
      dsn: environment.Sentry.dsn,
      environment: environment.EnvironmentName,
      tracesSampleRate: environment.Sentry.tracesSampleRate,
      replaysSessionSampleRate: environment.Sentry.replaysSessionSampleRate,
      replaysOnErrorSampleRate: environment.Sentry.replaysOnErrorSampleRate,
      enabled: environment.Sentry.isEnabled,
      integrations: [Sentry.replayIntegration()]
    })

    this.langTagService.setLanguage(this.translate.currentLang);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.redirectIfNeeded(document.location.toString());
      }
    });

    // add dataLayer
    this.headerScript.insertDataLayer(environment.CookieScript.url, environment.GoogleAnalytics[this.translate.currentLang]);

    // add GTM
    this.headerScript.insertGoogleTagManager(environment.GoogleTagManager.id);

    // add clarity heatmap
    this.headerScript.insertClarityScript(environment.Clarity[this.translate.currentLang]);

    // add adrevive to body
    this.bannerService.loadBannerScript(environment.AdRevive.url);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        let currentRoute = this.route.root;
        while (currentRoute.children.length > 0) {
          currentRoute = currentRoute.children[0];
        }
        const currentComponent = currentRoute.snapshot.component.name;
        if(currentComponent != "_SearchComponent")
          this.metaHelper.removeCanonicalTag()
      });
  }

  ngOnDestroy(): void {
    Sentry.init({
      enabled: false
    })

    Sentry.close()
  }

  private redirectIfNeeded(url: string) {
    const encodedUrl = this.encodeSpecialCharacters(url);

    console.log('Original URL:', url);
    console.log('Encoded URL:', encodedUrl);

    if (url !== encodedUrl) {
      window.location.href = encodedUrl;
    }
  }

  private encodeSpecialCharacters(url: string): string {
    return url.replaceAll(/\(/g, '%28').replaceAll(/\)/g, '%29').replaceAll(/\+/g, ' ').replace(/\bq_|qn_/g, '');
  }
}