import { Component, Input, OnInit } from '@angular/core';
import { SpecialActions } from '../../../../enums/special-actions.enum';
import { SpecialActionsHelper } from '../../../../helpers/special-actions.helper';
import { ListingModel } from '../../../../models/listing.model';
import { OpeningHours } from '../../../../models/opening-hours.model';
import { DetailPageType, Poi } from '../../../../services/share-data.service';

@Component({
  selector: 'app-oh-and-map',
  templateUrl: './oh-and-map.component.html',
  styleUrl: './oh-and-map.component.scss'
})
export class OhAndMapComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input('pois')
  pois: Poi[] = [];
  
  @Input('detailPageType')
  detailPageType: DetailPageType;
  
  oh: OpeningHours;
  
  @Input()
  open: boolean = false;

  @Input()
  title: string;
  
  isOh: boolean = false;

  disableMap: boolean = false;
  
  constructor(private specialActionsHelper: SpecialActionsHelper){}

  ngOnInit() {
    this.showOh();
    this.disableMap = this.specialActionsHelper.hasAction(this.listing, SpecialActions.DisableParkingInfo);
  }

  showOh() {
    this.isOh = this.listing?.OpeningHours?.hours.length > 0;
  }
}
