import { Component, Input } from '@angular/core';
import { SpecialActionsStatus } from '../../../helpers/special-actions.helper';
import { ListingModel } from '../../../models/listing.model';
import { Badge, DetailPageType, Poi } from '../../../services/share-data.service';

@Component({
  selector: 'app-traffic-page',
  templateUrl: './traffic-page.component.html',
  styleUrl: './traffic-page.component.scss'
})
export class TrafficPageComponent {
  @Input('listing')
  listing: ListingModel;

  @Input('detailPageType')
  detailPageType: DetailPageType;

  @Input('pois')
  pois: Poi[] = [];

  @Input('badges')
  badges: Badge[] = [];

  @Input('requestForms')
  requestForms;

  @Input('showTrafficDescription')
  showTrafficDescription;

  @Input('specialActions')
  specialActions: SpecialActionsStatus;
}