export function isValid(hours: Hours){
        if(hours == null || hours == undefined){
            return false;
        }  
        if(hours?.closing == null || hours.closing == undefined){
            return false;
        } 
        if(hours?.opening == null || hours.opening == undefined){
            return false;
        } 
        if(hours.opening == hours.closing){
            return false;
        }
        return true;
}

export class OHStatusModel {
    public CommentedOpeningHours: CommentedOpeningHoursStatus = new CommentedOpeningHoursStatus();
    public CurrentStatus: string = '';
}

export class OpeningHours {
    note: string = "";
    hours: Hours[] = [];
    key: string = "";
    text: string = "";

}

export class Hours {
    dow: DayOfWeek = DayOfWeek.Monday;
    closing: OpeningHoursTimeSpan = new OpeningHoursTimeSpan(0,0);
    opening: OpeningHoursTimeSpan = new OpeningHoursTimeSpan(0,0);
    comment?: string = "";

    constructor(_dow?: DayOfWeek, _closing?: OpeningHoursTimeSpan, _opening?: OpeningHoursTimeSpan, _comment?: string){
        this.dow = _dow ?? DayOfWeek.Monday;
        this.closing = _closing ?? new OpeningHoursTimeSpan(0,0);
        this.opening = _opening ?? new OpeningHoursTimeSpan(0,0);
        this.comment = _comment ?? '';
    }
}

export class OpeningHoursStatus {
    times: OpeningHoursTime[] = [];
    day: DayOfWeek = DayOfWeek.Monday;
}

export class CurrentOpeningHoursStatus extends OpeningHoursStatus {
    isCurrentlyOpen: boolean = false;
    isClosingSoon: boolean = false;
    isClosingSoonTime: OpeningHoursTime | null = null;
    isOpeningSoon: boolean = false;
}

export class CommentedOpeningHoursStatus extends CurrentOpeningHoursStatus {
    comment: string = "";
}

export class OpeningHoursTime {
    from: OpeningHoursTimeSpan | null = null;
    to: OpeningHoursTimeSpan | null = null;

    constructor(_from:any, _to:any){
        this.from = _from;
        this.to = _to;
    }
}

export class OpeningHoursTimeSpan {
    hours: number = 0;
    minutes: number = 0;

    constructor(hours: number, minutes: number) {
        this.hours = hours;
        this.minutes = minutes;
    }

    static parse(value: string): OpeningHoursTimeSpan {
        const match = value.match(/(\d+):(\d+)/);
        if (match) {
            const hr = parseInt(match[1]);
            const mn = parseInt(match[2]);
            return new OpeningHoursTimeSpan(hr, mn);
        }
        return new OpeningHoursTimeSpan(0, 0);
    }

    toString(): string {
        return `${this.hours}:${this.minutes < 10 ? '0' : ''}${this.minutes}`;
    }
}

export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday
}
