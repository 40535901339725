
    <ul class="text-gray-400">
        <li class="flex mb-2.5 localities">
            <div class="flex">
                <app-icon [icon]="'locations-icon'" svgClass="h-5 pt-0.5 self-start w-5 mr-2"></app-icon>
            </div>
            <!-- TODO: open modal with addresses -->
            <a class="hover:no-underline text-blue-200 underline cursor-pointer modal" (click)="openModalTemplate(folderModal)"
                id="openMap" 
                [trackingAction]="'MapClick'" 
                [trackingCategory]="'DETAIL'" 
                [trackingListingId]="listing?.Id"
                title="map">
                <span>
                    {{ 'AllAddresses' | translate }}
                </span>
                <span class="localities-count">
                    ({{ addressCount }})
                </span>
            </a>
        </li>
    </ul>

    <ng-template #folderModal>
        <div class="md:grid grid-cols-2 h-full">

            <!-- map -->
            <div id="folder-map" class="h-full order-last z-40">
                <app-map 
                    *ngIf="pois" 
                    [id]="'modal-map'" 
                    [mode]="'modal'" 
                    [mapType]="'folder'" 
                    [detailPageType]="detailPageType"
                    [pois]="pois">
                </app-map>
            </div>

            <!-- folder addresses -->
            <div
                class="absolute bottom-0 h-72 md:h-full md:p-8 md:pr-0 md:static md:w-auto overflow-hidden p-0 pr-0 w-full z-50">
                <div id="map-results-holder" class="h-full md:pb-0 md:pl-0 md:pr-3 md:pt-0 overflow-hidden pr-0">
                    <h3 class="border-b hidden mb-4 md:block pb-4 text-blue-600 text-xl">
                        {{ 'AllCompaniesFromFolder' | translate }} <span class="font-bold">{{ folderTitle }}</span>
                    </h3>

                    <div id="map-results"
                        class="custom-bar md:h-60-title overflow-x-scroll md:overflow-x-hidden md:overflow-y-scroll pb-8 md:pb-0">
                        <ol id="folder-child-addresses" class="flex gap-3 md:flex-col md:pr-3 result-items initialized">

                            <li *ngFor="let branch of addresses; let index = index;"
                                (click)="handleSelectChild(branch.id)"
                                [attr.data-index]="index"
                                class="folder-item md:border md:rounded-0 md:shadow-small">
                                <div class="flex justify-between mb-3">
                                    <h2 class="font-bold">{{ branch.title }}</h2>
                                </div>
                                <div class="flex justify-between">
                                    <div class="flex mb-2.5">
                                        <div class="flex">
                                            <app-icon [icon]="'poi-icon'"
                                                [svgClass]="'h-5 pt-0.5 self-start w-5 mr-2 text-blue-600'"></app-icon>
                                        </div>
                                        <span>
                                            <span data-yext="postal-code">{{ branch.zip }} </span>
                                            <span data-yext="city">{{ branch.city }}</span>
                                            <br>
                                            <span data-yext="street">{{ branch.address }}</span>
                                        </span>
                                    </div>
                                    <span
                                        class="absolute bottom-0 btn btn--filled left-0 md:mb-0 mb-3 md:static mt-auto md:ml-0 ml-19">
                                        {{ 'Contact' | translate }}
                                    </span>
                                </div>
                            </li>

                        </ol>
                    </div>

                </div>
            </div>

        </div>
    </ng-template>