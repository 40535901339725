<section id="GO__payment-methods" class="border-t mb-10 mt-4" *ngIf="listing?.SpokenLanguages.length > 0">
    <h2 class="font-medium mb-4 pt-8 text-1xl">
        {{ 'SpokenLanguages' | translate }}
    </h2>
    <ul class="spoken-languages flex flex-wrap" data-yext="payment-methods">
        <ng-container *ngFor="let spokenLanguage of listing?.SpokenLanguages">
            <!-- spoken languages -->
            <li class="spoken-language" 
                [title]="translateLanguages[spokenLanguage]" 
                *ngIf="languages.hasOwnProperty(spokenLanguage); else elseUnknownLanguage">
                <img class="spoken-language__icon"
                        src="assets/images/spoken_languages_icons/{{ spokenLanguage }}.png"
                        [alt]="translateLanguages[spokenLanguage]"
                        [title]="translateLanguages[spokenLanguage]" />
                <span class="spoken-language__label">{{ translateLanguages[spokenLanguage] }}</span>
            </li>

            <!-- unknown spoken language -->
            <ng-template #elseUnknownLanguage>
                <li class="spoken-language" 
                    [title]="spokenLanguage">
                    <span class="spoken-language__icon"></span>
                    <span class="spoken-language__label">{{ spokenLanguage }}</span>
                </li>
            </ng-template>
        </ng-container>
    </ul>
</section>