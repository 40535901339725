import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrl: './rating.component.scss'
})
export class RatingComponent {
  @Input() 
  class: string;
  
  @Input() 
  ratingAverage: string;

  getStarClass(index: number): string {
    const rating = parseFloat(this.ratingAverage) - index;
    return rating > 0.95 ? 'text-blue-200' : 'text-gray-100';
  }
}