import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ListingDetailViewModel } from '../../../models/listing-detail-results.model';
import { ListingModel } from '../../../models/listing.model';
import { SearchArguments } from '../../../models/search-arguments.model';
import { ShareDataService } from '../../../services/share-data.service';

declare var google: any;
declare var Wkt: any;

interface VisualizerData {
  headingRadius?: number,
  normalizedSearch?: boolean,
  circleLat?: number,
  circleLng?: number,
  wkt?: string
}

interface PolygonsData {
  searchLatitude?: any,
  searchLongitude?: any,
  normalizedSearch?: any,
  locationRadius?: any,
  locationWkt?: any,
  headingRadius?: any,
  zones?: any
}

enum PolygonTypes {
  CIRCLE = "CIRCLE",
  POLYGON = "POLYGON",
  MULTIPOLYGON = "MULTIPOLYGON"
}

type PolygonType = PolygonTypes.CIRCLE | PolygonTypes.POLYGON | PolygonTypes.MULTIPOLYGON;

@Component({
  selector: 'app-visualizer',
  templateUrl: './visualizer.component.html',
  styleUrl: './visualizer.component.scss'
})
export class VisualizerComponent implements OnInit {
  listing$: Observable<ListingDetailViewModel>;
  listing: ListingModel;
  listingForm: FormGroup;

  private gmap: any;
  private features: any[] = [];

  constructor(
    private renderer: Renderer2,
    private fb: FormBuilder,
    private shareDataService: ShareDataService,
    private route: ActivatedRoute) { 
      this.listingForm = this.fb.group({
        listingId: ['']
      });
    }

  ngOnInit(): void {
    this.initScriptAndMap();
  }

  private handleParams() {
    this.route.queryParams.subscribe(params => {
      console.log(params);

      if (params.hasOwnProperty('listingId')) {
        this.listingForm.get('listingId')?.setValue(params['listingId']);
        this.loadData(params)
      } else {
        this.gmap = this.initMap(params);
      }
    });
  }

  private initScriptAndMap() {
    const GOOGLE_MAP_API_KEY = environment?.GoogleMap?.apiKey;
    this.loadScript(`https://maps.googleapis.com/maps/api/js?libraries=drawing&key=${GOOGLE_MAP_API_KEY}`)
      .then(() => this.loadScript('assets/js/Wicket/wicket.js'))
      .then(() => this.loadScript('assets/js/Wicket/wicket-gmap3.js'))
      .then(() => this.handleParams())
      .catch(error => console.log('Error loading scripts:', error));
  }

  private loadScript(src: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.src = src;
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Script load error for ${src}`));
      this.renderer.appendChild(document.body, script);
    });
  }

  private initMap(params: VisualizerData) {
    const iconsPath = "assets/images/wicket";
    const mapOptions = {
      center: new google.maps.LatLng(30, 10),
      styles: {
        zone: {
          icon: new google.maps.MarkerImage(
            iconsPath + "/map_zone.png",
            new google.maps.Size(16, 16),
            new google.maps.Point(0, 0),
            new google.maps.Point(8, 8),
            new google.maps.Size(16, 16)
          ),
          strokeColor: '#990000',
          fillColor: '#990000',
          fillOpacity: 0.2
        },
        centre: {
          icon: new google.maps.MarkerImage(
            iconsPath + "/map_center.png",
            new google.maps.Size(16, 16),
            new google.maps.Point(0, 0),
            new google.maps.Point(8, 8),
            new google.maps.Size(16, 16)
          )
        },
        heading: {
          strokeColor: '#009900',
          fillColor: '#009900',
          fillOpacity: 0.1
        },
        location: {
          strokeColor: '#000099',
          fillColor: '#000099',
          fillOpacity: 0.1
        }
      },
      disableDefaultUI: true,
      mapTypeControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
      },
      panControl: false,
      streetViewControl: false,
      zoom: 2,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
        style: google.maps.ZoomControlStyle.SMALL
      }
    };

    const gmap = new google.maps.Map(document.getElementById('canvas'), mapOptions);

    google.maps.event.addListener(gmap, 'tilesloaded', () => {
      console.log("gmap loaded");

      const data: PolygonsData = {
        searchLatitude: params['circleLat'] || null,
        searchLongitude: params['circleLng'] || null,
        normalizedSearch: params['normalizedSearch'] || null,
        locationRadius: null,
        locationWkt: params['wkt'] || null,
        headingRadius: params['headingRadius'] || null,
        zones: this.handleZones(params['normalizedSearch'] || false)
      };

      console.log("zones", data?.zones);

      console.log(!this.gmap.loaded);

      if (!this.gmap.loaded) {
        this.gmap.loaded = true;
        this.handlePolygons(data);
      }
    });

    return gmap;
  }

  private handlePolygons(data: PolygonsData) {
    const { searchLatitude, searchLongitude, normalizedSearch, locationRadius, locationWkt, headingRadius, zones } = data;

    // Add zones
    zones.forEach(wkt => {
      this.mapIt(this.gmap.styles.zone, wkt);
    });

    // Add centre point if searchLatitude and searchLongitude are provided
    if (searchLatitude != null && searchLongitude != null) {
      this.mapIt(this.gmap.styles.centre, `POINT(${searchLongitude} ${searchLatitude})`);

      // Draw heading circle
      if (!normalizedSearch && headingRadius != null) {
        this.drawCircle(this.gmap.styles.heading, new google.maps.LatLng(searchLatitude, searchLongitude), headingRadius);
      }

      // Draw location circle
      if (normalizedSearch && locationRadius != null) {
        this.drawCircle(this.gmap.styles.location, new google.maps.LatLng(searchLatitude, searchLongitude), locationRadius);
      }
    }

    // Add location WKT if not normalized search
    if (!normalizedSearch && locationWkt != null) {
      this.mapIt(this.gmap.styles.location, locationWkt);
    }
  }

  private handleZones(normalizedSearch: boolean): string[] {
    const NationalZoningWkt = "POLYGON((2.40875244140625 51.07381659142035,3.26568603515625 51.38683417450748,3.84246826171875 51.273568341650304,4.42474365234375 51.47245542437112,4.77081298828125 51.53058624960701,5.13336181640625 51.47929819381273,5.26519775390625 51.31822161619772,5.58380126953125 51.32508749616024,5.91888427734375 51.142794261866825,5.75958251953125 50.82117385498326,6.13861083984375 50.77603858500294,6.31439208984375 50.63340322556774,6.45172119140625 50.33631235861487,6.14410400390625 50.114909424050666,5.97930908203125 50.11843178582313,5.89141845703125 49.934925228909876,5.94635009765625 49.508769992832654,5.45745849609375 49.494500371097004,4.80926513671875 49.80747134215085,4.18853759765625 49.95260056039561,4.03472900390625 50.04440776173312,4.10064697265625 50.24506427166955,3.74359130859375 50.287200516679526,3.27392578125 50.51479099437404,2.76580810546875 50.72390524831903,2.54058837890625 50.845459407458236,2.40875244140625 51.07381659142035))";

    let multiPolygons: string[] = [];
    let polygons: string[] = [];
    let circles: string[] = [];

    let zones: string[] = [];
      
    if (this.listing != null) {
      if (this.listing.ZoningFlag == "N") {
        zones = [NationalZoningWkt];
      }

      if (this.listing?.GeoLocation.length > 0) {
        zones = [...zones, this.listing?.GeoLocation?.[0]];
      }

      const listingOrGoogleGeoZoning = normalizedSearch ? this.listing?.GeoZoningGoogle : this.listing?.GeoZoning;

      multiPolygons = this.filterPolygon(listingOrGoogleGeoZoning, PolygonTypes.MULTIPOLYGON); 
      polygons = this.filterPolygon(this.listing?.GeoZoning, PolygonTypes.POLYGON);
      const circlePolygons = this.filterPolygon(listingOrGoogleGeoZoning, PolygonTypes.CIRCLE);
      circles = circlePolygons.map(wkt => new Circle(wkt).toWktPolygon())

      zones = [...zones, ...multiPolygons, ...polygons, ...circles];
    }

    return zones;
  }

  private filterPolygon(polygonsArray: string[], polygonType: PolygonType) {
    return polygonsArray.filter(polygon => polygon.startsWith(polygonType));
  }

  private mapIt(style: any, wktValue: string) {
    console.log('mapIt');
    const wkt = new Wkt.Wkt();
    let obj;

    try {
      wkt.read(wktValue);
    } catch (e1) {
      try {
        wkt.read(wktValue.replace('\n', '').replace('\r', '').replace('\t', ''));
      } catch (e2) {
        if (e2.name === 'WKTError') {
          alert('Wicket could not understand the WKT string you entered. Check that you have parentheses balanced, and try removing tabs and newline characters.');
          return;
        }
      }
    }

    obj = wkt.toObject(style);

    if (obj.setEditable) {
      obj.setEditable(false);
    }

    if (Array.isArray(obj)) {
      for (const i in obj) {
        if (obj.hasOwnProperty(i) && !Array.isArray(obj[i])) {
          obj[i].setMap(this.gmap);
          this.features.push(obj[i]);
        }
      }
      this.features = this.features.concat(obj);
    } else {
      obj.setMap(this.gmap);
      this.features.push(obj);
    }

    const beBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(51.618865, 2.610009),
      new google.maps.LatLng(49.475070, 6.323227)
    );

    this.gmap.fitBounds(beBounds);

    return obj;
  }

  private drawCircle(style: any, center: any, radius: number) {
    console.log('drawCircle');
    const obj = new google.maps.Circle({
      strokeColor: style.strokeColor,
      strokeOpacity: style.strokeOpacity || 1,
      strokeWeight: style.strokeWeight || 2,
      fillColor: style.fillColor,
      fillOpacity: style.fillOpacity,
      map: this.gmap,
      center: center,
      radius: radius * 1000,
    });

    obj.setMap(this.gmap);
    this.features.push(obj);

    return obj;
  }

  private loadData(params: VisualizerData) {
    const listingId = params['listingId'];
    if (!listingId) {
      return;
    }

    const req = new SearchArguments();
    req.id = listingId;

    this.shareDataService.fetchDetail(req).subscribe(data => {
      console.log("data", data);
      this.listing = data?.listing;
      this.gmap = this.initMap(params);
    })
  }

  public listingKeyPress(event: KeyboardEvent) {
    const listingId = this.listingForm.get('listingId')?.value;
    if (event.key === 'Enter' && listingId) {

      let url = document.location.toString();
      url = url.replace(/[&]?listingId=(\w+)/i, '');
      if (!url.includes('?')) {
        url += "?";
      }
      url += '&listingId=' + listingId;

      window.location.href = url;

      return false;
    }
    return true;
  }
}

// Circle
class GeoLocation {
  Latitude: number;
  Longitude: number;

  constructor(latitude: number, longitude: number) {
      this.Latitude = latitude;
      this.Longitude = longitude;
  }
}

class GeoLocationCircle {
  Center: GeoLocation;
  Radius: number;

  constructor(center: GeoLocation, radius: number) {
      this.Center = center;
      this.Radius = radius;
  }
}

class SearchConstants {
  static readonly KmAsDegree = 0.008993202039;
}

class Circle {
  private static readonly EARTH_RADIUS = 6378137;

  Location: { Center: GeoLocation, Radius: number };

  constructor(wkt: string) {
    this.Location = this.parseWktCircle(wkt);
  }

  parseWktCircle(wkt: string): GeoLocationCircle {
    const circleRegex = /Circle\(([\d\.]+) ([\d\.]+) d=([\d\.]+)\)/;
    const matches = wkt.match(circleRegex);

    if (!matches || matches.length !== 4) {
        throw new Error("WKT for a circle is incorrect.");
    }

    // Convert values from match groups to numbers
    const groups = matches.slice(1).map(value => parseFloat(value));

    const radiusInKm = Math.round(groups[2] / SearchConstants.KmAsDegree * 10) / 10;
    return new GeoLocationCircle(new GeoLocation(groups[1], groups[0]), radiusInKm);
  }

  toWktPolygon(): string {
      const n = 64;
      const coordinates: GeoLocation[] = [];

      for (let i = 0; i < n; i++) {
          coordinates.push(
            Circle.sphereOffset(
              this.Location.Center.Latitude,
              this.Location.Center.Longitude,
              this.Location.Radius * 1000,
              (2 * Math.PI * i) / n
            )
          );
      }

      const coordinatesString = coordinates
          .map((c) => `${c.Longitude.toFixed(6)} ${c.Latitude.toFixed(6)}`)
          .join(", ");

      return `POLYGON((${coordinatesString}))`;
  }

  private static sphereOffset(cLat: number, cLon: number, distance: number, bearing: number): GeoLocation {
      const lat1 = Circle.toRadians(cLat);
      const lon1 = Circle.toRadians(cLon);
      const dByR = distance / Circle.EARTH_RADIUS;
      const lat = Math.asin(
          Math.sin(lat1) * Math.cos(dByR) +
          Math.cos(lat1) * Math.sin(dByR) * Math.cos(bearing)
      );
      const lon = lon1 + Math.atan2(
                          Math.sin(bearing) * Math.sin(dByR) * Math.cos(lat1),
                          Math.cos(dByR) - Math.sin(lat1) * Math.sin(lat)
                        );

      return {
          Latitude: Circle.toDegrees(lat),
          Longitude: Circle.toDegrees(lon)
      };
  }

  private static toDegrees(angleInRadians: number): number {
    return (angleInRadians * 180) / Math.PI;
  }

  private static toRadians(angleInDegrees: number): number {
    return (angleInDegrees * Math.PI) / 180;
  }
}
// Circle