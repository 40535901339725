<!-- TODO -->
<!-- <div class="text-sm text-blue-600 mb-4">
    fallback.Title
</div> -->
<div class="relative" *ngIf="group !== 'C'; else elseC">
    <div class="mb-2" *ngIf="results?.data?.debug !== null && showDebug === true">{{ group }}</div>
    <ol class="result-items flex flex-col gap-4" itemscope itemtype="http://schema.org/ItemList">
        <li app-result-item 
            *ngFor="let listing of groupResults; let index = index;"
            [listing]="listing"
            [index]="index"
            [debug]="results"
            [showWebshopForFree]="showWebshopForFree"
            class="result-item">
        </li>
    </ol>
    <!-- @Html.Partial("_OverlayLoader") -->
</div>
<ng-template #elseC>
    <div id="resultList-c" class="my-10">
        <div class="h-52 horizontal-list horizontal-list--under-list overflow-hidden">
            <div class="flex horizontal-list__items overflow-x-scroll">

                <app-result-item-c 
                    class="c-list-item item"
                    *ngFor="let listing of groupResults"
                    [listing]="listing">
                </app-result-item-c>

            </div>
        </div>
    </div>
</ng-template>